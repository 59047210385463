<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="game_over_modal_container">
      <div class="table_wr">
        <table>
          <tr>
            <th>
              <img
                :src="`/images/logger/GameOver.png`"
                alt="cup"
                @mouseenter="hint_label_hover($event, { msg: 'result' })"
                @mouseleave="resetHint"
              />
            </th>
            <th><span class="material-icons"> person </span></th>
            <th>
              <img
                :src="`/images/logger/SelectCard.png`"
                alt="house"
                @mouseenter="hint_label_hover($event, { msg: 'home' })"
                @mouseleave="resetHint"
              />
            </th>
            <th
              class="castles_overal"
              @mouseenter="hint_label_hover($event, { msg: 'castles_strongholds' })"
              @mouseleave="resetHint"
            >
              <img :src="`/images/castle.png`" alt="low_castle" />
              <img :src="`/images/stronghold.png`" alt="stronghold" />
            </th>
            <th>
              <img
                :src="`/images/stronghold.png`"
                alt="stronghold"
                @mouseenter="hint_label_hover($event, { msg: 'castles_extra' })"
                @mouseleave="resetHint"
              />
            </th>
            <th>
              <img
                :src="`/images/logger/AddLand.png`"
                alt="areas"
                @mouseenter="hint_label_hover($event, { msg: 'areas' })"
                @mouseleave="resetHint"
              />
            </th>
            <th>
              <img
                :src="`/images/logger/Supply.png`"
                alt="supply"
                @mouseenter="hint_label_hover($event, { msg: 'supply_track' })"
                @mouseleave="resetHint"
              />
            </th>
            <th>
              <img
                :src="`/images/logger/Power.png`"
                alt="power"
                @mouseenter="hint_label_hover($event, { msg: 'allow_tokens' })"
                @mouseleave="resetHint"
              />
            </th>
            <th>
              <img
                :src="`/images/logger/KingChoice.png`"
                alt="throne"
                @mouseenter="hint_label_hover($event, { msg: 'throne_track' })"
                @mouseleave="resetHint"
              />
            </th>
          </tr>
          <tr v-for="(obj, idx) in sorted_homes" :key="idx">
            <td class="win_place_wr">
              <span>
                {{ obj.round_when_leaved ? "" : idx + 1 }}
              </span>

              <span v-if="obj.earned"> /{{ obj.earned }} </span>
            </td>
            <td class="table_ava">
              <div class="ava_info" v-if="obj.id && obj.id !== user.id">
                <span
                  class="material-icons like_up"
                  :class="{ graded: checkGraded(obj, 'like') }"
                  @click="makeGrade(obj, 'like')"
                >
                  thumb_up
                </span>
                <span
                  class="material-icons like_down"
                  :class="{ graded: checkGraded(obj, 'dislike') }"
                  @click="makeGrade(obj, 'dislike')"
                >
                  thumb_down
                </span>
              </div>
              <div class="ava_img_wr">
                <img :src="`/images/avatars/${obj.avatars[0]}`" :alt="obj.nickname" class="avatar" />

                <span
                  v-if="obj.id && !obj.round_when_leaved && obj.full"
                  class="material-icons"
                  @mouseenter="hint_label_hover($event, { msg: 'full' })"
                  @mouseleave="resetHint"
                >
                  check_circle
                </span>
                <span
                  v-if="obj.id && obj.id !== user.id && !obj.role?.includes('admin')"
                  class="material-icons complaint_btn"
                  @mouseenter="hint_label_hover($event, { msg: 'hint_complaint' })"
                  @mouseleave="resetHint"
                  @click="openConfirmComplaintModal(obj)"
                >
                  report
                </span>
              </div>
              <span>{{ `${obj.name}` }}</span>
            </td>

            <td class="house_token"><img :src="`/images/tokens/${obj.home}_token.png`" :alt="obj.home" /></td>
            <td :class="{ make_diff: obj.key_flag === 'castles' }">{{ obj.castles }}</td>
            <td :class="{ make_diff: obj.key_flag === 'strongholds' }">{{ obj.strongholds }}</td>
            <td :class="{ make_diff: obj.key_flag === 'lands' }">{{ obj.lands }}</td>
            <td :class="{ make_diff: obj.key_flag === 'supply' }">{{ obj.supply }}</td>
            <td :class="{ make_diff: obj.key_flag === 'tokens' }">{{ obj.tokens }}</td>
            <td :class="{ make_diff: obj.key_flag === 'throne' }">{{ obj.throne }}</td>
          </tr>

          <tr>
            <td>{{ $t(`message.game_start`) }}</td>
            <td colspan="8">{{ getTime(game?.state.start) }}</td>
          </tr>
          <tr>
            <td>{{ $t(`message.game_end`) }}</td>
            <td colspan="8">{{ getTime(game?.state.end) }}</td>
          </tr>
          <tr>
            <td>{{ $t(`message.game_duration`) }}</td>
            <td colspan="8">{{ getTime(game?.state.end - game?.state.start, "simple") }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  inject: ["socket"],

  emits: ["close"],

  data() {
    return {
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
    };
  },
  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["mainRoom", "game", "states", "tracks", "lands", "user"]),

    sorted_homes() {
      return this.game?.state.game_over_data?.sorted_homes || [];
    },

    lands_no_ports_no_seas() {
      const lands = this.lands;
      const res = Object.keys(lands).reduce((set, land_name) => {
        const _land_obj = lands[land_name];
        if (_land_obj.sea || land_name.includes("_port")) return set;
        set.add(land_name);
        return set;
      }, new Set());
      return Array.from(res);
    },
    my_finished_player() {
      return this.game?.state.game_over_data?.sorted_homes.find((h) => h.id === this.user.id);
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    openConfirmComplaintModal(user) {
      const data = {
        processed_user: {
          avatars: user.avatars,
          nickname: user.name,
          text: this.game.game_options.name,
          target_id: user.id,
          type: "play",
        },
      };
      this.setDataForModal({ data });
      this.processModal({ name: "complaint" });
    },

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal();
    },

    checkGraded(user, flag) {
      if (this.my_finished_player?.graded?.some((u) => u.id === user.id && u.grade === flag)) return true;
      return false;
    },
    makeGrade(unit, grade) {
      // if (this.my_finished_player.graded.some((u) => u.id === unit.id)) return;
      this.socket.emit("client_action", {
        action_type: "GradeUnit",
        data: {
          my_userid: this.user.id,
          user_id: unit.id,
          room_id: this.game?.id,
          grade,
        },
      });
    },
    getTime(time = 1, simple = false) {
      const date = new Date(time);
      if (simple) {
        return this.msToTime(time);
      }
      return date.toLocaleString();
    },
    msToTime(duration) {
      let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  pointer-events: all;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 30;
  top: 0;
  left: 0;
}

.game_over_modal_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 120;

  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  padding: 1rem 2rem;
  box-sizing: border-box;
  overflow: auto;
  width: auto;
  max-width: 85dvw;
  height: auto;
  max-height: 90dvh;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
  -moz-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
  box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
}

.table_wr {
  height: auto;
  max-width: 100%;
  width: auto;
  overflow: auto;
  // box-sizing: border-box;
}
table,
th,
td {
  border: 1px solid white;
  position: relative;
  color: white;
}
table {
  // box-sizing: border-box;
  // border: 3px solid rgb(0, 0, 0);
  // margin: 25px;
  // background-color: rgb(32, 7, 7);
  border-collapse: collapse;
  width: auto;
}

td {
  text-align: center;
  vertical-align: middle;
  padding: 3px;
}

th {
  padding: 0.1rem 0.5rem;
  img {
    width: 1.5rem;
  }
}
.castles_overal {
  img {
    width: 1.2rem;
  }
}

.table_ava {
  min-width: 100px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
  img {
    height: 40px;
    width: auto;
  }
}
.avatar {
  // cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  // transition: transform 0.35s;
  // &:hover {
  //   transform: scale(1.8);
  // }
}

.ava_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  span {
    font-size: 1rem;
    cursor: pointer;
  }
}

.ava_img_wr {
  position: relative;

  span {
    cursor: pointer;
    position: absolute;
    font-size: 1rem;
    color: green;
  }
}

.complaint_btn {
  position: absolute;
  top: 15px;
  color: red !important;
}

.house_token {
  img {
    width: 30px;
  }
}
.like_up {
  color: green;
}
.like_down {
  color: rgb(248, 2, 2);
}

.graded {
  // color: gray;
  transform: scale(1.4);
  pointer-events: none;
}
.make_diff {
  background-color: rgba(59, 144, 116, 0.656);
}
</style>
