import store from "../store/index.js";

export default class SupplyHandler {
  constructor() {}

  prepareGui() {
    this.initialUnitsPreset();
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const home = store.getters["general_store/home"];

    if (unit.owner !== home) return;

    return true;
  }

  initialUnitsPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "Supply") return;

    const home = active_players[0];

    this.zones_to_hint = this.getUnitZones(home);

    this.zones_to_hint.forEach((zone) => {
      store.commit("gui_store/setGapLand", { land_name: zone });
    });
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];

    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];

    if (!state) return;
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (phase_name !== "Supply") return;

    const lands = state.lands;

    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;

    const units_count = lands[e.target.dataset.source]?.units.length;

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
      units_count,
    };

    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });
  }

  resetselectedUnits(e) {
    const home = store.getters["general_store/home"];

    if (e && e.target.dataset.type === "unit" && e.target.dataset.house === home) {
      return;
    }
    if (!e || e.target.dataset.type !== "exec") {
      store.commit("gui_store/setSelectedUnits", { reset: true });
      store.commit("game_store/processSeparateUnits", { reset: true });
    }
  }

  getUnitZones(home) {
    const state = store.getters["general_store/state"];

    if (!state) return;
    const lands = state.lands;
    const states = state.states;
    const home_states = states.find((st) => st.home === home);
    const occupied_lands = home_states.lands;

    return occupied_lands.reduce((acc, land_name) => {
      const land_obj = lands[land_name];
      if (land_obj.units.length > 1) {
        acc.push(land_name);
      }
      return acc;
    }, []);
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }
}
