<template>
  <div>
    <div
      v-for="(land, land_name) in lands"
      :id="land_name + '-order_zone'"
      :key="land_name"
      class="order_zone"
      :data-source="land_name"
    >
      <div v-show="orders_heep_source === land_name" class="order_heep">
        <img
          v-for="orderName in av_orders"
          :key="orderName"
          :src="`/images/orders/${normalizeOrderName(orderName)}.png`"
          :alt="orderName"
          :data-name="orderName"
          data-type="order"
          :class="{ order_disable: checkSkipOrder(orderName), order_heep_item: true }"
          @pointerdown="orderHeepClick($event)"
        />
      </div>

      <div
        v-if="land.order"
        :id="land_name + '_order_wr'"
        class="order_wr"
        :class="{ enemy_order_av_wr: raid_available(land_name) }"
        :data-source="land_name"
      >
        <img
          :id="land_name + '-order'"
          :ref="land_name + '-order'"
          data-type="order"
          :src="getOrderImg(land)"
          class="order"
          :class="{
            active: isOrderActive(land_name),
            transparent_order: checkOrderDone(land.home),
            default_hint: default_hint(land_name),
            candidate_active: candidate_active(land_name, land.home),
            order_available: order_available(land_name),
            enemy_order_av: raid_available(land_name),
            disable: isHistoryModeOn,
          }"
          :data-ordertype="normalizeOrderName(land.order)"
          :data-id="land_name + '-order'"
          :data-owner="`${land.home}`"
          :data-source="land_name"
          :data-master_points="land.master_points"
          :alt="land.order"
          @click.prevent="orderClick"
          @pointerdown.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  props: {
    handler: Object,
  },

  data() {
    return {
      av_orders: [
        "crusade_minus",
        "crusade_zero",
        "crusade_spec",
        "support:1",
        "support:2",
        "support_spec",
        "raid:1",
        "raid:2",
        "raid_spec",
        "power:1",
        "power:2",
        "power_spec",
        "defence:1",
        "defence:2",
        "defence_spec",
      ],
    };
  },

  computed: {
    ...mapGetters("general_store", ["lands", "state", "phase_name", "active_players", "isHistoryModeOn", "home"]),
    ...mapState("gui_store", ["orders_heep_source", "active_orders", "hint_orders", "used_orders", "available_orders"]),

    ...mapState("game_store", ["order_heep"]),

    ordersCount() {
      if (!this.lands) return;
      const lands = Object.keys(this.lands);
      return lands?.reduce((acc, l) => {
        if (this.lands[l].order) {
          acc++;
        }
        return acc;
      }, 0);
    },
  },

  methods: {
    checkOrderDone(home) {
      if (this.phase_name !== "Conception") return;
      if (this.active_players?.includes(home)) return;
      return true;
    },

    isOrderActive(order_id) {
      return this.active_orders.has(order_id);
    },

    default_hint(land_name) {
      return this.hint_orders.has(land_name);
    },
    raid_available(land_name) {
      return this.available_orders.has(land_name);
    },

    order_available(land_name) {
      if (this.available_orders.has(land_name)) return true;
    },

    candidate_active(order_id, home) {
      const first_permit = this.default_hint(order_id);
      if (!first_permit) return;
      if (!this.active_players?.includes(this.home) || home !== this.home) return;
      return true;
    },

    orderClick(e) {
      if (this.isHistoryModeOn) return;

      this.handler?.orderClick?.(e);
    },
    orderHeepClick(orderName) {
      if (this.isHistoryModeOn) return;

      this.handler?.order_heep_click?.(orderName);
    },

    checkSkipOrder(orderName) {
      if (this.state?.skip_orders?.some((sk) => orderName.includes(sk))) return true;
      if (this.used_orders.has(orderName)) return true;
      return false;
    },

    normalizeOrderName(orderName) {
      const parts = orderName.split(":");
      return parts[0];
    },

    getOrderImg(land_obj) {
      let order_name = land_obj.order === "hide" ? `hide_${land_obj.home}_new` : land_obj.order;
      order_name = this.normalizeOrderName(order_name);
      if (this.phase_name === "Conception") {
        if (this.active_players.includes(this.home) && land_obj.home === this.home) {
          return `/images/orders/${order_name}.png`;
        }
        return `/images/orders/hide_${land_obj.home}_new.png`;
      }
      return `/images/orders/${order_name}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order_zone {
  // min-width: 80px;
  // min-height: 80px;
  // background-color: rgba(7, 71, 71, 0.512);
  border-radius: 50%;
  width: auto;
  display: flex;
  justify-content: center;
  position: absolute;
}

.order {
  // cursor: grab;
  width: 70px;
  height: auto;
  opacity: 0.8;
  -webkit-filter: grayscale(30%); /* Safari 6.0 - 9.0 */
  filter: grayscale(30%);
  &:hover {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

.default_hint {
  opacity: 1;
  transform: scale(1.15);
}

.candidate_active {
  border: 2px solid blue;
  margin: -2px;
  border-radius: 50px;
  cursor: pointer;
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
  &:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
  }
}
.transparent_order {
  opacity: 0.7;
}
.active {
  border: 5px solid rgb(255, 0, 21);
  border-radius: 50px;
  margin: -5px;
}
.order_available {
  opacity: 1;
  border: 5px solid rgb(34, 233, 34);
  border-radius: 50px;
  margin: -5px;
  cursor: pointer;
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
  &:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
  }
}

.current_active {
  cursor: pointer;
  &:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
    opacity: 1;
    border: 5px solid rgb(255, 255, 255);
  }
}

.force_grab_cursor {
  cursor: grabbing;
}
.order_heep {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 431px;

  height: 230px;
  top: -260px;
  z-index: 100;
  border: 3px solid rgb(250, 250, 250);
  border-radius: 10px;
  background-color: rgb(0, 0, 0);
  padding: 10px;

  img:hover {
    opacity: 1;
  }
}

.enemy_order_av {
  cursor: pointer;
  border: 3px solid rgb(188, 102, 3);
  border-radius: 50%;
  transform: scale(1.2);
  opacity: 1;

  &:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
    border: 3px solid rgb(253, 253, 253);
  }
}

.enemy_order_av_wr::before {
  content: "";
  background-image: url("/wing.png");
  background-repeat: no-repeat;
  background-size: contain;
  top: 44px;
  left: 14px;
  pointer-events: none;
  // background-position: 100% 100%;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
}

.disable {
  cursor: grab;
  pointer-events: none;
}

#winterfell-order_zone {
  // background-color: rgba(114, 178, 178, 0.564);
  left: 630px;
  top: 809px;
}

#winterfell_port-order_zone {
  left: 322px;
  top: 429px;
}

#the_stony_shore-order_zone {
  left: 355px;
  top: 878px;
}

#bay_of_ice-order_zone {
  left: 129px;
  top: 518px;
}

#castle_black-order_zone {
  left: 735px;
  top: 341px;
}

#karhold-order_zone {
  left: 1096px;
  top: 537px;
}

#castle_black-order_zone {
  left: 739px;
  top: 238px;
}

#karhold-order_zone {
  left: 1194px;
  top: 430px;
}

#the_stony_shore-order_zone {
  left: 241px;
  top: 805px;
}

#moat_cailin-order_zone {
  left: 620px;
  top: 1231px;
}

#white_harbor-order_zone {
  left: 761px;
  top: 897px;
}

#white_harbor_port-order_zone {
  left: 908px;
  top: 1097px;
}

#widows_watch-order_zone {
  left: 989px;
  top: 769px;
}

#the_narrow_sea-order_zone {
  left: 1331px;
  top: 1222px;
}

#the_shivering_sea-order_zone {
  left: 1340px;
  top: 572px;
}

#the_fingers-order_zone {
  left: 899px;
  top: 1259px;
}

#the_twins-order_zone {
  left: 694px;
  top: 1409px;
}

#the_mountains_of_the_moon-order_zone {
  left: 810px;
  top: 1531px;
}

#the_eyrie-order_zone {
  left: 971px;
  top: 1526px;
}

// baratheon
// baratheon
// baratheon
// baratheon

#shipsrearer_bay-order_zone {
  left: 1415px;
  top: 2091px;
}

#dragonstone-order_zone {
  left: 1411px;
  top: 1724px;
}

#dragonstone_port-order_zone {
  left: 1320px;
  top: 1941px;
}

#crackclaw_point-order_zone {
  left: 871px;
  top: 1791px;
}
#kings_landing-order_zone {
  left: 973px;
  top: 1998px;
}
#blackwater_bay-order_zone {
  left: 1079px;
  top: 1905px;
}
#kingswood-order_zone {
  left: 935px;
  top: 2127px;
}

// martell
// martell
// martell
// martell
// martell

#storms_end-order_zone {
  left: 983px;
  top: 2256px;
}
#storms_end_port-order_zone {
  left: 1172px;
  top: 2210px;
}
#the_boneway-order_zone {
  left: 766px;
  top: 2327px;
}
#princes_pass-order_zone {
  left: 523px;
  top: 2434px;
}
#starfall-order_zone {
  left: 595px;
  top: 2729px;
}
#yronwood-order_zone {
  left: 747px;
  top: 2542px;
}
#salt_shore-order_zone {
  left: 826px;
  top: 2699px;
}
#east_summer_sea-order_zone {
  left: 1096px;
  top: 2799px;
}
#sunspear-order_zone {
  left: 1169px;
  top: 2552px;
}
#sunspear_port-order_zone {
  left: 1332px;
  top: 2679px;
}
#sea_of_dorne-order_zone {
  left: 1023px;
  top: 2500px;
}

// greyJoy
// greyJoy
// greyJoy
// greyJoy
// greyJoy

#sunset_sea-order_zone {
  left: -2px;
  top: 1646px;
}
#ironmans_bay-order_zone {
  left: 62px;
  top: 1492px;
}
#pyke-order_zone {
  left: 273px;
  top: 1413px;
}
#pyke_port-order_zone {
  left: 354px;
  top: 1284px;
}
#flints_finger-order_zone {
  left: 341px;
  top: 1085px;
}

#greywater_watch-order_zone {
  left: 502px;
  top: 1191px;
}
#seagard-order_zone {
  left: 498px;
  top: 1302px;
}

//lannister
//lannister
//lannister
//lannister

#riverrun-order_zone {
  left: 633px;
  top: 1580px;
}
#lannisport-order_zone {
  left: 435px;
  top: 1644px;
}
#lannisport_port-order_zone {
  left: 202px;
  top: 1658px;
}
#the_golden_sound-order_zone {
  left: 112px;
  top: 1727px;
}
#searoad_marches-order_zone {
  left: 365px;
  top: 2030px;
}
#stoney_sept-order_zone {
  left: 624px;
  top: 1710px;
}
#harrenhal-order_zone {
  left: 802px;
  top: 1658px;
}
#blackwater-order_zone {
  left: 704px;
  top: 1958px;
}

// tyrell
// tyrell
// tyrell
// tyrell

#the_reach-order_zone {
  left: 735px;
  top: 2190px;
}
#dornish_marches-order_zone {
  left: 656px;
  top: 2290px;
}
#three_towers-order_zone {
  left: 389px;
  top: 2562px;
}
#west_summer_sea-order_zone {
  left: 308px;
  top: 2802px;
}
#the_arbor-order_zone {
  left: 145px;
  top: 2709px;
}
#redwyne_straights-order_zone {
  left: 52px;
  top: 2480px;
}
#oldtown-order_zone {
  left: 187px;
  top: 2480px;
}
#oldtown_port-order_zone {
  left: 114px;
  top: 2338px;
}
#highgarden-order_zone {
  left: 350px;
  top: 2244px;
}
</style>
