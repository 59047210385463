import store from "../store/index.js";

export default class RetreatCardHandler {
  constructor() {
    this.bindedCleaner = this.retreatResetHandler.bind(this);
    this.map = document.querySelector("#zoom");
  }

  clearGui() {
    store.commit("gui_store/setGapLand", { reset: true });
    store.commit("game_store/setDragState", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setDraggingUnits", { reset: true });
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const crusade_state = store.getters["general_store/crusade_state"];
    const phase_name = store.getters["general_store/phase_name"];
    const role =
      phase_name === "Retreat" ? store.getters["general_store/myRole"] : store.getters["general_store/enemyRole"];

    if (unit.owner !== role.home) return;
    if (unit.land_name !== crusade_state.conflict_land) return;
    return true;
  }

  beckup_gui() {
    const game = store.getters["general_store/game"];
    const lands = store.state.general_store.active_lands_beckup;
    if (!game) return;
    store.commit("general_store/beckupLands", {
      room_id: game.id,
      lands,
    });
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }

  prepareGui() {
    this.initialOrdersPreset();
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = active_players[0];
    const role =
      phase_name === "Retreat" ? store.getters["general_store/myRole"] : store.getters["general_store/enemyRole"];

    if (!role) return;
    if (!state) return;

    const crusade_state = state.phase.crusade_state;
    const lands = state.lands;
    const land_name = crusade_state.conflict_land;
    const av_lands = role?.lands_to_retreat?.map((l) => l.land_name);

    store.commit("gui_store/setAvailableLands", av_lands);
    store.commit("gui_store/setGapLand", { land_name });
    if (!active_players.includes(home)) return;
    store.commit("general_store/setBeckupLands", { lands });
    store.commit("game_store/setDragState", { from: land_name });
  }

  retreatResetHandler() {
    this.beckup_gui();
    this.clearGui();
    this.prepareGui();
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = store.getters["general_store/home"];
    const dragState = store.state.game_store.drag_state;
    const selected_units = store.state.gui_store.selected_units;
    const role =
      phase_name === "Retreat" ? store.getters["general_store/myRole"] : store.getters["general_store/enemyRole"];

    if (!dragState.from) return;
    if (!active_players.includes(home)) return;
    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.source !== dragState.from) return;
    if (e.target.dataset.house !== role.home) return;
    if (!e.target.classList.contains("retreated")) return;

    const lands = state.lands;
    const crusade_state = state.phase.crusade_state;
    const looser = crusade_state.looser;
    const looser_role = crusade_state[looser];
    if (!looser_role?.units?.length) return;

    const confLand = lands[crusade_state.conflict_land];
    const units = confLand.units.filter((u) => u.owner === looser_role.home);
    const unit_obj = units.find((u) => u.id === e.target.dataset.id);
    const unit_was_selected = selected_units.has(e.target.dataset.id);

    if (!unit_was_selected) {
      const src = `/images/units/${unit_obj.owner}/new/${unit_obj.type}_${unit_obj.owner}.png`;
      unit_obj.src = src;
      store.commit("gui_store/setSelectedUnits", { unit: { ...unit_obj } });
      return;
    }

    this.unit = e.target;
    this.unit.setPointerCapture(e.pointerId);

    const mapPosition = store.state.gui_store.mapPosition;
    const scale = mapPosition.scale;

    const clones = [];
    this.clones_container = document.createElement("div");

    selected_units.forEach(function (_unit) {
      const $u = document.getElementById(`${_unit.id}`);
      const clone = $u.cloneNode(true);
      clone.style.pointerEvents = "none";
      clones.push(clone);
      store.commit("gui_store/setDraggingUnits", { unit: _unit });
    });

    clones.forEach((clone) => {
      this.clones_container.appendChild(clone);
    });

    const default_styles = {
      width: "content-length",
      height: "auto",
      position: "absolute",
      display: "flex",
      color: "white",
      pointerEvents: "none",
    };

    const map = document.querySelector("#camera");
    Object.assign(this.clones_container.style, default_styles);
    map.appendChild(this.clones_container);

    default_styles.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
      e.clientY - this.clones_container.offsetHeight / 2
    }px) scale(${scale})`;

    Object.assign(this.clones_container.style, default_styles);

    this.unit.onpointermove = (e) => {
      this.clones_container.style.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
        e.clientY - this.clones_container.offsetHeight / 2
      }px) scale(${scale})`;

      let elementBelow = document.elementFromPoint(e.clientX, e.clientY);
      store.commit("game_store/setCurrentArea", {
        land_name: elementBelow.dataset?.source,
        owner: elementBelow.dataset?.owner,
      });
    };

    this.unit.onpointerup = this.dragEnd.bind(this);

    this.unit.onpointercancel = (e) => {
      this.removeDragClones();
      this.bindedCleaner();
    };
  }

  dragEnd(e) {
    const current_area = store.getters["game_store/areaUnderCursor"];
    const game = store.getters["general_store/game"];
    const avLands = store.state.gui_store.available_lands;

    if (!current_area?.land_name || !avLands.includes(current_area?.land_name)) {
      this.removeDragClones();
      this.bindedCleaner();
      return;
    }

    const dragState = store.state.game_store.drag_state;
    const selected_units = store.state.gui_store.selected_units;
    const tempUnits = Array.from(selected_units.values());

    tempUnits.forEach((unit) => {
      unit.land_name = current_area.land_name;
    });

    store.commit("game_store/setDragState", {
      from: dragState.from,
      units: tempUnits,
      token: "",
    });

    store.commit("general_store/moveUnits", { room_id: game.id, drag_state: dragState });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setDraggingUnits", { reset: true });
    store.commit("game_store/setExecTriger", { doit: true });
    this.removeDragClones();
  }

  removeDragClones() {
    if (this.unit) {
      this.unit.onpointermove = null;
      this.unit.onpointerup = null;
      this.unit.onpointercancel = null;
    }

    const map = document.querySelector("#camera");
    if (this.clones_container && map.contains(this.clones_container)) {
      map.removeChild(this.clones_container);
    }
  }
}
