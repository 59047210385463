<template>
  <div id="modal_base" class="modal_base" @click="closeModal">
    <div class="houses_modal_container">
      <div
        v-for="{ home, available } in houses"
        :key="home"
        class="house"
        :class="{ dis: !available }"
        @click="joinHouse(home)"
      >
        <span
          v-if="home !== 'random' && available && room.state.phase.round < 2"
          class="material-icons money_hint"
          @mouseenter="hint_label_hover($event, { msg: 'price', data: price.select_home })"
          @mouseleave="resetHint"
        >
          paid
        </span>

        <img
          :src="`/images/${home}_house.jpg`"
          :alt="home || 'not_found'"
          @mouseenter="hint_label_hover($event, { msg: 'select_home_random', home })"
          @mouseleave="resetHint"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { price } from "#static_data";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],

  data() {
    return {
      join_house_cand: {},
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
      price,
    };
  },
  computed: {
    ...mapState("general_store", ["data_for_modal", "rooms", "sid"]),
    ...mapGetters("general_store", ["user"]),

    room() {
      return this.rooms.find((r) => r.id === this.data_for_modal?.room_id);
    },
    houses() {
      const vars = this.room?.players.reduce((acc, player) => {
        if (player.id) {
          acc.push({ home: player.home, available: false });
        } else {
          acc.push({ home: player.home, available: true });
        }
        return acc;
      }, []);
      vars?.push({
        home: "random",
        available: true,
      });
      return vars;
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", ["processModal", "setDataForModal", "showError"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "houses", type: "close" });
      this.setDataForModal({ reset: true });
    },

    joinHouse(house) {
      if (this.user.ban.play.value) {
        this.socket.emit("client_action", {
          action_type: "BanUser",
          data: {
            user_id: this.user.id,
            target_user_id: this.user.id,
            update: true,
          },
        });
        return;
      }

      const buy = house === "random" ? false : true;

      if (house === "random") {
        const av_houses = [];
        for (let { home, available } of this.houses) {
          if (home === "random") continue;
          if (available) {
            av_houses.push(home);
          }
        }
        if (!av_houses.length) {
          this.showError({
            error: {
              code: 203,
            },
          });
        }
        if (av_houses.length === 1) {
          house = av_houses[0];
        } else {
          house = this.rand(av_houses);
        }
      }

      this.join_house_cand.house = house;
      this.join_house_cand.buy = buy;

      if (buy || this.room.game_options.bank.buy_in) {
        this.setDataForModal({
          data: {
            buyin: this.room.game_options.bank.buy_in,
            select_home: buy,
            cb: (result) => {
              if (!result) return;
              this.joinGameHouse({
                house: this.join_house_cand.house,
                buy: this.join_house_cand.buy,
                room_id: this.room.id,
              });
            },
          },
        });
        this.processModal({ name: "join_game" });
        return;
      }

      this.joinGameHouse({
        house,
        buy: false,
        room_id: this.room.id,
      });
    },

    rand(list) {
      const random = Math.floor(Math.random() * list.length);
      return list[random];
    },

    joinGameHouse({ house, skip, buy, room_id }) {
      let gameCand = this.rooms.find((g) => g.id === room_id);

      const player_cand = gameCand.players.find((player) => player.home === house);
      if (!player_cand.id) {
        if (gameCand.game_options.password && !skip) {
          this.setDataForModal({
            data: {
              cb: (result) => {
                if (!result) return;
                this.applyPassword(result);
              },
              password: this.room.game_options.password,
            },
          });
          this.processModal({
            name: "join_password",
            type: "open",
          });
          this.join_house_cand = {
            house,
            buy,
          };
          return;
        }

        this.socket.emit("client_action", {
          action_type: "JoinGame",
          data: {
            room_id: room_id,
            user_id: this.user.id,
            home: house,
            sid: this.sid,
            buy,
          },
        });

        this.processModal();
        this.setDataForModal({ reset: true });
      }
    },
    applyPassword(result) {
      if (!result) return;
      const house = this.join_house_cand.house;
      const buy = this.join_house_cand.buy;
      const _h = this.houses.find((h) => h.home === house);
      if (_h?.available) {
        this.joinGameHouse({ house, skip: true, buy, room_id: this.room.id });
      }
      this.setDataForModal({ reset: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.837);
  z-index: 20;
  top: 0;
  left: 0;
}
.houses_modal_container {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 80dvw;
  max-height: 90dvh;

  -webkit-box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
  -moz-box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
  box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
}
.house {
  width: auto;
  display: flex;
  align-items: flex-start;
  position: relative;
  transition: filter 0.5s ease-in;
  cursor: pointer;

  img {
    height: 60dvh;
    width: auto;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 500px) {
    img {
      height: 40dvh;
    }
  }
}

// .house:hover {
//   transition: filter 0.5s ease-in;
//   filter: none;
// }
.dis {
  opacity: 1;
  filter: brightness(0.4) grayscale(80%);
  pointer-events: none;
}

.money_hint {
  position: absolute;
  top: 10px; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  color: green;
  transform: translateX(-50%);
  cursor: pointer;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.35s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
