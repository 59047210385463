import store from "../store/index.js";
export default class MamothRidersHandler {
  constructor() {
    this.gap_zones = new Set();
  }

  prepareGui() {
    this.resetGui();
    this.initialUnitsPreset();
  }

  resetGui() {
    this.gap_zones.clear();

    store.commit("game_store/processSeparateUnits", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setHighlightedUnits", { reset: true });
    store.commit("gui_store/setGapLand", { reset: true });
    store.commit("gui_store/setSelectedCard", { reset: true });
  }

  initialUnitsPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = active_players[0];

    if (!state) return;
    if (phase_name !== "WildingManual") return;

    if (state.wildings_state.is_victory) return;

    this.units_to_hint = this.getAllUnitsAndSetGapZones(home);

    this.gap_zones.forEach((land) => {
      store.commit("gui_store/setGapLand", { land_name: land });
    });
    this.units_to_hint?.forEach((unit) => {
      store.commit("gui_store/setHighlightedUnits", unit);
    });
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];

    if (!state) return;
    if (phase_name !== "WildingManual") return;
    if (!active_players.includes(home)) return;
    if (state.wildings_state.is_victory) return;
    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;

    const max_count = this.calcMaxCount();

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };

    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });

    const separate_units = store.state.game_store.separate_units;

    if (separate_units.size > max_count) {
      this.prepareGui();
      return;
    }
  }

  calcMaxCount() {
    const state = store.getters["general_store/state"];
    const wildingsState = state.wildings_state;
    const home = store.getters["general_store/home"];
    const isHomeLowestBid = wildingsState.lowest_candidates.includes(home);
    return isHomeLowestBid ? 3 : 2;
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const home = store.getters["general_store/home"];
    if (unit.owner !== home) return;
    return true;
  }

  getAllUnitsAndSetGapZones(home) {
    const state = store.getters["general_store/state"];

    if (!state) return;

    const lands = state.lands;
    const home_states = state.states.find((st) => st.home === home);
    const occupied_lands = home_states.lands;
    return occupied_lands.reduce((acc, land_name) => {
      const land_obj = lands[land_name];
      if (land_obj.units.length > 1) {
        this.gap_zones.add(land_name);
      }
      let units = land_obj.units;
      units = units.map((u) => {
        u.land_name = land_name;
        return u;
      });
      return (acc = [...acc, ...units]);
    }, []);
  }

  cardClickHandler(e, card) {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    if (!state) return;
    if (phase_name !== "WildingManual") return;
    const wildingsState = state.wildings_state;

    if (!active_players.includes(home)) return;
    if (!wildingsState.is_victory) return;
    if (card.home !== home) return;
    if (!card || card.active) return;

    store.commit("game_store/selectCard", { title: card.title, home: card.home });
    store.commit("game_store/setExecTriger", { doit: true });
  }

  cardHoverHandler(e, card) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "WildingManual") return;
    if (card?.active) return;

    if (home === e.target.dataset.home && active_players.includes(home)) {
      const closestMask = e.target.closest(".mask");

      if (closestMask) {
        closestMask.classList.add("mask_hint");
      }
      if (e.type === "mouseenter") {
        if (closestMask) {
          closestMask.classList.add("mask_hint");
        }
        e.target.classList.remove("disable_cursor");
      }
      if (e.type === "mouseleave") {
        if (closestMask) {
          closestMask.classList.remove("mask_hint");
        }
        e.target.classList.add("disable_cursor");
      }
    }
  }
}
