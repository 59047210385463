<template>
  <div id="invitation_modal_wr" class="invitation_modal_wr" @click="close">
    <div class="invite_wr">
      <div class="game_list_wr">
        <table>
          <thead>
            <th>{{ $t("message.game_name") }}</th>
            <th>{{ $t("message.game_status") }}</th>
            <th>{{ $t("message.game_round") }}</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="game in games_to_invite" :key="game">
              <td>{{ game.game_options.name }}</td>
              <td>{{ $t(`message.${game.state.status}_short`) }}</td>
              <td>{{ game.state.round || 0 }}</td>
              <td>
                <button class="button" role="button" @click="confirm(game.id)">
                  {{ $t(`message.game_invite`) }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  inject: ["socket"],

  computed: {
    ...mapState("general_store", ["data_for_modal", "rooms"]),
    ...mapGetters("general_store", ["mainRoom", "user"]),

    games_log() {
      if (!this.user) return;
      return this.user.game_logs || [];
    },

    games_to_invite() {
      return this.rooms.filter((r) => {
        if (r.id === "mainRoom") return false;
        if (r.state.status !== "gathering" && r.state.status !== "searching") return false;
        return true;
      });
    },
    processed_user() {
      return this.data_for_modal?.processed_user;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    close(e) {
      if (e.target.id !== "invitation_modal_wr") return;
      this.processModal({ name: "invitation", type: "close" });
      this.setDataForModal({ reset: true });
    },
    confirm(game_id) {
      const game_cand = this.rooms.find((r) => r.id === game_id);

      this.socket.emit("client_action", {
        action_type: "InviteUser",
        data: {
          sender_obj: this.user,
          target_id: this.processed_user.id,
          target_locale: this.processed_user?.locale,
          game: {
            name: game_cand.game_options.name,
            round: game_cand.state.phase.round,
            status: game_cand.state.status,
          },
        },
      });
      this.processModal({ name: "invitation", type: "close" });
      this.setDataForModal({ reset: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.invitation_modal_wr {
  position: relative;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 200;
  top: 0;
  left: 0;
}
.invite_wr {
  z-index: 2;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  padding: 1rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-height: 85dvh;
  max-width: 85dvw;
  background-color: rgb(22, 22, 22);
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
  -moz-box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
  box-shadow: -1px -4px 97px 31px rgb(255, 255, 255);
}

.game_list_wr {
  display: flex;
  justify-content: flex-start;
  // background-color: pink;
  height: 100%;
  overflow: auto;
  width: 100%;
}

table {
  text-align: center;
  color: rgb(255, 255, 255);
  th {
    padding: 0.3rem;
  }
  td {
    max-width: 100px;
    overflow-x: hidden;
    border-bottom: 1px solid white;
  }
}

button {
  cursor: pointer;
}

// <button class="button-33" role="button">Button 33</button>

/* CSS */
</style>
