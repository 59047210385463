import store from "../store/index.js";
export default class ThroneDecisionHandler {
  constructor() {}

  resetGui() {
    store.commit("game_store/setThroneDecision", { choice: "" });
    store.commit("general_store/processModal");
  }

  prepareGui() {
    this.resetGui();
    store.commit("general_store/processModal", { name: "wilding_card" });
  }
}
