import store from "../store/index.js";
export default class PestryakHandler {
  constructor() {}

  clearGui() {
    store.commit("game_store/selectCard", { reset: true });
    store.commit("general_store/processModal", { name: "card_hint", type: "close" });
  }

  cardClickHandler(e, card) {
    const enemyRole = store.getters["general_store/enemyRole"];
    if (card.home !== enemyRole.home) {
      return;
    }
    if (!card.active) {
      return;
    }

    store.commit("game_store/selectCard", { title: card.title, home: card.home });
    store.commit("game_store/setExecTriger", { doit: true });
    store.commit("general_store/processModal");
    store.commit("gui_store/setGameSideTab", { tab: "warActive" });
  }
}
