<template>
  <div ref="units_heep" class="units_heep">
    <div v-for="(unit_count, unit_type) in av_units" :key="unit_type" class="unit_heep_wr">
      <span class="unit_heep_label">{{ getUnitsInfo(unit_type) }}</span>
      <img
        v-if="home"
        :src="`/images/units/${home}/new/${unit_type}_${home}.png`"
        :alt="unit_type"
        data-type="unit"
        :data-owner="home"
        :data-type_unit="unit_type"
        :data-id="`${unit_type}_${home}_shadow`"
        :data-force="getUnitForce(unit_type)"
        class="unit_heep_item"
        :class="{
          disabled: checkUnitHeepDisable || !calcUnitsHeepAvailable(unit_type).units_available_count,
          highlited: home === 'greyjoy',
        }"
        @pointerdown="mousedownHandler"
        @dragstart="dragStart"
      />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  props: {
    handler: Object,
  },

  computed: {
    ...mapGetters("general_store", ["states", "lands", "home", "active_players", "current_game_id", "inside_room"]),
    ...mapState("general_store", ["active_lands_beckup"]),
    ...mapState("game_store", ["mastering_state"]),

    checkUnitHeepDisable() {
      return this.mastering_state.points < 1;
    },

    av_units() {
      const home_state = this.states.find((st) => st.home === this.home);
      return home_state?.units || [];
    },
  },

  watch: {
    "mastering_state.land_name"() {
      if (!this.mastering_state.land_name) return;
      const wr = this.$refs.units_heep;
      const land_obj = this.lands[this.mastering_state.land_name];
      const port_name = land_obj.related_port;
      let drop_ref_name = `${this.mastering_state.land_name}-drop`;
      if (port_name) {
        drop_ref_name = `${port_name}-drop`;
      }
      const drag_zone = this.$parent.$refs.unitsLayer.$refs[drop_ref_name][0];
      if (!drag_zone) return;
      const { left, top } = window.getComputedStyle(drag_zone);

      let normalized_prop_left = normalizeProp(left);
      let normalized_prop_top = normalizeProp(top);

      wr.style.left = +normalized_prop_left - 150 + "px";
      wr.style.top = +normalized_prop_top + 100 + "px";

      function normalizeProp(prop) {
        return prop.split("px")[0];
      }
    },
  },

  mounted() {
    if (!this.mastering_state.land_name) return;
    const wr = this.$refs.units_heep;
    const land_obj = this.lands[this.mastering_state.land_name];
    const port_name = land_obj.related_port;
    let drop_ref_name = `${this.mastering_state.land_name}-drop`;
    if (port_name) {
      drop_ref_name = `${port_name}-drop`;
    }
    const drag_zone = this.$parent.$refs.unitsLayer.$refs[drop_ref_name][0];
    const { left, top } = window.getComputedStyle(drag_zone);

    let normalized_prop_left = normalizeProp(left);
    let normalized_prop_top = normalizeProp(top);

    wr.style.left = +normalized_prop_left - 150 + "px";
    wr.style.top = +normalized_prop_top + 100 + "px";

    function normalizeProp(prop) {
      return prop.split("px")[0];
    }

    this.map = this.$parent.$refs.zoom;
    if (!this.map) return;

    this.bindedcloseUnitsHeepHandler = this.closeUnitsHeepHandler.bind(this);
    setTimeout(() => {
      this.map.addEventListener("pointerdown", this.bindedcloseUnitsHeepHandler, { once: true });
    }, 0);
  },

  methods: {
    ...mapMutations("game_store", ["setMasteringState"]),
    ...mapMutations("general_store", ["beckupLands"]),
    dragStart() {
      return false;
    },

    closeUnitsHeepHandler(e) {
      if (!this.mastering_state.land_name) return;
      if (e.target.dataset.type === "unit") {
        this.map.addEventListener("pointerdown", this.bindedcloseUnitsHeepHandler, { once: true });
        return;
      }
      if (e.target.dataset.type === "order") {
        this.map.addEventListener("pointerdown", this.bindedcloseUnitsHeepHandler, { once: true });
        return;
      }

      this.beckupLands({
        current_game_id: this.current_game_id,
        lands: this.active_lands_beckup,
      });

      this.setMasteringState({
        reset: true,
      });
      this.setOrder({ key: "active_orders", reset: true });
    },

    getUnitForce(type) {
      return type === "kata" ? 4 : type === "knite" ? 2 : 1;
    },

    async mousedownHandler(ev) {
      if (ev.target.dataset.type !== "unit") return;
      if (!this.active_players.includes(this.home)) return;
      this.handler?.mousedownHandler?.(ev);
    },

    getUnitsInfo(unit_type) {
      const { units_available_count, max } = this.calcUnitsHeepAvailable(unit_type);
      return `${units_available_count}/ ${max}`;
    },

    calcUnitsHeepAvailable(unitType) {
      const max = unitType === "pawn" ? 10 : unitType === "knite" ? 5 : unitType === "ship" ? 6 : 2;
      const unitsOnBoardCount = this.av_units[unitType].length;
      const units_available_count = max - unitsOnBoardCount;
      return {
        units_available_count,
        max,
      };
    },
  },
};
</script>
<style lang="scss">
.units_heep {
  opacity: 0.7;
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 251px;
  // width: 370px;
  height: 80px;
  top: 100px;
  z-index: 1000;
  border: 3px solid rgb(250, 250, 250);
  border-radius: 10px;
  background-color: rgb(0, 0, 0);
  padding: 10px;

  img:hover {
    opacity: 1;
  }
}

.unit_heep_label {
  color: white;
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  top: 60px;
  left: 15px;
}
.order_heep_item {
  opacity: 0.7;
  padding: 3px;
  width: 70px;
  &:hover {
    cursor: pointer;
  }
}

.highlited {
  background-color: grey;
  border-radius: 10px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.2;
  &:hover {
    cursor: not-allowed;
    opacity: 0.2 !important;
  }

  // pointer-events: none;
}
.unit_heep_wr {
  position: relative;
}

.order_disable {
  opacity: 0.3 !important;

  &:hover {
    cursor: not-allowed;
  }
}
</style>
