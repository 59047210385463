export default {
  uk: {
    ablative_land: {
      the_shivering_sea: "Тремтливим Морем",
      the_narrow_sea: "Вузьким морем",
      bay_of_ice: "Крижаною  Затокою",
      winterfell: "Вінтерфеллом",
      winterfell_port: "Портом Вінтрефеллу",
      castle_black: "Чорним Замком",
      the_stony_shore: "Каменястим Берегом",
      white_harbor: "Білою Гаванню",
      white_harbor_port: "Портом Білої Гавані",
      karhold: "Карголдом",
      widows_watch: "Вдовартою",
      the_eyrie: "Соколиним Гніздом",
      moat_cailin: "Калин-Копом",
      the_twins: "Близнюками",
      the_fingers: "Пальцями",
      the_mountains_of_the_moon: "Місячними Горами ",
      shipsrearer_bay: "Затокою Човнозгуба",
      blackwater_bay: "Чорноводою Затокою",
      dragonstone: "Дракон-Каменем",
      dragonstone_port: "Портом Дракон-Каменя",
      crackclaw_point: "Гостроклішнею",
      kingswood: "Королівською Пущею",
      kings_landing: "Король-Берегом",
      east_summer_sea: "Східним Літнім Морем",
      sea_of_dorne: "Дорнійським Морем",
      sunspear: "Сонцесписом",
      sunspear_port: "Портом Сонцесписа",
      storms_end: "Штормоламом",
      storms_end_port: "Портом Штормоламу",
      the_boneway: "Кістяним Шляхом",
      princes_pass: "Великокняжим проходом",
      starfall: "Зорепадом",
      yronwood: "Крицаком",
      salt_shore: "Солемор'ям",
      west_summer_sea: "Західним Літнім Морем",
      redwyne_straights: "Рожвиновою протокою",
      highgarden: "Хайгарденом",
      oldtown: "Староградом",
      oldtown_port: "Портом Старограду",
      three_towers: "Трибаштою",
      dornish_marches: "Дорнійським Шляхом",
      the_reach: "Обширом",
      the_arbor: "островом Вертограда",
      sunset_sea: "Західним Морем",
      the_golden_sound: "Золотою Затокою",
      lannisport: "Ланніспортом",
      lannisport_port: "Портом Ланніспорту",
      riverrun: "Водоплином",
      harrenhal: "Гаренголом",
      stoney_sept: "Камінною Септою",
      searoad_marches: "Морським Шляхом",
      blackwater: "Чорноводою",
      ironmans_bay: "Залізняцькою Затокою",
      pyke: "Пайком",
      pyke_port: "Портом Пайка",
      seagard: "Морестражем",
      flints_finger: "Кремінь-Пальцем",
      greywater_watch: "Сіроводцем",
    },

    ablative_home: {
      baratheon: "Баратеоном",
      stark: "Старком",
      lannister: "Ланністером",
      tyrell: "Тіреллом",
      martell: "Мартеллом",
      greyjoy: "Грейджоєм",
      nobody: "ніким",
    },
  },
};
