export default {
  uk: {
    accusative_phase: {
      Conception: `Фазу Замислів`,
      Wildings: `Фазу Нашестя дикунів`,
      CrowCheck: `Фазу Посильному Ворона`,
      Crusade: `Фазу Походів`,
      Raid: "Фазу Рейдів",
      Power: "Фазу Посилення Влади",
      BidPhase: "Фазу Торгів",
      KingChoice: "Фазу Вибору Короля",
      ThroneDecision: "Фазу Вибору Короля",

      ConflictPort: "Фазу Захоплення Кораблів",
      PreAttackConflictPort: "Фазу Захоплення Кораблів",
      PermitSupport: "Фазу Запиту Підтримки",
      DefineSupport: "Фазу Підтримки",
      UseSword: "Фазу Валерійському Меча",
      SelectCard: "Фазу Вибору Карти Дому",
      FirstCardAction: "Фазу Властивості Карти Дому",
      LastCardAction: "Фазу Властивості Карти Дому",
      Retreat: "Фазу Відступу (жесту добрій волі)",

      WildingManual: "Фазу Властивості Карти Дикунів",

      Supply: "Фазу Постачання",
      Mastering: "Фазу Збору Військ",
      LastDays: "Останні Дні Літа",
      Winter: "Зима Близько",
      ThroneOfBlades: "Фазу Трону із Клинків",
      GameOfThrones: "Фазу Гри Престолів",

      BlackWings: "Фазу Вибір Чорному Ворона",
      WarOfKings: "Фазу Битви Королів",

      AutumnRains: "Осінні Дощі",
      FeastOfCrows: "Свято Ворон",
      SeaOfStorms: "Море Штормів",
      WebOfLies: "Павутина Брехні",
      PutToTheSword: "Фазу Відданий Мечу",
    },

    accusative_land: {
      the_shivering_sea: "Тремтливе Море",
      the_narrow_sea: "Вузьке море",
      bay_of_ice: "Крижану Затоку",
      winterfell: "Вінтерфелл",
      winterfell_port: "Порт Вінтрефеллу",
      castle_black: "Чорний Замок",
      the_stony_shore: "Каменястий Берег",
      white_harbor: "Білу Гавань",
      white_harbor_port: "Порт Білої Гавані",
      karhold: "Карголд",
      widows_watch: "Вдоварту",
      the_eyrie: "Соколине Гніздо",
      moat_cailin: "Калин-Коп",
      the_twins: "Близнюки",
      the_fingers: "Пальці",
      the_mountains_of_the_moon: "Місячні Гори ",
      shipsrearer_bay: "Затоку Човнозгуба",
      blackwater_bay: "Чорноводу Затоку",
      dragonstone: "Дракон-Камінь",
      dragonstone_port: "Порт Дракон-Каменя",
      crackclaw_point: "Гостроклішню",
      kingswood: "Королівську Пущу",
      kings_landing: "Король-Берег",
      east_summer_sea: "Східне Літнє Море",
      sea_of_dorne: "Дорнійське Море",
      sunspear: "Сонцеспис",
      sunspear_port: "Порт Сонцесписа",
      storms_end: "Штормолам",
      storms_end_port: "Порт Штормоламу",
      the_boneway: "Кістяний Шлях",
      princes_pass: "Великокняжий прохід",
      starfall: "Зорепад",
      yronwood: "Крицак",
      salt_shore: "Солемор'я",
      west_summer_sea: "Західне Літнє Море",
      redwyne_straights: "Рожвинову протоку",
      highgarden: "Хайгарден",
      oldtown: "Староград",
      oldtown_port: "Порт Старограду",
      three_towers: "Трибашту",
      dornish_marches: "Дорнійський Шлях",
      the_reach: "Обшир",
      the_arbor: "острів Вертограду",
      sunset_sea: "Західне Море",
      the_golden_sound: "Золоту Затоку",
      lannisport: "Ланніспорт",
      lannisport_port: "Порт Ланніспорту",
      riverrun: "Водоплин",
      harrenhal: "Гаренгол",
      stoney_sept: "Камінну Септу",
      searoad_marches: "Морський Шлях",
      blackwater: "Чорноводу",
      ironmans_bay: "Залізняцьку Затоку",
      pyke: "Пайк",
      pyke_port: "Порт Пайка",
      seagard: "Морестраж",
      flints_finger: "Кремінь-Палець",
      greywater_watch: "Сіроводець",
    },

    accusative_order: {
      crusade_minus: "Мінусову атаку",
      crusade_zero: "Звичайну атаку",
      crusade_spec: "Посилену атаку",
      support: "Піддтримку",
      support_spec: "Посилену піддтримку",
      defence: "Оборону",
      defence_spec: "Посилену оборону",
      power: "Зміцнення влади",
      power_spec: "Посилене зміцнення влади",
      Raid: "Рейд",
      raid_single: "Рейд",
      raid_spec: "Посилений рейд",
    },

    accusative_track_name: {
      throne: "Залізний Трон",
      sword: "Валерійский Меч",
      crow: "Посильного Ворона",
    },

    accusative_unit: {
      pawn: "пішого воїна",
      knite: "лицара",
      kata: "облогову вежу",
      ship: "корабель",
    },
    accusative_unit_plur: {
      pawn: "піших воїнів",
      knite: "лицара",
      kata: "облогові вежі",
      ship: "корабеля",
    },
    accusative_home: {
      baratheon: "Баратеонa",
      stark: "Старкa",
      lannister: "Ланністерa",
      tyrell: "Тіреллa",
      martell: "Мартеллa",
      greyjoy: "Грейджоя",
      nobody: "нікого",
    },

    accusative_home_card: {
      Eddard: "Еддарда Старка",
      Robb: "Робба Старка",
      Rodrik: "Сіра Родріка Касселя",
      Amber: "Джона Амбера",
      Roose: "Русе Болтона",
      Brynden: "Бріндена Чорну Рибу",
      Catelyn: "Кейтелін Старк",
      Stannis: "Станіса Баратеона",
      Salladhor: "Салладора Саана",
      Renly: "Ренлі Баратеона",
      Patchface: "Пестряка",
      Melisandre: "Мелісандру",
      Davos: "Сіра Давоса Сіворта",
      Brienne: "Брієнну Тарт",
      Asha: "Ашу Грейджой",
      Balon: "Бейлона Грейджоя",
      Dagmer: "Дагмера Щербатого",
      Euron: "Еурона Грейджоя",
      Aeron: "Еєрона Сироволосого",
      Theon: "Теона Грейджоя",
      Victarion: "Віктаріона Грейдоя",
      Cersei: "Серсею Ланністер",
      Jaime: "Сіра Джейме Ланністера",
      Sandor: "Пса",
      Gregor: "Сіра Грегора Клігана",
      Kevan: "Сіра Ківана Ланністера",
      Tyrion: "Тіріона Ланністера",
      Tywin: "Тайвіна Ланністера",
      Alester: "Алестера Флорента",
      Garlan: "Сіра Гарлана Тірелла",
      Loras: "Сіра Лораса Тірелла",
      Margaery: "Маргарі Тірелл",
      Mace: "Мейса Тірелла",
      Olenna: "Оленну Тірелл",
      Randyll: "Сіра Ренділа Тарлі",
      Areo: "Арео Хотаха",
      Arianne: "Аріанну Мартелл",
      Doran: "Дорана Мартелла",
      Gerold: "Сіра Герольда Темна Зірка",
      Nymeria: "Німерію Санд",
      Obara: "Обару Санд",
      Oberyn: "Оберіна Червону Гадюку",
    },
  },
};
