export default {
  en: {
    gui_hints: {
      del_all_units: "Delete all units",
      delete_avatar: "Remove image",
      user_creation_failed: "Creation user failed: {error}",
      user_creation_ok: "New user {username} was created",
      password_was_sent: `User with email {email} got new password on email`,
      password_reset_error: `  User with email {email} does not exist, or something else went wrong`,
      CrowKillers:
        "Knites will be downgraded in order of their selection. If you run out of foot soldiers, the remaing knites will be killed",
      LeaveGame_gathering:
        "You have decided to leave a game that has not started yet. Spent tokens will be refunded, and your statistics will remain unchanged. Are you sure you want to leave the game?",
      LeaveGame_playing:
        "You have decided to exit an ongoing game. Spent tokens will not be refunded, and your statistics will be negatively affected. However, if you return during the game, you won't have to pay again for choosing a house or other payments. Additionally, this game will not be counted in your statistics as either unfinished or completed. Therefore, you should keep an eye on the timer to avoid being kicked out of the game and further deterioration of your game history.",
      LeaveGame_searching:
        "You have decided to exit an ongoing game. Spent tokens will not be refunded, and your statistics will be negatively affected. However, if you return during the game, you won't have to pay again for choosing a house or other payments. Additionally, this game will not be counted in your statistics as either unfinished or completed. Therefore, you should keep an eye on the timer to avoid being kicked out of the game and further deterioration of your game history.",

      LeaveApp:
        "You have decided to fully exit the game. If you have an active game, you will automatically leave it. Your statistics may be negatively affected. Are you sure you want to exit the game?",
      LeaveGame_end: "Leave a completed game?",
      deny_transfer:
        "You are unable to send tokens. A minimum of 11 tokens is required. Also, you cannot send tokens that were earned during the creation of a new account, until you use them first.",
    },
  },
  uk: {
    gui_hints: {
      delete_avatar: "Видалити аватар",
      del_all_units: "Видалити всіх юнітів",
      user_creation_failed: "Не вдалося створити новго юзера: {error}",
      user_creation_ok: "Був створений новий юзер {username}",
      password_was_sent: `Новий пароль було надіслано на пошту: {email}`,
      password_reset_error: `Юзера з поштою {email} не інсує, або стався інший прикрий випадок`,
      CrowKillers:
        "Якщо не вистачає піших воЇнів на заміну лицарів, то лицарі, які будуть обрані першими,\nзалишаться живими після даунгрейду до піших воЇнів.",
      LeaveGame_gathering:
        "Ви вирішили вийти з гри, яка ще не почалася. Витрачені токени будуть повернуті. Ваша статистика залишиться без змін. Ви точно хочете залишити гру?",
      LeaveGame_playing:
        "Ви вирішили вийти з гри, яка триває. Витрачені токени повернуті не будуть. Ваша статистика погіршиться. Але, якщо упродовж гри ви поверенетесь, то вам не доведеться знову платити за вибір дому чи інші платежі. Також в статистику ця гра не буде зарахована як та, що не дограна. Але вона також не буде зарахована як повна гра. Саме тому ви повинні слідкувати за таймером, щоб уникнути вильоту з гри та подальшого погіршення історії ігор",
      LeaveGame_searching:
        "Ви вирішили вийти з гри, яка триває. Витрачені токени повернуті не будуть. Ваша статистика погіршиться. Але, якщо упродовж гри ви поверенетесь, то вам не доведеться знову платити за вибір дому чи інші платежі. Також в статистику ця гра не буде зарахована як та, що не дограна. Але вона також не буде зарахована як повна гра. Саме тому ви повинні слідкувати за таймером, щоб уникнути вильоту з гри та подальшого погіршення історії ігор",
      LeaveGame_end: "Покинути заверешену гру?",
      LeaveApp:
        "Ви вирішили повнітсю вийти з гри. Якщо у вас є активна гра, то ви автоматично покинете її. Статистика може бути погіршена. Точно покинути гру?",
      deny_transfer:
        "Ви не можете відправити токени. Необхідно мінімум 11 токенів. Також ви не можете відправляти токени, які були нараховані при створенні нового аккаунту. Спочатку треба їх витратити.",
    },
  },
};
