<template>
  <div class="SideBarRight_container prevent-select" :style="{ transform: container_hide }">
    <div class="inner_relative_wrapper">
      <NavButtonsRight class="" />
      <ListPlayers
        :class="{
          hide: hide('playersActive'),
          shrinkWidth: true,
          fast_hide: menu_open,
          tab: true,
        }"
      />

      <TabChat
        :class="{ hide: hide('chatActive'), fast_hide: menu_open, tab: true, shrinkWidth: true }"
        :room="game"
        :tab-type="'game_chat'"
      />
      <TabPrivateChat
        :tab-type="'game_private_chat'"
        :class="{
          hide: hide('privateGameChatActive'),
          shrinkWidth: true,
          fast_hide: menu_open,
          tab: true,
        }"
        :room="game"
      />
      <TabWar
        :class="{
          hide: hide('warActive'),
          shrinkWidth: true,
          fast_hide: menu_open,
          tab: true,
        }"
        @exec="$emit('exec')"
      />

      <TabCards
        :class="{
          hide: hide('infoActive'),

          fast_hide: menu_open,
          tab: true,
        }"
        :handler="handler"
      />
      <TabRedactorPhase
        v-if="isAdmin"
        :class="{
          hide: hide('stateRedactorActive'),
          shrinkWidth: true,
          fast_hide: menu_open,
          tab: true,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  props: {
    handler: Object,
  },
  emits: ["exec"],

  computed: {
    ...mapGetters("general_store", ["game", "crusade_state", "isAdmin"]),
    ...mapState("gui_store", ["game_side_bar"]),

    container_hide() {
      if (!this.menu_open) {
        return `translateX(0%)`;
      }
      if (window.innerWidth < 700) {
        if (this.game_side_bar.infoActive) {
          return `translateX(-100%)`;
        }

        return `translateX(-100%)`;
      }
      if (window.innerWidth < 1100 && window.innerWidth > 700) {
        if (this.game_side_bar.infoActive) {
          return `translateX(-100%)`;
        }

        return `translateX(-55%)`;
      }
      if (this.game_side_bar.infoActive) {
        return `translateX(-100%)`;
      }
      return `translateX(-40%)`;
    },

    menu_open() {
      return Object.keys(this.game_side_bar).some((k) => this.game_side_bar[k] === true);
    },

    users() {
      return this.game.players.concat(this.game.users);
    },
  },

  watch: {
    "crusade_state.conflict_land"(newValue) {
      if (this?.crusade_state?.conflict_land) {
        this.setGameSideTab("warActive");
      }
    },

    menu_open() {
      if (this.menu_open) {
        if (window.innerWidth < 1285) {
          this.setMainSideTab();
        }
      }
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setMainSideTab", "setGameSideTab"]),

    hide(type) {
      if (this.game_side_bar[type]) {
        return false;
      }
      return true;
    },
    show(type) {
      if (!this.game_side_bar[type]) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.SideBarRight_container {
  position: absolute;
  height: 100dvh;
  z-index: 6;
  // top: 0;
  bottom: 0;
  right: -940px;
  width: 940px;

  box-sizing: border-box;
  transition: all 1s;
  background-color: rgb(41, 40, 40);
  border: 3px solid white;
  border-top: none;
  box-sizing: border-box;
  pointer-events: all;
}

.inner_relative_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}
.tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}
@media screen and (max-width: 1100px) {
  .SideBarRight_container {
    right: -85dvw;
    width: 85dvw;
  }
  .tab {
    width: 55%;
  }
}
@media screen and (max-width: 700px) {
  .SideBarRight_container {
    right: -65dvw;
    width: 65dvw;
  }
  .tab {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .SideBarRight_container {
    right: -80dvw;
    width: 80dvw;
  }
}

.fast_show {
  transition-delay: 0s;
  transition-property: transform;
}
.hide {
  transition-delay: 1s;
  transition-property: transform;
  transform: translateX(310%);
}
.fast_hide {
  transition-delay: 0s;
  transition-property: transform;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
