export default {
  en: {
    errors: {
      "000": "Internal server error. A new record has been created in logfile",
      "001": "User with identificator '{user_id}' already exists",
      "0022": "User '{user_id}' was created",
      "003": "User '{user_id}' doesn't exist",
      "004": "Invalid password for '{user_id}', try again",
      "005": "Session was expired. You should relogin",
      "006": "Incorrect secret string",
      "0066": "Admin was created",
      "007": "User '{user_id}' can login only with facebook or google. This user doesn't have password",
      "008": "Need to login",
      "0099": "You are not admin anymore",
      "010": "Failed to send a new password to: {email}",
      "0101": "New password was send to: {email}",
      "0111": "Confirmation code was sent to: {email}",
      "011": "Error: Confirmation code wasn't sent to: {email}",
      "0122": "New email is: {email}",
      "012": "Incorect confirmation code",
      "0133": "Password was changed: {password}",
      "014":
        "You are lack of money. Pice is {price}, but you have only {money}. You need get tokens somehow. Buy or earn them",
      "015": "It's impossible to delete the default avatar. It's like parents. You can't choose it",
      "016":
        "You have tried to send {price} tokens to another person, but you can`t` send tokens that were gifted to you by the creator when registering a new account. You can send: {money} tokens.",
      200: "{land_name} area in unavailable to move units",
      201: "Its too late for change your opinion",
      202: "You can't join finished game. Only admin can do this",
      203: "Ouch! Somebody was faster than you!",
      204: "Supply is overloaded. You should kill more units",
      205: "Supply is overloaded",
      210: "You should move the units with the least damage. More units need to be saved",
      211: "You can move units only to adjacent lands or to lands connected by your ships at sea",
      212: "You can't move units to the area from which the attack came",
      213: "You can't retreat units at this area",
      214: "You can attack only one area with enemy",
      215: "You cant defeat neutral lord at {conflict_land}: {neutralGarrison} vs {agressorOveral}",

      301: "You should remove {del_unit_count} unit(s)",
      302: "You should choose your best track or remove {del_unit_count} units(s)",
      303: "The limit of active games has been reached. Please wait for some games to be removed and try again",
      304: "Impossible ban admin",
      305: "Only players who finished this game can grade other players",
      306: "You are already in another game. Try in separate window",
      307: "{del_unit_count} units need to be removed; the units will be converted into foot soldiers in the order they were selected. The last selected knights will perish. Available pawns: {pawns}",
      308: "Need to select no more than {upgrade_unit_count} foot soldier(s). They will be converted into knights. Available knights: {knites}",
      309: "You can only select your own foot soldiers",
      310: "You need to choose a home, click on wilding card",
      3001: "You have two cards with the same power left, and they will be discarded. The card you choose will remain in the discard pile after all other cards are restored. Be careful",
    },
  },
  uk: {
    errors: {
      "000": "Щось відпало на сервері. Був створений запис у лог-файл",
      "001": "Юзер з ідентифікатором '{user_id}' вже існує",
      "0022": "Юзер '{user_id}' був створений успішно",
      "003": "Юзер '{user_id}' не існує",
      "004": "Невірний пароль для '{user_id}', спробуй інший",
      "005": "Час сесії вичерпано. Потрібно перезайти",
      "006": "Невірна строка ініціації",
      "0066": "Адмін успішно створений",
      "007": "Юзер '{user_id}' немає паролю. Вхід можливий лише через Facebook або Google",
      "008": "Потрібно увійти",
      "0099": "Ти уже ніяхуя не адмін",
      "010": "Не вийшло надіслати новий пароль на пошту {email}",
      "0101": "Новий пароль був надісланий на пошту {email}",
      "0111": "Код був відправлений на пошту {email}",
      "011": "Помилка: код не був відправлений на пошту {email}",
      "0122": "Нова пошта: {email}",
      "012": "Невірний код підтвердження",
      "0133": "Пароль був успішно змінений: {password}",
      "014":
        "Недостатньо токенів. Потрібно {price}, тоді як на рахунку всього {money}. Поповни рахунок або виграй токени",
      "015": "Неможливо видалити базовий аватар. Його не обирають, як і батьків",
      "016":
        "Неможна відпрвляти токени, які вам подарував творець при реєстрації нового акаунту. Доступна кількість для відправки: {money} токенів",
      200: "{land_name}: ця локація недоступна для переміщення",
      201: "Уже запізно виправляти оцінку",
      202: "Тільки адмін може приєднатися то завершеної гри",
      203: "Не вспів!",
      204: "Ліміт постачання все ще перевищено. Треба прибрати більше юнітів",
      205: "Ліміт постачання перевищено",
      210: "Потрібно перемістити юнітів з найменшими втратами. Може бути врятовано більше юнітів",
      211: "Можна переміщати юнітів тільки на сусдні землі або на землі, які з'єднані морями, що знаходяться під вашим впливом",
      212: "Не можна відступати в зону, звідки відбувалась атака",
      213: "Неможна відступати у цю зону",
      214: "Можна розпочинати тільки один бій",
      215: "Не вистачає сил, щоб перемогти нейтрального Лорда: {conflict_land}: {neutralGarrison} проти {agressorOveral}",

      301: "Потрібно видалити {del_unit_count} юніта(ів)",
      302: "Обери найкращий трек або видали {del_unit_count} юніта(ів)",
      303: "Ліміт одночасно активних ігор вичерпано. Дочекайтесь закінчення хоча б однієї гри та спробуйте ще раз",
      304: "Ти що, з глузду з'їхав? Не можна банити адмінів",
      305: "Лише гравці, які дограли до кінця можуть ставити оцінки іншим гравцям",
      306: "Ти вже в іншій грі. Спробуй зайти у новому вікні",
      307: "Потрібно обрати {del_unit_count} юніта(ів). Юніти будуть перетворені на піших воїнів у порядку обрання. Останні обрані лицарі загинуть. Кількість доступних піших воїнів: {pawns}",
      308: "Потрібно обрати не більше {upgrade_unit_count} піших воїнів. Вони будуть перетворені на лицарів. Кількість доступних лицарів: {knites}",
      309: "Можна обирати лише своїх піших воїнів",
      310: "Потрібно обрати найнижчу ставку на карті дикунів",
      3001: "У вас залишилось дві карти з однаковою силою, вони будуть скинуті. Карту, яку ви оберете, залиишиться у скиді після відновлення всіх інших карт. Будьте уважні",
    },
  },
};
