<template>
  <div class="admininit_container">
    <LayerModalWrapper />
    <button @click="returnToGame">BACK</button>
    <div class="user_info_wr">
      <div class="name_wr">{{ user?.name }}</div>
      <div class="avatar_wr">
        <img :src="`/images/avatars/${user?.avatars[0]}`" alt="avatar" />
      </div>
    </div>
    <div class="input_wr">
      <input v-model="admin_secret" type="text" placeholder="secret key" />
      <button @click="sendAdminSecret">{{ $t(`message.confirm`) }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      admin_secret: "",
    };
  },
  computed: {
    ...mapGetters("general_store", ["user"]),
    ...mapState("general_store", ["error"]),
  },

  watch: {
    error(newValue) {
      if (!newValue) return;

      this.setFreeze({ reset: true });

      this.processModal({
        name: "error",
      });
    },
  },

  mounted() {
    if (this.error) {
      this.processModal({
        name: "error",
      });
    }
  },

  methods: {
    ...mapMutations("gui_store", ["setFreeze"]),
    ...mapMutations("general_store", ["processModal", "showError"]),
    sendAdminSecret() {
      if (!this.admin_secret.length) return;
      this.axios
        .post("/app/setAdmin", { user: this.user, key: this.admin_secret })
        .then((res) => {
          const { error } = res.data;
          this.showError({ error: { code: error.code, data: error.data } });
        })
        .catch((err) => {
          console.error("CATHCED ERROR: ", err);
        });
      this.admin_secret = "";
    },

    returnToGame() {
      this.$router.push({ name: "lobby" });
    },
  },
};
</script>

<style lang="scss" scoped>
.admininit_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  button {
    width: 150px;
    align-self: center;
    cursor: pointer;
  }
}

.user_info_wr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.name_wr {
  text-align: center;
  font-size: 2rem;
  color: rgb(97, 13, 125);
}

.input_wr {
  align-self: center;
  justify-self: center;

  button {
    cursor: pointer;
  }
}

.avatar_wr {
  img {
    width: 10rem;
    border-radius: 0.4rem;
  }
}
</style>
