<template>
  <div id="modal_base" class="modal_base" @click="closePassModal">
    <div class="pass_wr">
      <h3 v-if="isValid">{{ $t(`message.join_password`) }}</h3>
      <h3 v-else>{{ $t(`message.invalid_password`) }}</h3>

      <input v-model="password" type="password" :class="{ invalid: !isValid }" />
      <button :disabled="!password || password.length < 3" @click="applyPassword">
        {{ $t(`message.join_password_btn`) }}
      </button>
    </div>
  </div>
</template>

<script>
import bcrypt from "bcryptjs";
import { mapGetters, mapMutations, mapState } from "vuex";
const { compareSync } = bcrypt;
export default {
  data() {
    return {
      password: "",
      isValid: true,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user"]),
    ...mapState("general_store", ["data_for_modal"]),
  },

  methods: {
    ...mapMutations("general_store", ["setDataForModal", "processModal"]),

    closePassModal(e) {
      if (e.target.id !== "modal_base") return;
      this.data_for_modal?.cb?.(false);
      this.processModal({ name: "join_password", type: "close" });
      this.isValid = true;
      this.password = "";
    },
    applyPassword() {
      this.isValid = compareSync(this.password, this.data_for_modal.password);
      if (!this.isValid) return;
      this.data_for_modal?.cb?.(this.isValid);
      this.processModal({ name: "join_password", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 350;
  top: 0;
  left: 0;
}
.pass_wr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(37, 36, 36);
  color: white;
  padding: 2rem;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0.4rem;
  gap: 0.5rem;
  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  -webkit-box-shadow: -1px -4px 97px 31px rgb(246, 75, 75);
  -moz-box-shadow: -1px -4px 97px 31px rgb(246, 75, 75);
  box-shadow: -1px -4px 97px 31px rgb(246, 75, 75);
}

h3 {
  white-space: nowrap;
}

button {
  &:hover {
    cursor: pointer;
  }
}
input {
  border: 1px solid white;
  height: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  background-color: #292828;
  color: white;
  font-size: 0.85rem;
}

input:hover {
  border-color: rgb(87, 194, 236);
}
input:focus {
  outline: none;
  border-color: rgb(87, 194, 236);
}
input:invalid {
  border: 2px dashed red;
}

.invalid {
  border: 2px solid red;
}
</style>
