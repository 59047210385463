import app from "../main.js";
import store from "../store/index.js";

export default class CrusadeHandler {
  constructor() {
    this._seas = new Set();
    this.unit = null;
    this.clones_container = null;
  }

  beckup_gui() {
    const game = store.getters["general_store/game"];
    const lands_to_beckup = store.state.general_store.active_lands_beckup;
    if (!lands_to_beckup) return;
    if (!game) return;
    store.commit("general_store/beckupLands", {
      room_id: game.id,
      lands: lands_to_beckup,
    });
  }

  prepareGui() {
    this._seas.clear();
    this.initialOrdersPreset();
  }

  crusadeResetHandler() {
    this._seas.clear();
    store.commit("game_store/resetGameState");
    store.commit("gui_store/resetGuiState");
    this.beckup_gui();
    this.prepareGui();
  }

  initialOrdersPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];

    if (!state) return;
    if (!active_players) return;
    if (!active_players.length) return;

    const lands = state.lands;
    const states = state.states;
    const activePlayer = active_players[0];
    const home_state = states.find((st) => st.home === activePlayer);
    const occupied_lands = home_state.lands;

    for (let land_name of occupied_lands) {
      const land_obj = lands[land_name];
      const order = land_obj.order;

      if (!order.includes("crusade")) continue;
      if (!active_players.includes(land_obj.home)) continue;
      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
    }
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const dragState = store.state.game_store.drag_state;
    const home = store.getters["general_store/home"];
    const state = store.getters["general_store/state"];

    if (!e.target?.dataset?.source) return;
    if (!active_players.includes(home)) return;
    if (!e.target?.dataset?.ordertype.includes("crusade")) return;
    if (e.target?.dataset?.owner !== home) return;

    const nodeTarget = e.target;
    const from = nodeTarget.dataset.source;
    const prev_area = dragState.from;

    if (!prev_area || prev_area !== from) {
      this.crusadeResetHandler();
      store.commit("gui_store/setOrder", { key: "active_orders", land_name: from });
      store.commit("game_store/setDragState", { from });
      store.commit("gui_store/setGapLand", { land_name: from });
    } else if (prev_area === from) {
      this.crusadeResetHandler();
    }

    const lands = state.lands;
    const source_land_obj = lands[from];
    const av_lands = this.find_lands(source_land_obj);

    store.commit("gui_store/setAvailableLands", Array.from(av_lands));
  }

  async pointerdownHandler(e) {
    const dragState = store.state.game_store.drag_state;
    const source_name = dragState.from;

    if (!source_name) return;
    if (e.target.classList.contains("retreated")) return;
    if (e.target.dataset.source !== source_name) return;
    if (!e.target.classList.contains("selectedUnit")) {
      const unit = {
        id: e.target.id,
        land_name: e.target.dataset.source,
        owner: e.target.dataset.house,
        type: e.target.dataset.unit_type,
        force: e.target.dataset.force,
      };
      store.commit("gui_store/setSelectedUnits", { unit });
      return;
    }

    this.unit = e.target;
    this.unit.setPointerCapture(e.pointerId);

    const selected_units = store.state.gui_store.selected_units;
    const mapPosition = store.state.gui_store.mapPosition;
    const scale = mapPosition.scale;
    const clones = [];
    this.clones_container = document.createElement("div");

    selected_units.forEach(function (_unit) {
      const $u = document.getElementById(`${_unit.id}`);
      const clone = $u.cloneNode(true);
      clone.style.pointerEvents = "none";
      clones.push(clone);
      store.commit("gui_store/setDraggingUnits", { unit: _unit });
    });

    clones.forEach((clone) => {
      this.clones_container.appendChild(clone);
    });

    const default_styles = {
      width: "content-length",
      height: "auto",
      position: "absolute",
      display: "flex",
      color: "white",
      pointerEvents: "none",
    };

    const map = document.querySelector("#camera");
    Object.assign(this.clones_container.style, default_styles);
    map.appendChild(this.clones_container);

    default_styles.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
      e.clientY - this.clones_container.offsetHeight / 2
    }px) scale(${scale})`;

    Object.assign(this.clones_container.style, default_styles);

    this.unit.onpointermove = (e) => {
      this.clones_container.style.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
        e.clientY - this.clones_container.offsetHeight / 2
      }px) scale(${scale})`;

      let elementBelow = document.elementFromPoint(e.clientX, e.clientY);
      store.commit("game_store/setCurrentArea", {
        land_name: elementBelow.dataset?.source,
        owner: elementBelow.dataset?.owner,
      });
    };

    this.unit.onpointerup = this.dragEnd.bind(this);

    this.unit.onpointercancel = (e) => {
      this.removeDragClones();
      this.crusadeResetHandler();
    };
  }

  validate(data = {}) {
    const { source_land_name } = data;
    if (!source_land_name) return false;
    return true;
  }

  find_lands(source_land) {
    if (!source_land) return [];
    this._seas.clear();
    const lands = source_land.adjacent_lands.reduce(this.reduceLoop.bind(this), new Set());

    return lands;
  }

  dragEnd(e) {
    const current_area = store.state.game_store.current_area_under_cursor;
    const game = store.getters["general_store/game"];
    const state = store.getters["general_store/state"];
    const lands = state.lands;
    const home = store.getters["general_store/home"];
    const avLands = store.state.gui_store.available_lands;
    const dragState = store.state.game_store.drag_state;

    if (current_area?.land_name === dragState.from) {
      this.removeDragClones();
      this.crusadeResetHandler();

      return;
    }

    if (!current_area?.land_name || !avLands.includes(current_area?.land_name)) {
      const l = app.config.globalProperties?.$t(`land.${current_area?.land_name}`);
      this.removeDragClones();
      store.commit("general_store/showError", {
        error: {
          code: "200",
          data: {
            land_name: l || "empty",
          },
        },
      });
      return;
    }

    const selected_units = store.state.gui_store.selected_units;
    const tempUnits = Array.from(selected_units.values());

    tempUnits.forEach((unit) => {
      unit.land_name = current_area.land_name;
    });

    store.commit("game_store/setDragState", {
      from: dragState.from,
      units: tempUnits,
      token: "",
    });

    store.commit("general_store/moveUnits", { room_id: game.id, drag_state: dragState });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setDraggingUnits", { reset: true });
    const player_state = state.states.find((s) => s.home === home);

    const token_is_in_state = player_state.tokens.length;
    const no_units_left = !lands[dragState.from].units.length;
    const this_no_my_capital = lands[dragState.from].capital !== home;
    const no_token_on_land = !lands[dragState.from].token;
    const land_in_not_port = !dragState.from.includes("_port");
    const land_in_not_sea = !lands[dragState.from].sea;
    if (
      token_is_in_state &&
      no_units_left &&
      this_no_my_capital &&
      no_token_on_land &&
      land_in_not_port &&
      land_in_not_sea
    ) {
      store.commit("game_store/setShallowToken", { land_name: dragState.from });
    }

    this.removeDragClones();
  }

  removeDragClones() {
    if (this.unit) {
      this.unit.onpointermove = null;
      this.unit.onpointerup = null;
      this.unit.onpointercancel = null;
    }

    const map = document.querySelector("#camera");
    if (this.clones_container && map.contains(this.clones_container)) {
      map.removeChild(this.clones_container);
    }
  }

  reduceLoop(landsSet, land_name) {
    const state = store.getters["general_store/state"];
    const lands = state.lands;
    const dragState = store.state.game_store.drag_state;
    const source_land_name = dragState.from;
    const cand = lands[land_name];

    if (!source_land_name) return landsSet;
    const source_land = lands[source_land_name];
    if (!source_land) return landsSet;
    const home = source_land.home;
    if (source_land_name.includes("_port")) {
      const allow_land = source_land.allow_sea;
      landsSet.add(allow_land);
      return landsSet;
    }
    if (source_land?.sea) {
      // const cand = lands[land_name];
      if (!cand.sea && !land_name.includes("_port")) {
        return landsSet;
      }
      if (land_name.includes("_port")) {
        if (cand.home !== home) {
          return landsSet;
        }
      }
      landsSet.add(land_name);
      return landsSet;
    }

    if (land_name.includes("_port")) {
      return landsSet;
    }

    // const cand = lands[land_name];
    const isCandLock = !cand.home || cand.home !== home;

    const isLandDisable = cand.garrison > 20;

    if (isLandDisable) {
      return landsSet;
    }

    if (cand?.sea && !this._seas.has(land_name)) {
      this._seas.add(land_name);
      if (isCandLock) {
        return landsSet;
      }
      return cand.adjacent_lands.reduce(this.reduceLoop.bind(this), landsSet);
    }
    if (!cand?.sea && land_name !== source_land.title) {
      landsSet.add(land_name);
    }

    return landsSet;
  }

  allowCursorPointer(unit) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const dragState = store.state.game_store.drag_state;
    const source_name = dragState.from;

    if (!active_players.includes(home)) return;
    if (unit.owner !== home) return;
    if (unit.retreated) return;
    if (!source_name) return;
    return true;
  }
}
