<template>
  <div id="modal_base" class="modal_base" @pointerdown="closeModal">
    <div class="modal_wr">
      <form @submit.prevent>
        <div class="room">
          <div class="legacy_form">
            <label for="email-field"
              ><strong>{{ $t(`message.enter_email`) }}</strong></label
            >
            <br />
            <input id="email-field" v-model="email" autocomplete="email" required type="text" @input="inputFn" />

            <div class="btn" :class="{ enable_btn: resetEnable }" @pointerdown="apllyChange">
              {{ $t(`message.reset_password`) }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      email: "",
      resetEnable: false,
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
      response: null,
    };
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "showError"]),
    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.resetForm();
      this.response = null;
      this.processModal({ name: "reset_password", type: "close" });
    },
    resetForm() {
      this.email = "";
      this.resetEnable = false;
    },
    inputFn() {
      if (!this.pattern.test(this.email)) {
        this.resetEnable = false;
        return;
      }
      this.resetEnable = true;
    },
    async apllyChange(e) {
      if (!e.target.classList.contains("enable_btn")) return;

      this.email = this.email.trim().toLowerCase();
      if (!this.pattern.test(this.email)) {
        alert("email is invalid");
        return;
      }

      this.response = await this.axios
        .post("/app/reset_password", {
          email: this.email,
        })
        .catch((err) => {
          this.showError({
            error: { code: "000", message: err.message },
          });
        });

      this.resetForm();
      this.processModal({
        name: "reset_pasword",
        type: "close",
      });
      this.showError({
        error: this.response.data.error,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  pointer-events: all;
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 35;
  top: 0;
  left: 0;
}

.modal_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-height: 80%;
  max-width: 80%;

  color: white;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.5rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  -moz-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
}

.room {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.legacy_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    margin-bottom: 1rem;
    height: 2rem;
    width: 80%;
    background-color: rgb(134, 184, 185);
    color: rgb(22, 67, 68);
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.4rem;
  }
  input::placeholder {
    font-size: 0.8rem;
    white-space: pre-line;
  }
  .btn {
    width: 80%;
    text-align: center;
    box-sizing: border-box;
    min-height: 2rem;
    color: rgb(143, 145, 145);
    background-color: rgb(105, 112, 115);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
    border-radius: 0.4rem;
    cursor: not-allowed;
  }

  .enable_btn {
    cursor: pointer;
    color: rgb(213 255 254);
    background-color: rgb(87 124 140);
  }
}
</style>
