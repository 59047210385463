<template>
  <div class="user_list_container">
    <div v-if="show_game_map" class="gap_for_logger"></div>
    <div class="users_tab_header_container">
      <h3>{{ $t("message.players_rate") }}</h3>
      <div class="tap_filters">
        <div class="online-filter">
          <input id="onlineFilter" v-model="onlineFilter" type="checkbox" />
          <label for="onlineFilter">{{ $t("message.online_checkbox") }}</label>
        </div>
        <div class="filters">
          <div class="filter_item">
            <input id="friends_filter" v-model="filter" type="radio" value="friends" />
            <span
              class="material-icons"
              @pointerenter="hint_label_hover($event, { msg: 'hint_friends' })"
              @pointerleave="resetHint"
            >
              people
            </span>
          </div>
          <div class="filter_item">
            <input id="wins_filter" v-model="filter" type="radio" value="win_overal" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_win_overal' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: 'rgb(253 248 0)',
              }"
            >
              emoji_events
            </span>
          </div>
          <div class="filter_item">
            <input
              id="intelligence_filter"
              v-model="filter"
              type="radio"
              value="intelligence_overal"
              class="filter_btn"
            />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_intelligence' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: '#bfbff1',
              }"
            >
              psychology
            </span>
          </div>
          <div class="filter_item">
            <input id="fullGames_filter" v-model="filter" type="radio" value="full_games_count" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_fullgames_overal' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: '#75cf75',
              }"
            >
              verified
            </span>
          </div>
          <div class="filter_item">
            <input id="honor_filter" v-model="filter" type="radio" value="full_games_percent" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_honor' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: 'orange',
              }"
            >
              stars
            </span>
          </div>
          <div class="filter_item">
            <input id="hours_filter" v-model="filter" type="radio" value="total_hours_count" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_hours_overal' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: '#e26464',
              }"
            >
              query_builder
            </span>
          </div>
          <div class="filter_item">
            <input id="charm_filter" v-model="filter" type="radio" value="charm" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_charm' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: '#da1717',
              }"
            >
              favorite
            </span>
          </div>
          <div class="filter_item">
            <input id="money_filter" v-model="filter" type="radio" value="money_count" class="filter_btn" />

            <span
              @pointerenter="hint_label_hover($event, { msg: 'hint_money' })"
              @pointerleave="resetHint"
              class="material-icons filter_btn"
              :style="{
                color: 'green',
              }"
            >
              currency_bitcoin
            </span>
          </div>
        </div>
      </div>
      <div class="filter">
        <label for="usersFilter" class="filter-label">{{ $t("message.players_search") }}</label>

        <input id="usersFilter" v-model="filterValue" type="text" />
      </div>
    </div>

    <ListUsers :visible-users="visibleUsers" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      onlineFilter: false,
      filterValue: "",
      filter: "win_overal",
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user", "show_game_map"]),
    ...mapState("general_store", ["users"]),

    visibleUsers() {
      let users = [];
      if (!this.users) return [];
      if (this.onlineFilter) {
        users = this.users.filter((user) => {
          return user.online;
        });
      } else {
        users = [...this.users];
      }

      if (this.filter === "friends") {
        users = users.filter((user) => {
          return this.user.my_friends.includes(user.id);
        });
      }

      if (this.filterValue) {
        users = users.filter((user) => user.name.includes(this.filterValue));
      }
      return users.sort((b, a) => {
        if (b.role?.value === "admin") return -1;
        if (a.role?.value === "admin") return 1;
        let flag;
        let filter = this.filter;
        if (this.filter === "freinds") {
          filter === "win_overal";
        }
        if (filter === "money_count") {
          flag = +a.money.total - +b.money.total;
        } else {
          flag = +a.stats[filter] - +b.stats[filter];
        }
        if (flag === 0) {
          return +b.creation_time - +a.creation_time;
        }
        return flag;
      });
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },
  },
};
</script>

<style lang="scss" scoped>
.user_list_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  color: white;
}

h3 {
  margin: 0;
  padding: 0;
}

.gap_for_logger {
  width: 100%;
  min-height: 3.5rem;
}
.users_tab_header_container {
  display: flex;
  height: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  // height: 100%;
  padding: 1rem;
  padding-top: 0;
  // margin-bottom: 0.2rem;
  box-sizing: border-box;
}

.tap_filters {
  display: flex;

  .online-filter {
    margin: 5px 0;
  }

  .online-filter label {
    font-size: 1rem;
  }
}

@media screen and (max-height: 440px) {
  .users_tab_header_container {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 30%;
    margin-bottom: 0.1rem;
    margin-top: 0.5rem;
    // padding-top: 0.1rem;

    .tap_filters {
      display: none;
    }
  }
  .tap_filters {
    flex: 1;
    flex-direction: column;
  }

  .filter {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    flex: 1;
  }

  .filter label {
    width: 100% !important;
    text-align: center;
  }

  h3 {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .user_list_container {
    width: 100%;
    padding-top: 3rem;
  }
  .users_tab_header_container {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    gap: 0.5rem;
    height: 30%;
    // margin-top: 0.5rem;
  }
  .tap_filters {
    flex: 1;
    flex-direction: row;
  }
}

@media screen and (max-height: 700px) {
  h3 {
    display: none;
  }
}

.filters {
  width: 100%;
  display: flex;
  justify-content: space-around;
  .filter_item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filter_btn {
    cursor: pointer;
  }
}

.filter {
  font-size: 16px;
  // margin: 10px 0;
  display: flex;
  width: 90%;
  align-items: center;
  .filter-label {
    width: 30%;
  }
  input {
    margin-left: 10px;
    background-color: #292828;
    color: white;
    border: 1px solid white;
    height: 20px;
    width: 100%;
  }

  input:hover {
    border-color: rgb(87, 194, 236);
  }
  input:focus {
    outline: none;
    border-color: rgb(87, 194, 236);
  }
}
</style>
