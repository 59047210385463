<template>
  <div class="lobby_container">
    <audio ref="exec_sound" src="/audio/crow_sound.mp3"></audio>
    <audio ref="timer_sound" src="/audio/timer_sound.wav"></audio>
    <audio ref="battle_sound" src="/audio/battle_sound_2.mp3"></audio>
    <audio ref="round_sound" src="/audio/round_sound.mp3"></audio>
    <audio ref="message_sound" src="/audio/message_sound.mp3"></audio>

    <LayerModalWrapper />
    <Transition name="loadScreen-fade">
      <div v-show="show_load_screen" class="load_screen">
        <clip-loader :loading="show_load_screen" color="white" size="150px"></clip-loader>
      </div>
    </Transition>

    <Transition name="loadScreen-fade">
      <div v-show="showLogOutScreen" class="load_screen">
        <clip-loader :loading="showLogOutScreen" color="white" size="150px"></clip-loader>
      </div>
    </Transition>
    <Transition>
      <div v-show="isHistoryModeOn" class="history_mode_hint"></div>
    </Transition>
    <Transition>
      <div v-show="freeze.freeze" class="freeze_mode_hint" :class="{ [`freeze_${freeze.color}`]: freeze.freeze }"></div>
    </Transition>

    <Transition>
      <div v-if="hint_label.msg" class="hint_label" :style="{ transform: hint_label.coords }">
        <span>{{ $t(`message.${hint_label.msg}`, { data: hint_label.data }) }}</span>
      </div>
    </Transition>

    <Transition name="slide-fade">
      <div v-if="showMoneyChangeHint" class="money_change_hint">
        <span>{{ showMoneyChangeHint }} $</span>
      </div>
    </Transition>

    <div v-if="user?.locale" class="tools_layer">
      <NavButtonsTop />
      <div class="sidebars_wrapper">
        <SideBarLeft />
        <ListGames v-if="!show_game_map && rooms.length > 1" />
        <SideBarRight v-if="show_game_map" :handler="handler" @exec="exec" />
      </div>
      <ExecBtn v-if="inside_room" @exec="exec" />
    </div>
    <LayerGameField v-if="!show_load_screen && show_game_map" ref="gameField" :handler="handler" />
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState, useStore } from 'vuex'

  import { io } from 'socket.io-client'
  import getDataForEngine from '../utils/getDataForEngine.js'
  import { getHandler } from '../utils/getPhaseHandler.js'

  export default {
    provide() {
      try {
        const store = useStore()
        const general_store = store?.state.general_store

        if (general_store?.socket) {
          this.socket = general_store.socketsocket
          return { socket: general_store?.socket }
        }

        const url = import.meta.env.VITE_CLIENT_URI
        console.log('provide ~ url:', url)

        this.socket = io(url, {
          query: { user_id: this.my_user_id, ssid: this.sid },
          reconnection: true,
          autoConnect: false,
          withCredentials: true,
        })

        if (!this.socket) {
          return { socket: null }
        }

        store.commit('general_store/set_socket', this.socket)

        this.socket.on('update_from_server', (data = {}) => {
          const { process, payload } = data
          this.setFreeze({ reset: true })
          if (!process) return
          if (!this[process]) {
            console.error(`you need to create mutation with name ${process}`)
            return
          }

          if (process === 'processMessage' && payload.type === 'add') {
            if (payload.chat_id && payload.message.sender_id !== this.user.id) {
              this.playSound('message')
            }
          }
          this[process](payload)
        })

        this.socket.on('connect', () => {
          console.log('connect success', this.socket.id)
          this.setFreeze({ reset: true })
        })
        this.socket.on('disconnect', async (err) => {
          console.log('socket was disconnected from client...')
          this.setFreeze({ freeze: true, color: 'red' })
        })
        this.socket.connect()
      } catch (e) {
        console.error(`SOCKET CONNECTION WAS FAILED: `, e)
      }
      return {
        socket: this.socket,
      }
    },

    data() {
      return {
        showMoneyChangeHint: false,
        handler: null,
        socket: '',
        soundTimeout: null,
        showLogOutScreen: false,
      }
    },

    head() {
      return {
        title: 'AGOT Online Board Game',
      }
    },
    computed: {
      ...mapState('general_store', ['my_user_id', 'error', 'rooms', 'sid', 'active_lands_beckup']),
      ...mapState('gui_store', ['show_load_screen', 'hint_label', 'freeze']),
      ...mapState('game_store', ['trigerExecutor']),
      ...mapGetters('game_store', ['engineData']),

      ...mapGetters('general_store', [
        'home',
        'current_game_id',
        'inside_room',
        'user',
        'game',
        'state',
        'round',
        'original_round',
        'phase_name',
        'active_players',
        'myRole',
        'crusade_state',
        'lastWildingsCard',
        'wildingsState',
        'isHistoryModeOn',
        'show_game_map',
        'socket_from_store',
      ]),

      sound() {
        return this.user?.sound_settings
      },
    },

    watch: {
      lastWildingsCard(newV, oldV) {
        if (newV) {
          if (!['WildingManual', 'ThroneDecision'].includes(this.phase_name)) return
          this.processModal({ name: 'wilding_card' })
        }
      },
      show_game_map() {
        this.setLoadScreen(true)
        setTimeout(() => {
          this.setLoadScreen(false)
        }, 1500)
      },
      sound: {
        handler(newSettings, oldSettings) {
          if (!oldSettings || !newSettings) return
          const changedKeys = Object.keys(newSettings).reduce((acc, key) => {
            if (newSettings[key] !== oldSettings[key]) {
              acc.push(key)
            }
            return acc
          }, [])
          changedKeys.forEach((key) => {
            if (key === 'main') return
            if (newSettings[key]) {
              this.playSound(key)
            } else {
              this.stopSound(key)
            }
          })
        },
        deep: true,
      },
      trigerExecutor() {
        if (this.trigerExecutor) {
          this.exec()
          this.setExecTriger({ doit: false })
        }
      },

      original_round(newRound, oldRound) {
        if (this.isHistoryModeOn) return

        if (!this.original_round || this.original_round < 2) return
        if (!this.state?.westeros_0) return
        if (this.game.state.status === 'end') return
        this.processModal({ name: 'round_changed' })
        if (newRound > oldRound) {
          this.playSound('round')
        }
      },

      'user.money.total'(newV, oldV) {
        this.showMoneyChangeHint = newV - oldV
        if (this.showMoneyChangeHint > 0) {
          this.showMoneyChangeHint = '+' + this.showMoneyChangeHint
        }
        setTimeout(() => {
          this.showMoneyChangeHint = false
        }, 100)
      },
      error() {
        if (!this.handler) {
          const options = {
            phase_name: this.phase_name,
            role: this.myRole,
            wildings_card: this.lastWildingsCard,
            wildingsState: this.wildingsState,
          }
          this.handler = getHandler(options)
        }

        if (!this.error) {
          this.handler?.prepareGui?.()
          return
        }
        this.setFreeze({ reset: true })
        this.resetGameState()
        this.resetGuiState()
        if (this.game) {
          this.beckupLands({
            room_id: this.game?.id,
            lands: this.active_lands_beckup,
          })
        }
        this.processModal()
        this.processModal({
          name: 'error',
        })
      },
      home() {
        if (!this.inside_room) return
        const options = {
          phase_name: this.phase_name,
          role: this.myRole,
          wildings_card: this.lastWildingsCard,
          wildingsState: this.wildingsState,
        }
        this.handler = getHandler(options)
        this.beckupLands({
          room_id: this.game.id,
          lands: this.active_lands_beckup,
        })
        this.resetGameState()
        this.resetGuiState()
        this.handler?.prepareGui?.()
        if (this.active_players.includes(this.home)) {
          this.playSound('timer')
        }
      },

      active_players() {
        if (!this.active_players) return
        if (!this.inside_room) return
        const options = {
          phase_name: this.phase_name,
          role: this.myRole,
          wildings_card: this.lastWildingsCard,
          wildingsState: this.wildingsState,
        }
        this.handler = getHandler(options)

        if (this.active_players.includes(this.home)) {
          if (this.phase_name !== 'GameOver') {
            this.playSound('timer')
          }
        }
        this.resetGameState()
        this.resetGuiState()
        this.handler?.prepareGui?.()
      },

      phase_name(new_name, old_name) {
        const options = {
          phase_name: this.phase_name,
          role: this.myRole,
          wildings_card: this.lastWildingsCard,
          wildingsState: this.wildingsState,
        }
        this.handler = getHandler(options)

        this.resetGameState()
        this.resetGuiState()
        this.handler?.prepareGui?.()

        if (this.phase_name === 'GameOver') {
          if (!old_name || new_name === old_name) return
          this.playSound('end')
        }

        if (!this.inside_room) return
        const crusadePhases = [
          'ConflictPort',
          'DefineSupport',
          'SelectCard',
          'FirstCardAction',
          'UseSword',
          'LastCardAction',
          'PreAttackConflictPort',
        ]
        if (!this.isHistoryModeOn && crusadePhases.includes(this.phase_name)) {
          this.playSound('battle')
        }
      },

      'user.locale'(newValue) {
        console.log('locale triger2')
        if (!newValue) return
        this.$i18n.locale = newValue
      },
    },

    mounted() {
      if (!this.socket) {
        this.socket = this.socket_from_store
      }
      this.$i18n.locale = this.user?.locale
      this.map = this.$refs.gameField?.$refs.zoom
      this.setFreeze({ reset: true })
      const options = {
        phase_name: this.phase_name,
        role: this.myRole,
        wildings_card: this.lastWildingsCard,
        wildingsState: this.wildingsState,
      }
      this.handler = getHandler(options)
      this.resetGameState()
      this.resetGuiState()
      this.handler?.prepareGui?.()
    },

    methods: {
      ...mapMutations('general_store', [
        'logOut',
        'processUser',
        'updateUsers',
        'processMessage',
        'updateMessages',
        'deleteMessages',
        'updatePlayer',
        'deleteTempMessage',
        'setTempPrivateChat',
        'resetState',
        'newRoom',
        'restoreRooms',
        'restoreChats',
        'setTempState',
        'retreiveGame',
        'joinGame',
        'setToken',
        'setOrder',
        'setPlayer',
        'moveUnits',
        'setTrack',
        'showError',
        'beckupLands',
        'updateRoomFields',
        'restoreRoomsAndPrivateChats',
        'restorePrivateChats',
        'processModal',
        'setDataForModal',
        'setTimer',
        'setBeckupLands',
        'deleteRoom',
      ]),

      ...mapMutations('gui_store', ['resetGuiState', 'setGameSideTab', 'setMainSideTab', 'setFreeze', 'setLoadScreen']),
      ...mapMutations('game_store', ['resetGameState', 'setExecTriger', 'selectCard']),

      closeTab() {
        window.close()
      },

      playSound(sound) {
        if (!this.sound[sound]) return
        const $sound = this.$refs[`${sound}_sound`]
        if (!$sound) return
        if ($sound.readyState >= 4) {
          // 4 means HAVE_ENOUGH_DATA
          // The audio is ready to play
          $sound.play()
        } else {
          // If the audio is not ready, load it and wait for canplaythrough event
          $sound.addEventListener(
            'canplaythrough',
            () => {
              $sound.play()
            },
            { once: true }
          )

          // Handle errors for unsupported sources or other playback issues
          $sound.addEventListener(
            'error',
            (e) => {
              console.error(`Error playing sound: ${sound}`, e)
              // You can add additional handling here, such as showing a user message
            },
            { once: true }
          )

          // Attempt to load the audio file
          $sound.load()
        }
        // $sound?.play();
      },
      stopSound(sound) {
        const $sound = this.$refs[`${sound}_sound`]
        if (!$sound) return
        $sound.pause()
        // $sound.currentTime = 0;
      },

      async leaveApp() {
        this.showLogOutScreen = true

        await this.axios.post('/app/logout')

        setTimeout(
          function () {
            this.$router.push('/')
            this.showLogOutScreen = false
            this.logOut()
            this.resetState()
            this.socket.close()
          }.bind(this),
          2000
        )
      },

      updateTime(payload) {
        console.log('🚀 ~TIMER', payload)

        if (this.active_players.includes(payload.home)) {
          if (payload.time < 11) {
            this.playSound('timer')
            return
          }
          if (payload.time % 30) return
          this.playSound('timer')
        }
      },

      notifyUser({ sender, data, type }) {
        let msg, img
        if (type === 'invitation') {
          const game = data?.game
          img = `/avatars/${sender.avatar}`

          msg = this.$t(`phrase.invitation`, {
            name: game?.name,
            round: game?.round,
            status: this.$t(`message.${game?.status}_short`),
          })
        }

        if (type === 'friendJoinedGame') {
          img = `/avatars/${sender.avatar}`
          msg = this.$t(`phrase.friendJoinedGame`, {
            sender_name: sender?.name,
            sender_home: this.$t(`home.${sender?.home}`),
            game_title: data.game_title,
          })
        }
        if (type === 'friendFinishedGame') {
          img = `/avatars/${sender.avatar}`
          msg = this.$t(`phrase.friendFinishedGame`, {
            sender_name: sender?.name,
            sender_home: this.$t(`home.${sender?.home}`),
            game_title: data.game_title,
            place: data.place,
          })
        }

        if (Notification.permission !== 'denied') {
          // We need to ask the user for permission
          Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              new Notification(`${sender.name}:`, { body: `${msg}`, icon: img })
              // …
            }
          })
          return
        }
        new Notification(`${sender.name}:`, { body: `${msg}`, icon: img })
      },

      execSignal() {
        this.playSound('exec')
      },

      async exec() {
        const data = getDataForEngine(
          this.engineData,
          this.phase_name,
          this.home,
          this.crusade_state,
          this.wildingsState
        )
        let valid = true
        if (this.handler && 'validate' in this.handler) {
          valid = this.handler.validate?.(data)
        }
        if (!valid) {
          this.setFreeze({ freeze: true, color: 'red' })
          setTimeout(() => {
            this.setFreeze({ reset: true })
          }, 500)
          return
        }
        this.socket.emit('client_action', {
          action_type: 'Exec',
          data: {
            data,
            sid: this.sid,
            user_name: this.user.name,
            user_id: this.user.id,
            phase_name: this.phase_name,
            room_id: this.current_game_id,
          },
        })
        this.resetGuiState()
        this.resetGameState()
        // this.handler?.clearGui?.();
        this.setFreeze({ freeze: true, color: 'red' })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .lobby_container {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .tools_layer {
    position: absolute;
    z-index: 150;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }

  .sidebars_wrapper {
    display: flex;
    position: relative;
    // background-color: pink;
    height: 100%;
  }

  .load_screen {
    background-color: rgba(0, 0, 0, 0.977);
    // background-image: url("/load_screen.jpg");
    // background-size: cover;
    position: absolute;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    // img {
    //   height: auto;
    //   width: 100%;
    // }
  }

  // .loader_wr {
  //   position: relative;
  //   text-align: center;
  //   margin: auto;
  //   width: 500px;
  //   height: 500px;
  // }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .loadScreen-fade-enter-active {
    transition: opacity 0s;
  }

  .loadScreen-fade-leave-active {
    transition: all 3s;
  }

  .loadScreen-fade-enter-from,
  .loadScreen-fade-leave-to {
    // transform: translateY(-430px);
    opacity: 0;
  }
  .slide-fade-enter-active {
    transition: opacity 0.3s;
  }

  .slide-fade-leave-active {
    transition: all 3s;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-430px);
    opacity: 0;
  }
  .history_mode_hint {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(#f8f8f800 60%, #0dc1f385);
    z-index: 500;
    pointer-events: none;
  }
  .freeze_mode_hint {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(#f8f8f800 60%, #c6080ecc);
    z-index: 500;
    pointer-events: none;
  }
  .freeze_red {
    background: radial-gradient(#f8f8f800 60%, #c6080ecc);
  }
  .freeze_orange {
    background: radial-gradient(#f8f8f800 60%, #faa628db);
  }
  .freeze_pink {
    background: radial-gradient(#f8f8f800 60%, #d11bd7cc);
  }

  .money_change_hint {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: green;
    background-color: rgb(95, 93, 93);
    width: fit-content;
    height: fit-content;
    font-size: 3rem;
    border-radius: 15px;
    padding: 10px;
    // width: 500px;
    // height: 500px;
    z-index: 200;
  }

  .hint_label {
    position: absolute;
    z-index: 151;
    top: 0px;
    left: 0px;
    width: auto;
    max-width: 140px;
    height: auto;
    padding: 3px;
    text-align: center;
    background-color: rgb(51, 52, 52);
    font-size: 1rem;
    color: white;
    padding: 5px;
    pointer-events: none;
    border-radius: 5px;

    span {
      display: block;
      width: auto;
      word-break: break-word;
      // white-space: nowrap;
    }
  }
</style>
