import store from "../store/index.js";
export default class HordeHandler {
  constructor() {
    this.temp_selected_castle = "";
    this.permit_different_areas = false;
  }

  prepareGui() {
    this.resetGui();
    this.initialUnitsPreset();
    this.initialOrdersPreset();
  }

  resetGui() {
    this.temp_selected_castle = "";
    this.permit_different_areas = false;
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setGapLand", { reset: true });
    store.commit("game_store/processSeparateUnits", { reset: true });
  }

  initialUnitsPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = active_players?.[0];

    if (!state) return;
    if (phase_name !== "WildingManual") return;

    const wildingsState = state.wildings_state;

    if (wildingsState.is_victory) return;

    const lowestHome = wildingsState.lowest_candidates[0];

    const units_count_flag = lowestHome === home ? 2 : 1;

    this.lands_to_hint_units = this.getLandsWithEnoughUnits(home, units_count_flag);

    if (lowestHome === home) {
      this.lands_to_hint_units = this.getLandsWithEnoughUnits(home, units_count_flag, true);

      if (!this.lands_to_hint_units.length) {
        this.lands_to_hint_units = this.getLandsWithEnoughUnits(home, 1);
        this.permit_different_areas = true;
      }
    }

    this.lands_to_hint_units.forEach((land_name) => {
      store.commit("gui_store/setGapLand", { land_name });
    });
  }

  initialOrdersPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];

    if (!state) return;
    if (phase_name !== "WildingManual") return;

    const wildingsState = state.wildings_state;

    if (!wildingsState.is_victory) return;

    const lands = state.lands;
    const states = state.states;
    const activePlayer = active_players[0];
    const home_state = states.find((st) => st.home === activePlayer);
    const occupied_lands = home_state.lands;

    for (let land_name of occupied_lands) {
      const land_obj = lands[land_name];
      const order = land_obj.order;
      if (!order.includes("power_spec")) continue;
      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
    }
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    if (!active_players.includes(home)) return;
    const state = store.getters["general_store/state"];
    if (!state) return;
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "WildingManual") return;
    const wildingsState = state.wildings_state;
    if (wildingsState.is_victory) return;

    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;

    if (!this.lands_to_hint_units.includes(e.target.dataset.source)) return;
    if (
      !this.permit_different_areas &&
      this.temp_selected_castle &&
      this.temp_selected_castle !== e.target.dataset.source
    ) {
      store.commit("gui_store/setSelectedUnits", { reset: true });
      store.commit("game_store/processSeparateUnits", { reset: true });
      this.temp_selected_castle = null;
    }

    const lowestHome = wildingsState.lowest_candidates[0];
    const max_count = lowestHome === home ? 2 : 1;
    const separate_units = store.state.game_store.separate_units;
    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };

    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });

    this.temp_selected_castle = e.target.dataset.source;
    if (separate_units.size > max_count) {
      this.temp_selected_castle = "";
      store.commit("gui_store/setSelectedUnits", { reset: true });
      store.commit("game_store/processSeparateUnits", { reset: true });
      this.temp_selected_castle = null;
      return;
    }
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const home = store.getters["general_store/home"];
    if (unit.owner !== home) return;
    return true;
  }

  getLandsWithEnoughUnits(home, units_count_flag, needCastle = false) {
    const state = store.getters["general_store/state"];
    const lands = state.lands;
    const home_states = state.states.find((st) => st.home === home);
    const occupied_lands = home_states.lands;
    let lands_with_enough_units = occupied_lands.filter(
      (land_name) => lands[land_name].units.length >= units_count_flag
    );
    if (needCastle) {
      lands_with_enough_units = lands_with_enough_units.filter((land_name) => lands[land_name].master_points);
    }
    return lands_with_enough_units;
  }
}
