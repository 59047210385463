<template>
  <div class="player_list_container">
    <div class="gap_for_logger"></div>
    <div v-for="(_user, i) in users" :key="i" class="unit" :class="{ myself: user?.id === _user.id }">
      <div class="avatar">
        <img
          :src="`/images/avatars/${_user?.avatars?.[0]}`"
          alt="ava"
          class="ava_img"
          :class="{ offline: !getOnlineStatus(_user.id) }"
        />

        <div v-if="_user.home !== 'watcher'" class="home_label">
          <img :src="`/images/tokens/${_user.home}_token.png`" :alt="_user.home || 'watcher'" />
          <div class="timer">{{ get_user_timer(_user) }}</div>
        </div>
      </div>
      <div class="info">
        <div v-if="showJoinGameBtn(_user)" class="join_btn" @click="joinGame(_user.home)">
          {{ $t(`message.join_game`) }}
        </div>
        <span v-if="_user.name" class="user-status">{{ _user.name }}</span>
        <span
          v-if="showPrivateMsgButton(_user)"
          class="material-icons private_chat_btn"
          @click="openPrivateChatTab($event, _user.home)"
        >
          mail
        </span>
        <span
          v-if="_user.home !== 'watcher'"
          class="material-icons player_log_btn"
          @click="openPlayersLogModal($event, _user)"
        >
          toc
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { genPrivateChatId } from "../utils/utilsFrontend.js";
export default {
  inject: ["socket"],
  computed: {
    ...mapGetters("general_store", [
      "mainRoom",
      "current_game_id",
      "game_private_chats",
      "main_private_chats",
      "isAdmin",
      "user",
      "isMainAdmin",
      "home",
      "game",
    ]),
    ...mapState("general_store", ["timers", "sid"]),

    ...mapState({
      main_users(state) {
        return state.general_store.users;
      },
    }),

    users() {
      return this.game?.players.concat(this.game?.users);
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setGameSideTab", "setHintLabel"]),
    ...mapMutations("general_store", ["setTempPrivateChat", "selectPrivateChat", "setDataForModal", "processModal"]),
    openPlayersLogModal(e, player) {
      this.setDataForModal({
        data: {
          player,
        },
      });
      this.processModal({
        name: "players_log",
      });
    },
    joinGame(home) {
      if (this.user.ban.play.value) {
        this.socket.emit("client_action", {
          action_type: "BanUser",
          data: {
            user_id: this.user.id,
            target_user_id: this.user.id,
            update: true,
          },
        });
        return;
      }

      let av_houses = this.game.players.reduce((acc, player) => {
        if (!player.id) {
          acc.push(player.home);
        }
        return acc;
      }, []);
      const buy = av_houses.length === 1 ? false : true;

      if (buy || this.game.game_options.bank.buy_in) {
        this.setDataForModal({
          data: {
            buyin: this.game.game_options.bank.buy_in,
            select_home: buy,
            cb: (result) => {
              if (!result) return;
              this.socket.emit("client_action", {
                action_type: "JoinGame",
                data: {
                  room_id: this.game.id,
                  user_id: this.user.id,
                  home,
                  sid: this.sid,
                  buy,
                },
              });
            },
          },
        });
        this.processModal({ name: "join_game" });
        return;
      }

      this.socket.emit("client_action", {
        action_type: "JoinGame",
        data: {
          room_id: this.game.id,
          user_id: this.user.id,
          home,
          sid: this.sid,
          buy,
        },
      });
    },

    getOnlineStatus(user_id) {
      const user = this.main_users.find((u) => u.id === user_id);
      return user?.online;
    },

    showJoinGameBtn(user) {
      if (user.home === "watcher") return false;
      if (this.home) return false;
      if (this.user.role.value.includes("admin")) {
        if (user.id) return false;
      }
      if (!this.current_game_id && this.game?.state.status === "end") {
        return false;
      }
      if (user.id) return false;

      return true;
    },

    showPrivateMsgButton(user) {
      if (!user.id) return;
      if (this.home === "watcher") return false;

      if (!this.home) return false;
      if (user.home === "watcher") return false;
      if (user.id === this.user.id) return false;
      return true;
    },

    openPrivateChatTab(e, target_user_id) {
      if (target_user_id === this.home) return;

      const chat_id = genPrivateChatId(target_user_id, this.home);

      let existed_pr_chat = this.game_private_chats.find((ch) => ch.users.includes(target_user_id));
      if (!existed_pr_chat) {
        this.setTempPrivateChat({ room_id: this.game.id, target_user_id, chat_id, my_home: this.home });
        existed_pr_chat = this.game_private_chats.find((ch) => ch.users.includes(target_user_id));
      }
      this.selectPrivateChat({ chat_id: existed_pr_chat?.id || chat_id, room_id: this.game.id });
      this.setGameSideTab({ tab: "privateGameChatActive" });
    },

    get_user_timer(user) {
      return this.formatTimer(this.timers[user.home]);
    },
    formatTimer(duration) {
      const hrs = ~~(duration / 3600);
      const mins = ~~((duration % 3600) / 60);
      const secs = ~~duration % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let ret = "";

      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.gap_for_logger {
  width: 100%;
  min-height: 5.4rem;
}
.player_list_container {
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;

  .unit {
    border-bottom: 2px solid rgb(241, 234, 234);
    overflow: hidden;
    margin: 5px;
    // box-sizing: border-box;
    padding: 10px;
    word-wrap: break-word;
    display: flex;
    background-color: rgb(73, 73, 73);
    // width: 95%;
    height: auto;

    .avatar {
      width: auto;
      display: flex;
      gap: 1rem;

      position: relative;
      .ava_img {
        border-radius: 0.4rem;
        width: 60px;

        -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
        -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
        box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
      }

      .home_label {
        img {
          width: 30px;
        }
      }

      .offline {
        -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
        -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
        box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
      }
    }

    .info {
      height: 100%;
      width: 100%;
      align-self: center;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      .join_btn {
        padding: 1rem;
        border: 1px solid green;
        border-radius: 0.4rem;
        cursor: pointer;
        &:hover {
          background-color: green;
        }
      }

      .user-status {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .private_chat_btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        z-index: 50;
        bottom: 30px;
        color: black;
        font-size: 1rem;
      }
      .player_log_btn {
        cursor: pointer;
        position: absolute;
        right: 0px;
        z-index: 50;
        bottom: 0px;
        color: black;
        font-size: 1rem;
      }
    }
  }

  .myself {
    border: none;
    // outline: 3px solid rgb(164, 6, 208);
    background-color: rgba(183, 88, 104, 0.741);
  }
}

// @media screen and (max-width: 700px) {
//   .player_list_container {
//     width: 60% !important;
//   }
// }

// @media screen and (max-width: 400px) {
//   .player_list_container {
//     // right: -80dvw;
//     width: 100% !important;
//   }
// }

.player_list_container::-webkit-scrollbar {
  width: 20px;
}

.player_list_container::-webkit-scrollbar-track {
  background: grey;
  border-left: 8px solid rgb(53, 53, 53);
  border-right: 9px solid rgb(53, 53, 53); /* color of the tracking area */
}

.player_list_container::-webkit-scrollbar-thumb {
  background: grey; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */

  border-left: 6px solid rgb(53, 53, 53);
  border-right: 7px solid rgb(53, 53, 53);
}
</style>
