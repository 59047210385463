import shortid from "shortid";

export function createInitialUser({ user_name, user_id, password, filename, email = "" }) {
  const user = {
    name: user_name,
    password,
    avatars: [],
    email: {
      value: email,
      verified: false,
    },
    room: "mainRoom",
    current_games: [],
    sessions: [],
    id: user_id || shortid.generate(),
    role: {
      title: "bastard",
      value: "bastard",
    },
    online: false,
    friends: [],
    locale: "uk",
    creation_time: Date.now(),
    my_friends: [],
    i_am_friend: [],
    black_list: [],
    recent_emoji: [],
    money: {
      initial: 100,
      total: 100,
      donate: 0,
      earned: 0,
    },
    nicknames_log: [],
    money_logs: {
      spend_log: [],
      send_log: [],
      earn_log: [],
      donate_log: [],
    },
    game_logs: [],
    sound_settings: {
      main: true,
      battle: true,
      message: true,
      exec: true,
      timer: true,
      round: true,
    },
    complaints: {
      play: [],
      chat: [],
      private_chat: [],
      avatar: [],
      nickname: [],
    },
    ban_counter: {
      play: 0,
      chat: 0,
      private_chat: 0,
      avatar: 0,
      nickname: 0,
    },
    ban: {
      play: {
        value: false,
        duration: null,
        ban_start: null,
        duration_ms: null,
        duration_type: null,
      },
      chat: {
        value: false,
        duration: null,
        ban_start: null,
        duration_ms: null,
        duration_type: null,
      },
      avatar: {
        value: false,
        duration: null,
        ban_start: null,
        duration_ms: null,
        duration_type: null,
      },
      nickname: {
        value: false,
        duration: null,
        ban_start: null,
        duration_ms: null,
        duration_type: null,
      },
      private_chat: {
        value: false,
        duration: null,
        ban_start: null,
        duration_ms: null,
        duration_type: null,
      },
    },
    stats: {
      win_overal: 0,
      intelligence_overal: 0,
      intelligence_stark: 0,
      intelligence_baratheon: 0,
      intelligence_lannister: 0,
      intelligence_tyrell: 0,
      intelligence_martell: 0,
      intelligence_greyjoy: 0,
      charm: 0,
      total_games_count: 0,
      leaved_games_count: 0,
      full_games_count: 0,
      full_games_percent: 0,
      total_hours_count: 0,
      saved_games_count: 0,

      more: {
        stark: {
          home: "stark",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
        lannister: {
          home: "lannister",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
        baratheon: {
          home: "baratheon",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
        martell: {
          home: "martell",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
        tyrell: {
          home: "tyrell",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
        greyjoy: {
          home: "greyjoy",
          types: {
            classic_6: {
              total: 0,
              wins: [0, 0, 0, 0, 0, 0],
            },
            classic_5: {
              total: 0,
              wins: [0, 0, 0, 0, 0],
            },
            classic_4: {
              total: 0,
              wins: [0, 0, 0, 0],
            },
            classic_3: {
              total: 0,
              wins: [0, 0, 0],
            },
          },
        },
      },
    },
  };
  if (filename) {
    user.avatars = [filename];
  }
  return user;
}
