import store from "../store/index.js";

export default class CrowCheckHandler {
  constructor() {
    this.spec_limit = 0;
    this.spec_count = 0;
    this.bindedcloseOrderHeepHandler = this.closeOrderHeepHandler.bind(this);
  }

  caclSpecUsedSpecOrders() {
    const active_players = store.getters["general_store/active_players"];
    const activePlayer = active_players[0];
    if (!active_players) return;
    const state = store.getters["general_store/state"];
    const home_state = state.states.find((st) => st.home === activePlayer);
    const occupied_lands = home_state.lands;
    this.spec_count = 0;
    const lands = state.lands;
    if (!lands) return;

    for (let land_name of occupied_lands) {
      const order = lands[land_name].order;
      if (!order.includes("spec")) continue;
      this.spec_count++;

      if (this.spec_limit && this.spec_count >= this.spec_limit) {
        state.skip_orders.push("_spec");
      }
    }
  }

  initialOrdersPreset() {
    let order_label = 1;

    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];

    if (!state) return;
    if (!active_players) return;

    const activePlayer = active_players[0];

    this.caclSpecUsedSpecOrders();
    const home_state = state.states.find((st) => st.home === activePlayer);
    const occupied_lands = home_state.lands;

    const lands = state.lands;
    if (!lands) return;

    for (let land_name of occupied_lands) {
      const land_obj = lands[land_name];

      const order = land_obj.order;

      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });

      if (!active_players.includes(home)) continue;

      if (order && order !== "hide") {
        let _order = order;
        if (!order.includes("spec") && !order.includes("crusade")) {
          _order = _order + ":" + order_label;
          order_label++;
          if (order_label > 2) {
            order_label = 1;
          }
        }
        store.commit("gui_store/setOrder", { key: "used_orders", land_name: _order });
      }
    }
  }

  prepareGui() {
    this.spec_count = 0;
    this.spec_limit = initSpecLimit();
    this.initialOrdersPreset();
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    if (!active_players) return;
    if (!home) return;
    if (!active_players.includes(home)) return;
    if (e.target.dataset.owner !== home) {
      return false;
    }

    const nodeTarget = e.target;
    const updeted_source = nodeTarget.dataset.source;
    const land_name = store.state.gui_store.orders_heep_source ? "" : updeted_source;
    const state = store.getters["general_store/state"];
    const lands = store.getters["general_store/lands"];
    store.commit("gui_store/setHeepSource", { land_name });

    if (land_name) {
      const land_obj = lands[land_name];
      if (land_obj.order.includes("_spec")) {
        this.spec_count--;
        if (this.spec_count < this.spec_limit) {
          state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
        }
      } else {
        this.caclSpecUsedSpecOrders();
        if (this.spec_count < this.spec_limit) {
          state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
        }
      }
      store.commit("gui_store/setOrder", { key: "active_orders", land_name: updeted_source });
    }

    setTimeout(() => {
      document.addEventListener("click", this.bindedcloseOrderHeepHandler, { once: true });
    }, 0);
  }

  order_heep_click(e) {
    if (!e.target.dataset?.name) return;
    if (store.state.gui_store.used_orders.has(e.target.dataset.name)) {
      return;
    }

    const state = store.getters["general_store/state"];

    if (!state) return;

    const orderNameWithLabel = e.target.dataset.name;

    if (state.skip_orders.some((sk) => orderNameWithLabel.includes(sk))) return;

    const orders_heep_source = store.state.gui_store.orders_heep_source;
    const land_obj = state.lands[orders_heep_source];
    const oldOrderWithLabel = land_obj.order; //get possible prevOrder to reset it in gui_store (styles)

    if (oldOrderWithLabel) {
      if (oldOrderWithLabel.includes("_spec")) {
        this.spec_count--;
      }
      store.commit("gui_store/deleteOrder", { key: "used_orders", land_name: oldOrderWithLabel });
      // here key is land_name due to unificate with others filelds in state, but actualy in this case its order label insted land_name
    }

    const _orderName = this.normalizeOrderName(orderNameWithLabel);

    if (_orderName.includes("_spec")) {
      this.spec_count++;
    }

    if (this.spec_count === this.spec_limit) {
      state.skip_orders.push("_spec");
    }
    if (this.spec_count < this.spec_limit) {
      state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
    }

    land_obj.order = orderNameWithLabel; // here i set not normalised order name due to recognize proper used order is stack of orders menu-modal

    store.commit("gui_store/setOrder", { key: "used_orders", land_name: orderNameWithLabel });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    // set order for GUI purpose in gui_store to define class-styles of used orders

    store.commit("game_store/setConceptionState", {
      land_name: orders_heep_source,
      title: _orderName,
    });

    store.commit("gui_store/setHeepSource", { reset: true });
    // this flag closes order heep modal

    store.commit("game_store/setExecTriger", { doit: true });
  }

  closeOrderHeepHandler(e) {
    if (!store.state.gui_store.orders_heep_source) {
      store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
      return;
    }
    if (e.target.dataset.type === "order") {
      document.addEventListener("click", this.bindedcloseOrderHeepHandler, { once: true });
      return;
    }
    store.commit("gui_store/setHeepSource", { key: "orders_heep_source", land_name: "" });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
  }

  normalizeOrderName(orderName) {
    const parts = orderName.split(":");

    return parts[0];
  }
}

function initSpecLimit() {
  const home = store.getters["general_store/home"];
  const game = store.getters["general_store/game"];
  const state = store.getters["general_store/state"];
  if (!game) return;
  if (!state) return;
  const mapArr = getMapArr(game?.game_options.max_players);
  const tracks = state.tracks;
  const crowTrackIdx = tracks.crow.findIndex((h) => h === home);
  return mapArr[crowTrackIdx];
}

function getMapArr(max) {
  if (max === 3) {
    return [3, 2, 1];
  } else {
    return [3, 3, 2, 1, 0, 0];
  }
}
