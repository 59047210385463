<template>
  <div class="sidebar_left_container prevent-select" :style="{ transform: container_hide }">
    <div class="inner_relative_wrapper">
      <TabUsersList
        :class="{
          hide: hide('usersActive'),
          fast_hide: main_menu_open,
          tab: true,
        }"
      />
      <TabPrivateChat
        :tab-type="'main_private_chat'"
        :class="{
          hide: hide('privateChatActive'),
          fast_hide: main_menu_open,
          tab: true,
        }"
        :room="mainRoom"
      />
      <TabChat
        :class="{
          hide: hide('chatActive'),
          fast_hide: main_menu_open,
          tab: true,
        }"
        :room="mainRoom"
        :tab-type="'main_chat'"
      />
      <NavButtonsLeft :game="game" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  props: {
    game: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      chatActive: false,
    };
  },

  computed: {
    ...mapGetters("general_store", ["mainRoom", "users", "user"]),

    ...mapState("gui_store", ["main_side_bar"]),

    container_hide() {
      if (this.main_menu_open) {
        return `translate(100%)`;
      }
      return `none`;
    },

    main_menu_open() {
      return Object.keys(this.main_side_bar).some((k) => this.main_side_bar[k] === true);
    },
  },

  watch: {
    main_menu_open() {
      if (this.main_menu_open) {
        if (window.innerWidth < 1285) {
          this.setGameSideTab();
        }
      }
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setGameSideTab"]),

    hide(type) {
      if (this.main_side_bar[type]) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar_left_container {
  position: absolute;
  z-index: 7;
  height: 100dvh;
  bottom: 0;
  left: -400px;
  width: 400px;
  max-width: 80vw;
  box-sizing: border-box;
  background-color: rgb(41, 40, 40);
  transition: all 1s;
  border: 3px solid white;
  border-top: none;
  pointer-events: all;
}
.inner_relative_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .sidebar_left_container {
    left: -65dvw;
    width: 65dvw;
  }
}
@media screen and (max-width: 400px) {
  .sidebar_left_container {
    left: -80dvw;
    width: 80dvw;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hide {
  transform: translateX(-250%);
  transition-delay: 1s;
  transition-property: all;
}
.tab {
  position: absolute;
  right: 0;
  width: 100%;
}

.fast_hide {
  transition-delay: 0s;
  transition-property: all;
}
</style>
