export default {
  en: {
    phase: {
      empty: "-",
      GameOver: `Game Over`,
      Conception: `Conception`,
      Wildings: `Wildings Invasion`,
      CrowCheck: `Crow Check`,
      Crusade: `Crusade`,

      Raid: "Raid",
      Power: "Gain Of Power",
      BidPhase: "War Of Kings",
      KingChoice: "King's Decision",
      ThroneDecision: "King's Decision",

      ConflictPort: "Seizure Of Ships",
      PreAttackConflictPort: "Seizure Of Ships",
      PermitSupport: "Support Permission",
      DefineSupport: "Support",
      UseSword: "The Valerian Sword",
      SelectCard: "Choosing A House Card",
      FirstCardAction: "Applying House Card",
      LastCardAction: "Applying House Card",
      Retreat: "Retreat Troops",
      ManualDelUnits: "Tough decision",

      WildingManual: "Applying Wilding`s Card",

      Supply: "Supply",
      Mastering: "Mastering",
      LastDays: "Last Summer Days",
      Winter: "Winter Is Comming",
      ThroneOfBlades: "Throne Of Blades",
      GameOfThrones: "Game Of Thrones",

      BlackWings: "Black Wings",
      WarOfKings: "War Of Kings",

      AutumnRains: "Autumn Rains",
      FeastOfCrows: "Feast Of Crows",
      SeaOfStorms: "Sea Of Storms",
      WebOfLies: "Web Of Lies",
      StormOfSwords: "Storm Of Swords",
      PutToTheSword: "Put To The Sword",
    },
  },
  uk: {
    phase: {
      empty: "-",
      GameOver: `Гра завершена`,
      Conception: `Замисли`,
      Wildings: `Нашестя дикунів`,
      CrowCheck: `Посильний Ворон`,
      Crusade: `Походи`,
      Raid: "Рейди",
      Power: "Посилення Влади",

      KingChoice: "Вибір Короля",
      ThroneDecision: "Вибір Короля",

      ConflictPort: "Захоплення Кораблів",
      PreAttackConflictPort: "Захоплення Кораблів",
      PermitSupport: "Запит Підтримки",
      DefineSupport: "Підтримка",
      UseSword: "Валерійський Меч",
      SelectCard: "Вибір Карти Дому",
      FirstCardAction: "Властивість Карти Дому",
      LastCardAction: "Властивість Карти Дому",
      Retreat: "Відступ (жест доброї волі)",
      ManualDelUnits: "Непрості рішення",

      WildingManual: "Властивість Карти Дикунів",

      Supply: "Постачання",
      Mastering: "Збір Військ",
      LastDays: "Останні Дні Літа",
      Winter: "Зима Близько",
      ThroneOfBlades: "Трон із клинків",
      GameOfThrones: "Гра Престолів",

      BlackWings: "Чорні Ворони, Темні Новини",
      WarOfKings: "Битва Королів",

      AutumnRains: "Осінні Дощі",
      FeastOfCrows: "Свято Ворон",
      SeaOfStorms: "Море Штормів",
      WebOfLies: "Павутина Брехні",
      StormOfSwords: "Буря Мечів",
      PutToTheSword: "Віддані Мечу",
    },
  },
};
