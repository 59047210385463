<template>
  <div class="private_chat_container" :class="{ reverse: room?.id !== 'mainRoom' }">
    <div class="user_list_wr" :class="{ reverse_scroll: room?.id === 'mainRoom' }">
      <div v-if="show_game_map" class="gap"></div>
      <div
        v-for="{ user: _user_, unread_count, chat_id } in users_to_chat"
        :key="_user_.id"
        class="user_item"
        :class="{ selected_chat_id: selected_chat_id === chat_id }"
        @click="selectPrivateChatByClick($event, chat_id)"
      >
        <span class="user_name">{{ _user_.name }}</span>

        <div class="ava_wr">
          <img
            :src="`/images/avatars/${_user_.avatars?.[0]}`"
            alt="avatar"
            :class="{ offline: !getOnlineStatus(_user_.id) }"
          />
        </div>
        <div v-if="tabType !== 'main_private_chat'" class="home_wr">
          <img :src="`/images/tokens/${_user_.home}_token_new.png`" :alt="_user_.home" />
        </div>
        <div class="user_btns">
          <span v-if="unread_count" class="online_status">{{ unread_count }}</span>
          <span class="material-icons" data-id="btn" @click="cleanChatHistory(chat_id)"> delete </span>
        </div>
      </div>
    </div>
    <div class="chat_area_wr">
      <div
        ref="chat_area"
        class="inner_chat_area"
        @scroll="onScrollArea"
        :class="{ reverse_scroll: room?.id !== 'mainRoom' }"
      >
        <div v-if="show_game_map" class="gap"></div>
        <div v-if="selected_chat_id" class="messages_wr">
          <div class="inner_messages_wrapper">
            <transition-group name="messages">
              <MessageCard
                v-for="(msg, index) in selected_chat?.messages"
                :key="index"
                :ref="msg.id"
                :tab-type="tabType"
                :message="msg"
                :user="user"
                @reply="reply"
                @scroll-to-message="scrollToMessage"
                @open-confirm-complaint-modal="openConfirmComplaintModal(msg)"
              />
            </transition-group>
          </div>
        </div>
      </div>
      <div class="form_wr">
        <div v-if="reply_obj" class="reply_wr">
          <div class="reply_title">
            <span class="material-icons reply_reset" @click="resetReply"> cancel </span>
            <span class="material-icons"> reply </span>
            <span>{{ getSenderTitle(reply_obj) }}</span>
            <span>{{ getDate(reply_obj.time) }}</span>
          </div>
          <div class="reply_body">
            <span>{{ normalizeReplyText(reply_obj.text) }}</span>
          </div>
        </div>
        <div class="input_wr" :class="{ reverse: room?.id !== 'mainRoom' }">
          <textarea
            id="message"
            ref="textarea"
            v-model="message"
            :disabled="!selected_chat_id"
            name="message"
            maxlength="120"
            cols="30"
            rows="1"
            @input="auto_grow"
            @keydown="sendMessage($event, target_user_indicator)"
          ></textarea>
          <span class="material-icons open_emoji_button" @click="emojiPopupOpen"> sentiment_satisfied_alt </span>
          <span
            class="material-icons send_button"
            :class="{ rotate_icon: room?.id !== 'mainRoom' }"
            @click="sendMessage($event, target_user_indicator)"
          >
            send
          </span>
          <span
            v-if="needPayForMessage"
            class="material-icons paid_hint"
            @mouseenter="hint_label_hover($event, { msg: 'price', data: price.send_private_message })"
            @mouseleave="hint_label_hover"
          >
            paid
          </span>
          <div v-if="unread_messages_count" class="new_messages_hint_wr" @click="setScrollToBottom">
            <div class="circle_wr">
              <span class="material-icons"> expand_circle_down </span>
            </div>
            <div class="msgs_count_hint">
              <span>{{ unread_messages_count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { price } from "#static_data";
import shortid from "shortid";
import { nextTick } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  inject: ["socket"],

  props: {
    tabType: String,
    room: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      price,
      message: "",
      reply_obj: null,
      hint_delay: null,
      permit_send_chat_status: true,
      temp_msg_timeout: null,
      emojiis: new Set(),
    };
  },

  computed: {
    ...mapState("general_store", ["selected_game_private_chat", "selected_main_private_chat"]),
    ...mapGetters("general_store", [
      "home",
      "user",
      "current_game_id",
      "show_game_map",
      "game",
      "mainRoom",
      "main_private_chats",
      "game_private_chats",
    ]),

    ...mapState({
      private_chat_active(state) {
        if (this.room.id === "mainRoom") {
          return state.gui_store.main_side_bar.privateChatActive;
        }
        return state.gui_store.game_side_bar.privateGameChatActive;
      },
      users(state) {
        if (this.tabType.includes("game")) {
          const _users = this.room.players.concat(this.room.users);
          // return _users.map((u) => {
          //   return state.general_store.users.find((_u) => _u.id === u.id);
          // });
          return _users;
        }
        return state.general_store.users;
      },
    }),

    my_user_indicator() {
      if (this.room.id === "mainRoom") {
        return this.user.id;
      }
      return this.home;
    },

    selected_chat_id() {
      return this.room.id === "mainRoom"
        ? this.selected_main_private_chat?.chat_id
        : this.selected_game_private_chat?.chat_id;
    },
    selected_chat() {
      return this.private_chats.find((ch) => ch.id === this.selected_chat_id);
    },

    private_chats() {
      return this.room.id === "mainRoom" ? this.main_private_chats : this.game_private_chats;
    },

    target_user_indicator() {
      return this.selected_chat?.users.find((u) => u !== this.my_user_indicator);
    },

    target_user() {
      const uid = this.selected_chat?.users.find((u) => u !== this.my_user_indicator);
      const user_key = this.room.id === "mainRoom" ? "id" : "home";
      return this.users?.find((u) => u[user_key] === uid);
    },

    room_id() {
      if (this.room.id === "mainRoom") return "mainRoom";
      return this.current_game_id;
    },

    needPayForMessage() {
      return false;
      // if (this.tabType === "game_private_chat") return true;
      // if (this.user?.current_games?.length) return true;

      // if (!this.target_user) return false;
      // if (this.target_user.current_games.some((g) => g.home !== "watcher")) {
      //   return true;
      // }
      // return false;
    },

    unread_messages_count() {
      return this.get_unread_count(this.selected_chat?.messages);
    },

    users_to_chat() {
      let users = this.private_chats.reduce((acc, chat) => {
        const cand = chat.users?.find((u) => u !== this.my_user_indicator);
        const key = this.room.id === "mainRoom" ? "id" : "home";
        const cand_obj = this.users.find((u) => u[key] === cand);
        if (!cand_obj) return acc;
        acc.push({
          user: cand_obj,
          unread_count: this.get_unread_count(chat.messages),
          last_update: chat.last_update,
          chat_id: chat.id,
        });

        return acc;
      }, []);
      users?.sort((a, b) => b.last_update - a.last_update);
      return users;
    },
  },

  watch: {
    private_chat_active() {
      if (!this.private_chat_active) {
        this.setTempPrivateChat({ del: true, room_id: this.room.id });
        this.message = "";
      } else {
        if (this.selected_chat_id) {
          this.selectPrivateChatByClick(null, this.selected_chat_id);
        }
      }
    },
  },

  mounted() {
    this.socket.on("update_from_server", async ({ process, payload } = {}) => {
      if (process !== "processMessage") return;
      const { message, type } = payload;
      if (type !== "add") return;
      if (!this.private_chat_active) return;
      const area = this.$refs.chat_area;
      if (!area) return;
      if (this.user.id === message?.sender_id) {
        await nextTick();
        this.setScrollToBottom();
        return;
      }
      const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 100;

      if (!bottom) return;
      await nextTick();
      this.setScrollToBottom();
      this.dispatchScrollEvent();
    });

    this.socket.on("setInputChatState", async (payload) => {
      if (!this.private_chat_active) return;
      if (this.selected_chat_id !== payload?.chat_id) return;

      const area = this.$refs.chat_area;
      if (!area) return;

      const message = {
        id: `private-message-${shortid.generate()}`,
        time: Date.now(),
        sender_id: payload.sender_id,
        sender_home: payload.sender_home,
        sender_name: payload.sender_name,
        read: [],
        temp: true,
        room_id: this.room.id,
        text: "TEMP",
      };

      if (this.temp_msg_timeout) {
        this.deleteTempMessage({ room_id: this.room.id, chat_id: this.selected_chat_id });
        clearTimeout(this.temp_msg_timeout);
        this.temp_msg_timeout = null;
      }

      this.processMessage({ message, chat_id: this.selected_chat_id, room_id: this.room.id, type: "add" });
      clearTimeout(this.temp_msg_timeout);
      this.temp_msg_timeout = setTimeout(() => {
        this.processMessage({ message, room_id: this.room.id, type: "del", chat_id: this.selected_chat_id });
        this.temp_msg_timeout = null;
      }, 2500);
      await nextTick();
      const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 100;
      if (!bottom) return;
      this.setScrollToBottom();
    });
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", [
      "setTempPrivateChat",
      "processModal",
      "processMessage",
      "deleteTempMessage",
      "setDataForModal",
      "selectPrivateChat",
      "delPrivateChat",
    ]),

    getOnlineStatus(user_id) {
      const user = this.users.find((u) => u.id === user_id);
      return user?.online;
    },

    auto_grow(e) {
      e.target.style.height = "1rem";
      e.target.style.height = e.target.scrollHeight + "px";
      if (!this.permit_send_chat_status) return;
      this.socket.emit("chat_action", {
        data: {
          room_id: this.room.id,
          action_type: "ProcessChatInput",
          target_id: this.target_user_indicator,
          chat_id: this.selected_chat_id,
          sender_id: this.user.id,
          sender_home: this.home,
          sender_name: this.user.name,
        },
      });
      this.permit_send_chat_status = false;

      setTimeout(() => {
        this.permit_send_chat_status = true;
      }, 1000);
    },

    sendMessage(e, target_id) {
      if (e.type == "keydown") {
        if (e.keyCode !== 13) return;
        if (e.shiftKey) {
          return;
        }
        e.preventDefault();
      }
      if (!target_id) return;
      if (target_id === this.my_user_indicator) return;
      const msg_clone = this.message;
      if (!msg_clone.trim().length) return;
      if (this.user.ban?.private_chat?.value) {
        this.socket.emit("client_action", {
          action_type: "BanUser",
          data: {
            user_id: this.user.id,
            target_user_indicator: this.target_user.id,
            update: true,
          },
        });
        this.message = "";
        return;
      }
      const reply = { ...this.reply_obj };

      this.socket.emit("client_action", {
        action_type: "NewPrivateMessage",
        data: {
          message: {
            id: `private-message-${shortid.generate()}`,
            chat_id: this.selected_chat_id,
            time: Date.now(),
            sender_id: this.user.id,
            target_id: this.target_user.id,
            sender_home: this.home,
            sender_name: this.user.name,
            target_home: this.target_user.home,
            reply,
            read: [[this.user.id]],
            room_id: this.room.id,
            text: this.message,
            emojiis: Array.from(this.emojiis),
          },
          cost: this.needPayForMessage,
        },
      });
      this.message = "";
      this.emojiis.clear();
      this.reply_obj = null;
      this.$refs.textarea?.removeAttribute("style");
    },

    getSenderTitle(reply_obj) {
      if (this.tabType.includes("game")) {
        return this.$t(`home.${reply_obj.sender_home}`);
      }
      return reply_obj.sender_name;
    },

    openConfirmComplaintModal(msg) {
      const data = {
        processed_user: {
          avatar: msg.sender_avatar,
          nickname: msg.sender_name,
          target_id: msg.sender_id,
          type: "private_chat",
          complaint_data: {
            text: msg.text,
          },
        },
      };

      this.setDataForModal({ data });
      this.processModal({ name: "complaint" });
    },

    hint_label_hover(e, item) {
      if (!item) {
        window.clearTimeout(this.hint_delay);
        this.hint_delay = null;
        this.setHintLabel({ reset: true });
        return;
      }
      this.hint_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top - 20}px)`;
        this.setHintLabel({
          msg: item.msg,
          data: item.data,
          coords: logYpos,
        });
      }, 550);
    },

    emojiPopupOpen() {
      this.setDataForModal({
        data: {
          cb: function (em) {
            this.message += em;
            this.emojiis.add(em);
          }.bind(this),
        },
      });
      this.processModal({ name: "emojii" });
    },
    get_unread_count(messages) {
      if (!messages || !messages.length) return 0;
      return messages.reduce((sum, msg) => {
        if (!msg.temp && msg.sender_id !== this.user.id && !msg.read.some((arr) => arr[0] === this.user.id))
          return ++sum;
        return sum;
      }, 0);
    },

    getUserName(user_id) {
      const user = this.users.find((u) => u.id === user_id);
      return user?.name;
    },

    onScrollArea(e) {
      const area = e.target;

      if (!this.private_chat_active) return;
      if (!area) return;
      const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 1;

      if (!bottom) return;
      if (!this.unread_messages_count) return;

      this.socket.emit("client_action", {
        action_type: "ReadMyMessages",
        data: {
          user_id: this.user.id,
          room_id: this.room.id,
          chat_id: this.selected_chat_id,
        },
      });
    },

    setScrollToBottom() {
      const el = this.$refs.chat_area;
      if (!el) return;
      el.scrollTop = 100000;
    },

    dispatchScrollEvent() {
      const el = this.$refs.chat_area;
      const scrollEvent = new Event("scroll");
      el.dispatchEvent(scrollEvent);
    },

    async selectPrivateChatByClick(e, chat_id) {
      if (e && e.target?.dataset.id === "btn") return;
      this.selectPrivateChat({ chat_id, room_id: this.room.id });
      await nextTick();
      this.setScrollToBottom();
      this.dispatchScrollEvent();
    },

    scrollToMessage(reply_id) {
      const el_component = this.$refs[reply_id]?.[0];
      const el = this.$refs[reply_id]?.[0].$el;
      el.scrollIntoView({ behavior: "smooth", block: "center" });
      el_component.temp_message_hint();
    },
    async cleanChatHistory(chat_id) {
      if (!chat_id) return;
      const chat = this.private_chats.find((ch) => ch.id === chat_id);
      if (chat?.temp) {
        this.delPrivateChat({ chat_id, room_id: this.room.id });
        this.selectPrivateChat({ reset: true, room_id: this.room.id });
        this.message = "";
        return;
      }

      this.setDataForModal({
        data: {
          cb: function (result) {
            if (!result) return;

            this.setTempPrivateChat({ chat_id, del: true, room_id: this.room.id });
            this.delPrivateChat({ chat_id, room_id: this.room.id });
            this.selectPrivateChat({ reset: true, room_id: this.room.id });
            this.message = "";

            this.socket.emit("client_action", {
              action_type: "DeleteMessage",
              data: {
                room_id: this.room.id,
                chat_id,
                chat_type: this.tabType,
                type: "del_all",
              },
            });
          }.bind(this),
          type: "delete_all_messages",
        },
      });
      this.processModal({ name: "confirm_del" });
    },

    resetReply() {
      this.reply_obj = null;
    },
    normalizeReplyText(text) {
      return text.slice(0, 12) + "...";
    },
    getDate(ms) {
      return new Date(ms).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },

    reply({ id, sender_name, sender_id, sender_home, target_id, text, home = "watcher", time }) {
      this.reply_obj = {
        id,
        sender_name,
        sender_home,
        sender_id,
        target_id,
        text,
        home,
        time,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.private_chat_container {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  padding-left: 5px;
}

.reverse {
  flex-direction: row-reverse;
}

.rotate_icon {
  transform: rotate(180deg);
}

.user_list_wr {
  min-width: 25%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 3rem;
  background-color: rgb(68, 71, 70);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .user_item {
    cursor: pointer;
    background-color: rgb(68, 71, 70);
    box-sizing: border-box;
    padding: 5px 10px;
    position: relative;
    color: gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 1px solid white;
    // width: fit-content;
    width: 100%;

    // background-color: rgba(129, 151, 144, 0.838);

    .home_wr {
      position: absolute;
      top: 65px;
      left: 5px;
      img {
        width: 25px;
      }
    }

    .user_btns {
      display: flex;
      align-items: center;
      gap: 1rem;
      span {
        font-size: 1rem;
      }

      .online_status {
        font-size: 0.8rem;
        font-weight: 800;
        pointer-events: none;
        background-color: rgb(206, 81, 8);
        padding: 0.1rem 0.3rem;
        border-radius: 0.4rem;
        color: white;
        text-align: center;
      }
    }

    .ava_wr {
      // position: relative;
      img {
        border-radius: 0.4rem;
        width: 60px;
        -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
        -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
        box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
      }

      .offline {
        -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
        -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
        box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
      }
      @media screen and (max-width: 600px) {
        img {
          width: 30px;
        }
      }
    }

    .user_name {
      text-align: center;
      max-height: 2.2rem;
      overflow-y: hidden;
      overflow-x: hidden;
    }

    &:hover {
      background-color: rgb(101, 138, 138);
    }
  }

  .selected_chat_id {
    // border: 4px solid green;
    // box-sizing: border-box;
    background-color: #292828 !important;
    &:hover {
      background-color: #292828 !important;
    }
  }
}

.gap {
  width: 100%;
  min-height: 2.4rem;
}

@media screen and (max-width: 600px) {
  .user_list_wr {
    width: 30% !important;
  }

  .home_wr {
    top: 35px !important;
    left: 0px !important;
    img {
      width: 0.8rem !important;
    }
  }
}
.chat_area_wr {
  // flex: 3;
  width: auto;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
}

.reverse_scroll {
  direction: rtl;
}

// @media screen and (max-width: 400px) {
//   .user_list_wr {
//     flex: 1;
//   }

//   .chat_area_wr {
//     flex: 2;
//   }
// }

.messages_wr {
  height: 100%;
  width: 100%;

  .inner_messages_wrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    direction: ltr;
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 3rem;
  }
}

.inner_chat_area {
  // padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  // width: 92%;
}

.form_wr {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px;
  textarea {
    resize: none;
    min-height: 1rem;
    height: auto;
    max-height: 100px;
    overflow: hidden;
    width: 80%;
    background-color: black;
    color: White;
    line-height: 1rem;
  }
  .send_button {
    cursor: pointer;
    color: rgb(66, 183, 222);
    &:hover {
      color: orange;
    }
  }

  .open_emoji_button {
    color: white;
    cursor: pointer;
  }
}

.new_messages_hint_wr {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 0px;
}

.circle_wr {
  position: relative;
  span {
    color: green;
    font-size: 2rem;
  }
}

.msgs_count_hint {
  pointer-events: none;
  position: absolute;
  width: 20px;
  height: 20px;
  color: gold;
  background-color: blue;
  border-radius: 50%;
  top: 0px;
  left: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reply_wr {
  padding: 5px;
  align-self: center;
  width: 90%;
  background-color: rgba(174, 137, 225, 0.821);
  border-radius: 10px;
}

.reply_title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.reply_body {
  text-align: center;
}

.reply_reset {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: -11px;
  color: rgb(133, 56, 222);
}

.input_wr {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.paid_hint {
  position: absolute;
  font-size: 1rem;
  color: green;
  top: 0px;
  right: 10px;
  cursor: pointer;
}

.msg_temp_hint {
  background-color: aqua;
}

.inner_chat_area::-webkit-scrollbar {
  width: 20px;
}

.inner_chat_area::-webkit-scrollbar-track {
  background: grey;
  border-left: 8px solid rgb(53, 53, 53);
  border-right: 9px solid rgb(53, 53, 53); /* color of the tracking area */
}

.inner_chat_area::-webkit-scrollbar-thumb {
  background: grey; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */

  border-left: 6px solid rgb(53, 53, 53);
  border-right: 7px solid rgb(53, 53, 53);
}
.user_list_wr::-webkit-scrollbar {
  width: 20px;
}

.user_list_wr::-webkit-scrollbar-track {
  background: grey;
  border-left: 8px solid rgb(53, 53, 53);
  border-right: 9px solid rgb(53, 53, 53); /* color of the tracking area */
}

.user_list_wr::-webkit-scrollbar-thumb {
  background: grey; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */

  border-left: 6px solid rgb(53, 53, 53);
  border-right: 7px solid rgb(53, 53, 53);
}

.messages-enter-active,
.messages-leave-active {
  transition: all 0.25s ease;
}
.messages-enter-from,
.messages-leave-to {
  opacity: 0;
}
</style>
