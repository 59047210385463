import clone from "clone";

const state_obj = {
  show_load_screen: false,
  mapPosition: {
    rotate: 0,
  },
  freeze: { color: "", freeze: false },

  hint_label: {
    msg: "",
    coords: "",
    data: null,
  },
  game_side_bar: {
    menuActive: false,
    playersActive: false,
    chatActive: false,
    privateGameChatActive: false,
    warActive: false,
    infoActive: false,
    stateRedactorActive: false,
  },
  main_side_bar: {
    usersActive: false,
    chatActive: false,
    privateChatActive: false,
  },

  gap_lands: new Set(),
  pawns_for_upgrade: new Set(),
  selected_units: new Map(),
  highlighted_units: new Set(),
  dragging_units: new Set(),
  orders_heep_source: "",
  available_lands: [],
  active_orders: new Set(),
  available_orders: new Set(),
  used_orders: new Set(),
  hint_orders: new Set(),
  hint_track_labels: new Set(),
  selected_cards: new Set(),
};

const state_obj_for_reset = {
  show_load_screen: false,
  freeze: { color: "", freeze: false },

  hint_label: {
    msg: "",
    coords: "",
    data: null,
  },
  gap_lands: new Set(),
  pawns_for_upgrade: new Set(),
  selected_units: new Map(),
  highlighted_units: new Set(),
  dragging_units: new Set(),
  orders_heep_source: "",
  available_lands: [],
  active_orders: new Set(),
  available_orders: new Set(),
  used_orders: new Set(),
  hint_orders: new Set(),
  hint_track_labels: new Set(),
  selected_cards: new Set(),
};

export default {
  namespaced: true,
  state: () => clone(state_obj),

  mutations: {
    resetGuiState(state) {
      // for (let key in clone(state_obj_for_reset)) {
      //   state[key] = state_obj_for_reset[key];
      // }
      Object.assign(state, clone(state_obj_for_reset));
    },
    setAvailableLands(state, lands) {
      if (!lands) {
        state.available_lands = [];
        return;
      }
      state.available_lands = lands;
    },

    setHintLabel(state, { msg, coords, data, reset }) {
      if (reset) {
        state.hint_label.msg = "";
        state.hint_label.coords = "";
        state.hint_label.data = null;
        return;
      }
      state.hint_label.msg = msg;
      state.hint_label.coords = coords;
      state.hint_label.data = data;
    },
    setFreeze(state, { freeze = false, color = "", reset = false }) {
      if (reset) {
        state.freeze.freeze = false;
        state.freeze.color = "";
        return;
      }
      state.freeze.freeze = freeze;
      state.freeze.color = color;
      return;
    },

    setMapPosition(state, scale) {
      // state.mapPosition = Object.assign(state.mapPosition, scale);
      state.mapPosition = { ...state.mapPosition, ...scale };
    },

    setSelectedCard(state, { title = "", cards = [], reset = false }) {
      if (reset) {
        state.selected_cards.clear();
        return;
      }
      if (title) {
        if (state.selected_cards.has(title)) {
          state.selected_cards.delete(title);
          return;
        }
        state.selected_cards.add(title);
        return;
      }
      if (cards.length) {
        cards.forEach((c) => state.selected_cards.add(c));
      }
    },
    setGameSideTab(state, opt = { tab: false }) {
      const { tab } = opt;
      const prev_value = state.game_side_bar[tab];
      const res = {
        menuActive: false,
        playersActive: false,
        chatActive: false,
        privateGameChatActive: false,
        warActive: false,
        infoActive: false,
        stateRedactorActive: false,
      };
      state.game_side_bar = Object.assign(state.game_side_bar, res);

      if (tab) {
        state.game_side_bar[tab] = !prev_value;
      }
    },
    setMainSideTab(state, opt = { tab: false }) {
      const { tab } = opt;
      const res = {
        usersActive: false,
        chatActive: false,
        privateChatActive: false,
      };

      const prev_value = state.main_side_bar[tab];
      state.main_side_bar = Object.assign(state.main_side_bar, res);

      if (tab) {
        state.main_side_bar[tab] = !prev_value;
      }
    },
    setHintTrackLabels(state, { trackName = "", reset, trackNames = [] }) {
      if (reset) {
        state.hint_track_labels.clear();
        return;
      }
      if (trackNames.length) {
        trackNames.forEach((tn) => {
          state.hint_track_labels.add(tn);
        });
        return;
      }

      state.hint_track_labels.add(trackName);
    },
    setHeepSource(state, { land_name = "", reset }) {
      if (reset) {
        state.orders_heep_source = "";
        return;
      }
      state.orders_heep_source = land_name;
    },

    deleteOrder(state, { land_name }) {
      state.used_orders.delete(land_name);
    },

    setOrder(state, { reset = false, key, land_name, land_names = [] }) {
      if (reset) {
        if (!key) return;

        state[key].clear();
        return;
      }
      if (!key || !land_name) return;
      if (land_names.length) {
        land_names.forEach((ln) => {
          state[key].delete(ln);
        });
        return;
      }

      state[key].add(land_name);
    },

    setGapLand(state, { land_name, reset }) {
      if (reset) {
        state.gap_lands.clear();
        return;
      }
      state.gap_lands.add(land_name);
    },

    setLoadScreen(state, value = false) {
      state.show_load_screen = value;
    },

    setPawnsForUpgrade(state, { id, reset }) {
      if (reset) {
        state.pawns_for_upgrade.clear();
        return;
      }
      if (!id) return;
      state.pawns_for_upgrade.add(id);
    },

    setHighlightedUnits(state, { id, reset }) {
      if (reset) {
        state.highlighted_units.clear();
        return;
      }
      if (!id) return;
      if (state.highlighted_units.has(id)) {
        state.highlighted_units.delete(id);
        return;
      }

      state.highlighted_units.add(id);
    },
    setDraggingUnits(state, { unit, reset }) {
      if (reset) {
        state.dragging_units.clear();
        return;
      }
      const { id } = unit;
      if (state.dragging_units.has(id)) {
        state.dragging_units.delete(id);
        return;
      }
      if (!id) return;
      state.dragging_units.add(id);
    },
    setSelectedUnits(state, { unit, reset }) {
      if (reset) {
        state.selected_units.clear();
        return;
      }
      if (!unit?.id) return;
      if (state.selected_units.has(unit?.id)) {
        state.selected_units.delete(unit?.id);
        return;
      }

      state.selected_units.set(unit?.id, unit);
    },
  },
};
