import store from "../store/index.js";

export default class PreemptiveRaidHandler {
  constructor() {
    this.zones_to_hint = new Set();
    this.units_to_hint = [];

    this.bestTracks = [];
  }

  prepareGui() {
    this.resetGui();
    this.initialTracksPreset();
    this.initialUnitsPreset();
  }

  allowCursorPointer(unit) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const highlighted_units = store.state.gui_store.highlighted_units;
    if (!highlighted_units.has(unit.id)) return;
    if (!active_players.includes(home)) return;
    if (unit.owner !== home) return;
    return true;
  }
  allowCursorPointerOnTrack(permit) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];

    if (!active_players.includes(home)) return;
    if (!permit) return;

    return true;
  }

  resetGui() {
    store.commit("game_store/processSeparateUnits", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setHintTrackLabels", { reset: true });
    store.commit("gui_store/setHighlightedUnits", { reset: true });
    store.commit("gui_store/setGapLand", { reset: true });
  }

  initialTracksPreset() {
    const phase_name = store.getters["general_store/phase_name"];

    if (phase_name !== "WildingManual") return;

    this.bestTracks = this.findBestTracks().map((obj) => obj.trackName);

    store.commit("gui_store/setHintTrackLabels", { trackNames: this.bestTracks });
  }

  initialUnitsPreset() {
    const phase_name = store.getters["general_store/phase_name"];

    if (phase_name !== "WildingManual") return;

    this.init_zones_and_units_to_hint();

    this.units_to_hint.forEach((unit) => {
      store.commit("gui_store/setHighlightedUnits", { id: unit.id });
    });
    this.zones_to_hint.forEach((land_name) => {
      store.commit("gui_store/setGapLand", { land_name });
    });
  }

  init_zones_and_units_to_hint() {
    const state = store.getters["general_store/state"];
    if (!state) return;
    const active_players = store.getters["general_store/active_players"];
    const lands = state.lands;
    const home_states = state.states.find((st) => st.home === active_players[0]);
    const occupied_lands = home_states.lands;

    this.units_to_hint = occupied_lands.reduce((acc, land_name) => {
      const land_obj = lands[land_name];
      const units = land_obj.units;
      if (units.length > 1) {
        this.zones_to_hint.add(land_name);
      }
      return (acc = [...acc, ...units]);
    }, []);
  }

  findBestTracks() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    if (!state) return;
    const tracks = state.tracks;
    const names = ["throne", "sword", "crow"];
    const res = [];
    const indexes = [];

    for (let trackName in tracks) {
      if (!names.includes(trackName)) continue;
      let arr = tracks[trackName];
      const idx = arr.findIndex((h) => h === active_players[0]);
      const resObj = { trackName, idx };
      res.push(resObj);
      indexes.push(idx);
    }
    const bestIdx = Math.min(...indexes);
    const bestTracks = res.filter(({ trackName, idx }) => idx === bestIdx);

    return bestTracks;
  }

  trackLabelClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    if (!active_players.includes(home)) return;
    const trackName = e.target.dataset.source;
    if (!this.bestTracks.includes(trackName)) return;
    store.commit("game_store/setTrackLabel", { trackName });
    store.commit("game_store/setExecTriger", { doit: true });
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = store.getters["general_store/home"];
    if (phase_name !== "WildingManual") return;
    if (!active_players.includes(home)) return;
    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;
    const selected_units = store.state.gui_store.selected_units;
    store.commit("game_store/setTrackLabel", { reset: true });

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };

    store.commit("gui_store/setSelectedUnits", { unit });
    store.commit("game_store/processSeparateUnits", { unit });

    const max_del_count = 2;

    if (selected_units.size > max_del_count) {
      store.commit("game_store/processSeparateUnits", { reset: true });
      store.commit("gui_store/setSelectedUnits", { reset: true });
      return;
    }
  }
}
