<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @pointerdown="close">
    <div class="confirm_wr">
      <div v-if="maxAmount < 11" class="trnasfer-deny-wr">
        <p>{{ $t(`gui_hint.deny_transfer`) }}</p>
        <button class="btn_confirm" @pointerdown="deny">{{ $t(`message.got_it`) }}</button>
      </div>
      <div v-else class="modal-body">
        <h2>{{ $t(`message.transfer`) }}</h2>
        <div class="price_wr">
          <div class="icons_wr">
            <img src="/images/money.png" alt="money" />
            <span class="material-icons"> double_arrow </span>
            <div class="target_wr">
              <span>{{ processed_user.name }}</span>
              <img :src="`/images/avatars/${processed_user.avatars[0]}`" alt="target_ava" />
            </div>
          </div>
          <input v-model="amount" type="range" min="10" :max="maxAmount" />
          <span class="total_transfer">{{ dynamicTotal }}</span>
          <span
            >{{ $t("message.target_sum") }}: <span :style="{ color: '#73e473' }">{{ amount }}</span>
          </span>
          <span
            >{{ $t("message.comission") }}: <span :style="{ color: '#73e473' }">{{ comission }}</span></span
          >
        </div>
        <div class="btns-wr">
          <button class="btn_confirm" @pointerdown="confirm">{{ $t(`message.send_money`) }}</button>
          <button class="btn_deny" @pointerdown="deny">
            {{ $t(`message.cancel`) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],

  data() {
    return {
      amount: 0,
    };
  },

  computed: {
    ...mapState("general_store", ["data_for_modal", "rooms"]),

    ...mapGetters("general_store", ["isAdmin", "user", "mainRoom"]),

    games_log() {
      if (!this.user) return;
      return this.user.game_logs || [];
    },
    maxAmount() {
      const diff = +this.user.money.total - +this.user.money.initial;
      const total = diff - diff * 0.05;
      if (this.isAdmin) {
        return +this.user.money.total;
      }

      return total.toFixed();
    },
    comission() {
      return +(this.amount * 0.05).toFixed();
    },
    dynamicTotal() {
      return +this.amount + +this.comission;
    },
    processed_user() {
      return this.data_for_modal?.processed_user;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      if (e.target.id !== "confirm_modal_wr") return;
      this.processModal({ name: "transfer", type: "close" });
    },
    confirm() {
      this.socket.emit("client_action", {
        action_type: "SendMoney",
        data: {
          sender_id: this.user.id,
          target_id: this.processed_user.id,
          amount: this.amount,
        },
      });
      this.processModal({ name: "transfer", type: "close" });
    },
    deny() {
      this.processModal({ name: "transfer", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_modal_wr {
  position: absolute;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;

  background-color: rgba(2, 2, 2, 0.43);
  z-index: 50;
  top: 0;
  left: 0;
}

.confirm_wr {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 1rem;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
}
.trnasfer-deny-wr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;

  .container {
    display: flex;
    justify-content: center;
  }
  // text-align: left;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

h2 {
  color: white;
  margin: 0;
  padding: 0;
}

.price_wr {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  align-items: center;
  input {
    width: 100%;
  }
  img {
    width: 2.5rem;
    border-radius: 5px;
  }
  span {
    color: yellow;
    font-size: 1rem;
    text-align: center;
  }
  .total_transfer {
    font-size: 1.7rem;
  }
}

.icons_wr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  align-items: center;
  .target_wr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 2.5rem;
    }
    span {
      font-size: 1rem;
    }
  }
  span {
    font-size: 2rem;
  }
}
.btns-wr {
  // margin-top: 10px;
  display: flex;
  gap: 1rem;
}

button {
  margin: 0;
  line-height: 1.15;
  padding: 10px 20px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  transition: 0.25s ease;
  text-transform: none;
}

.btn_confirm {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
  &:hover {
    background: #065291;
    border-color: #065291;
  }
}
.btn_deny {
  color: #fff;
  background: #e94242;
  border-color: #e94242;
  &:hover {
    background: #aa0000;
    border-color: #aa0000;
  }
}
</style>
