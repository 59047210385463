<template>
  <div class="exec-wrap" data-type="exec">
    <div v-if="searching" class="exec dis-hover" data-type="exec">
      <span data-type="exec">{{ $t("message.searching") }}</span>
      <div class="timer" data-type="exec">{{ searching_timer }}</div>
    </div>
    <div v-if="isMyTurn" class="exec" data-type="exec" @click="$emit('exec')">
      <span data-type="exec">{{ $t("message.exec") }}</span>
      <div class="timer" data-type="exec">{{ user_timer }}</div>
    </div>
  </div>
</template>

<script>
import { searching_timer } from "#static_data";
import { mapState, mapGetters } from "vuex";
export default {
  emits: ["exec"],

  computed: {
    ...mapState("general_store", ["timers"]),
    ...mapState("gui_store", ["freeze"]),

    ...mapGetters("general_store", ["game", "phase_name", "active_players", "isHistoryModeOn", "home"]),

    searching() {
      return this.game?.state.status === "searching";
    },

    user_timer() {
      if (this.home === "watcher") return;

      return this.formatTimer(this.timers[this.home]);
    },

    searching_timer() {
      return this.formatTimer(this.timers[this.game.id] || searching_timer);
    },

    isMyTurn() {
      if (this.phase_name === "GameOver") return false;
      if (this.active_players?.includes(this.home) && !this.isHistoryModeOn && !this.freeze.freeze) {
        return true;
      }
      return false;
    },
  },

  methods: {
    formatTimer(duration) {
      const hrs = ~~(duration / 3600);
      const mins = ~~((duration % 3600) / 60);
      const secs = ~~duration % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let ret = "";

      if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
.exec-wrap {
  position: absolute;
  z-index: 4;
  height: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  align-items: center;
  pointer-events: all;

  // width: 2rem;
  // height: auto;
}
.timer {
  height: auto;
  z-index: 50;
  font-size: 1.5rem;
  color: red;
}

.exec {
  display: flex;
  // flex-direction: column;
  gap: 0.5rem;
  // width: auto;
  // min-width: 90px;
  // height: 90px;
  border: 2px solid rgb(171, 84, 84);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  box-sizing: border-box;
  // margin-right: 15px;
  background-color: rgba(50, 47, 47, 0.897);
  color: white;
  font-weight: 600;
  white-space: nowrap;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgb(50, 47, 47);
  }
}
</style>
