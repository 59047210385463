<template>
  <div class="wrapper">
    <div
      v-for="arrow in arrows_on_board"
      :key="arrow.name"
      class="arrow_wr"
      :style="{
        left: arrow.left,
        top: arrow.top,
        transform: `rotate(${arrow.angle}deg)`,

        transformOrigin: `left top`,
      }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" :width="arrow.side / 5" :height="arrow.side">
        <defs>
          <linearGradient :id="`gradient_${arrow.name}_${arrow.color}`" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" :style="`stop-color: ${arrow.color}; stop-opacity: 0.2`" />
            <stop offset="30%" :style="`stop-color: ${arrow.color}; stop-opacity: 0.3`" />
            <stop offset="100%" :style="`stop-color: ${arrow.color}; stop-opacity: 1`" />
          </linearGradient>
        </defs>
        <path :fill="`url(#gradient_${arrow.name}_${arrow.color})`">
          <animate
            attributeName="d"
            :from="`M20,0 q0,0 0,0 l10,0 l-16,20 l-10,-25 l10,4 q0,0 0,0 z`"
            :to="`M20,0 Q${arrow.side / 5},${arrow.side / 5} 15,${arrow.side - 40} l10,0 l-16,20 l-10,-25 l10,4 Q${
              arrow.side / 6
            },${arrow.side / 6} 0,40 z`"
            dur="3s"
            fill="freeze"
          />
        </path>
      </svg>
    </div>
  </div>
</template>
<script>
import { nextTick } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapGetters("general_store", ["game", "arrow_list", "show_game_map"]),
    ...mapState("general_store", ["arrows_on_board"]),

    arrows_dyn() {
      if (!this.arrow_list) return;
      if (!this.arrow_list.crusade_arrows) return;
      return this.arrow_list.crusade_arrows
        .concat(this.arrow_list.attack_arrows)
        .concat(this.arrow_list.support_arrows)
        .concat(this.arrow_list.retreat_arrows);
    },
  },
  watch: {
    async arrows_dyn(new_, old_) {
      await nextTick();
      this.generateArrows();
    },

    "arrow_list.support_arrows"(newArrows, oldArrows) {
      if (newArrows?.length >= oldArrows?.length) return;
      const arrowsToDel = oldArrows?.reduce((arr, old_arrow) => {
        if (!newArrows?.some((new_arrow) => new_arrow.id === old_arrow.id)) {
          arr.push(old_arrow.id);
        }
        return arr;
      }, []);
      arrowsToDel?.forEach((arrow_id) => {
        this.processArrows({ arrow_id });
      });
    },
  },

  async mounted() {
    if (!this.arrow_list?.crusade_arrows?.length && !this.arrow_list?.retreat_arrows?.length) return;
    await nextTick();
    this.generateArrows();
  },

  methods: {
    ...mapMutations("general_store", ["processArrows"]),

    generateArrows() {
      if (!this.arrow_list) return;
      if (!this.show_game_map) return;
      let arrows = this.arrow_list?.crusade_arrows
        .concat(this.arrow_list.support_arrows)
        .concat(this.arrow_list.retreat_arrows);

      const tempArrows = [];

      arrows.forEach((arrow) => {
        const { target_land_name, source_land_name, color, id } = arrow;
        let targetlandEl = document.querySelector(`#${target_land_name}_garrison`);
        if (!targetlandEl) {
          targetlandEl = document.querySelector(`#${target_land_name}-drop`);
        }
        let source_landEl = document.querySelector(`#${source_land_name}-order_zone`);

        if (source_land_name.includes("_port")) {
          targetlandEl = document.querySelector(`#${target_land_name}-drop`);
        }
        const box_target_styles = window.getComputedStyle(targetlandEl, null);
        const box_source_styles = window.getComputedStyle(source_landEl, null);

        const target_center_x = +box_target_styles.left.split("px")[0];
        const target_center_y = +box_target_styles.top.split("px")[0];

        const source_center_x = +box_source_styles.left.split("px")[0];
        const source_center_y = +box_source_styles.top.split("px")[0];

        const deltaY = Math.abs(source_center_y - target_center_y);
        const deltaX = Math.abs(source_center_x - target_center_x);

        const side = Math.sqrt(Math.pow(deltaY, 2) + Math.pow(deltaX, 2));
        const angle = findAngle(target_center_y - source_center_y, target_center_x - source_center_x);

        const left = source_center_x + 40 + "px";
        const top = source_center_y + 40 + "px";

        const isArrowAlreadyExist = tempArrows.some((_arrow) => _arrow.id === arrow.id);

        if (!isArrowAlreadyExist) {
          tempArrows.push({
            name: source_land_name,
            left,
            top,
            side,
            angle,
            color,
            id,
          });
        }

        function findAngle(dy, dx) {
          var theta = Math.atan2(dy, dx);
          theta *= 180 / Math.PI;
          return theta - 90;
        }
      });

      this.processArrows({ arrows: tempArrows });
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow_wr {
  z-index: 1555;
  pointer-events: none;
  position: absolute;
}
</style>
