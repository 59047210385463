import { createStore } from "vuex";
import gameModule from "./game_store.js";
import generalModule from "./general_store.js";
import guiModule from "./gui_store.js";

export default createStore({
  modules: {
    general_store: generalModule,
    game_store: gameModule,
    gui_store: guiModule,
  },
});
