<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @click="close">
    <div class="inner_wr">
      <div class="table">
        <div v-for="log in wilding_history" :key="log" class="row">
          <div class="round">{{ log.round }}</div>
          <div class="card">{{ $t(`wild_card.${log.card}`) }}</div>

          <div class="force">{{ `${$t("message.force")}: ${log.force}` }}</div>
          <div class="victory">
            <span v-if="!log.is_victory" class="material-icons loose"> cancel </span>
            <span v-else class="material-icons victory"> check_circle </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["mainRoom", "user", "game"]),

    wilding_history() {
      if (!this.game) return;
      return this.game.state.wildings_history;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      this.processModal({ name: "wildings_history", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_modal_wr {
  position: absolute;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;

  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}

.inner_wr {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  color: White;
  width: auto;
  height: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  overflow-y: auto;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: all-scroll;
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
}

.inner_wr::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inner_wr {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.row {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid gray;
}

.round {
  width: 50px;
  font-size: 2rem;
  color: aquamarine;
}

.card {
  width: auto;
}

.victory {
  color: green;
}
.loose {
  color: rgb(207, 62, 33);
}
</style>
