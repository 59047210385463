<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="inner_wr">
      <div v-for="user in bank_users" :key="user.id" class="item_wr">
        <div class="ava_wr">
          <span> {{ user.name }}</span>
          <img :src="`/images/avatars/${user.avatars[0]}`" alt="ava" />
        </div>
        <span>{{ game.game_options.bank.buy_in }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("general_store", ["users"]),
    ...mapGetters("general_store", ["user", "game"]),
    bank_users() {
      return this.game?.game_options.bank.list.reduce((arr, user_id) => {
        const mockUser = {
          id: "mock_user",
          name: "faceless man",
          avatars: ["default_avatar.png"],
        };
        const user = this.users.find((u) => u.id === user_id) || mockUser;

        arr.push(user);

        return arr;
      }, []);
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "bank_log", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  pointer-events: all;
}

.inner_wr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  color: white;
  box-sizing: border-box;
  width: fit-content;
  max-width: 85dvw;
  // height: 200px;
  height: fit-content;
  max-height: 90dvh;
  align-items: center;
  overflow-y: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(239, 211, 99);
  -moz-box-shadow: -1px -4px 97px 31px rgb(239, 211, 99);
  box-shadow: -1px -4px 97px 31px rgb(239, 211, 99);
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Hide scrollbar for other browsers */
}

.item_wr {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid white;

  .ava_wr {
    gap: 0.6rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    img {
      width: 100px;
      border-radius: 0.4rem;
    }
    span {
      display: block;
      font-size: 1rem;
      text-align: center;
      color: white;
    }
  }

  span {
    font-size: 2rem;
    color: gold;
  }
}
</style>
