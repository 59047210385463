import store from "../store/index.js";

export default class WildingsHandler {
  constructor() {}

  initialTracksPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    if (!state) return;
    if (phase_name !== "Wildings") return;
    if (!active_players.includes(home)) {
      store.commit("general_store/processModal");
      return;
    }
    const curTrack = state.current_track_bid;
    if (!curTrack) return;

    store.commit("general_store/processModal", { name: "bid" });
    store.commit("game_store/setBidTrack", { home: home, bid: "0", trackName: curTrack });
  }

  resetGui() {
    store.commit("general_store/processModal");
  }

  prepareGui() {
    this.resetGui();
    this.initialTracksPreset();
  }
}
