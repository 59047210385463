import store from "../store/index.js";
export default class KingBeyondTheWallHandler {
  constructor() {}

  allowCursorPointerOnTrack(permit) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];

    if (!active_players.includes(home)) return;
    if (!permit) return;

    return true;
  }

  initialTracksPreset() {
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "WildingManual") return;
    const state = store.getters["general_store/state"];

    if (!state) return;
    if (phase_name !== "WildingManual") return;

    const wildingsState = state.wildings_state;

    const labels = wildingsState.is_victory ? ["throne", "sword", "crow"] : ["sword", "crow"];
    store.commit("gui_store/setHintTrackLabels", { trackNames: labels });
  }

  trackLabelClick(e) {
    store.commit("game_store/setTrackLabel", { trackName: e.target.dataset.source });
    store.commit("game_store/setExecTriger", { doit: true });
  }

  prepareGui() {
    this.resetGui();
    this.initialTracksPreset();
  }

  resetGui() {
    store.commit("gui_store/setHintTrackLabels", { reset: true });
  }
}
