export default {
  uk: {
    locative_land: {
      the_shivering_sea: "Тремтливому Морі",
      the_narrow_sea: "Вузькому Морі",
      bay_of_ice: "Крижаній Затоці",
      winterfell: "Вінтерфеллі",
      winterfell_port: "Порту Вінтрефеллу",
      castle_black: "Чорному Замку",
      the_stony_shore: "Каменястому Березі",
      white_harbor: "Білій Гавані",
      white_harbor_port: "Порту Білої Гавані",
      karhold: "Карголді",
      widows_watch: "Вдоварті",
      the_eyrie: "Соколиному Гнізді",
      moat_cailin: "Калин-Копі",
      the_twins: "Близнюках",
      the_fingers: "Пальцях",
      the_mountains_of_the_moon: "Місячних Горах",
      shipsrearer_bay: "Затоці Човнозгуба",
      blackwater_bay: "Чорноводій Затоці",
      dragonstone: "Дракон-Камені",
      dragonstone_port: "Порту Дракон-Каменя",
      crackclaw_point: "Гостроклішні",
      kingswood: "Королівській Пущі",
      kings_landing: "Король-Березі",
      east_summer_sea: "Східному Літньому Морі",
      sea_of_dorne: "Дорнійському Морі",
      sunspear: "Сонцесписі",
      sunspear_port: "Порту Сонцесписа",
      storms_end: "Штормоламі",
      storms_end_port: "Порту Штормоламу",
      the_boneway: "Кістяному Шлясі",
      princes_pass: "Великокняжому проході",
      starfall: "Зорепаді",
      yronwood: "Крицаку",
      salt_shore: "Солемор'ї",
      west_summer_sea: "Західному Літньому Морі",
      redwyne_straights: "Рожвиновій протоці",
      highgarden: "Хайгардені",
      oldtown: "Старограді",
      oldtown_port: "Порту Старограду",
      three_towers: "Трибашті",
      dornish_marches: "Дорнійському Шлясі",
      the_reach: "Обширі",
      the_arbor: "острові Вертограда",
      sunset_sea: "Західному Морі",
      the_golden_sound: "Золотій Затоці",
      lannisport: "Ланніспорті",
      lannisport_port: "Порту Ланніспорту",
      riverrun: "Водоплині",
      harrenhal: "Гаренголі",
      stoney_sept: "Камінній Септі",
      searoad_marches: "Морському Шлясі",
      blackwater: "Чорноводій",
      ironmans_bay: "Залізняцькій Затоці",
      pyke: "Пайку",
      pyke_port: "Порту Пайка",
      seagard: "Морестражі",
      flints_finger: "Кремінь-Пальці",
      greywater_watch: "Сіроводці",
    },
  },
};
