<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="modal_container">
      <span class="title">{{ $t(`message.${data_for_modal.title}`) }}</span>
      <div class="price_wr">
        <img src="/images/money.png" alt="money" />
        <span>{{ price[data_for_modal.title || data_for_modal.type] }}</span>
      </div>

      <button class="button" role="button" @click="confirm">{{ $t(`message.pay`) }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { price } from "#static_data";
export default {
  inject: ["socket"],

  data() {
    return {
      price,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user"]),
    ...mapState("general_store", ["data_for_modal"]),
  },

  methods: {
    ...mapMutations("general_store", ["setDataForModal", "processModal"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.data_for_modal?.cb(false);
      this.setDataForModal({
        data: {
          cb: null,
          title: null,
          type: null,
        },
      });
      this.processModal({ name: "confirm_pay", type: "close" });
    },
    confirm() {
      this.data_for_modal?.cb(true);
      this.setDataForModal({
        data: {
          cb: null,
          title: null,
          type: null,
        },
      });
      this.processModal({ name: "confirm_pay", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}
.modal_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  gap: 1rem;
  background-color: rgb(22, 22, 22);
  border-radius: 0.4rem;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  -webkit-box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
  -moz-box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
  box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
}

@media (max-width: 500px) {
  .modal_container {
    width: 85dvw;
  }
}

.title {
  color: white;
  text-align: center;
  font-size: 1.3rem;
}

.price_wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 3rem;
  }
  span {
    color: yellow;
    font-size: 2rem;
    text-align: center;
  }
}

.button {
  background-color: #c2fbd7;
  border-radius: 3rem;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.15) 0 1px 2px,
    rgba(44, 187, 99, 0.15) 0 2px 4px, rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 1rem;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.25) 0 1px 2px,
    rgba(44, 187, 99, 0.25) 0 2px 4px, rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
</style>
