<template>
  <div class="top_tools_container prevent-select">
    <div class="tools_inner_container">
      <div class="left_side_tools">
        <span
          class="material-icons logout-btn tool_item"
          @click="logOutModalOpen"
          @pointerenter="hint_label_hover($event, { msg: 'logout' })"
          @pointerleave="resetHint"
        >
          logout
        </span>
        <span class="material-icons tool_item drop_area" @click="dropMenu = !dropMenu"> list </span>

        <img
          class="person_icon"
          :src="`/images/avatars/${user.avatars[0]}`"
          alt="ava"
          @click="processModal({ name: `person` })"
        />
        <span class="tool_username" @click.prevent="routeToAdminInit"> {{ user?.name }}</span>
      </div>
      <div v-if="phase_name" class="tool_item phase_title">
        <span v-if="phase_name === 'GameOver'" class="material-icons openModalIcon" @click="showInfoGameOverModal">
          info
        </span>
        <span v-else>{{ $t(`phase.${phase_name}`) }}</span>
      </div>
      <div v-if="current_watch_id || current_game_id" class="right_side_tools">
        <div class="houses_drop_btn tool_item">
          <img
            class="home_img drop_area"
            :class="{ cursor_pointer: isAdmin }"
            :src="`/images/tokens/${_home || 'watcher'}_token.png`"
            alt="home"
            @click="dropHousesBar"
          />
        </div>

        <span class="material-icons tool_item drop_area" @click="dropInfo = !dropInfo"> info </span>
        <span
          class="material-icons tool_item"
          @click="leaveRoom(current_watch_id || current_game_id)"
          @pointerenter="hint_label_hover($event, { msg: 'LeaveGame', left: 50 })"
          @pointerleave="resetHint"
        >
          logout
        </span>
        <span
          v-if="!isWatcher"
          class="material-icons tool_item"
          @click="openDoor"
          @pointerenter="hint_label_hover($event, { msg: 'open_door_room', left: 140 })"
          @pointerleave="resetHint"
        >
          transfer_within_a_station
        </span>
      </div>
    </div>
    <div class="logger_wrapper">
      <LoggerComponent v-if="game && inside_room" />
    </div>
    <div v-if="game" class="houses_drop_container drop_area" :class="{ housesDrop }">
      <div
        v-for="home in houses"
        :key="home"
        class="btn drop_area"
        :class="{ inactive: !active_players.includes(home) }"
        :style="{
          transform: home === home ? 'scale(1.4)' : 'none',
        }"
        @click="changePlayer(home)"
      >
        <img :src="`/images/tokens/${home}_token.png`" alt="home" class="drop_area" />
      </div>
    </div>

    <div class="drop_menu_container drop_area" :class="{ dropMenu }">
      <div class="audio_player">
        <div class="title_wr">
          <span class="drop_area">{{ current_track_name }}</span>
          <div class="btns">
            <span @click="playPrevTrack" class="material-icons drop_area"> skip_previous </span>
            <span @click="playNextTrack" class="material-icons drop_area"> skip_next </span>
          </div>
        </div>

        <audio ref="main_sound" class="drop_area" controls @ended="playNextTrack"></audio>
      </div>
      <span class="material-icons tool_item drop_area" @click="processModal({ name: `sound_settings` })">
        music_note
      </span>

      <div class="local drop_area">
        <img src="/images/ukraine.png" alt="uk" class="ukraine drop_area" />
        <input v-model="eng_lang" type="checkbox" class="check drop_area" @change="changeLocale" />
        <img src="/images/united-kingdom.png" alt="us" class="britain drop_area" />
      </div>
      <div class="btns_wr drop_area">
        <div v-if="inside_room" class="history_btn drop_area">
          <span
            class="material-icons drop_area"
            @click="rotateMap"
            @pointerenter="hint_label_hover($event, { msg: 'rotate_map' })"
            @pointerleave="resetHint"
          >
            crop_rotate
          </span>
        </div>
        <div class="history_btn drop_area">
          <span
            class="material-icons drop_area"
            @click="reloadPage"
            @pointerenter="hint_label_hover($event, { msg: 'page_reload' })"
            @pointerleave="resetHint"
          >
            restore_page
          </span>
        </div>
        <div v-if="inside_room" class="history_btn drop_area">
          <span
            class="material-icons drop_area"
            @click="centerMap"
            @pointerenter="hint_label_hover($event, { msg: 'center_map' })"
            @pointerleave="resetHint"
          >
            filter_center_focus
          </span>
        </div>
        <div v-if="isAdmin && inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="startTime"> timer </span>
        </div>
        <div v-if="isAdmin && inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="stopTime"> alarm_off </span>
        </div>
        <div v-if="isAdmin && inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="startGame"> restart_alt </span>
        </div>
        <div v-if="inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `reset`, game })">
            mode_standby
          </span>
        </div>
        <div v-if="inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `back`, game })"> west </span>
        </div>
        <div v-if="inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `forward`, game })"> east </span>
        </div>
        <div v-if="isAdmin && inside_room" class="history_btn drop_area">
          <span class="material-icons drop_area" @click="applyHistoryState"> approval </span>
        </div>
      </div>
    </div>
    <div v-if="game" class="drop_info_container drop_area" :class="{ dropInfo }">
      <span class="game_name">{{ this.game?.game_options.name }}</span>
      <span class="game_status">{{ $t(`message.${this.game?.state.status}`) }}</span>
      <div class="main_info">
        <div
          v-for="field in info_fields"
          :key="field[0]"
          class="info_field prevent-select"
          @pointerenter="hint_label_hover($event, { msg: field[0] })"
          @pointerleave="resetHint"
        >
          <span class="material-icons" :style="{ color: field[2] }">{{ field[1] }}</span>
          <span class="span">{{ getFieldData(field[0], game) }}</span>
        </div>
      </div>
      <div class="open_cards_heep_btn drop_area" @click="processModal({ name: 'all_cards' })">
        {{ $t(`message.all_cards`) }}
      </div>

      <!-- <span>{{ JSON.stringify(this.game.game_options, null, 4) }}</span> -->
    </div>
  </div>
</template>

<script>
  import { searching_timer } from '#static_data'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    setup() {
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array
      }
      const tracks = [
        { src: '/audio/bleed_it_out.mp3', name: 'Bleed It Out' },
        { src: '/audio/crawling.mp3', name: 'Crawling' },
        { src: '/audio/easier_to_run.mp3', name: 'Easier To Run' },
        { src: '/audio/faint.mp3', name: 'Faint' },
        { src: '/audio/from_the_inside.mp3', name: 'From The Inside' },
        { src: '/audio/in_the_end.mp3', name: 'In The End' },
        { src: '/audio/lying_from_you.mp3', name: 'Lying From You' },
        { src: '/audio/numb.mp3', name: 'Numb' },
        { src: '/audio/one_more_light.mp3', name: 'One More Light' },
        { src: '/audio/papercut.mp3', name: 'Papercut' },
        { src: '/audio/somewhere_i_belong.mp3', name: 'Somewhere I Belong' },
        { src: '/audio/the_messanger.mp3', name: 'The Messanger' },
        { src: '/audio/until_its_gone.mp3', name: "Until It's Gone" },
        { src: '/audio/waiting_for_the_end.mp3', name: 'Waiting For The End' },
        { src: '/audio/what_ive_done.mp3', name: "What I've Done" },
      ]
      return {
        tracks: shuffleArray(tracks),
      }
    },
    inject: ['socket'],

    data() {
      return {
        hint_delay: 250,
        clear_hint_delay: null,
        hint_timer_delay: null,

        dropMenu: false,
        dropInfo: false,
        housesDrop: false,
        eng_lang: false,
        info_fields: [
          ['bank.buy_in', 'paid', 'green'],
          ['password', 'lock', 'white'],
          ['time_to_move', 'more_time', '#babae2'],
          ['timebank', 'query_builder', '#babae2'],
          ['random_force', 'library_add', 'red'],
          ['scull', 'cancel', 'white'],
          ['charm', 'favorite', 'red'],
          ['intelligence', 'psychology', '#babae2'],
          ['honor', 'stars', 'orange'],
        ],

        audio_elements: [],

        current_track_index: 0,
        current_track_name: '',
        currently_playing: null,
        paused_time: 0,
        audio_loaded: false,
      }
    },

    computed: {
      ...mapGetters('general_store', [
        'game',
        'phase_name',
        'current_game_id',
        'inside_room',
        'active_players',
        'isHistoryModeOn',
        'isAdmin',
        'user',
        'home',
        'inside_watch_room',
        'show_game_map',
        'current_watch_id',
      ]),
      ...mapState('general_store', ['sid', 'timers', 'temp_gui_state']),
      ...mapState('gui_store', ['mapPosition', 'freeze']),

      houses() {
        const houses = this.game?.state.states.map((st) => st.home)
        return houses
      },
      user_locale() {
        return this.user?.locale
      },

      searching_timer() {
        return this.formatTimer(this.timers[this.game.id] || searching_timer)
      },

      isWatcher() {
        return this.home === 'watcher' || this.inside_watch_room
      },
      _home() {
        const cur_watching_game = this.user.current_games.find((g) => g.room_id === this.current_watch_id)
        if (cur_watching_game) {
          return cur_watching_game.home
        }
        return this.home
      },
      sound() {
        return this.user?.sound_settings
      },
    },

    mounted() {
      this.eng_lang = this.user?.locale === 'uk' ? false : true

      document.addEventListener(
        'click',
        function (e) {
          if (!e.target.classList.contains('drop_area')) {
            this.dropMenu = false
            this.housesDrop = false
            this.dropInfo = false
          }
        }.bind(this)
      )

      this.audio_elements = this.tracks.map((track) => {
        const audio = new Audio(track.src)
        audio.preload = 'auto'
        return audio
      })

      this.$refs.main_sound.addEventListener(
        'canplaythrough',
        () => {
          this.audio_loaded = true
          this.updateTrack()
          this.playAudio()
        },
        { once: true }
      )
      this.updateTrack()
    },

    watch: {
      sound: {
        handler(newSettings, oldSettings) {
          if (!oldSettings || !newSettings) return
          const changedKeys = Object.keys(newSettings).reduce((acc, key) => {
            if (newSettings[key] !== oldSettings[key]) {
              acc.push(key)
            }
            return acc
          }, [])
          changedKeys.forEach((key) => {
            if (key !== 'main') return
            if (newSettings[key]) {
              this.playAudio()
            } else {
              this.pauseAudio()
            }
          })
        },
        deep: true,
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setMainSideTab', 'setHintLabel', 'setMapPosition', 'setFreeze']),
      ...mapMutations('general_store', [
        'setTempState',
        'setPlayer',
        'processModal',
        'setDataForModal',
        'setInsideRoom',
      ]),

      updateTrack() {
        this.current_track_name = `${this.tracks[this.current_track_index].name}`
        this.$refs.main_sound.src = this.tracks[this.current_track_index].src
      },

      playAudio() {
        if (!this.sound.main) return
        if (!this.audio_loaded) return
        if (this.$refs.main_sound.src !== this.tracks[this.current_track_index].src) {
          this.updateTrack()
        }

        const current_audio = this.$refs.main_sound

        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        if (this.paused_time > 0) {
          current_audio.currentTime = this.paused_time
          this.paused_time = 0 // Reset paused time after resuming
        }

        current_audio.play()
        this.currently_playing = current_audio
      },

      pauseAudio() {
        this.$refs.main_sound.pause()
        this.paused_time = this.currently_playing?.currentTime || 0
      },

      playNextTrack() {
        // Pause any currently playing audio
        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        // Update the current track index
        this.current_track_index = this.current_track_index + 1
        if (this.current_track_index > this.tracks.length - 1) {
          this.current_track_index = 0
        }
        this.updateTrack()

        // Play the new track
        this.playAudio()
      },
      playPrevTrack() {
        // Pause any currently playing audio
        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        // Update the current track index
        this.current_track_index = this.current_track_index - 1
        if (this.current_track_index < 0) {
          this.current_track_index = this.tracks.length - 1
        }
        this.updateTrack()

        // Play the new track
        this.playAudio()
      },

      routeToAdminInit(e) {
        if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
          this.$router.push({ name: 'admin_init' })
          return
        }
        if (e.shiftKey) {
          this.$router.push({ name: 'home' })
          return
        }
        this.processModal({ name: `person` })
      },

      reloadPage() {
        window.location.reload()
      },
      centerMap() {
        const map = document.getElementById('zoom')
        const map_styles = window.getComputedStyle(map)
        const transform_matrix = map_styles.getPropertyValue('transform')
        const matrixValues = transform_matrix.match(/matrix\(([^)]+)\)/)[1].split(', ')
        const scale = matrixValues[0]
        const map_width = parseFloat(map_styles.getPropertyValue('width'))
        const map_height = parseFloat(map_styles.getPropertyValue('height'))
        const window_width = window.innerWidth
        const window_height = window.innerHeight
        const map_width_after = map_width * scale
        const map_height_after = map_height * scale
        const height_delta = window_height - map_height_after

        this.setMapPosition({
          x: window_width / 2 - map_width_after / 2,
          y: height_delta / 2,
          scale,
          centerMap: true,
        })
      },

      rotateMap() {
        const rotate_value = this.mapPosition.rotate
        let res = rotate_value + 90
        if (res > 360) {
          res = 0
          this.setMapPosition({
            rotate: 0,
          })

          return
        }
        this.setMapPosition({
          rotate: res,
        })
      },

      changePlayer(home) {
        if (!this.isAdmin) return
        if (home === this.home) return
        this.setPlayer({
          game_id: this.current_game_id,
          user: this.user,
          home,
        })
        this.housesDrop = false
      },

      showInfoGameOverModal(e) {
        if (this.phase_name !== 'GameOver') return
        this.processModal({ name: 'game_over' })
      },

      formatTimer(duration) {
        const hrs = ~~(duration / 3600)
        const mins = ~~((duration % 3600) / 60)
        const secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs

        return ret
      },

      changeLocale(e) {
        const locale = this.eng_lang ? 'en' : 'uk'
        this.socket.emit('client_action', {
          action_type: 'SetLocale',
          data: {
            user_id: this.user.id,
            locale,
          },
        })
      },

      logOutModalOpen() {
        this.setDataForModal({
          data: { action_type: 'LeaveApp', sid: this.sid, processed_user: this.user },
        })
        this.processModal({ name: 'leave_room' })
      },

      leaveRoom(room_id) {
        if (this.isWatcher) {
          this.socket.emit('client_action', {
            action_type: 'LeaveGame',
            data: {
              room_id,
              user_id: this.user.id,
              processed_user: this.user,
              sid: this.sid,
            },
          })
          return
        }

        this.setDataForModal({
          data: { action_type: 'LeaveGame', room_id, processed_user: this.user, sid: this.sid },
        })
        this.processModal({ name: 'leave_room' })
      },

      openDoor() {
        const cur_game = this.user.current_games.find((g) => g.room_id === this.current_game_id)
        if (!cur_game || cur_game.sid !== this.sid) return

        this.socket.emit('client_action', {
          action_type: 'OpenDoor',
          data: {
            room_id: this.current_game_id,
            user_id: this.user.id,
          },
        })
      },

      resetHint() {
        clearTimeout(this.hint_timer_delay)
        clearTimeout(this.clear_hint_delay)

        this.clear_hint_delay = null
        this.hint_timer_delay = null
        this.setHintLabel({ reset: true })
      },
      hint_label_hover(e, item) {
        if (e.pointerType !== 'mouse') return
        if (!item) return
        this.resetHint()

        this.hint_timer_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const hint_max_width = 140
          let delta = hint_max_width - (window.innerWidth - coords.left)
          if (delta <= 0) {
            delta = 0
          }

          const gap_from_top = 40

          const logYpos = `translate(${+coords.left - delta}px, ${coords.top + gap_from_top}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
          })

          this.clear_hint_delay = setTimeout(() => {
            this.resetHint()
          }, 2000)
        }, this.hint_delay)
      },
      startGame() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              start: true,
              room_id: this.game.id,
            },
          },
        })
        this.setFreeze({ freeze: true, color: 'pink' })
      },

      applyHistoryState() {
        if (!this.temp_gui_state.snapshot_id) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              history_snapshot_id: this.temp_gui_state.snapshot_id,
              room_id: this.game.id,
            },
          },
        })
        this.setTempState({ direction: 'reset', game: this.game })
        this.setFreeze({ freeze: true, color: 'pink' })
      },

      stopTime() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              stopTime: true,
              room_id: this.game.id,
            },
          },
        })

        this.setFreeze({ freeze: true, color: 'pink' })
      },

      startTime() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              startTime: true,
              room_id: this.game.id,
            },
          },
        })

        this.setFreeze({ freeze: true, color: 'pink' })
      },
      dropHousesBar() {
        if (!this.isAdmin) return
        if (!this.home || this.home === 'watcher') return
        this.housesDrop = !this.housesDrop
      },

      getFieldData(key, game) {
        if (!game) return
        // const game = this.rooms.find((r) => r.id === room_id);
        let key_1 = key,
          key_2
        if (key.includes('.')) {
          const keys = key.split('.')
          key_1 = keys[0]
          key_2 = keys[1]
        }
        let data
        if (key_2) {
          data = game.game_options[key_1][key_2]
        } else {
          data = game.game_options[key_1]
        }

        if (key_1 === 'password' && data) {
          return '***'
        }
        if (key_1 === 'random_force' && data === '0') {
          if (game.game_options.scull) {
            return '0'
          }
          return '-'
        }

        if (key_1 !== 'charm' && (!data || data === '0')) {
          return '-'
        }
        return data
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top_tools_container {
    position: relative;
    display: flex;
    height: 2.5rem;
    flex-direction: column;
    width: 100%;
    overflow: visible;
    pointer-events: all;
    // height: 6rem;
  }

  .tools_inner_container {
    position: absolute;
    z-index: 155;
    height: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #292828;
    pointer-events: all;

    .left_side_tools {
      align-items: center;
      display: flex;
      flex: 1;
      gap: 1rem;
      color: white;
      span {
        cursor: pointer;
      }

      .person_icon {
        cursor: pointer;
        width: 1.8rem;
        border-radius: 0.4rem;
      }

      .tool_username {
        color: white;
        pointer-events: all;
      }

      @media screen and (max-width: 400px) {
        .tool_username {
          display: none;
        }
      }
    }

    .phase_title {
      width: auto;

      display: flex;
      flex: 4;
      color: white;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      gap: 1rem;
      .openModalIcon {
        cursor: pointer;
        color: rgb(129, 214, 235);
      }
      span {
        font-size: 1.5rem;
      }

      @media (max-width: 400px) {
        span {
          font-size: 0.8rem;
        }
      }
    }

    .right_side_tools {
      align-items: center;
      margin-right: 10px;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      flex: 1;
      color: white;
      span {
        cursor: pointer;
      }
      .houses_drop_btn {
        // position: absolute;
        // right: 5rem;
        margin-top: 0.4rem;

        .home_img {
          // width: 2rem;
          height: 2rem;
          overflow: hidden;
        }
      }
      .cursor_pointer {
        cursor: pointer;
      }
    }
  }

  .dropMenu {
    transform: translateY(530px);
  }

  .drop_info_container {
    width: 200px;
    height: auto;
    // max-width: 100px;
    position: absolute;
    background-color: #292828;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    z-index: 100;
    box-sizing: border-box;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    top: -400px;
    right: 3rem;
    align-items: center;
    border-radius: 0.4rem;
    transition: all 0.35s;
    color: white;
    .game_name {
      font-size: 1.4rem;
    }
    .game_status {
      font-size: 1.1rem;
    }
    .main_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: nowrap;

      .info_field {
        color: White;
        display: flex;
        align-items: center;
        width: 20%;
        gap: 1rem;
        justify-content: space-between;
        span {
          font-size: 1.25rem;
        }
        .span {
          font-size: 1rem;
        }
      }
    }
    .open_cards_heep_btn {
      cursor: pointer;
      background-color: rgb(103, 103, 185);
      padding: 0.7rem;
      border-radius: 0.4rem;
      &:hover {
        background-color: rgb(51, 91, 137);
      }
    }
  }

  .dropInfo {
    top: 0;
    transform: translateY(12%);
  }

  .drop_menu_container {
    width: auto;
    position: absolute;
    background-color: #292828;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    z-index: 152;
    box-sizing: border-box;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    top: -500px;
    align-items: center;
    border-radius: 1rem;
    transition: transform 0.35s;

    .audio_player {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
      justify-content: center;
      .title_wr {
        align-self: center;
        display: flex;
        align-items: center;
        gap: 1rem;
        .btns {
          display: flex;
          span {
            font-size: 2rem;
            color: white;
            cursor: pointer;
            &:hover {
              color: rgb(160, 159, 159);
            }
          }
        }
        span {
          font-size: 1.2rem;
          text-align: center;
          color: rgb(93, 154, 218);
        }
      }
    }

    .local {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      height: auto;
      gap: 5px;

      img {
        width: 1.5rem;
      }
    }
    .check {
      cursor: pointer;
      position: relative;
      width: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 35px;
        height: 20px;
        background-color: #333;
        border-radius: 15px;
        top: -3px;
        left: -3px;
      }
      &:checked:before {
        background: #00a1ff;
      }
      &:checked:after {
        left: 12px;
        border: 2px solid #00a1ff;
      }

      &:after {
        content: '';
        position: absolute;
        left: -3px;
        top: -3px;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 15px;
        transition: 0.25s;
        border: 2px solid #333;
        box-sizing: border-box;
      }
    }
    .tool_item {
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      overflow: visible;
      &:hover {
        color: rgb(228, 101, 101);
      }
    }

    .btns_wr {
      display: flex;
      // background-color: #292828;
      padding: 10px;
      transition: all 0.5s linear;
      border-radius: 5px;

      .history_btn {
        margin: auto 5px;
        span {
          font-size: 22px;
          color: white;
          &:hover {
            cursor: pointer;
            color: red;
          }
        }
        // width: 30px;
      }
    }
  }

  .houses_drop_container {
    // top: -100vh;
    top: 0px;
    transform: translateY(-100%);
    right: 7.5rem;
    z-index: 152;
    position: absolute;
    transition: all 0.35s;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    height: fit-content;
    padding: 0.5rem;
    border-radius: 0.4rem;
    background-color: rgb(39, 38, 38);
    img {
      height: 2rem;
    }

    .btn {
      transition: transform 250ms;
      position: relative;
      &:hover {
        cursor: pointer;
      }
    }
    .inactive::before {
      position: absolute;
      width: 2rem;
      height: 2rem;
      left: 0;
      top: 0;
      content: '';
      background-image: url('/images/forbidden.png');
      background-size: 1.8rem 1.8rem;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .housesDrop {
    transform: translateY(2.2rem);
  }

  .logger_wrapper {
    pointer-events: all;
    position: absolute;
    z-index: 10;
    top: 2.4rem;
    // overflow: visible;
  }

  .dis-hover {
    &:hover {
      cursor: default;
      background-color: rgba(50, 47, 47, 0.638);
    }
  }

  .game_over_btn {
    width: auto;
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .hide {
    visibility: hidden;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s;
    opacity: 1;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateY(0);
    opacity: 0;
  }
</style>
