import store from "../store/index.js";
export default class ManualDelUnitsHandler {
  constructor() {
    this.max_del_count = 0;
  }

  resetGui() {
    store.commit("gui_store/setGapLand", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("game_store/processSeparateUnits", { reset: true });
    this.max_del_count = 0;
  }

  prepareGui() {
    this.resetGui();
    this.initialUnitsPreset();
    this.initialOrdersPreset();
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const crusade_state = store.getters["general_store/crusade_state"];
    const home = store.getters["general_store/home"];
    if (unit.owner !== home) return;
    if (unit.land_name !== crusade_state.conflict_land) return;
    return true;
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }

  initialUnitsPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = active_players[0];

    if (!state) return;
    if (phase_name !== "ManualDelUnits") return;

    const crusade_state = state.phase.crusade_state;

    const candidates = [crusade_state.manual_del_units.winner, crusade_state.manual_del_units.looser];
    const cand = candidates.find((c) => c.home === home);

    this.max_del_count = cand?.count || 0;

    store.commit("gui_store/setGapLand", { land_name: crusade_state.conflict_land });
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = store.getters["general_store/home"];
    if (!state) return;
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (phase_name !== "ManualDelUnits") return;
    const crusade_state = state.phase.crusade_state;

    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;

    if (crusade_state.conflict_land !== e.target.dataset.source) return;
    const separate_units = store.state.game_store.separate_units;

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };

    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });

    if (separate_units.size > this.max_del_count) {
      this.prepareGui();
      return;
    }
  }
}
