<template>
  <div id="modal_base" class="modal_base" @click="closeModal">
    <div class="modal_wr">
      <div class="legacy_form">
        <span class="material-icons clean_btn" @click="resetForm"> cleaning_services </span>

        <div class="item_i">
          <label for="email-field"
            ><strong>{{ $t(`message.enter_email`) }}</strong></label
          >

          <input id="email-field" v-model="email" autocomplete="email" required type="text" @input="inputFn" />
        </div>
        <div class="item_i">
          <label for="username-field"
            ><strong>{{ $t(`message.create_username`) }}</strong></label
          >

          <input
            id="username-field"
            v-model="username"
            autocomplete="username"
            required
            type="text"
            min="3"
            max="30"
            :placeholder="`${$t('message.length')}: 3-30`"
            @input="inputFn"
            :class="{ invalid: invalidEmail }"
          />
        </div>
        <div class="item_i">
          <label for="pass-field"
            ><strong>{{ $t(`message.create_password`) }}</strong></label
          >

          <input
            id="pass-field"
            v-model="password"
            required
            type="password"
            autocomplete="new-password"
            min="5"
            max="20"
            :placeholder="$t(`message.pass_options`)"
            @input="inputFn"
            :class="{ invalid: invalidPass }"
          />
        </div>
        <div class="item_i">
          <label for="pass-conf-field"
            ><strong>{{ $t(`message.repeat_password`) }}</strong></label
          >

          <input
            id="pass-conf-field"
            v-model="password_confirm"
            required
            type="password"
            autocomplete="new-password"
            min="5"
            max="20"
            @input="inputFn"
            :class="{ invalid: invalidPassConfirm }"
          />
        </div>

        <div class="btn" :class="{ enable_btn: creationEnable }" @click="createUser">
          {{ $t(`message.create_account`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bcrypt from "bcryptjs";
import { mapMutations } from "vuex";
import { createInitialUser } from "@/utils/createUser.js";
import passwordValidator from "password-validator";
export default {
  setup() {
    const schema = new passwordValidator().is().min(5).is().max(20).has().digits(2).has().not().spaces();
    return {
      schema,
    };
  },
  data() {
    return {
      creationEnable: false,
      invalidEmail: false,
      invalidPassConfirm: false,
      invalidPass: false,
      password: "",
      password_confirm: "",
      username: "",
      email: "",
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    };
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "showError"]),
    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.resetForm();
      this.processModal();
    },
    resetForm() {
      this.password = "";
      this.username = "";
      this.password_confirm = "";
      this.email = "";
      this.creationEnable = false;
      this.invalidEmail = false;
      this.invalidPassConfirm = false;
      this.invalidPass = false;
    },
    inputFn() {
      if (this.username.length < 3 || this.username.length > 30) {
        this.creationEnable = false;
        return;
      }
      if (this.password !== this.password_confirm) {
        this.creationEnable = false;
        return;
      }

      if (!this.pattern.test(this.email)) {
        this.creationEnable = false;
        return;
      }
      this.creationEnable = true;
    },
    async createUser(e) {
      if (!e.target.classList.contains("enable_btn")) return;
      if (this.username.trim().length < 3) {
        return;
      }

      const passIsInvalid = this.schema.validate(this.password, { details: true });
      let res;
      if (passIsInvalid.length) {
        res = passIsInvalid.reduce((str, msg) => {
          str = str + msg.message + "\n";
          return str;
        }, "");
        this.invalidPass = true;
        alert(res);
        return;
      }

      if (this.password !== this.password_confirm) {
        this.invalidPassConfirm = true;
        return;
      }
      this.email = this.email.trim().toLowerCase();
      if (!this.pattern.test(this.email)) {
        this.invalidEmail = true;
        return;
      }
      try {
        const hashPassword = await bcrypt.hash(this.password, 10);
        const user = createInitialUser({ user_name: this.username, password: hashPassword, email: this.email });
        const res = await this.axios.post("/app/newUser", { user });
        const { error } = res.data;
        this.showError({
          error: { code: error.code, data: error.data },
        });
        this.resetForm();
        this.processModal({
          name: "new_user",
          type: "close",
        });
      } catch (err) {
        console.error("index.vue- createUser: ", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  pointer-events: all;
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 35;
  top: 0;
  left: 0;
}

.modal_wr {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;

  max-height: 90%;
  max-width: 90%;
  color: white;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.5rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
}

// overflow: hidden;

.legacy_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  gap: 1rem;

  .item_i {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    max-height: fit-content;
    input {
      height: auto;
      max-width: 80%;
      background-color: rgb(134, 184, 185);
      color: rgb(22, 67, 68);
      font-size: 1rem;
      font-weight: bold;
      padding: 10px;
      border-radius: 0.4rem;
    }
    input::placeholder {
      font-size: 0.7rem;
      text-align: center;
      margin: auto;
      white-space: pre-line;
      color: black;
    }
  }

  .invalid {
    border: 2px solid red;
  }

  .btn {
    width: 80%;
    min-width: 10rem;
    color: rgb(143, 145, 145);
    background-color: rgb(105, 112, 115);
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 0.4rem;
    cursor: not-allowed;
  }
  .enable_btn {
    cursor: pointer;
    color: rgb(213 255 254);
    background-color: rgb(87 124 140);
  }

  .clean_btn {
    position: absolute;
    align-self: flex-end;
    cursor: pointer;

    &:hover {
      color: green;
    }
  }
}
</style>
