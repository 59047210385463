export default function getDataForEngine(data, phase_name, home, crusade_state, wildingState) {
  const {
    conception_state,
    dig_wildings_card,
    raid_state,
    drag_state,
    support_choice,
    selected_card,
    use_sword,
    change_random_card,
    mastering_state,
    track_bid,
    king_choice,
    throne_decision,
    separate_units,
    westeros_choice,
    permit_support,
  } = data;
  const arr_from_separate_unit = Array.from(separate_units, ([name, value]) => ({ ...value }));
  switch (phase_name) {
    case "Wildings": {
      return {
        ...track_bid,
      };
    }
    case "PutToTheSword": {
      return {
        home,
        nextPhase: westeros_choice,
      };
    }
    case "BlackWings": {
      return {
        home,
        nextPhase: westeros_choice,
      };
    }
    case "ThroneOfBlades": {
      return {
        home,
        nextPhase: westeros_choice,
      };
    }
    case "WildingManual": {
      return getDataForWildingsCard(data, home, wildingState);
    }
    case "ThroneDecision": {
      return {
        home,
        choice: throne_decision,
      };
    }
    case "WarOfKings": {
      return {
        ...track_bid,
      };
    }
    case "KingChoice": {
      return {
        home,
        ...king_choice,
      };
    }

    case "Supply": {
      return {
        home,
        units: arr_from_separate_unit,
      };
    }
    case "Mastering": {
      const { land_name, units } = mastering_state;
      return {
        home,
        source_land_name: land_name,
        units,
      };
    }
    case "Conception": {
      return {
        home,
        orders: [...conception_state],
      };
    }
    case "CrowCheck": {
      return {
        home,
        land_name: conception_state[0] ? conception_state[0].land_name : "",
        order: conception_state[0] ? conception_state[0].title : "",
        dig: dig_wildings_card,
      };
    }
    case "Raid": {
      return {
        home,
        land_name: raid_state.source_land,
        targetLand: raid_state.target_land,
        target_order: raid_state.target_order,
      };
    }
    case "Power": {
      return {
        home,
        source_land_name: mastering_state.land_name,
        units: mastering_state.units,
      };
    }
    case "Crusade": {
      return {
        home,
        source_land_name: drag_state.from,
        token: drag_state.token,
        units: drag_state.units,
      };
    }
    case "PermitSupport": {
      const { permission, home } = permit_support;
      return {
        home,
        permission,
      };
    }
    case "DefineSupport": {
      return {
        home,
        choice: support_choice,
      };
    }
    case "SelectCard": {
      return {
        home,
        card: { title: selected_card.title, home: selected_card.home },
      };
    }
    case "UseSword": {
      return {
        home,
        force: use_sword && !change_random_card ? 1 : 0,
        change_random_card,
      };
    }
    case "ManualDelUnits": {
      return {
        home,
        units: arr_from_separate_unit,
      };
    }

    case "ConflictPort": {
      return {
        home,
        units: arr_from_separate_unit,
      };
    }
    case "PreAttackConflictPort": {
      return {
        home,
        units: arr_from_separate_unit,
      };
    }
    case "LastCardAction": {
      return getDataForCard(data, home, crusade_state);
    }
    case "FirstCardAction": {
      return getDataForCard(data, home, crusade_state);
    }
    case "Retreat": {
      const { from, units } = drag_state;
      return {
        home,
        source_land_name: from,
        units,
      };
    }
    default: {
      return data;
    }
  }
}

function getDataForWildingsCard(data, home, wildingsState) {
  const { mastering_state, track_label, separate_units, selected_card } = data;
  const card = wildingsState.card;
  const arr_from_separate_unit = Array.from(separate_units, ([name, value]) => ({ ...value }));
  switch (card) {
    case "KingBeyondTheWall": {
      return {
        home,
        trackName: track_label.trackName,
      };
    }
    case "PreemptiveRaid": {
      return {
        home,
        trackName: track_label.trackName,
        units: arr_from_separate_unit,
      };
    }
    case "CrowKillers": {
      return {
        home,
        units: arr_from_separate_unit,
      };
    }
    case "MassingMilkwater": {
      return {
        home,
        card: selected_card,
      };
    }
    case "MammothRiders": {
      return {
        home,
        units: arr_from_separate_unit,
        card: selected_card,
      };
    }
    case "Horde": {
      const units = wildingsState.is_victory ? mastering_state.units : arr_from_separate_unit;
      const source_land_name = wildingsState.is_victory ? mastering_state.land_name : "";

      return {
        home,
        units,
        source_land_name,
      };
    }
    default: {
      return {};
    }
  }
}

function getDataForCard(data, home, crusade_state) {
  const { drag_state, selected_card, mastering_state, order_to_del_land_name, track_label } = data;
  const myRole = crusade_state.agressor.home === home ? crusade_state.agressor : crusade_state.victim;
  const enemyRole = crusade_state.agressor.home === home ? crusade_state.victim : crusade_state.agressor;
  const cardTitle = myRole.card.title;
  switch (cardTitle) {
    case "Patchface": {
      return {
        card: { title: myRole.card.title, home },
        payload: { card: selected_card, home: enemyRole.home },
      };
    }
    case "Robb": {
      const { from, units } = drag_state;
      return {
        card: { title: myRole.card.title, home },
        payload: { source_land_name: from, units },
      };
    }
    case "Tyrion": {
      return {
        card: { ...myRole.card },
        payload: {},
      };
    }
    case "Aeron": {
      return {
        card: { ...myRole.card },
        payload: {},
      };
    }
    case "Cersei": {
      return {
        card: { ...myRole.card },
        payload: { land_name: order_to_del_land_name },
      };
    }
    case "Olenna": {
      return {
        card: { ...myRole.card },
        payload: { land_name: order_to_del_land_name },
      };
    }
    case "Renly": {
      const { land_name, units } = mastering_state;
      return {
        card: { ...myRole.card },
        payload: {
          land_name,
          unit: units[0],
        },
      };
    }
    case "Doran": {
      return {
        card: { ...myRole.card },
        payload: {
          track: track_label.trackName,
          home: track_label.home,
        },
      };
    }

    default: {
      return {};
    }
  }
}
