<template>
  <div id="modal_base" class="modal_base" @pointerdown="close">
    <div class="confirm_wr">
      <div class="inner_wr">
        <div v-for="(v, k) in sound_settings" :key="k" class="item_wr">
          <input :id="k" class="check" type="checkbox" :checked="v" @change="change(k)" />
          <label :for="k">{{ $t(`message.${k}_sound`) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  inject: ["socket"],

  emits: ["close"],

  data() {
    return {
      sound_settings: null,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user", "sound"]),
  },

  mounted() {
    this.sound_settings = { ...this.sound };
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    change(k) {
      this.sound_settings[k] = !this.sound_settings[k];

      this.socket.emit("client_action", {
        action_type: "SetSound",
        data: {
          user_id: this.user.id,
          sound_settings: this.sound_settings,
        },
      });
    },

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "sound_settings", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  pointer-events: all;
}
.confirm_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  color: white;
  box-sizing: border-box;
  width: fit-content;
  max-width: 95dvw;
  height: fit-content;
  max-height: 90dvh;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);
  -moz-box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);
  box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);

  @media (max-width: 550px) {
    width: 95dvw;
  }
}
.inner_wr {
  display: flex;
  flex-direction: column;
}

.item_wr {
  width: 100%;
  input {
    margin-right: 2rem;
  }
  label {
    color: rgb(144, 222, 244);
  }
}

.check {
  cursor: pointer;
  position: relative;
  width: 0.5rem;
  &:before {
    content: "";
    left: -0.2rem;
    position: absolute;
    width: 2rem;
    height: 1rem;
    background-color: #333;
    border-radius: 1rem;
  }
  &:checked:before {
    background: #00a1ff;
  }
  &:checked:after {
    left: 0.8rem;
    border: 2px solid #00a1ff;
  }

  &:after {
    content: "";
    position: absolute;
    left: -0.2rem;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.25s;
    border: 2px solid #333;
    box-sizing: border-box;
  }
}
</style>
