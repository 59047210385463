<template>
  <div id="modal_base" class="modal_base" @pointerdown="close">
    <div class="confirm_wr">
      <h3>{{ $t(`message.${data_for_modal?.action_type}`) }}?</h3>
      <div class="description">
        {{ $t(`gui_hint.${type}`) }}
      </div>

      <div class="btns-wr">
        <button class="btn_confirm" @pointerdown="confirm">{{ $t(`message.logout`) }}</button>
        <button class="btn_deny" @pointerdown="decline">
          {{ $t(`message.cancel`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],

  computed: {
    ...mapState("general_store", ["data_for_modal", "rooms"]),
    ...mapGetters("general_store", ["mainRoom", "user"]),

    processed_user() {
      return this.data_for_modal.processed_user;
    },
    type() {
      if (this.data_for_modal.action_type === "LeaveGame") {
        const game_room = this.rooms.find((r) => r.id === this.data_for_modal.room_id);
        return `LeaveGame_${game_room.state.status}`;
      }
      return this.data_for_modal.action_type;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "setTimer"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "leave_room", type: "close" });
    },
    confirm() {
      this.processModal();
      if (this.data_for_modal.action_type === "LeaveApp") {
        this.socket.emit("client_action", {
          action_type: "LeaveApp",
          data: {
            sid: this.data_for_modal.sid,
            user_id: this.processed_user.id,
          },
        });
      } else {
        this.socket.emit("client_action", {
          action_type: "LeaveGame",
          data: {
            sid: this.data_for_modal.sid,
            room_id: this.data_for_modal.room_id,
            user_id: this.processed_user.id,
          },
        });

        this.setTimer({ reset: true });
      }
    },

    decline() {
      this.processModal({ name: "leave_room", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 22;
  top: 0;
  pointer-events: all;
}
.confirm_wr {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  color: white;
  padding: 1rem;
  height: auto;
  width: fit-content;
  max-width: 85dvw;
  max-height: 95dvh;
  gap: 1rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
}

h3 {
  color: white;
  margin: 0;
}

.btns-wr {
  display: flex;
  gap: 1rem;
}

.description {
  width: auto;
  text-align: center;
}
@media (max-width: 600px) {
  .confirm_wr {
    width: 80dvw;
    height: auto;
  }
}

button {
  margin: 0;
  line-height: 1.15;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  transition: 0.25s ease;
  text-transform: none;
}

.btn_confirm {
  color: #fff;
  background: #2196f3;
  border-color: #2196f3;
  &:hover {
    background: #065291;
    border-color: #065291;
  }
}
.btn_deny {
  color: #fff;
  background: #e94242;
  border-color: #e94242;
  &:hover {
    background: #aa0000;
    border-color: #aa0000;
  }
}
</style>
