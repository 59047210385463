import store from "../store/index.js";
export default class OlennaHandler {
  constructor() {}

  prepareGui() {
    this.resetGui();
    this.initialOrdersPreset();
  }
  resetGui() {
    store.commit("gui_store/setOrder", { key: "available_orders", reset: true });
    store.commit("game_store/setOrderToDel", { land_name: "" });
  }
  initialOrdersPreset() {
    const state = store.getters["general_store/state"];
    const enemyRole = store.getters["general_store/enemyRole"];
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];
    if (!state) return;
    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });

    const conflict_land_object = lands[crusade_state.conflict_land];

    const enemy_state = state.states.find((st) => st.home === enemyRole.home);

    enemy_state.lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!conflict_land_object.adjacent_lands.includes(land_name)) return;
      if (!land_obj.order) {
        return;
      }
      if (crusade_state.source_land === land_name) {
        return;
      }
      store.commit("gui_store/setOrder", { key: "available_orders", land_name });
    });
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];

    if (!active_players.includes(home)) return;
    const land_name = e.target.dataset.source;
    const available_orders = store.state.gui_store.available_orders;

    if (!available_orders.has(land_name)) return;

    store.commit("game_store/setOrderToDel", { land_name });

    store.commit("game_store/setExecTriger", { doit: true });
  }
}
