import store from "../store/index.js";

export default class ConceptionHandler {
  constructor() {
    this.bindedcloseOrderHeepHandler = this.closeOrderHeepHandler.bind(this);
    this.spec_limit = 0;
    this.spec_count = 0;
  }

  prepareGui() {
    this.spec_limit = initSpecLimit();
    this.spec_count = 0;
    this.initialOrdersPreset();
  }

  initialOrdersPreset() {
    const active_players = store.getters["general_store/active_players"];

    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    if (!state) return;

    if (!active_players) return;
    const lands = state.lands;
    if (!lands) return;

    if (this.spec_limit) {
      state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
    }

    if (!this.spec_limit) {
      state.skip_orders?.push("_spec");
    }

    for (let land_name in lands) {
      const land_obj = lands[land_name];
      if (!land_obj) continue;
      if (!active_players.includes(land_obj.home)) continue;
      if (land_obj.order && land_obj.home === home) {
        store.commit("gui_store/setOrder", { key: "used_orders", land_name: land_obj.order });
        store.commit("game_store/setConceptionState", {
          land_name,
          title: land_obj.order,
        });
      }
      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
    }
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (e.target.dataset.owner !== home) {
      return false;
    }

    const nodeTarget = e.target;
    const updeted_source = nodeTarget.dataset.source;

    if (store.state.gui_store.orders_heep_source) {
      store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
      store.commit("gui_store/setHeepSource", { key: "orders_heep_source", land_name: updeted_source });
      store.commit("gui_store/setOrder", { key: "active_orders", land_name: updeted_source });
      return;
    }
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    store.commit("gui_store/setHeepSource", { land_name: updeted_source });
    store.commit("gui_store/setOrder", { key: "active_orders", land_name: updeted_source });

    setTimeout(() => {
      document.addEventListener("pointerdown", this.bindedcloseOrderHeepHandler, { once: true });
    }, 0);
  }

  order_heep_click(e) {
    if (!e.target.dataset?.name) return;
    if (store.state.gui_store.used_orders.has(e.target.dataset.name)) {
      return;
    }

    const state = store.getters["general_store/state"];

    if (!state) return;

    const orderNameWithLabel = e.target.dataset.name;

    if (state.skip_orders.some((sk) => orderNameWithLabel.includes(sk))) return;

    const orders_heep_source = store.state.gui_store.orders_heep_source;
    const land_obj = state.lands[orders_heep_source];
    const oldOrderWithLabel = land_obj.order; //get possible prevOrder to reset it in gui_store (styles)

    if (oldOrderWithLabel) {
      if (oldOrderWithLabel.includes("_spec")) {
        this.spec_count--;
      }
      store.commit("gui_store/deleteOrder", { key: "used_orders", land_name: oldOrderWithLabel });
      // here key is land_name due to unificate with others filelds in state, but actualy in this case its order label insted land_name
    }

    const _orderName = this.normalizeOrderName(orderNameWithLabel);

    if (_orderName.includes("_spec")) {
      this.spec_count++;
    }

    if (this.spec_count === this.spec_limit) {
      state.skip_orders.push("_spec");
    }
    if (this.spec_count < this.spec_limit) {
      state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
    }

    land_obj.order = orderNameWithLabel; // here i set not normalised order name due to recognize proper used order is stack of orders menu-modal

    store.commit("gui_store/setOrder", { key: "used_orders", land_name: orderNameWithLabel });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    // set order for GUI purpose in gui_store to define class-styles of used orders

    store.commit("game_store/setConceptionState", {
      land_name: orders_heep_source,
      title: _orderName,
    });

    store.commit("gui_store/setHeepSource", { reset: true });
    // this flag closes order heep modal
  }

  normalizeOrderName(orderName) {
    const parts = orderName.split(":");
    return parts[0];
  }

  closeOrderHeepHandler(e) {
    const orders_heep_source = store.state.gui_store.orders_heep_source;

    if (!orders_heep_source) {
      store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
      return;
    }
    if (e.target.dataset.type === "order") {
      document.addEventListener("pointerdown", this.bindedcloseOrderHeepHandler, { once: true });
      return;
    }

    const state = store.getters["general_store/state"];

    if (!state) return;
    const lands = state.lands;
    if (!lands) return;

    const land_obj = lands[orders_heep_source];
    const oldOrder = land_obj.order;
    const prev_land = orders_heep_source;
    const phase_name = store.getters["general_store/phase_name"];

    store.commit("gui_store/setHeepSource", { key: "orders_heep_source", land_name: "" });

    if (phase_name !== "CrowCheck" && !store.state.gui_store.orders_heep_source) {
      store.commit("gui_store/deleteOrder", { key: "used_orders", land_name: oldOrder });
      store.commit("gui_store/setOrder", { key: "active_orders", reset: true });

      land_obj.order = "hide";
      store.commit("game_store/setConceptionState", { land_name: prev_land, title: "hide" });
      if (oldOrder.includes("_spec")) {
        this.spec_count--;

        if (this.spec_count < this.spec_limit) {
          state.skip_orders = state.skip_orders.filter((o) => o !== "_spec");
        }
      }
    }
  }
}

function initSpecLimit() {
  const home = store.getters["general_store/home"];
  const game = store.getters["general_store/game"];
  const state = store.getters["general_store/state"];
  if (!game) return;
  if (!state) return;
  const mapArr = getMapArr(game?.game_options.max_players);
  const tracks = state.tracks;
  const crowTrackIdx = tracks.crow.findIndex((h) => h === home);
  return mapArr[crowTrackIdx];
}

function getMapArr(max) {
  if (max === 3) {
    return [3, 2, 1];
  } else {
    return [3, 3, 2, 1, 0, 0];
  }
}
