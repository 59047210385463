import clone from "clone";

const state_obj = {
  trigerExecutor: false,
  current_area_under_cursor: { land_name: "", owner: "" },

  king_choice: {
    trackName: "",
    sortedTrack: [],
  },
  throne_decision: {
    choice: "",
  },
  drag_state: {
    from: "",
    units: [],
    token: "",
  },
  track_bid: {
    home: "",
    bid: "0",
    trackName: "throne",
  },
  conception_state: [],
  dig_wildings_card: false,
  shalow_token: "",

  raid_state: {
    source_land: "",
    target_land: "",
    target_order: "",
  },
  mastering_state: { land_name: "", units: [], points: 0 },
  selected_card: {},
  order_to_del_land_name: "",
  support_choice: "",
  use_sword: false,
  change_random_card: false,
  track_label: {
    trackName: "",
    home: "",
  },
  separate_units: new Map(),

  westeros_choice: "",
  permit_support: { home: "", permission: true },
};

export default {
  namespaced: true,
  state: () => clone(state_obj),
  getters: {
    dragState(state) {
      return state.drag_state;
    },
    masteringState(state) {
      return state.mastering_state;
    },

    areaUnderCursor(state) {
      return state.current_area_under_cursor;
    },

    engineData(state) {
      return state;
    },
  },
  mutations: {
    resetGameState(state) {
      Object.assign(state, clone(state_obj));
    },
    setWesterosChoice(state, { choice = "" }) {
      state.westeros_choice = choice;
    },
    processSeparateUnits(state, { unit, reset = false }) {
      if (reset) {
        state.separate_units.clear();
        return;
      }
      if (state.separate_units.has(unit.id)) {
        state.separate_units.delete(unit.id);
        return;
      }
      state.separate_units.set(unit.id, unit);
    },

    setThroneDecision(state, { choice }) {
      state.throne_decision = choice;
    },
    setMainTracks(state, { sortedTrack = [], trackName = "throne" }) {
      state.king_choice.sortedTrack = sortedTrack;
      state.king_choice.trackName = trackName;
    },
    setBidTrack(state, { home, bid, trackName }) {
      state.track_bid.home = home;
      state.track_bid.bid = bid;
      state.track_bid.trackName = trackName;
    },

    setCurrentArea(state, { land_name = "", owner = "" }) {
      state.current_area_under_cursor.land_name = land_name;
      state.current_area_under_cursor.owner = owner;
    },

    setTrackLabel(state, { trackName = "", home = "", reset = false }) {
      if (reset) {
        state.track_label.trackName = "";
        state.track_label.home = "";
        return;
      }
      state.track_label.trackName = trackName;
      state.track_label.home = home;
    },
    setOrderToDel(state, { land_name = "" }) {
      state.order_to_del_land_name = land_name;
    },

    setMasteringState(state, { land_name, units = [], points = -1, reset = false }) {
      if (reset) {
        state.mastering_state.land_name = "";
        state.mastering_state.units = [];
        state.mastering_state.points = 0;
        return;
      }
      if (land_name) {
        state.mastering_state.land_name = land_name;
      }
      if (points > -1) {
        state.mastering_state.points = points;
      }
      if (units.length) {
        state.mastering_state.units = state.mastering_state.units.concat(units);
      }
    },

    setSupportChoice(state, { choice }) {
      state.support_choice = choice;
    },
    setSwordChoice(state, { use_sword = false, changeCard = false }) {
      state.use_sword = use_sword;
      state.change_random_card = changeCard;
    },
    selectCard(state, { title, home, reset = false }) {
      if (reset) {
        state.selected_card.title = "";
        state.selected_card.home = "";
        return;
      }
      state.selected_card.title = title;
      state.selected_card.home = home;
    },

    setConceptionState(state, { land_name, title, reset = false }) {
      if (reset) {
        state.conception_state = [];
        return;
      }
      let oldOrder = state.conception_state.find((ord) => {
        return ord.land_name === land_name;
      });

      if (oldOrder) {
        oldOrder.title = title;
      } else {
        state.conception_state.push({ land_name, title });
      }
    },
    setRaidState(state, options) {
      const { reset = false } = options;
      if (reset) {
        state.raid_state.source_land = "";
        state.raid_state.target_land = "";
        state.raid_state.target_order = "";
        return;
      }
      state.raid_state = Object.assign(state.raid_state, options);
    },

    setDragState(state, options) {
      const { units = [], reset = false } = options;
      if (reset) {
        state.drag_state.units = [];
        state.drag_state.from = "";
        state.drag_state.token = "";
        return;
      }
      const unic_units = [...state.drag_state.units];

      units.forEach((u) => {
        const idx = unic_units.findIndex((_u) => _u.id === u.id);
        if (idx !== -1) {
          unic_units[idx] = { ...u };
        } else {
          unic_units.push(u);
        }
      });

      state.drag_state = Object.assign(state.drag_state, options, { units: [...unic_units] });
    },

    setShallowToken(state, { land_name, reset = false }) {
      if (reset) {
        state.shalow_token = "";
        return;
      }
      state.shalow_token = land_name;
    },

    setDigWildingsCard(state, { dig }) {
      state.dig_wildings_card = dig;
    },
    setSupportPermissionChoice(state, { permission, home }) {
      state.permit_support = { home, permission };
    },
    setExecTriger(state, { doit }) {
      state.trigerExecutor = doit;
    },
  },
};
