<template>
  <div class="nav_buttons_rigth_container">
    <div class="gap_for_logger"></div>
    <div class="btns_container">
      <div v-if="game.state.status !== 'end'" class="active_players_container">
        <div v-for="home in active_players" :key="home" class="pl_wr">
          <div class="pulse"></div>
          <img :src="`/images/tokens/house_tokens/track_tokens/${home}_track.png`" :alt="home" />
        </div>
      </div>
      <div class="inner_btns_container">
        <div class="btn_wr">
          <span
            class="material-icons side-btn"
            :class="{ active: game_side_bar.playersActive }"
            @click="openPlayersList"
          >
            people
          </span>
        </div>
        <div class="btn_wr">
          <span class="material-icons side-btn" :class="{ active: game_side_bar.infoActive }" @click="openInfo">
            settings_accessibility
          </span>
        </div>
        <div class="btn_wr">
          <span class="material-icons side-btn" :class="{ active: game_side_bar.warActive }" @click="openWar">
            security
          </span>
        </div>
        <div v-show="home !== 'watcher'" class="btn_wr">
          <span
            class="material-icons side-btn"
            :class="{ active: game_side_bar.privateGameChatActive }"
            @click="openPrivateChat"
          >
            forum
          </span>
          <div v-show="total_unread_private_messages_count" class="total_unread_private">
            {{ total_unread_private_messages_count }}
          </div>
        </div>

        <div class="btn_wr">
          <span class="material-icons side-btn" :class="{ active: game_side_bar.chatActive }" @click="openChatBar">
            insert_comment
          </span>
          <div v-show="total_unread_main_messages_count" class="total_unread_main">
            {{ total_unread_main_messages_count }}
          </div>
        </div>

        <div class="btn_wr">
          <span class="material-icons side-btn" :class="{ hint_bid }" @click="processModal({ name: 'bid' })">
            payments
          </span>
        </div>

        <div class="btn_wr" v-if="isAdmin">
          <span
            class="material-icons side-btn"
            :class="{ active: game_side_bar.stateRedactorActive }"
            @click="openRedactor"
          >
            settings
          </span>
        </div>
        <div class="btn_wr">
          <span class="material-icons side-btn" @click="hideSideBar"> keyboard_double_arrow_right </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],

  computed: {
    ...mapGetters("general_store", [
      "game_private_chats",
      "game",
      "isAdmin",
      "home",
      "phase_name",
      "active_players",
      "home",
      "user",
    ]),

    ...mapState("gui_store", ["game_side_bar"]),
    ...mapState("general_store", ["modals"]),

    total_unread_private_messages_count() {
      let sum = 0;
      for (let chat of this.game_private_chats) {
        sum += chat.messages.reduce((acc, msg) => {
          if (!msg.read.some((arr) => arr[0] === this.user.id) && msg.sender_id !== this.user.id && !msg.temp) {
            acc++;
          }
          return acc;
        }, 0);
      }
      return sum;
    },
    total_unread_main_messages_count() {
      if (!this.game) return 0;
      return this.game.messages.reduce((sum, msg) => {
        if (!msg.read.some((arr) => arr[0] === this.user.id) && msg.sender_id !== this.user.id && !msg.temp)
          return ++sum;
        return sum;
      }, 0);
    },

    hint_bid() {
      return (
        ["WarOfKings", "Wildings"].includes(this.phase_name) &&
        this.active_players.includes(this.home) &&
        !this.modals.bid
      );
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setGameSideTab", "setMainSideTab"]),
    ...mapMutations("general_store", ["processModal"]),
    openRedactor() {
      this.setGameSideTab({ tab: "stateRedactorActive" });
    },

    openPlayersList() {
      this.setGameSideTab({ tab: "playersActive" });
    },
    openWar() {
      this.setGameSideTab({ tab: "warActive" });
    },
    openChatBar() {
      this.setGameSideTab({ tab: "chatActive" });
    },
    hideSideBar() {
      this.setGameSideTab();
    },
    openInfo() {
      this.setGameSideTab({ tab: "infoActive" });
    },
    openPrivateChat() {
      this.setGameSideTab({ tab: "privateGameChatActive" });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_buttons_rigth_container {
  position: relative;
  transform: translateX(-2.1rem);
  height: 100dvh;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #77243200;

  .btns_container {
    // background-color: antiquewhite;
    display: flex;
    gap: 0.3rem;

    .active_players_container {
      position: absolute;
      right: 2rem;
      pointer-events: none;
      align-self: flex-end;
      .pl_wr {
        position: relative;
        img {
          width: 1.5rem;
        }
      }

      .pulse {
        position: absolute;
        text-align: center;
        // margin-left: 7px;
        width: 1.5rem;
        height: 1.5rem;
        pointer-events: none;
        touch-action: none;
        background-color: aliceblue;
        border-radius: 50%;
        animation: pulse 1s linear 0s infinite alternate;
      }
    }

    .inner_btns_container {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }

    @media screen and (max-height: 380px) {
      .inner_btns_container {
        gap: 0;
      }
    }
  }
}
.btn_wr {
  position: relative;
}

.side-btn {
  font-size: 1.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
  background-color: rgba(161, 163, 163, 0.727);

  &:hover {
    color: white;
  }
}

@media screen and (max-height: 340px) {
  .side-btn {
    width: 2rem;
    font-size: 1.6rem;
  }
}

.gap_for_logger {
  width: 20px;
  min-height: 2.7rem;
}

.active {
  color: red;
  // background-color: yellow;
  // font-size: 42px;
  &:hover {
    color: rgb(255, 0, 0);
    background-color: rgb(48, 50, 50);
    border-radius: 7px;
  }
}

.hint_bid {
  background-color: rgb(84, 204, 32);
}

.total_unread_private {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  width: 25px;
  height: 25px;
  text-align: center;

  bottom: 0px;
  left: -4px;
  border-radius: 50%;
  background-color: rgb(172, 0, 0);
  color: white;
  font-size: 1.3rem;
  pointer-events: none;
}
.total_unread_main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  width: 25px;
  height: 25px;
  text-align: center;

  bottom: 0px;
  left: -4px;
  border-radius: 50%;
  background-color: rgb(172, 0, 0);
  color: white;
  font-size: 1.3rem;
  pointer-events: none;
}

@keyframes pulse {
  from {
    background-color: rgba(249, 249, 249, 0.712);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}
</style>
