import store from "../store/index.js";
export default class CrowKillersHandler {
  constructor() {
    this.zones_to_hint = new Set();
  }

  prepareGui() {
    this.initialUnitsPreset();
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const highlighted_units = store.state.gui_store.highlighted_units;
    const home = store.getters["general_store/home"];
    if (!highlighted_units.has(unit.id)) return false;
    if (unit.owner !== home) return;
    return true;
  }

  initialUnitsPreset() {
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const active_players = store.getters["general_store/active_players"];
    const home = active_players[0];

    if (!state) return;
    if (phase_name !== "WildingManual") return;

    const wildingsState = state.wildings_state;

    const units_to_hint = wildingsState.is_victory ? this.getAllUnits(home, "pawn") : this.getAllUnits(home, "knite");

    units_to_hint.forEach((unit) => {
      store.commit("gui_store/setHighlightedUnits", { id: unit.id });
    });
    this.zones_to_hint.forEach((land_name) => {
      store.commit("gui_store/setGapLand", { land_name });
    });
  }

  getAllUnits(home, type) {
    const state = store.getters["general_store/state"];
    if (!state) return;
    const lands = state.lands;
    const home_states = state.states.find((st) => st.home === home);
    if (!home_states) return;
    const occupied_lands = home_states.lands;

    return occupied_lands.reduce((acc, land_name) => {
      const land_obj = lands[land_name];
      const units = land_obj.units.filter((u) => u.type === type);
      if (units.length) {
        this.zones_to_hint.add(land_name);
      }
      return (acc = [...acc, ...units]);
    }, []);
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    if (!state) return;
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "WildingManual") return;
    const home = store.getters["general_store/home"];
    if (!active_players.includes(home)) return;
    const tempUnits = store.state.game_store.separate_units;
    const wildingsState = state.wildings_state;
    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== home) return;

    const unit_type_flag = wildingsState.is_victory ? "pawn" : "knite";
    if (e.target.dataset.unit_type !== unit_type_flag) {
      store.commit("game_store/processSeparateUnits", { reset: true });
      store.commit("gui_store/setSelectedUnits", { reset: true });
      return;
    }

    const max_count = this.calcMaxCount();

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };
    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });

    if (tempUnits.length > max_count) {
      store.commit("gui_store/resetGuiState");
      this.prepareGui();
      return;
    }
  }

  calcMaxCount() {
    const home = store.getters["general_store/home"];
    const state = store.getters["general_store/state"];
    if (!state) return;
    const wildingsState = state.wildings_state;
    const is_victory = wildingsState.is_victory;
    const home_states = state.states.find((st) => st.home === home);
    const knites_on_board = home_states.units.knite.length;
    const pawns_on_board = home_states.units.pawn.length;

    const av_knites_count = 5 - knites_on_board;
    const av_pawns_count = 10 - pawns_on_board;

    const isHomeLowestBid = wildingsState.lowest_candidates[0] === home;

    if (is_victory) {
      const maxPossibleUpgrade = pawns_on_board > av_knites_count ? av_knites_count : pawns_on_board;
      return maxPossibleUpgrade > 2 ? 2 : maxPossibleUpgrade;
    }

    if (isHomeLowestBid) {
      return knites_on_board > av_pawns_count ? av_pawns_count : knites_on_board;
    }

    return knites_on_board < 2 ? knites_on_board : 2;
  }
}
