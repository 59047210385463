<template>
  <div id="modal_base" class="confirm_modal_wr" @click="close">
    <div class="inner_wr">
      <div v-if="player_history?.length" class="table_wr">
        <div class="table">
          <div v-for="log in player_history" :key="log" class="row">
            <div class="avatar_wr">
              <span>{{ log.name }}</span>
              <img :src="`/images/avatars/${log.avatars[0]}`" alt="avatar" />
            </div>
            <div class="home_wr">
              <img :src="`/images/tokens/${log.home}_token.png`" alt="home" />
            </div>
            <div class="round_info_wr">
              <span class="material-icons green"> login </span>
              <span>{{ log.round_when_start }} </span>
            </div>
            <div class="round_info_wr">
              <span class="material-icons red"> logout </span>
              <span>{{ log.round_when_leaved }} </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty">---</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

// users_log: [
//         {
//           name: String,
//           id: String,
//           home: String,
//           room_id: String,
//           round_when_leaved: Number,
//           round_when_start: Number,
//           start: Number,
//           end: Number,
//           avatars: [String],
//         },
//       ],

export default {
  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["mainRoom", "game", "user"]),

    player_history() {
      if (!this.data_for_modal) return;
      return this.data_for_modal.player.users_log;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "players_log", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_modal_wr {
  position: absolute;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;

  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}

.inner_wr {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  color: White;
  width: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  overflow: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
}

.inner_wr::-webkit-scrollbar {
  display: none;
}

.table_wr {
  width: 100%;
  display: flex;
}

.table {
  width: auto;
  // overflow: auto;
}
.table::-webkit-scrollbar {
  display: none;
}

.row {
  display: flex;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  border: 1px solid gray;
}

.avatar_wr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  span {
    font-size: 1.2rem;
  }
  img {
    border-radius: 0.4rem;
    width: 80px;
  }
}

.home_wr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 2rem;
  }
}

.round_info_wr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

.green {
  color: green;
}
.red {
  color: red;
}
</style>
