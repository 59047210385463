import ablative from "./ablative.js";
import accusative from "./accusative.js";
import dative from "./dative.js";
import errors from "./errors.js";
import exec_msg from "./exec_msg.js";
import genetive from "./genetive.js";
import gui_hints from "./gui_hints.js";
import gui_parts from "./gui_parts.js";
import land_names from "./land_names.js";
import locative from "./locative.js";
import phase_names from "./phase_names.js";

const messages = {
  en: {
    message: gui_parts.en.message,
    land: land_names.en.land,
    phase: phase_names.en.phase,
    exec: exec_msg.en.exec,
    gui_hint: gui_hints.en.gui_hints,
    error: errors.en.errors,

    home: {
      baratheon: "Baratheon",
      stark: "Stark",
      lannister: "Lannister",
      tyrell: "Tyrell",
      martell: "Martell",
      greyjoy: "Greyjoy",
      nobody: "nobody",
      none: "nobody",
      watcher: "watcher",
    },

    phrase: {
      invitation: "Join the game:\nTiltle: {name}\nStatus: {status}\nRound: {round}",
      friendJoinedGame: "Joined the game: {game_title}\nHome: {sender_home}",
      friendFinishedGame: "Finished the game: {game_title}\nHome: {sender_home}\nPlace: {place}",
    },

    game_type: {
      classic_six: "Classic Game (six players)",
      classic_three: "Classic Game (three players)",
      classic_four: "Classic Game (four players)",
      classic_five: "Classic Game (five players)",
    },

    order: {
      crusade_minus: "Minus March",
      crusade_zero: "Simple March",
      crusade_spec: "Reinforced March",
      support: "Support",
      support_spec: "Enhanced support",
      defence: "Defence",
      defence_spec: "Strengthened Defence",
      power: "Strengthening of power",
      power_spec: "Increased strengthening of power",
      raid: "Raid",
      raid_spec: "Enhanced raid",
      hide: "hidden",
      none: "empty",
    },

    wild_card: {
      KingBeyondTheWall: "A King Beyond The Wall",
      CrowKillers: "Crow Killers",
      MammothRiders: "Mammoth Riders",
      MassingMilkwater: "Massing On The Milkwater",
      PreemptiveRaid: "Preemptive Raid",
      Rattleshirts: "Rattleshirts Raiders",
      SilenceAtWall: "Silence At The Wall",
      Skinchanger: "Skinchanger Scout",
      Horde: "The Horde Descends",
    },

    home_card: {
      Eddard: "Eddard Stark",
      Robb: "Robb Stark",
      Rodrik: "Ser Rodrick Cassel",
      Amber: "Greatjon Amber",
      Roose: "Roose Bolton",
      Brynden: "The Blackfish",
      Catelyn: "Catelyn Stark",

      Stannis: "Stannis Baratheon",
      Salladhor: "Salladhor Saan",
      Renly: "Renly Baratheon",
      Patchface: "Patchface",
      Melisandre: "Melisandre",
      Davos: "Ser Davos Seaworth",
      Brienne: "Brienne of Tarth",

      Asha: "Asha Greyjoy",
      Balon: "Balon Greyjoy",
      Dagmer: "Dagmer Cleftjaw",
      Euron: "Euron Crow's Eye",
      Aeron: "Aeron Damphair",
      Theon: "Theon Greyjoy",
      Victarion: "Victarion Greyjoy",

      Cersei: "Cersei Lannister",
      Jaime: "Ser Jaime Lannister",
      Sandor: "The Hound",
      Gregor: "Ser Gregor Clegane",
      Kevan: "Ser Kevan Lannister",
      Tyrion: "Tyrion Lannister",
      Tywin: "Tywin Lannister",

      Alester: "Alester Florent",
      Garlan: "Ser Garlan Tyrell",
      Loras: "Ser Loras Tyrell",
      Margaery: "Margaery Tyrell",
      Mace: "Mace Tyrell",
      Olenna: "Queen of Thorns",
      Randyll: "Randyll Tarly",

      Areo: "Areo Hotah",
      Arianne: "Arianne Martell",
      Doran: "Doran Martell",
      Gerold: "Darkstar",
      Nymeria: "Nymeria Sand",
      Obara: "Obara Sand",
      Oberyn: "The Red Viper",
    },

    init: {
      Wildings: `with power {power}`,
      Retreat: "{card} {plural} ready to escape from {land_name}",
      WarOfKings: "{active_players}\n{plural} ready to fight for {trackName}",
      Default: `{active_players}\n{plural} ready to play {phase_name} phase`,
    },

    unit: {
      pawn: "Footmen",
      knite: "Knite",
      kata: "Siege Engine",
      ship: "Ship",
    },

    part: {
      LeftToken: "Left a token in {land_name}",
      RemoveOrder: "{home} removed token from {source_land_name}",
      RemoveLand: "{home} lost power over {land_name}",
      AddLand: "{home} enhanced {land_name}",
      Power: "{home}: {land_name} - {tokens} {plur}\n",
      NightWatch: "Night Watch",
    },

    track_name: {
      throne: "Throne",
      sword: "The Valerian Sword",
      crow: "Messenger Raven",
      wildings: "Wildings Invasion",
    },

    prop: {
      sword: "sword",
      shield: "shield",
    },

    plur: {
      token: "token | tokens",
      ready: "is | are",
      1: "first",
      2: "second",
      3: "third",
      4: "fourth",
      5: "fifth",
      6: "sixth",
      7: "seventh",
      8: "eighth",
      9: "nineth",
      10: "tenth",
    },
  },

  uk: {
    message: gui_parts.uk.message,
    land: land_names.uk.land,
    phase: phase_names.uk.phase,
    exec: exec_msg.uk.exec,
    gui_hint: gui_hints.uk.gui_hints,
    error: errors.uk.errors,

    genetive_phase: genetive.uk.genetive_phase,
    genetive_order: genetive.uk.genetive_order,
    genetive_home: genetive.uk.genetive_home,
    genetive_land: genetive.uk.genetive_land,
    genetive_track_name: genetive.uk.genetive_track_name,
    genetive_home_card: genetive.uk.genetive_home_card,
    ablative_land: ablative.uk.ablative_land,
    ablative_home: ablative.uk.ablative_home,
    accusative_land: accusative.uk.accusative_land,
    accusative_unit: accusative.uk.accusative_unit,
    accusative_phase: accusative.uk.accusative_phase,
    accusative_order: accusative.uk.accusative_order,
    accusative_home_card: accusative.uk.accusative_home_card,
    accusative_track_name: accusative.uk.accusative_track_name,
    accusative_home: accusative.uk.accusative_home,
    accusative_unit_plur: accusative.uk.accusative_unit_plur,
    dative_land: dative.uk.dative_land,
    locative_land: locative.uk.locative_land,

    init: {
      Wildings: `з силою {power}`,
      WarOfKings: "{active_players}\n{plural} битися за {trackName}",
      Retreat: "{card} {gender} тікати з {land_name}",
      Default: `{active_players}\n{plural} до фази {phase_name}`,
    },

    phrase: {
      invitation: "Приєднуйся до гри:\nНазва: {name}\nСтатус: {status}\nРаунд: {round}",
      friendJoinedGame: "Приєднався до гри: {game_title}\nДім: {sender_home}",
      friendFinishedGame: "Закінчив гру: {game_title}\nДім: {sender_home}\nМісце: {place}",
    },

    game_type: {
      classic_six: "Класична Гра (шість гравців)",
      classic_three: "Класична Гра (троє гравців)",
      classic_four: "Класична Гра (четверо гравців)",
      classic_five: "Класична Гра (п'ятеро гравців)",
    },

    home: {
      baratheon: "Баратеон",
      stark: "Старк",
      lannister: "Ланністер",
      tyrell: "Тірелл",
      martell: "Мартелл",
      greyjoy: "Грейджой",
      nobody: "нікого",
      watcher: "глядач",
      none: "безхатько",
    },

    order: {
      crusade_minus: "Мінусова атака",
      crusade_zero: "Звичайна атака",
      crusade_spec: "Посилена атака",
      support: "Піддтримка",
      support_spec: "Посилена піддтримка",
      defence: "Оборона",
      defence_spec: "Посилена оборона",
      power: "Зміцнення влади",
      power_spec: "Посилене зміцнення влади",
      raid: "Рейд",
      raid_spec: "Посилений рейд",
      hide: "Перевернутий",
      none: "Немає",
    },

    wild_card: {
      KingBeyondTheWall: "Король за стіною",
      CrowKillers: "Вбивці Ворон",
      MammothRiders: "Наїзники на мамонтах",
      MassingMilkwater: "Збір на Молоководній",
      PreemptiveRaid: "Передовий загін",
      Rattleshirts: "Розбійники Гримучої Сорочки",
      SilenceAtWall: "Тиша за стіною",
      Skinchanger: "Розвідник-Перевертень",
      Horde: "Навала орди",
    },

    // Aeron Damphair

    home_card: {
      Eddard: "Еддард Старк",
      Robb: "Робб Старк",
      Rodrik: "Сір Родрік Кассель",
      Amber: "Джон Амбер",
      Roose: "Русе Болтон",
      Brynden: "Брінден Чорна Риба",
      Catelyn: "Кейтелін Старк",
      Stannis: "Станіс Баратеон",
      Salladhor: "Салладор Саан",
      Renly: "Ренлі Баратеон",
      Patchface: "Пестряк",
      Melisandre: "Мелісандра",
      Davos: "Сір Давос Сіворт",
      Brienne: "Брієнна Тарт",
      Asha: "Аша Грейджой",
      Balon: "Бейлон Грейджой",
      Dagmer: "Дагмер Щербатий",
      Euron: "Еурон Грейджой",
      Aeron: "Еєрон Сироволосий",
      Theon: "Теон Грейджой",
      Victarion: "Віктаріон Грейдой",
      Cersei: "Серсея Ланністер",
      Jaime: "Сір Джейме Ланністер",
      Sandor: "Пес",
      Gregor: "Сір Грегор Кліган",
      Kevan: "Сір Ківан Ланністер",
      Tyrion: "Тіріон Ланністер",
      Tywin: "Тайвін Ланністер",
      Alester: "Алестер Флорент",
      Garlan: "Сір Гарлан Тірелл",
      Loras: "Сір Лорас Тірелл",
      Margaery: "Маргарі Тірелл",
      Mace: "Мейс Тірелл",
      Olenna: "Оленна Тірелл",
      Randyll: "Сір Ренділ Тарлі",
      Areo: "Арео Хотах",
      Arianne: "Аріанна Мартелл",
      Doran: "Доран Мартелл",
      Gerold: "Сір Герольд Темна Зірка",
      Nymeria: "Німерія Санд",
      Obara: "Обара Санд",
      Oberyn: "Оберін Червона Гадюка",
    },

    unit: {
      pawn: "Піший воїн",
      knite: "Лицар",
      kata: "Облогова вежа",
      ship: "Корабель",
    },

    track_name: {
      throne: "Залізний Трон",
      sword: "Валерійский Меч",
      crow: "Посильний Ворон",
      wildings: "Нашестя дикунів",
    },

    prop: {
      sword: "меч",
      shield: "щит",
    },

    part: {
      LeftToken: "Залишив токен у {land_name}",
      RemoveOrder: "{home} прибрав наказ з {source_land_name}",
      RemoveLand: "{home} втратив владу над {land_name}",
      AddLand: "{home} захопив {land_name}",
      Power: "{home}: {land_name} - {tokens} {plur}\n",
      kill_male: "вбив",
      kill_fem: "вбила",
      ready_male: "готовий",
      ready_fem: "готова",
      retreat_fem: "втекла",
      retreat_male: "втік",
      NightWatch: "Нічна Варта",
    },

    plur: {
      token: "токен | токени",
      ready: "готовий | готові",
      Raid: "Рейду | Рейдів",
      1: "перший",
      2: "другий",
      3: "третій",
      4: "четевертий",
      5: "п'ятий",
      6: "шостий",
      7: "сьомий",
      8: "восьмий",
      9: "дев'ятий",
      10: "десятий",
    },
  },
};

//           home: _home,
//           targetLand: _land_name,
//           target_home: _target_home,
//           target_order: _target_order,
//           source_land_name: _source_land_name,

export default messages;
