import store from "../store/index.js";

export default class ConflictPortHandler {
  constructor() {}

  prepareGui() {
    this.resetGui();
    this.initialUnitsPreset();
    this.initialOrdersPreset();
  }

  resetGui() {
    store.commit("game_store/processSeparateUnits", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setHighlightedUnits", { reset: true });
    store.commit("gui_store/setGapLand", { reset: true });
  }

  initialOrdersPreset() {
    const phase_name = store.getters["general_store/phase_name"];
    if (phase_name !== "PreAttackConflictPort") return;
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }

  initialUnitsPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    if (!active_players) return;
    const home = active_players[0];
    if (!home) return;

    if (phase_name !== "ConflictPort" && phase_name !== "PreAttackConflictPort") return;
    // if (!active_players.includes(home)) return;

    const crusade_state = state.phase.crusade_state;
    const lands = state.lands;
    store.commit("gui_store/setHighlightedUnits", { reset: true });

    const conflict_ports = crusade_state.conflict_ports;

    this.myConfictPorts = conflict_ports.filter((port) => port.home === home);

    this.myConfictPorts.forEach(({ port_name }) => {
      const ships = lands[port_name].units;
      ships.forEach(({ id }) => {
        store.commit("gui_store/setHighlightedUnits", { id });

        if (ships.length > 1) {
          store.commit("gui_store/setGapLand", { land_name: port_name });
        }
      });
    });
  }

  pointerdownHandler(e) {
    if (e.target.dataset.type !== "unit") return;
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];

    const home = store.getters["general_store/home"];
    if (!home) return;
    if (!active_players) return;

    if (phase_name !== "ConflictPort" && phase_name !== "PreAttackConflictPort") return;
    if (!active_players.includes(home)) return;

    const separate_units = store.state.game_store.separate_units;

    const validShip = this.myConfictPorts.some((port) => port.port_name === e.target.dataset.source);

    if (!validShip) {
      return;
    }

    const unit = {
      id: e.target.id,
      land_name: e.target.dataset.source,
      owner: e.target.dataset.house,
      type: e.target.dataset.unit_type,
      force: e.target.dataset.force,
    };

    store.commit("game_store/processSeparateUnits", { unit });
    store.commit("gui_store/setSelectedUnits", { unit });
  }

  allowCursorPointer(unit) {
    const phase_name = store.getters["general_store/phase_name"];
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const dragState = store.state.game_store.drag_state;
    const source_name = dragState.from;

    if (!active_players.includes(home)) return;
    if (unit.owner !== home) return;
    if (unit.retreated) return;
    if (phase_name === "Crusade") {
      if (!source_name) return;
    }
    return true;
  }
}
