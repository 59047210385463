<template>
  <div id="close_area" class="modal_wr" @click="closeModal">
    <div class="bid_wr">
      <span class="material-icons bid_history" @click.stop="openBidHistory"> history_edu </span>
      <div v-if="home !== 'watcher'" class="range-option">
        <h1>{{ track_bid ? $t(`message.${track_bid}`, { force: wildings_force }) : "-" }}</h1>
        <div class="info_wr">
          <h3>{{ bid }} / {{ my_state?.tokens.length }}</h3>
          <img :src="token_src" alt="token" />
        </div>
        <div class="range_wr"><input v-model="bid" type="range" min="0" :max="my_state?.tokens.length" /></div>
        <div class="confirm_btn">
          <button @click="exec">{{ $t("message.exec") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      bid: 0,
    };
  },

  computed: {
    ...mapGetters("general_store", ["game", "phase_name", "states", "active_players", "home"]),

    wildings_force() {
      return this.game?.state.tracks.wildings;
    },

    track_bid() {
      return this.game?.state.current_track_bid || null;
    },

    my_state() {
      if (!this.home) return null;
      return this.states.find((st) => st.home === this.home);
    },
    token_src() {
      return `/images/tokens/${this.home}_token.png`;
    },
    i_am_active() {
      return this.active_players.includes(this.home);
    },
  },

  watch: {
    track_bid() {
      this.bid = 0;
      this.setBidTrack({ home: this.home, bid: "0", trackName: this.track_bid || "throne" });
    },
    home() {
      if (this.phase_name !== "WarOfKings" && this.phase_name !== "Wildings") {
        this.processModal();
        return;
      }
      this.bid = 0;
      this.setBidTrack({ home: this.home, bid: "0", trackName: this.track_bid });
    },
    phase_name() {
      if (this.phase_name !== "WarOfKings" && this.phase_name !== "Wildings") {
        this.processModal();
      }
    },
  },

  mounted() {
    this.setBidTrack({ home: this.home, bid: "0", trackName: this.track_bid || "throne" });
  },

  methods: {
    ...mapMutations("game_store", ["setBidTrack", "setExecTriger"]),
    ...mapMutations("general_store", ["processModal"]),
    openBidHistory() {
      this.processModal({ name: "bid_history" });
    },

    closeModal(e) {
      if (e.target.id !== "close_area") return;
      this.processModal();
    },
    exec() {
      if (this.phase_name !== "WarOfKings" && this.phase_name !== "Wildings") return;
      if (!this.active_players.includes(this.home)) return;
      this.setBidTrack({ home: this.home, bid: this.bid, trackName: this.track_bid });
      this.setExecTriger({ doit: true });
      this.processModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal_wr {
  pointer-events: all;
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
}

.bid_wr {
  position: absolute;
  padding: 2rem;
  box-sizing: border-box;
  width: auto;
  max-width: 85dvw;
  height: auto;
  max-height: 90dvh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(1, 1, 1, 0.782);
  border: 3px solid grey;
  border-radius: 5px;
}

.bid_history {
  position: absolute;
  font-size: 3rem;
  bottom: 0px;
  right: 0px;
  color: rgb(168, 50, 50);
  cursor: pointer;
  &:hover {
    color: white;
  }
}
.confirm_btn {
  margin-top: 1rem;
  button {
    cursor: pointer;
    color: red;
    background-color: black;
    font-size: 1rem;
    width: 150px;
    height: 60px;
    transition: background-color 0.3s;
    &:hover {
      background-color: rgb(41, 40, 40);
    }
  }
}

.range-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: auto;

  text-align: center;
  // margin-left: auto;
  white-space: pre-wrap;
  h1 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    color: white;
  }
}

.range_wr {
  width: 100%;
}
.info_wr {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    height: 2rem;
    width: auto;
  }
}

input[type="range"] {
  //margin-left: auto;
  -webkit-appearance: none;
  overflow: hidden;
  width: 100%;
  height: 1.5rem;
  background-color: rgb(134, 131, 131);
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
}
input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
}
input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 2rem;
  width: 15px;
  border-radius: 5%;
  box-shadow: -310px 0 0 300px rgb(202, 202, 202);
  background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
  cursor: ew-resize;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 2rem;
  width: 15px;
  border-radius: 5%;
  box-shadow: -310px 0 0 300px rgb(202, 202, 202);
  background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
  cursor: ew-resize;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 2rem;
  width: 15px;
  border-radius: 5%;
  background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
  cursor: ew-resize;
  box-shadow: -310px 0 0 300px rgb(202, 202, 202);
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
}

input[type="range"]::-moz-range-thumb:hover {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
}
input[type="range"]::-ms-thumb:hover {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
}

input[type="range"]::-webkit-slider-thumb:active {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
}
input[type="range"]::-moz-range-thumb:active {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
}
input[type="range"]::-ms-thumb:active {
  background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
}
</style>
