export default {
  en: {
    land: {
      the_shivering_sea: "The Shivering Sea",
      the_narrow_sea: "The Narrow Sea",
      bay_of_ice: "Bay Of Ice",
      winterfell: "Winterfell",
      winterfell_port: "Winterfell Port",
      castle_black: "Castle Black",
      the_stony_shore: "The Stony Shore",
      white_harbor: "White Harbor",
      white_harbor_port: "White Harbor Port",
      widows_watch: "Widows Watch",
      karhold: "Karhold",
      the_eyrie: "The Eyrie",
      moat_cailin: "Moat Cailin",
      the_twins: "The Twins",
      the_fingers: "The Fingers",
      the_mountains_of_the_moon: "The Mountains Of The Moon",
      shipsrearer_bay: "Shipsrearer Bay",
      blackwater_bay: "Blackwater Bay",
      dragonstone: "Dragonstone",
      dragonstone_port: "Dragonstone Port",
      crackclaw_point: "Crackclaw Point",
      kingswood: "Kingswood",
      kings_landing: "Kings Landing",
      east_summer_sea: "East Summer Sea",
      sea_of_dorne: "Sea Of Dorne",
      sunspear: "Sunspear",
      sunspear_port: "Sunspear Port",
      storms_end: "Storms End",
      storms_end_port: "Storms End Port",
      the_boneway: "The Boneway",
      princes_pass: "Princes Pass",
      starfall: "Starfall",
      yronwood: "Yronwood",
      salt_shore: "Salt Shore",
      west_summer_sea: "West Summer Sea",
      redwyne_straights: "Redwyne Straights",
      highgarden: "Highgarden",
      oldtown: "Oldtown",
      oldtown_port: "Oldtown Port",
      three_towers: "Three Towers",
      dornish_marches: "Dornish Marches",
      the_reach: "The Reach",
      the_arbor: "The Arbor",
      sunset_sea: "Sunset Sea",
      the_golden_sound: "The Golden Sound",
      lannisport: "Lannisport",
      lannisport_port: "Lannisport Port",
      riverrun: "Riverrun",
      harrenhal: "Harrenhal",
      stoney_sept: "Stoney Sept",
      searoad_marches: "Searoad Marches",
      blackwater: "Blackwater",
      ironmans_bay: "Ironmans Bay",
      pyke: "Pyke",
      pyke_port: "Pyke Port",
      seagard: "Seagard",
      flints_finger: "Flints Finger",
      greywater_watch: "Greywater Watch",
    },
  },
  uk: {
    land: {
      the_shivering_sea: "Тремтливе Море",
      the_narrow_sea: "Вузьке море",
      bay_of_ice: "Крижана  Затока",
      winterfell: "Вінтерфелл",
      winterfell_port: "Порт Вінтрефеллу",
      castle_black: "Чорний Замок",
      the_stony_shore: "Каменястий Берег",
      white_harbor: "Біла Гавань",
      white_harbor_port: "Порт Білої Гавані",
      karhold: "Карголд",
      widows_watch: "Вдоварта",
      the_eyrie: "Соколине Гніздо",
      moat_cailin: "Калин-Коп",
      the_twins: "Близнюки",
      the_fingers: "Пальці",
      the_mountains_of_the_moon: "Місячні Гори",
      shipsrearer_bay: "Затока Човнозгуба",
      blackwater_bay: "Чорновода Затока",
      dragonstone: "Дракон-Камінь",
      dragonstone_port: "Порт Дракон-Каменя",
      crackclaw_point: "Гостроклішня",
      kingswood: "Королівська Пуща",
      kings_landing: "Король-Берег",
      east_summer_sea: "Східне Літнє Море",
      sea_of_dorne: "Дорнійське Море",
      sunspear: "Сонцеспис",
      sunspear_port: "Порт Сонцеспису",
      storms_end: "Штормолам",
      storms_end_port: "Порт Штормоламу",
      the_boneway: "Кістяний Шлях",
      princes_pass: "Великокняжий прохід",
      starfall: "Зорепад",
      yronwood: "Крицак",
      salt_shore: "Солемор'я",
      west_summer_sea: "Західне Літнє Море",
      redwyne_straights: "Рожвинова протока",
      highgarden: "Хайгарден",
      oldtown: "Староград",
      oldtown_port: "Порт Старограду",
      three_towers: "Трибашта",
      dornish_marches: "Дорнійський Шлях",
      the_reach: "Обшир",
      the_arbor: "о. Вертоград",
      sunset_sea: "Західне Море",
      the_golden_sound: "Золота Затока",
      lannisport: "Ланніспорт",
      lannisport_port: "Порт Ланніспорту",
      riverrun: "Водоплин",
      harrenhal: "Гаренгол",
      stoney_sept: "Камінний Септ",
      searoad_marches: "Морський Шлях",
      blackwater: "Чорновода",
      ironmans_bay: "Залізняцька Затока",
      pyke: "Пайк",
      pyke_port: "Порт Пайк",
      seagard: "Морестраж",
      flints_finger: "Кремінь-Палець",
      greywater_watch: "Сіроводець",
    },
  },
};
