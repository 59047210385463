<template>
  <div class="buttons_left_container">
    <div class="tools">
      <span
        v-if="!inside_room && (!user.current_games || user.current_games?.length < 2)"
        class="material-icons tool_item"
        @click="processModal({ name: 'create_game' })"
      >
        add_box
      </span>
      <span class="material-icons tool_item" :class="{ active: main_side_bar.usersActive }" @click="usersActiveOpen">
        people
      </span>
      <span
        class="material-icons tool_item"
        :class="{ active: main_side_bar.privateChatActive }"
        @click="privatChatOpen"
      >
        forum
      </span>
      <div v-if="total_unread_private_messages_count" class="total_unread_private">
        {{ total_unread_private_messages_count }}
      </div>
      <span class="material-icons tool_item" :class="{ active: main_side_bar.chatActive }" @click="chatActiveOpen">
        chat
      </span>
      <div v-if="total_unread_main_messages_count" class="total_unread_main">
        {{ total_unread_main_messages_count }}
      </div>
      <span class="material-icons tool_item" @click="hideSideBar"> keyboard_double_arrow_left </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapGetters("general_store", ["mainRoom", "user", "main_private_chats"]),
    ...mapState("general_store", ["inside_room"]),
    ...mapState("gui_store", ["main_side_bar"]),

    total_unread_private_messages_count() {
      let sum = 0;
      for (let chat of this.main_private_chats) {
        sum += chat.messages.reduce((acc, msg) => {
          if (!msg.read.some((arr) => arr[0] === this.user?.id) && msg.sender_id !== this.user?.id && !msg.temp) {
            acc++;
          }
          return acc;
        }, 0);
      }
      return sum;
    },

    total_unread_main_messages_count() {
      if (!this.mainRoom) return 0;
      return this.mainRoom.messages.reduce((sum, msg) => {
        if (!msg.read.some((arr) => arr[0] === this.user?.id) && msg.sender_id !== this.user?.id && !msg.temp)
          return ++sum;
        return sum;
      }, 0);
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setMainSideTab"]),
    ...mapMutations("general_store", ["processModal"]),

    privatChatOpen() {
      this.setMainSideTab({ tab: "privateChatActive" });
    },
    chatActiveOpen() {
      this.setMainSideTab({ tab: "chatActive" });
    },
    usersActiveOpen() {
      this.setMainSideTab({ tab: "usersActive" });
    },
    redactorActiveOpen() {
      this.setMainSideTab({ tab: "redactorActive" });
    },
    hideSideBar() {
      this.setMainSideTab();
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons_left_container {
  position: relative;
  width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(119, 36, 50, 0);
}

.tools {
  position: absolute;
  bottom: 0;
  left: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-height: 400px) {
  // .btn_wr span {
  //   font-size: 1.5rem;
  // }

  // .tools span {
  //   font-size: 1.5rem;
  // }
}

.total_unread_private {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  width: 25px;
  height: 25px;
  text-align: center;

  bottom: 103px;
  left: 23px;
  border-radius: 50%;
  background-color: rgb(172, 0, 0);
  color: white;
  font-size: 1.3rem;
  pointer-events: none;
}
.total_unread_main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  width: 25px;
  height: 25px;
  text-align: center;

  bottom: 56px;
  left: 23px;
  border-radius: 50%;
  background-color: rgb(172, 0, 0);
  color: white;
  font-size: 1.3rem;
  pointer-events: none;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  // transition-delay: 0.5s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.active {
  color: red;
  // font-size: 42px;
  &:hover {
    color: rgb(255, 0, 0);
    background-color: rgb(48, 50, 50);
    border-radius: 7px;
  }
}

.logger {
  flex: 3;
}
.tool_item {
  font-size: 1.8rem;
  border-radius: 0.3rem;
  cursor: pointer;
  background-color: rgba(161, 163, 163, 0.727);
}

.tools *:hover {
  color: white;
}

.material-icons {
  margin: 10px auto;
}

.confirm_move:hover {
  color: white;
  position: relative;
}
</style>
