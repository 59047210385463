<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @click="close">
    <div class="inner_wr">
      <div v-if="bid_history.length" class="table_wr">
        <div class="table">
          <div v-for="log in bid_history" :key="log" class="row">
            <div class="round">
              <span>{{ log.round }}</span>
              <img :src="`/images/logger/Round.png`" :alt="log.round" />
            </div>
            <div class="track">
              <img :src="`/images/logger/${getImgSrc(log.track)}.png`" :alt="log.track" />
            </div>
            <div v-for="bid in sortByHome(log.bids)" :key="bid" class="bids">
              <div class="bid_wr">
                <div class="home">
                  <img :src="`/images/tokens/${bid.home}_token.png`" :alt="bid.home" />
                </div>
                <div class="tokens_wr">
                  <div class="bid">
                    <span class="material-icons"> shopping_cart_checkout </span>
                    <span>
                      {{ bid.bid }}
                    </span>
                  </div>

                  <div class="left">
                    <span class="material-icons"> savings </span>
                    <span>
                      {{ bid.left }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty">---</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["mainRoom", "game", "user"]),

    bid_history() {
      if (!this.game) return;
      return this.game.state.bid_history;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      this.processModal({ name: "bid_history", type: "close" });
    },
    sortByHome(bids) {
      bids.sort((a, b) => {
        if (a.home < b.home) {
          return -1;
        }
        if (a.home > b.home) {
          return 1;
        }
        return 0;
      });
      return bids;
    },

    getImgSrc(trackName) {
      if (trackName === "throne") return "WarOfKings";
      if (trackName === "sword") return "UseSword";
      if (trackName === "crow") return "CrowCheck";
      if (trackName === "wildings") return "Wildings";
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_modal_wr {
  position: absolute;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;

  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}

.inner_wr {
  // z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  color: White;
  width: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  overflow: auto;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
}

.inner_wr::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inner_wr {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  width: auto;
  -webkit-overflow-scrolling: touch;
}

.table_wr {
  width: 100%;
  display: flex;
}

.table {
  width: auto;
  // overflow: auto;
}
.table::-webkit-scrollbar {
  display: none;
}

.row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid gray;
}

.round {
  display: flex;
  align-items: center;
  width: auto;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  img {
    width: 2rem;
  }
}

.track {
  width: auto;

  img {
    width: 2.5rem;
  }
}

.bids {
  width: auto;
}

.home {
  img {
    width: 1.5rem;
  }
}

.bid {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: red;
}
.left {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  color: green;
}
.bid_wr {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.tokens_wr {
}
</style>
