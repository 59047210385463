import { v4 as uuidv4 } from "uuid";
import store from "../store/index.js";

export default class KingChoiceHandler {
  constructor() {
    this.oldIndex = null;
  }

  resetGui() {
    // store.commit("general_store/processModal");
  }
  prepareGui() {
    store.commit("general_store/processModal", { name: "bid", type: "close" });
    this.initialTracksPreset();
  }

  initialTracksPreset() {
    const state = store.getters["general_store/state"];
    if (!state) return;
    const tracks = state.tracks;
    const current_track = state.current_track_bid;
    if (!current_track) return;
    store.commit("game_store/setMainTracks", { sortedTrack: tracks[current_track], trackName: current_track });
  }

  mousedownHandler(e) {
    e.preventDefault();
    store.commit("gui_store/setSelectedUnits", { reset: true });

    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    const mapPosition = store.state.gui_store.mapPosition;
    const scale = mapPosition.scale;
    const { house, track, type, num } = e.target.dataset;

    if (!state) return;
    const conflict_track = state.current_track_bid;
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (!state.conflict_bids.includes(house)) return;
    if (conflict_track !== track) return;

    this.unit = e.target;

    this.unit.setPointerCapture(e.pointerId);
    const src = `/images/tokens/house_tokens/track_tokens/${house}_track.png`;
    this.oldIndex = num;
    this.dragging_house = house;

    store.commit("gui_store/setSelectedUnits", { unit: { src, id: uuidv4() } });

    const default_styles = {
      width: "content-length",
      height: "auto",
      position: "absolute",
      zIndex: 10000,
      // backgroundColor: "black",
      display: "flex",
      color: "white",
      pointerEvents: "none",
    };
    this.clones_container = document.createElement("div");
    const clone = document.createElement("div");
    const img = document.createElement("img");
    clone.style.width = "120px";
    clone.style.height = "120px";
    // clone.style.backgroundColor = "red";
    img.src = `/images/tokens/house_tokens/track_tokens/${house}_track.png`;
    img.style.width = "100%";
    img.style.height = "auto";
    img.style.pointerEvents = "none";
    clone.appendChild(img);
    this.clones_container.appendChild(clone);
    const map = document.querySelector("#camera");
    Object.assign(this.clones_container.style, default_styles);
    map.appendChild(this.clones_container);

    default_styles.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
      e.clientY - this.clones_container.offsetHeight / 2
    }px) scale(${scale})`;

    Object.assign(this.clones_container.style, default_styles);

    this.unit.onpointermove = (e) => {
      this.clones_container.style.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
        e.clientY - this.clones_container.offsetHeight / 2
      }px) scale(${scale})`;

      let elementBelow = document.elementFromPoint(e.clientX, e.clientY);
    };

    this.unit.onpointerup = this.dragEnd.bind(this);

    this.unit.onpointercancel = (e) => {
      this.removeDragClones();
    };
  }

  dragEnd(e) {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    this.removeDragClones();
    if (!state) return;
    const tracks = state.tracks;
    if (!active_players) return;
    if (this.oldIndex === null) return;
    if (!active_players.includes(home)) return;
    let elementBelow = document.elementFromPoint(e.clientX, e.clientY);
    const { house, track, type, num } = elementBelow.dataset;

    if (track !== state.current_track_bid) return;

    if (!state.conflict_bids.includes(house)) return;

    const bid_state = state.bid_state[state.current_track_bid];
    const dragging_bid = bid_state.find((b) => b.home === this.dragging_house);
    const target_bid = bid_state.find((b) => b.home === house);

    if (+dragging_bid.bid !== +target_bid.bid) return;

    const list = [...tracks[track]];
    list.splice(num, 0, list.splice(this.oldIndex, 1)[0]);

    store.commit("general_store/setTrack", { homes: list, track_name: track, room_id: state.id });
    store.commit("game_store/setMainTracks", { sortedTrack: list, trackName: track });
  }

  removeDragClones() {
    if (this.unit) {
      this.unit.onpointermove = null;
      this.unit.onpointerup = null;
      this.unit.onpointercancel = null;
    }

    const map = document.querySelector("#camera");
    if (this.clones_container && map.contains(this.clones_container)) {
      map.removeChild(this.clones_container);
    }
  }
}
