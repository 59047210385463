<template>
  <div class="garrison_wrap">
    <div
      v-for="land_obj in _lands"
      v-show="land_obj.garrison"
      :id="land_obj.title + '_garrison'"
      :key="land_obj.title"
      class="garrison_zone"
      :data-source="land_obj.title"
    >
      <img
        v-if="land_obj.garrison"
        src="/images/garrison.png"
        alt="garrison"
        class="garrison_img"
        :data-source="land_obj.title"
        @load="load_status++"
      />
      <span v-if="land_obj.garrison" class="garrison_force" :data-source="land_obj.title">
        {{ getGarrisonForce(land_obj.garrison) }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  emits: ["loaded"],
  data() {
    return {
      load_status: 0,
    };
  },

  computed: {
    ...mapGetters({
      lands: "general_store/lands",
      state: "general_store/state",
    }),

    _lands() {
      const filteredLands = [];
      for (let land_name in this.lands) {
        const land_obj = this.lands[land_name];
        if ("garrison" in land_obj) {
          filteredLands.push(land_obj);
        }
      }
      return filteredLands;
    },

    garrisonCount() {
      const lands = Object.keys(this.lands);
      return lands?.reduce((acc, l) => {
        if (this.lands[l].garrison) {
          acc++;
        }
        return acc;
      }, 0);
    },
  },

  methods: {
    ...mapMutations("game_store", ["setDragState", "setRaidState", "setShallowToken", "setConceptionState"]),
    ...mapMutations("general_store", ["beckupLands"]),

    getGarrisonForce(force) {
      if (force > 30) return "~";
      return force;
    },
  },
};
</script>

<style lang="scss" scoped>
.garrison_wrap {
  position: absolute;
}
.garrison_zone {
  // min-width: 40px;
  // min-height: 40px;
  // background-color: rgba(0, 0, 0, 0.584);
  pointer-events: none;
  position: absolute;
  // pointer-events: none;
}
.garrison_img {
  pointer-events: none;
  position: absolute;
  // pointer-events: none;
}
.garrison_force {
  pointer-events: none;
  position: absolute;
  left: 12px;
  top: 8px;
  color: white;
  font-size: 28px;
  font-weight: 600;
}

//stark
//stark
//stark
//stark

#winterfell_garrison {
  left: 716px;
  top: 664px;
}

#the_stony_shore_garrison {
  left: 331px;
  top: 732px;
}
#bay_of_ice_garrison {
  left: 62px;
  top: 358px;
}
#castle_black_garrison {
  left: 868px;
  top: 219px;
}
#karhold_garrison {
  left: 1072px;
  top: 388px;
}

#white_harbor_garrison {
  left: 846px;
  top: 919px;
}
#widows_watch_garrison {
  left: 1055px;
  top: 805px;
}
#moat_cailin_garrison {
  left: 694px;
  top: 1062px;
}
#the_shivering_sea_garrison {
  left: 1357px;
  top: 776px;
}
#the_narrow_sea_garrison {
  left: 1357px;
  top: 1086px;
}

#the_eyrie_garrison {
  left: 1046px;
  top: 1534px;
}
#the_twins_garrison {
  left: 727px;
  top: 1306px;
}
#the_fingers_garrison {
  left: 1004px;
  top: 1237px;
}
#the_mountains_of_the_moon_garrison {
  left: 901px;
  top: 1412px;
}

//martell
//martell
//martell
//martell

#storms_end_garrison {
  left: 1095px;
  top: 2163px;
}
#the_boneway_garrison {
  left: 875px;
  top: 2311px;
}
#princes_pass_garrison {
  left: 611px;
  top: 2402px;
}
#starfall_garrison {
  left: 548px;
  top: 2642px;
}
#yronwood_garrison {
  left: 832px;
  top: 2589px;
}
#salt_shore_garrison {
  left: 932px;
  top: 2653px;
}
#sea_of_dorne_garrison {
  left: 1001px;
  top: 2449px;
}
#east_summer_sea_garrison {
  left: 1084px;
  top: 2850px;
}

//baratheon
//baratheon
//baratheon
//baratheon

#shipsrearer_bay_garrison {
  left: 1355px;
  top: 2092px;
}

#dragonstone_garrison {
  left: 1372px;
  top: 1769px;
}

#kings_landing_garrison {
  left: 931px;
  top: 1905px;
}
#kingswood_garrison {
  left: 1111px;
  top: 2037px;
}
#blackwater_bay_garrison {
  left: 1046px;
  top: 1907px;
}

#crackclaw_point_garrison {
  left: 951px;
  top: 1716px;
}

//tyrell
//tyrell
//tyrell
//tyrell

#highgarden_garrison {
  left: 436px;
  top: 2244px;
}
#the_reach_garrison {
  left: 770px;
  top: 2096px;
}
#dornish_marches_garrison {
  left: 598px;
  top: 2282px;
}
#oldtown_garrison {
  left: 265px;
  top: 2505px;
}
#three_towers_garrison {
  left: 425px;
  top: 2504px;
}
#west_summer_sea_garrison {
  left: 425px;
  top: 2846px;
}
#the_arbor_garrison {
  left: 106px;
  top: 2724px;
}
#redwyne_straights_garrison {
  left: 148px;
  top: 2606px;
}

//lannister
//lannister
//lannister

#lannisport_garrison {
  left: 311px;
  top: 1830px;
}
#the_golden_sound_garrison {
  left: 85px;
  top: 1895px;
}
#searoad_marches_garrison {
  left: 339px;
  top: 1919px;
}
#blackwater_garrison {
  left: 635px;
  top: 1992px;
}
#stoney_sept_garrison {
  left: 585px;
  top: 1727px;
}
#riverrun_garrison {
  left: 545px;
  top: 1650px;
}
#harrenhal_garrison {
  left: 763px;
  top: 1650px;
}

//greyjoy
//greyjoy
//greyjoy
//greyjoy
#sunspear_garrison {
  left: 1189px;
  top: 2635px;
}

#pyke_garrison {
  left: 253px;
  top: 1499px;
}
#flints_finger_garrison {
  left: 235px;
  top: 1200px;
}

#greywater_watch_garrison {
  left: 482px;
  top: 1078px;
}
#seagard_garrison {
  left: 582px;
  top: 1447px;
}
#ironmans_bay_garrison {
  left: 238px;
  top: 1255px;
}
#sunset_sea_garrison {
  left: 56px;
  top: 1103px;
}
</style>
