<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="body_wr">
      <div class="reason_wr">
        <div class="ava">
          <div class="username">
            <span>{{ processed_user.name }}</span>
          </div>
          <img :src="targetAvatar" alt="ava" />
          <span>{{ targetName }}</span>
        </div>
        <div class="reason_body">
          <div v-if="processed_user?.type === 'chat' || processed_user?.type === 'private_chat'" class="reason_msg">
            {{ processed_user?.complaint_data?.text }}
          </div>
          <div
            v-if="
              processed_user?.type !== 'play' &&
              processed_user?.type !== 'chat' &&
              processed_user?.type !== 'private_chat'
            "
            class="choice_wr"
          >
            <div class="ch_wr">
              <label for="nickname">{{ $t(`message.nickname`) }}</label>
              <input id="nickname" v-model="picked" type="radio" value="nickname" />
            </div>
            <div class="ch_wr">
              <label for="avatar">{{ $t(`message.avatar`) }}</label>
              <input id="avatar" v-model="picked" type="radio" value="avatar" />
            </div>
          </div>
          <div v-if="processed_user?.type === 'play'" class="reason_msg">
            {{ processed_user?.text }}
          </div>
        </div>
      </div>
      <textarea id="complaint" ref="textarea" v-model="msg" name="complaint"></textarea>
      <button @click="confirm">{{ $t(`message.complaint`) }}</button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  inject: ["socket"],

  data() {
    return {
      msg: "",
      picked: "nickname",
    };
  },

  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["user"]),

    processed_user() {
      return this.data_for_modal?.processed_user;
    },

    targetAvatar() {
      return `/images/avatars/${this.processed_user?.avatars[0]}`;
    },
    targetName() {
      return this.processed_user?.nickname;
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", ["processModal"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "complaint", type: "close" });
    },
    confirm() {
      let type = this.processed_user?.type;
      if (!type) {
        type = this.picked;
      }

      this.socket.emit("client_action", {
        action_type: "Complaint",
        data: {
          user_id: this.user.id,
          target_id: this.processed_user.id,
          message: this.msg,
          complaint_data: { ...this.processed_user, type },
        },
      });
      this.processModal({ name: "complaint", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: relative;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.312);
  z-index: 200;
  top: 0;
  left: 0;
}

.body_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  border-radius: 0.4rem;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
}
.reason_wr {
  display: flex;
  justify-content: center;
  gap: 1rem;
  max-height: 150px;

  .username {
    font-size: 1.3rem;
  }

  .ava {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    align-items: center;
    span {
      max-width: 100px;
      overflow-x: hidden;
      color: rgb(220, 36, 36);
      text-align: center;
    }
    // display: inline-block;
    // width: 50px;
    img {
      width: 100px;
      border-radius: 10px;
    }
  }

  .ch_wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    // gap: 1rem;
  }

  .reason_body {
    // background-color: aqua;
    position: relative;
    color: white;
    display: flex;
    align-items: center;

    .reason_msg {
      word-break: break-all;
      max-height: 100px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

textarea {
  resize: none;
  overflow-y: scroll;
  height: 50px;
  width: 80%;
  background-color: rgb(163, 162, 162);
  color: black;
  &::-webkit-scrollbar {
    display: none;
  }
  // line-height: 1rem;
}

button {
  margin: 0;
  line-height: 1.15;
  padding: 10px 20px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  transition: 0.25s ease;
  text-transform: none;
  &:hover {
    background-color: rgb(179, 159, 129);
  }
}
</style>
