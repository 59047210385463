import CerseiHandler from "../game_phase_handlers/CerseiHandler.js";
import DoranHandler from "../game_phase_handlers/DoranHandler.js";
import OlennaHandler from "../game_phase_handlers/OlennaHandler.js";
import PatchfaceHandler from "../game_phase_handlers/PatchfaceHandler.js";
import RenleyHandler from "../game_phase_handlers/RenleyHandler.js";

import ConceptionHandler from "../game_phase_handlers/ConceptionHandler.js";
import ConflictPortHandler from "../game_phase_handlers/ConflictPortHandler.js";
import CrowCheckHandler from "../game_phase_handlers/CrowCheckHandler.js";
import CrusadeHandler from "../game_phase_handlers/CrusadeHandler.js";
import DefineSupportHandler from "../game_phase_handlers/DefineSupportHandler.js";
import KingChoiceHandler from "../game_phase_handlers/KingChoiceHandler.js";
import ManualDelUnitsHandler from "../game_phase_handlers/ManualDelUnitsHandler.js";
import MasteringHandler from "../game_phase_handlers/MasteringHandler.js";
import RaidHandler from "../game_phase_handlers/RaidHandler.js";
import RetreatHandler from "../game_phase_handlers/RetreatHandler.js";
import SelectCardHandler from "../game_phase_handlers/SelectCardHandler.js";
import SupplyHandler from "../game_phase_handlers/SupplyHandler.js";
import ThroneDecisionHandler from "../game_phase_handlers/ThroneDecisionHandler.js";
import WarOfKingsHandler from "../game_phase_handlers/WarOfKingsHandler.js";
import WesterosDecisionHandler from "../game_phase_handlers/WesterosDecisionHandler.js";

import CrowKillersHandler from "../game_phase_handlers/CrowKillersHandler.js";
import HordeHandler from "../game_phase_handlers/HordeHandler.js";
import KingBeyondTheWallHandler from "../game_phase_handlers/KingBeyondTheWallHandler.js";
import MamothRidersHandler from "../game_phase_handlers/MamothRidersHandler.js";
import MassingMilkwaterHandler from "../game_phase_handlers/MassingMilkwaterHandler.js";
import PreemptiveRaidHandler from "../game_phase_handlers/PreemptiveRaidHandler.js";

import WildingsHandler from "../game_phase_handlers/WildingsHandler.js";

import GameOverHandler from "../game_phase_handlers/GameOverHandler.js";

export function getHandler(options) {
  const { phase_name, role, wildings_card, wildingsState } = options;
  switch (phase_name) {
    case "GameOver": {
      return new GameOverHandler();
    }
    case "WildingManual": {
      const is_victory = wildingsState?.is_victory;

      const Handler = getCardHandler(wildings_card, is_victory);
      if (!Handler) return null;
      return new Handler();
    }
    case "Wildings": {
      return new WildingsHandler();
    }

    case "ThroneDecision": {
      return new ThroneDecisionHandler();
    }
    case "ThroneOfBlades": {
      return new WesterosDecisionHandler();
    }
    case "BlackWings": {
      return new WesterosDecisionHandler();
    }
    case "PutToTheSword": {
      return new WesterosDecisionHandler();
    }
    case "Conception": {
      return new ConceptionHandler();
    }
    case "Power": {
      return new MasteringHandler();
    }
    case "CrowCheck": {
      return new CrowCheckHandler();
    }
    case "Supply": {
      return new SupplyHandler();
    }
    case "Mastering": {
      return new MasteringHandler();
    }
    case "WarOfKings": {
      return new WarOfKingsHandler();
    }
    case "KingChoice": {
      return new KingChoiceHandler();
    }
    case "Crusade": {
      return new CrusadeHandler();
    }
    case "UseSword": {
      return new DefineSupportHandler();
    }

    case "PermitSupport": {
      return new DefineSupportHandler();
    }
    case "DefineSupport": {
      return new DefineSupportHandler();
    }
    case "ConflictPort": {
      return new ConflictPortHandler();
    }
    case "PreAttackConflictPort": {
      return new ConflictPortHandler();
    }
    case "SelectCard": {
      return new SelectCardHandler();
    }
    case "Raid": {
      return new RaidHandler();
    }
    case "Retreat": {
      return new RetreatHandler();
    }
    case "ManualDelUnits": {
      return new ManualDelUnitsHandler();
    }
    case "FirstCardAction": {
      const cardTitle = role.card.title;
      const cardhandler = getCardHandler(cardTitle);
      return cardhandler;
    }
    case "LastCardAction": {
      const cardTitle = role.card.title;
      const cardhandler = getCardHandler(cardTitle);
      return cardhandler;
    }
    default: {
      return null;
    }
  }
}

export function getCardHandler(title, is_victory) {
  switch (title) {
    case "Olenna": {
      return new OlennaHandler();
    }
    case "Doran": {
      return new DoranHandler();
    }
    case "Robb": {
      return new RetreatHandler();
    }
    case "Renly": {
      return new RenleyHandler();
    }
    case "Cersei": {
      return new CerseiHandler();
    }
    case "Patchface": {
      return new PatchfaceHandler();
    }
    case "KingBeyondTheWall": {
      return KingBeyondTheWallHandler;
    }
    case "PreemptiveRaid": {
      return PreemptiveRaidHandler;
    }
    case "CrowKillers": {
      return CrowKillersHandler;
    }
    case "MassingMilkwater": {
      return MassingMilkwaterHandler;
    }
    case "MammothRiders": {
      return MamothRidersHandler;
    }
    case "Horde": {
      if (is_victory) {
        return MasteringHandler;
      }
      return HordeHandler;
    }
    default: {
      return new DefineSupportHandler();
    }
  }
}
