export default {
  uk: {
    genetive_land: {
      the_shivering_sea: "Тремтливого Моря",
      the_narrow_sea: "Вузького моря",
      bay_of_ice: "Крижаної Затоки",
      winterfell: "Вінтерфеллу",
      winterfell_port: "Порту Вінтрефеллу",
      castle_black: "Чорного Замку",
      the_stony_shore: "Каменястого Берегу",
      white_harbor: "Білої Гавані",
      white_harbor_port: "Порту Білої Гавані",
      karhold: "Карголду",
      widows_watch: "Вдоварти",
      the_eyrie: "Соколиного Гнізда",
      moat_cailin: "Калин-Копу",
      the_twins: "Близнюків",
      the_fingers: "Пальців",
      the_mountains_of_the_moon: "Місячних Гір",
      shipsrearer_bay: "Затоки Човнозгуба",
      blackwater_bay: "Чорноводої Затоки",
      dragonstone: "Дракон-Каменя",
      dragonstone_port: "Порту Дракон-Каменя",
      crackclaw_point: "Гостроклішні",
      kingswood: "Королівської Пущі",
      kings_landing: "Король-Берега",
      east_summer_sea: "Східного Літнього Моря",
      sea_of_dorne: "Дорнійського Моря",
      sunspear: "Сонцесписа",
      sunspear_port: "Порту Сонцесписа",
      storms_end: "Штормоламу",
      storms_end_port: "Порту Штормоламу",
      the_boneway: "Кістяного Шляху",
      princes_pass: "Великокняжого проходу",
      starfall: "Зорепаду",
      yronwood: "Крицаку",
      salt_shore: "Солемор'я",
      west_summer_sea: "Західного Літнього Моря",
      redwyne_straights: "Рожвинової протоки",
      highgarden: "Хайгардену",
      oldtown: "Старограду",
      oldtown_port: "Порту Старограду",
      three_towers: "Трибашти",
      dornish_marches: "Дорнійського Шляху",
      the_reach: "Обширу",
      the_arbor: "острову Вертограда",
      sunset_sea: "Західного Моря",
      the_golden_sound: "Золотої Затоки",
      lannisport: "Ланніспорту",
      lannisport_port: "Порту Ланніспорту",
      riverrun: "Водоплину",
      harrenhal: "Гаренголу",
      stoney_sept: "Камінної Септи",
      searoad_marches: "Морського Шляху",
      blackwater: "Чорноводої",
      ironmans_bay: "Залізняцької Затоки",
      pyke: "Пайку",
      pyke_port: "Порту Пайка",
      seagard: "Морестражу",
      flints_finger: "Кремінь-Пальця",
      greywater_watch: "Сіроводця",
    },

    genetive_phase: {
      Conception: `Замислів`,
      Wildings: `Нашестя дикунів`,
      CrowCheck: `Посильного Ворона`,
      Crusade: `Походів`,
      Raid: "Рейдів",
      Power: "Посилення Влади",
      BidPhase: "Торгів",
      KingChoice: "Вибору Короля",
      ThroneDecision: "Вибору Короля",

      ConflictPort: "Захоплення Кораблів",
      PreAttackConflictPort: "Захоплення Кораблів",
      PermitSupport: "Запиту Підтримки",
      DefineSupport: "Підтримки",
      UseSword: "Валерійського Меча",
      SelectCard: "Вибору Карти Дому",
      FirstCardAction: "Властивості Карти Дому",
      LastCardAction: "Властивості Карти Дому",
      Retreat: "Відступу (жесту доброї волі)",
      ManualDelUnits: "Непростих рішень",

      WildingManual: "Властивості Карти Дикунів",

      Supply: "Постачання",
      Mastering: "Збору Військ",
      LastDays: "Останні Дні Літа",
      Winter: "Зима Близько",
      ThroneOfBlades: "Трону із Клинків",
      GameOfThrones: "Гри Престолів",

      BlackWings: "Вибір Чорного Ворона",
      WarOfKings: "Битви Королів",

      AutumnRains: "Осінні Дощі",
      FeastOfCrows: "Свято Ворон",
      SeaOfStorms: "Море Штормів",
      WebOfLies: "Павутина Брехні",
      PutToTheSword: "Відданий Мечу",
    },

    genetive_order: {
      crusade_minus: "Мінусової атаки",
      crusade_zero: "Звичайної атаки",
      crusade_spec: "Посиленої атаки",
      support: "Піддтримки",
      support_spec: "Посиленої піддтримки",
      defence: "Оборони",
      defence_spec: "Посиленої оборони",
      power: "Зміцнення влади",
      power_spec: "Посиленого зміцнення влади",
      raid: "Рейду",
      raid_single: "Рейду",
      raid_spec: "Посиленого рейду",
    },

    genetive_home: {
      baratheon: "Баратеона",
      stark: "Старка",
      lannister: "Ланністера",
      tyrell: "Тірелла",
      martell: "Мартелла",
      greyjoy: "Грейджоя",
      nobody: "нікого",
    },

    genetive_track_name: {
      throne: "Залізного Трона",
      sword: "Валерійского Меча",
      crow: "Посильного Ворона",
    },

    genetive_home_card: {
      Eddard: "Еддарда Старка",
      Robb: "Робба Старка",
      Rodrik: "Сіра Родріка Касселя",
      Amber: "Джона Амбера",
      Roose: "Русе Болтона",
      Brynden: "Бріндена Чорної Риби",
      Catelyn: "Кейтелін Старк",
      Stannis: "Станіса Баратеона",
      Salladhor: "Салладора Саана",
      Renly: "Ренлі Баратеона",
      Patchface: "Пестряка",
      Melisandre: "Мелісандри",
      Davos: "Сіра Давоса Сіворта",
      Brienne: "Брієнни Тарт",
      Asha: "Аші Грейджой",
      Balon: "Бейлона Грейджоя",
      Dagmer: "Дагмера Щербатого",
      Euron: "Еурона Грейджоя",
      Aeron: "Еєрона Сироволосого",
      Theon: "Теона Грейджоя",
      Victarion: "Віктаріона Грейдоя",
      Cersei: "Серсеї Ланністер",
      Jaime: "Сіра Джейме Ланністера",
      Sandor: "Пса",
      Gregor: "Сіра Грегора Клігана",
      Kevan: "Сіра Ківана Ланністера",
      Tyrion: "Тіріона Ланністера",
      Tywin: "Тайвіна Ланністера",
      Alester: "Алестера Флорента",
      Garlan: "Сіра Гарлана Тірелла",
      Loras: "Сіра Лораса Тірелла",
      Margaery: "Маргарі Тірелл",
      Mace: "Мейса Тірелла",
      Olenna: "Оленни Тірелл",
      Randyll: "Сіра Ренділа Тарлі",
      Areo: "Арео Хотаха",
      Arianne: "Аріанни Мартелл",
      Doran: "Дорана Мартелла",
      Gerold: "Сіра Герольда Темної Зірки",
      Nymeria: "Німерії Санд",
      Obara: "Обари Санд",
      Oberyn: "Оберіна Червоної Гадюки",
    },
  },
};
