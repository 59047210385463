<template>
  <div id="modal_base" class="modal_base" @click="closeModal">
    <div class="modal_wraper">
      <div class="round_wr">
        <img src="/images/round_token.png" alt="round" />
        <span>{{ game?.state?.phase.round }}</span>
      </div>
      <div class="cards_wr">
        <div v-for="n of 3" :key="n" class="card_wr">
          <img :src="getWesterosCard(n)" alt="card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["mainRoom", "game", "user"]),

    locale() {
      return this.user.locale;
    },
  },
  methods: {
    ...mapMutations("general_store", ["processModal"]),

    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "round_changed", type: "close" });
    },
    getWesterosCard(n) {
      if (this.game?.state[`westeros_${n - 1}`]) {
        return `/images/westeros_cards/westeros_${n - 1}/${this.locale}/${this.game?.state[`westeros_${n - 1}`]}.png`;
      } else {
        return `/images/westeros_cards/westeros_${n - 1}/westeros_${n - 1}_back.png`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  pointer-events: all;
  z-index: 300;
  top: 0;
  left: 0;
}
.modal_wraper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 1.6rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 85dvh;

  justify-content: flex-start;
  align-items: center;
  background-color: rgb(22, 22, 22);
  border-radius: 0.5rem; // border: 4px solid rgb(174, 173, 173);
  -webkit-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
  -moz-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
  box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
}
.cards_wr::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.cards_wr {
  scrollbar-width: none;
}

.round_wr {
  display: flex;
  align-items: center;
  color: white;
  img {
    width: 3rem;
  }
  span {
    font-size: 2rem;
  }
}

.cards_wr {
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.card_wr {
  max-width: 80%;
  margin: auto;
  img {
    width: 100%;
  }
}
</style>
