export default {
  en: {
    exec: {
      GameOver: `Game Over`,
      StartGame: `Welocome to the Game Of Thrones!\nRemember that winter is comming...`,
      SetWesteros: `Westeros Crads have been changed\n1: {0}\n2: {1}\n3: {2}\n`,
      StartWar: `Started a battle with {0} for {1}`,

      Round: `{round} round started`,
      ResetCards: `{home} retreated his cards`,
      Winter: `Winter is comming...\nWesteros cards was shuffled.\nNext phase become "{phase}"`,

      CrowCheck_dig: `{home} looked at the card of wildings\nand decided to {flag}`,
      CrowCheck_order: `{home} changed order on {land_name}: {oldOrder} was replaced by {order}`,

      WesterosDecision: `{home} decided to play {phase}`,
      WesterosDecision_skip: `{home} decided to do nothing`,

      PutToTheSword: `{home} decided to disallow {order} orders`,
      PutToTheSword_skip: `{home} allows all orders to use`,
      AutumnRains: `{order} is denied during this round`,
      FeastOfCrows: `{order} is denied during this round`,
      SeaOfStorms: `{order} is denied during this round`,
      StormOfSwords: `{order} is denied during this round`,
      WebOfLies: `{order} is denied during this round`,

      Crusade: "{home} moved from {source_land_name}\nto {dest_msg}",
      NeutralLordDefeated: "Killed neutral lord in {land_name}\nand destroyed the garrison",

      ConflictPort: "Seizure of ships:\nLosses:\n{del_msg}\nCaptured ships:\n{cap_msg}",
      ConflictPort_auto: "Seizure of ships:\nLosses due Supply:\n{del_msg}",

      DefineSupport: "{home} supports to {choice}",
      DefineSupport_skip: "{home} supports to {choice}",

      PermitSupport: "{home} permit support",
      DenySupport: "{home} denу support",
      SelectCard: "{home} selected the card",
      UseSword: "{home} applied The Valerian Sword",
      UseSword_random: "{home} applied The Valerian Sword to change the Tides of Battle Card",
      UseSword_skip: "{home} did't apply The Valerian Sword",
      Retreat: "{card} retreated to the {destination}",
      Retreat_del: "{card} killed all {home} units at {source}",
      ManualDelUnits: "{home} removed troops:\n{str}",
      KillUnits_scull: "Scull on random card:\n{card} killed {unit} of {home} at {land_name}",
      KillUnits: "Execution of prisoners (swords and scull):\n{card} killed {count} unit(s) of {home} at {land_name}",

      Conception: "{home} set all orders to his troops",

      Bet: "{home} made a bet",
      WarOfKings: "{home} made a bet for {trackName}",

      ThroneDecision: "King {home} selected house {choice}",
      KingChoice: "{home} sorted Houses with same rates for {trackName}",

      WildingManual: "{home} is done with {card}",
      WildingsEnd_victory:
        "Night Watch has won with power {power} vs {force}.\n{bid_msg}\n{bigest_candidates} did top bid.\nReady to play {card}",
      WildingsEnd_loose:
        "Wildings has won with force {force} vs {power}.\n{bid_msg}\n{lowest_candidates} did lowest bid.\nReady to play {card}",

      CrowKillers: "{card}:\nUpgrades:\n{upd_msg}\nDowngrades:\n{down_msg}Losses:\n{del_msg}",

      PreemptiveRaid: "{card}:\nLosses:\n{del_msg}",
      PreemptiveRaid_track: "{card}:\n{home} selected the last place on {track} track",
      PreemptiveRaid_victory: "{card}:\n{home} doesn't participate in auction and awards against next invasion",

      Horde: "{card}:\nLosses:\n{del_msg}",
      Horde_victory: "{card}:\nRecruited:\n{cr_msg}",

      Skinchanger_victory: "{card}:\n{home} returned the money spent",
      Skinchanger_loose: "{card}:\n{home} droped {count} token(s)",

      MammothRiders: "{card}:\nLosses:\n{del_msg}",
      MammothRiders_victory: "{card}\n{home} restored {card_target} card",
      MammothRiders_victory_skip: "{card}\n{home} decieded to skip restoring his card",

      MassingMilkwater_loose: "{card}\n{home} droped {cardNames}",

      KingBeyondTheWall_lowest: "{card}:\n{home} took last place on all influence tracks",
      KingBeyondTheWall_loose: "{card}:\n{home} took last place on {track} track",
      KingBeyondTheWall_victory: "{card}:\n{home} took first place on {track} track\nand got a token of those track",

      Supply: "{home} have changed level of support \nand removed troops:\n{str}",
      SupplyChange: "Supply levels were changed:\n{homes}",

      Mastering: "{home} created units from {source_land_name}:\n{str}",
      Mastering_skip: '{home} did not create troops for "{source_land_name}"',

      Raid_power:
        "{home} made a raid from {source_land_name}\nto {targetLand}\nBurned {target_order} order\nand gained {tokens_count} {plur}",
      Raid_standart: "{home} made a raid from {source_land_name}\nto {targetLand}\nBurned {target_order} order",
      Raid_empty: "{home} removed the Raid order from {source_land_name}",

      Power_token: '{home} gained extra token from "{land_name}"',

      Patchface: "{myCardName} drop {victim_card_title}",
      Patchface_skip: "{myCardName} didn't drop any card",
      Stannis: "{myCardName} increased his power by +1",
      Davos: "{myCardName} increased his power by +1 and got the sword",
      Salladhor: "{myCardName} nullified power of all enemy ships",
      Renly: "{myCardName} replaced his pawn for knite at {land_name}",
      Renly_skip: "{myCardName} didn't upgrade any pawn",

      Robb: "{myCardName} moved {home} to {victim_land}",
      Robb_del: "{myCardName} killed all units at {source}",
      Catelyn: "{myCardName} doubled defence order at {land_name}",
      Roose: "{myCardName} restored all cards for Stark",
      Brynden: "{myCardName} didn't allow to kill anyone from his squad",

      Cersei: "{myCardName} removed order from {land_name}",
      Cersei_skip: "{myCardName} decided to skip",
      Tyrion_apply: "{myCardName} canceled {victim_card_title}",
      Tyrion_deny: "{myCardName} did nothing with {victim_card_title}",
      Kevan: "{myCardName} doubled power of his pawns: +{extra_power}",
      Tywin: "{myCardName} gained two tokens",

      Olenna: "{myCardName} removed order at {land_name}",
      Mace: "{target_home} lost his pawn. {myCardName} killed him",
      Loras: "{myCardName} kept crusade order at {land_name}",

      Arianne: "{myCardName} send {victim_card_title} back to {land_name}",
      Doran: "{myCardName} put {target_home} at the last place on track: {track}",
      Nymeria: "{myCardName} got {prop}",

      Asha: "{myCardName} got two swords and one shield",
      Balon: "{myCardName} nullified main power of enemey's card: {victim_card_title}",
      Aeron_apply: "{myCardName} paid two tokens for replacing his card",
      Aeron_deny: "{myCardName} dicided to stay",
      Theon: "{myCardName} increased his power by +1 and got a sword",
      Victarion: "{myCardName} doubled power og his ships",
    },
  },
  uk: {
    exec: {
      GameOver: `Гра Завершена`,

      StartGame: `Вітаю Вас у Грі Престолів!\nПам'ятайте, що зима близько...`,
      SetWesteros: `Зміна карт Вестеросу\n1: {0}\n2: {1}\n3: {2}\n`,

      StartWar: `Розпочав битву з {0} за {1}`,

      Round: `Почався {round} раунд`,
      ResetCards: `{home} відновив свої карти`,
      Winter: `Зима близько...\nКарти Вестеросу були перемішані.\nНаступна фаза: {phase}`,

      CrowCheck_dig: `{home} вирішив {flag} карту дикунів`,
      CrowCheck_order: `{home} змінив наказ у {land_name}:\nнаказ {oldOrder} був замінений на {order}`,

      WesterosDecision: `{home} обрав наступною фазою {phase}`,
      WesterosDecision_skip: `{home} вирішив нічого не робити`,

      PutToTheSword: `{home} вирішив заборонити накази {order}`,
      PutToTheSword_skip: `{home} дозволив всі накази`,

      AutumnRains: `Накази {order} заборонені у цьому раунді`,
      FeastOfCrows: `Накази {order} заборонені у цьому раунді`,
      SeaOfStorms: `Накази {order} заборонені у цьому раунді`,
      StormOfSwords: `Накази {order} заборонені у цьому раунді`,
      WebOfLies: `Накази {order} заборонені у цьому раунді`,

      Crusade: "{home} зробив похід з {source_land_name}\nу {dest_msg}",
      NeutralLordDefeated: "Вбив нейтрального лорда у {land_name}\nта знищив гарнізон",

      ConflictPort: "Захоплення кораблів:\nВтрати:\n{del_msg}\nЗахоплені кораблі:\n{cap_msg}",
      PreAttackConflictPort: "Захоплення кораблів:\nВтрати:\n{del_msg}\nЗахоплені кораблі:\n{cap_msg}",
      ConflictPort_auto: "Захоплення кораблів:\nВтрати через ліміт постачання:\n{del_msg}",
      PreAttackConflictPort_auto: "Захоплення кораблів:\nВтрати через ліміт постачання:\n{del_msg}",

      DefineSupport: "{home} підтримує {choice}",
      DefineSupport_skip: "{home} не підтримав нікого",
      PermitSupport: "{home} очікує підтримку",
      DenySupport: "{home} заборонив підтримку",
      SelectCard: "{home} обрав карту",
      UseSword: "{home} використав Валерійский Меч",
      UseSword_random: "{home} використав Валерійский Меч для заміни карти преваг",
      UseSword_skip: "{home} не задіяв Валерійский Меч",

      Retreat: "{card} {retreat_gender} у {destination}",

      Retreat_del: "{card} {kill_gender} усіх воїнів у {source}",
      ManualDelUnits: "{home} видалив воїнів:\n{str}",
      KillUnits_scull: "Череп на картці переваг:\n{card} наостанок {kill_gender} {unit} {home} у {land_name}",
      KillUnits:
        "Страта полонених (мечі та череп):\n{card} {kill_gender} {count}{count_prefix} {unit} {home} у {land_name}",

      Conception: "{home} віддав накази своїм воїнам",

      Bet: "{home} зробив ставку",
      WarOfKings: "{home} зробив ставку за {trackName}",

      WildingsEnd_victory:
        "Нічна Варта перемогла із силою {power} проти {force}.\n{bid_msg}\nНайвищі ставки у {bigest_candidates}.\nКарта дикунів: {card}",
      WildingsEnd_loose:
        "Дикуни перемогли із силою {force} проти {power}.\n{bid_msg}\nНайнижчі ставки у {lowest_candidates}.\nКарта дикунів: {card}",

      CrowKillers: "{card}:\nАпгрейд:\n{upd_msg}\nДаунгрейд:\n{down_msg}\nВтрати:\n{del_msg}",
      PreemptiveRaid: "{card}:\nВтрати:\n{del_msg}",
      PreemptiveRaid_track: "{card}:\n{home} обрав останню позицію на треці {track}",
      PreemptiveRaid_victory: "{card}:\n{home} не приймає участі у торгах проти наступного вторгнення дикунів",

      Horde: "{card}:\nВтрати:\n{del_msg}",
      Horde_victory: "{card}:\nНові воїни:\n{cr_msg}",

      Skinchanger_victory: "{card}:\n{home} повернув собі витрачені токени",
      Skinchanger_loose: "{card}:\n{home} скинув токени у кількості: {count}",

      KingBeyondTheWall_lowest: "{card}:\n{home} зайняв останні місця на усіх треках впливу",
      KingBeyondTheWall_loose: "{card}:\n{home} зайняв останнє місцe на треці {track}",
      KingBeyondTheWall_victory: "{card}:\n{home} зайняв перше місцe на треці {track}\nта отримав жетон цього треку",

      MammothRiders: "{card}:\nВтрати:\n{del_msg}",
      MammothRiders_victory: "{card}\n{home} відновив карту {card_target}",
      MammothRiders_victory_skip: "{card}\n{home} вирішив не відновлювати свою карту",

      MassingMilkwater_loose: "{card}\n{home} скинув {cardNames}",

      ThroneDecision: "Король {home} обрав дім {choice}",
      KingChoice: "{home} відсортував Доми з однаковими ставками за {trackName}",

      WildingManual: "{home} виконав умови карти дикунів {card}",
      Supply: "{home} змінив рівень постачання \nта видалив війська:\n{str}",
      SupplyChange: "Був змінений рівень постачання:\n{homes}",

      Mastering: "{home} створив війська:\n{str}",
      Mastering_skip: "{home} не зібрав війск у {source_land_name}",

      Raid_power:
        "{home} з {source_land_name}\nвиконав набіг на {targetLand}\nCпалив наказ {target_order}\nта отримав {tokens_count} {plur}",
      Raid_standart: "{home} з {source_land_name}\nвиконав набіг на {targetLand}\nCпалив наказ {target_order}",
      Raid_empty: "{home} прибрав свій наказ набігу з {source_land_name}",

      Power_token: "{home} отримав додатковий токен з {land_name}",

      Cersei: "Серсея зняла наказ у {land_name}",
      Cersei_ignore: "Серсея вирішила не знімати наказ",
      Tyrion_apply: "Тіріон відмінив карту {victim_card_title}",
      Tyrion_deny: "Тіріон залишив карту {victim_card_title}",
      Kevan: "Ківан подвоЇв силу піших воЇнів: +{extra_power}",
      Tywin: "Тайвін отримав два токени",

      Patchface: "Пестряк скинув карту {victim_card_title}",
      Patchface_skip: "Пестряк вирішив не скидувати карту",
      Stannis: "Станіс збільшив свою силу на +1",
      Davos: "Сір Давос збільшив свою силу на +1 та отримав меч",
      Salladhor: "Салладор обнулив силу кораблів противника",
      Renly: "Ренлі замінив пішого воїна на лицаря у {land_name}",
      Renly_skip: "{myCardName} не став міняти пішого воїна на лицаря",

      Robb: "Робб відправив {target_home} у {land_name}",
      Robb_del: "Робб вбив усіх у {land_name}",
      Catelyn: "Кейтелін подвоЇла наказ оборони у {land_name}",
      Roose: "Русе Болтон відновив усі карти для Старка",
      Brynden: "Брінден врятував своїх воЇнів від гибелі",

      Olenna: "Оленна Тірел видалила наказ у {land_name}",
      Mace: "{target_home} втратив пішого воЇна. Мейс Тірелл вбив його",
      Loras: "Лорас Тірелл зберіг наказ походу у {land_name}",

      Arianne: "Аріана відпривила {victim_card_title} назад у {land_name}",
      Doran: "Доран змістив {target_home} на останнє місце на треці {track}",
      Nymeria: "Німерія отримала {prop}",

      Asha: "Аша отримала два меча та один щит",
      Balon: "Бейлон обнулив основну силу {victim_card_title}",
      Aeron_apply: "Еєрон заплатив два токени, щоб замінити карту",
      Aeron_deny: "Еєрон вирішив битися далі",
      Theon: "Теон збільшив свою силу на +1 та отримав меч",
      Victarion: "Віктаріон подвоЇв силу своЇх кораблів",
    },
  },
};
