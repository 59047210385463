<template>
  <div>
    <div
      v-for="land_obj in lands_with_token_fields"
      v-show="showToken(land_obj.title)"
      :id="land_obj.title + '-token'"
      :key="land_obj.title"
      data-type="token"
      class="token_zone"
      :data-source="land_obj.title"
      :data-house="land_obj.home"
    >
      <img
        v-if="showToken(land_obj.title)"
        :src="'/images/tokens/' + getToken(land_obj, land_obj.title)"
        :class="{ shalow: isShalow(land_obj.title), disable: isHistoryModeOn }"
        data-type="token"
        :data-source="land_obj.title"
        :data-house="land_obj.home"
        :alt="land_obj.home || 'token'"
        @click="setToken(land_obj.title)"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("general_store", ["isHistoryModeOn", "lands", "home"]),
    ...mapState("game_store", ["shalow_token", "drag_state"]),

    lands_with_token_fields() {
      const filteredLands = [];
      for (let land_name in this.lands) {
        const land_obj = this.lands[land_name];
        if ("token" in land_obj) {
          filteredLands.push(land_obj);
        }
      }
      return filteredLands;
    },
  },

  methods: {
    ...mapMutations("game_store", ["setDragState"]),

    showToken(land_name) {
      if (!land_name) return false;

      if (this.shalow_token === land_name) return true;
      return this.lands[land_name].token;
    },
    getToken(land, land_name) {
      if (this.shalow_token === land_name) return `/${land.home}_token_new.png`;
      return `/${this.lands[land_name].token}_token_new.png`;
    },
    isShalow(land_name) {
      if (this.drag_state.token === this.home) return false;
      if (this.shalow_token === land_name) return true;
      return false;
    },
    setToken(land_name) {
      if (this.isHistoryModeOn) return;
      if (this.lands[land_name].token) return;

      if (this.shalow_token !== land_name) return true;
      if (!this.drag_state.token) {
        this.setDragState({ token: this.home });
      } else {
        this.setDragState({ token: "" });
      }
    },
  },
};
</script>

<style lang="scss">
.token_zone {
  // min-width: 50px;
  // min-height: 50px;
  // background-color: rgba(249, 40, 40, 0.564);
  border-radius: 15%;
  width: auto;
  display: flex;
  justify-content: center;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  img {
    max-width: 50px;
    max-height: 50px;
    cursor: pointer;
    pointer-events: all;
  }
}

.shalow {
  opacity: 0.5;
}

.disable {
  cursor: grab;
  pointer-events: none;
}

//stark
//stark
//stark
//stark
//stark

#winterfell-token {
  left: 583px;
  top: 586px;
}

#white_harbor-token {
  left: 893px;
  top: 936px;
}
#the_stony_shore-token {
  left: 394px;
  top: 776px;
}
#castle_black-token {
  left: 872px;
  top: 287px;
}
#karhold-token {
  left: 1134px;
  top: 438px;
}
#widows_watch-token {
  left: 965px;
  top: 916px;
}
#moat_cailin-token {
  left: 624px;
  top: 1096px;
}
#the_twins-token {
  left: 780px;
  top: 1296px;
}
#the_fingers-token {
  left: 1061px;
  top: 1239px;
}
#the_mountains_of_the_moon-token {
  left: 996px;
  top: 1423px;
}
#the_eyrie-token {
  left: 1099px;
  top: 1607px;
}

//baratheon
//baratheon
//baratheon
//baratheon

#dragonstone-token {
  left: 1287px;
  top: 1762px;
}

#crackclaw_point-token {
  left: 960px;
  top: 1811px;
}
#kings_landing-token {
  left: 913px;
  top: 2025px;
  // background-color: red;
}
#kingswood-token {
  left: 1036px;
  top: 2097px;
}

//martell
//martell
//martell
//martell

#storms_end-token {
  left: 1062px;
  top: 2267px;
}
#the_boneway-token {
  left: 725px;
  top: 2466px;
}
#princes_pass-token {
  left: 636px;
  top: 2466px;
}
#starfall-token {
  left: 601px;
  top: 2644px;
}
#yronwood-token {
  left: 799px;
  top: 2620px;
}
#salt_shore-token {
  left: 973px;
  top: 2657px;
}
#sunspear-token {
  left: 1241px;
  top: 2561px;
}

// greyjoy
// greyjoy
// greyjoy
// greyjoy

#greywater_watch-token {
  left: 436px;
  top: 1196px;
}
#flints_finger-token {
  left: 206px;
  top: 1149px;
}
#pyke-token {
  left: 234px;
  top: 1412px;
}
#seagard-token {
  left: 567px;
  top: 1347px;
}

//lannister
//lannister
//lannister
//lannister
#riverrun-token {
  left: 585px;
  top: 1608px;
}
#lannisport-token {
  left: 389px;
  top: 1770px;
}
#searoad_marches-token {
  left: 288px;
  top: 2037px;
}
#stoney_sept-token {
  left: 507px;
  top: 1825px;
}
#harrenhal-token {
  left: 707px;
  top: 1649px;
}
#blackwater-token {
  left: 555px;
  top: 2000px;
}

// tyrell
// tyrell
// tyrell
// tyrell

#the_reach-token {
  left: 604px;
  top: 2148px;
}
#dornish_marches-token {
  left: 504px;
  top: 2298px;
}
#highgarden-token {
  left: 387px;
  top: 2305px;
}
#oldtown-token {
  left: 303px;
  top: 2496px;
}
#three_towers-token {
  left: 309px;
  top: 2577px;
}
#the_arbor-token {
  left: 67px;
  top: 2831px;
}
</style>
