import store from "../store/index.js";
export default class MilkwatersHandler {
  constructor() {}

  prepareGui() {
    this.resetGui();
    const home = store.getters["general_store/home"];
    const wildings_state = store.getters["general_store/wildingsState"];
    const active_players = store.getters["general_store/active_players"];
    const players_states = store.getters["general_store/states"];
    if (!active_players.includes(home)) return;
    if (!wildings_state.lowest_candidates.includes(home)) return;
    const pl_state = players_states.find((st) => st.home === home);
    const active_cards = pl_state.cards.filter((card) => card.active);
    if (active_cards.length !== 2) return;
    store.commit("general_store/showHintModal", {
      data: {
        code: "3001",
      },
    });
  }

  resetGui() {
    store.commit("gui_store/setSelectedCard", { reset: true });
    store.commit("general_store/showHintModal", { data: false });
  }

  cardClickHandler(e, card) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];

    if (!card || !card.active) return;
    if (card.home !== home) return;
    if (!active_players.includes(home)) return;

    store.commit("game_store/selectCard", { title: card.title, home: card.home });
    store.commit("game_store/setExecTriger", { doit: true });
  }
}
