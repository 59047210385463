<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="ban_log_container">
      <div class="table_wr">
        <table>
          <tr>
            <th>
              {{ $t("message.ban") }}
              <div class="update_btn">
                <span
                  v-if="!loading"
                  class="material-icons"
                  @click="updateBanList"
                  @pointerenter="hint_label_hover($event, { msg: 'ban_update' })"
                  @pointerleave="resetHint"
                >
                  autorenew
                </span>
                <pulse-loader :loading="loading" color="white" size="10px"></pulse-loader>
              </div>
            </th>
            <th>{{ $t("message.ban_start") }}</th>
            <th>{{ $t("message.ban_duration") }}</th>
            <th>{{ $t("message.ban_end") }}</th>
            <th class="th_paid">
              <span>
                {{ $t("message.ban_del") }}
              </span>
              <span
                class="material-icons paid_btn"
                @pointerenter="hint_label_hover($event, { msg: 'price', data: price.del_ban })"
                @pointerleave="resetHint"
              >
                paid
              </span>
            </th>
          </tr>
          <tr v-for="(ban, key) in processed_user?.ban" :key="key" class="log_wr">
            <td>{{ $t(`message.ban_${key}`) }}</td>
            <td>{{ getTime(ban.ban_start) }}</td>
            <td v-if="ban.duration">{{ ban.duration }}/{{ $t(`message.ban_${ban.duration_type}`) }}</td>
            <td v-else></td>
            <td>{{ getTime(ban.ban_start + ban.duration_ms) }}</td>
            <td>
              <span v-if="ban.duration" class="material-icons del_ban_btn" @pointerdown="cancelBan(key)"> cancel </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { price } from "#static_data";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  inject: ["socket"],

  data() {
    return {
      price,
      loading: false,
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
    };
  },

  computed: {
    ...mapState("general_store", ["data_for_modal"]),
    ...mapGetters("general_store", ["user"]),

    processed_user() {
      return this.data_for_modal?.processed_user;
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "ban_state", type: "close" });
    },

    async cancelBan(type) {
      this.processModal({
        name: "confirm_pay",
        type: "open",
      });
      this.setDataForModal({
        data: {
          title: "cancel_ban",
          ban_type: type,
          cb: (result) => {
            if (!result) return;
            this.socket.emit("client_action", {
              action_type: "BanUser",
              data: {
                user_id: this.user.id,
                target_user_id: this.processed_user?.id,
                type,
                value: false,
              },
            });
          },
        },
      });
    },
    updateBanList() {
      this.loading = true;
      this.socket.emit("client_action", {
        action_type: "BanUser",
        data: {
          user_id: this.user.id,
          target_user_id: this.processed_user?.id,
          update: true,
        },
      });
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    getTime(ms) {
      if (!ms) return;
      return new Date(ms).toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
  pointer-events: all;
}
.ban_log_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  box-sizing: border-box;
  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: rgb(22, 22, 22);
  border-radius: 0.4rem; // border: 4px solid rgb(174, 173, 173);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(188, 58, 80);
  -moz-box-shadow: -1px -4px 97px 31px rgb(188, 58, 80);
  box-shadow: -1px -4px 97px 31px rgb(188, 58, 80);
}

.table_wr {
  height: 100%;
  width: 100%;
  overflow: auto;
}

table {
  box-sizing: border-box;
  position: relative;
  border-collapse: collapse;
  width: auto;
  font-size: 1rem;
  height: auto;
}

th,
td {
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.625);
}

th {
  color: rgb(199, 193, 237);
  padding: 0.4rem;
  text-align: center;
  background: rgba(170, 74, 74, 0.407);
  border-bottom: none;
}

td {
  position: relative;
  color: white;
  text-align: center;
  vertical-align: center;
  padding: 0.6rem;
}

.th_paid {
  display: flex;
  position: relative;
  gap: 0.3rem;
  align-items: center;
}

.paid_btn {
  // position: absolute;
  color: green;
  font-size: 1rem;
  cursor: pointer;
}
.del_ban_btn {
  color: rgba(175, 25, 25, 0.793);
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    color: red;
  }
}

.update_btn {
  top: 0px;
  left: 0px;
  color: white;
  position: absolute;
  color: rgb(22, 226, 237);
  cursor: pointer;
  &:hover {
    color: rgb(135, 198, 228);
  }
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 253, 253, 0.863);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
