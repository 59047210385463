<template>
  <div class="info_wr">
    <div class="inner_cards_tab_container">
      <div v-for="{ home: house } in states" :key="house" class="item-wr" :style="{ backgroundColor: colors[house] }">
        <div v-if="getHomeState(house)" class="status">
          <div class="status_name">{{ $t(`home.${house}`) }}</div>
          <div v-if="getHomeState(house)" class="stats">
            <div class="stat_item lands">
              <img src="/images/lands_stat.png" alt="lands_stat" />
              <span>{{ getNormalizedInvadedLands(house) }}| {{ lands_no_ports_no_seas.size }}</span>
            </div>
            <div class="stat_item token">
              <img :src="`/images/tokens/${house}_token.png`" alt="tokens" @click="resetAllowTokens(house)" />
              <span v-if="getHomeState(house)">{{
                getHomeState(house).tokens.length + `|${game.state.pool_tokens[house].length}`
              }}</span>
            </div>
            <div class="stat_item">
              <div v-for="(val_, unitType) in getHomeState(house)?.units" :key="unitType" class="unit_stat">
                <img
                  :src="`/images/units/${house}/new/${unitType}_${house}.png`"
                  :alt="unitType"
                  @click="resetUnitCount(e, unitType)"
                />
                <span>{{ val_?.length + "|" + game.state.pool_units[house][unitType].length }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getHomeState(house)" class="cards">
          <div
            v-for="card in getHomeState(house)?.cards"
            :key="card.title"
            class="mask"
            @click="cardClickHandler($event, card)"
            @mouseenter="cardHoverHandler($event, card)"
            @mouseleave="cardHoverHandler($event, card)"
            :data-home="house"
          >
            <div class="card_power">
              <span :data-home="house">{{ card.power }}</span>
            </div>

            <img
              v-if="showTrueStatus(card)"
              src="/images/forbidden.png"
              alt="forbiden"
              class="disable_label"
              :data-home="house"
            />

            <img
              class="card_img"
              :data-home="house"
              :src="`/images/cards/${card.home}/faces/${card.title}.png`"
              :alt="card.title"
              :class="{
                hint_my_card: card.home === home && card.active,
                grey_mask: showTrueStatus(card),
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],
  props: {
    handler: Object,
  },

  data() {
    return {
      colors: {
        lannister: "#c80707",
        baratheon: "#9f9f53",
        stark: "grey",
        tyrell: "#0b4a0b",
        martell: "#cb8a13",
        greyjoy: "#1f1f1e",
      },
      limits: {
        pawn: 10,
        knite: 5,
        ship: 6,
        kata: 2,
      },
    };
  },
  computed: {
    ...mapGetters("general_store", ["game", "home", "isAdmin", "phase_name", "lands", "states", "enemyRole"]),

    ...mapState("gui_store", ["selected_cards", "freeze"]),

    lands_no_ports_no_seas() {
      const lands = this.lands;
      return Object.keys(lands).reduce((set, land_name) => {
        const _land_obj = lands[land_name];
        if (_land_obj.sea || land_name.includes("_port")) return set;
        set.add(land_name);
        return set;
      }, new Set());
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setFreeze"]),
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    cardHoverHandler(e, card) {
      this.handler?.cardHoverHandler?.(e, card);
    },

    getNormalizedInvadedLands(home) {
      const home_state = this.states.find((st) => st.home === home);
      const inv_lands = home_state.lands;
      const res = inv_lands.reduce((set, land_name) => {
        const land_obj = this.lands[land_name];
        if (land_obj.sea || land_name.includes("_port")) return set;
        set.add(land_name);
        return set;
      }, new Set());
      return res.size;
    },

    getHomeState(home) {
      return this.states.find((st) => st.home === home);
    },

    showTrueStatus(card) {
      if (this.phase_name !== "SelectCard") {
        return !card.active;
      }

      const card_cand = this.enemyRole.card?.title;
      if (card_cand === card.title) {
        return false;
      }
      return !card.active;
    },

    resetUnitCount(e) {
      if (!this.isAdmin) return;
      if (this.freeze) return;
      this.socket.emit("client_action", {
        action_type: "RedactorApply",
        data: {
          phase_name: "redactor",
          data: {
            room_id: this.game.id,
            resetUnits: true,
            home: this.home,
          },
        },
      });
      this.setFreeze({ freeze: true, color: "pink" });
    },
    resetAllowTokens(home) {
      if (!this.isAdmin) return;
      if (this.freeze) return;
      this.socket.emit("client_action", {
        action_type: "RedactorApply",
        data: {
          phase_name: "redactor",
          data: {
            room_id: this.game.id,
            home,
            token_count: 10,
          },
        },
      });
      this.setFreeze({ freeze: true, color: "pink" });
    },

    cardClickHandler(e, card) {
      this.setDataForModal({ data: { card: { ...card } } });
      this.processModal({ name: "card_hint" });
    },
  },
};
</script>
<style lang="scss" scoped>
.info_wr {
  box-sizing: border-box;

  position: absolute;
  width: 100%;
  // min-width: 530px;
  height: 100%;
  background-color: #292828;
  border-top: 3px solid white;
  overflow-y: auto;

  span {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
  }
}

@media (min-width: 900px) {
  .info_wr {
    width: 100%;
    // min-width: 530px;
  }
}

.inner_cards_tab_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 6rem;
  // gap: 0.3rem;
}

.info_wr::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.info_wr {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// .gap_for_logger {
//   width: 100%;
//   min-height: 2.5rem;
//   // background-color: red;
// }

.item-wr {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
}

.status {
  padding: 0.2rem 0;
  height: 2.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .status_name {
    flex: 1;
    margin-left: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
  }
  .stats {
    flex: 3;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    .lands {
      position: relative;
      img {
        margin-top: 0.21rem;
        height: 1.3rem;
        width: auto;
      }
      span {
        font-size: 1rem;
        position: absolute;
        top: 23px;
        left: 7px;
      }
    }
    .token {
      position: relative;
      img {
        margin-top: 0.21rem;
        height: 1.2rem;
        width: auto;
      }
      span {
        font-size: 0.8rem;
        position: absolute;
        top: 25px;
        left: -2px;
      }
    }
    .stat_item {
      width: auto;
      display: flex;
      gap: 0.5rem;
      .unit_stat {
        position: relative;
        // margin-left: 7px;
        img {
          height: 1.5rem;
          width: auto;
        }
        span {
          font-size: 0.8rem;
          position: absolute;
          top: 25px;
          left: 2px;
        }
      }
    }

    @media (max-width: 400px) {
      gap: 0.3rem;

      .unit_stat {
        position: relative;
        img {
          height: 1rem;
          width: auto;
        }
        span {
          font-size: 0.8rem;
          position: absolute;
          top: 25px;
          left: 2px;
        }
      }
    }
  }
}
.cards {
  display: flex;
  justify-content: space-between;
  // height: 50%;
  width: 100%;
  height: auto;
}

.mask {
  position: relative;
  width: auto;
  // height: 80px;
  display: flex;
  height: fit-content;
  overflow: hidden;
  border: none;
}

.card_img {
  // position: absolute;
  transition: 0.35s;
  width: 100%;
  z-index: 10;
  cursor: pointer;
}

.hint_my_card {
  &:hover {
    transform: scale(1.1);

    // margin: -2px;
  }
}

.disable_cursor {
  &:hover {
    border: 2px solid rgb(204, 28, 28);
    margin: -2px;
    // z-index: 13;
    cursor: no-drop;
  }
}
.grey_mask {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  // &:hover {
  //   cursor: no-drop;
  // }
}
.disable_label {
  position: absolute;
  z-index: 12;
  top: 40%;
  right: 40%;
  width: 1rem;
}

.card_power {
  position: absolute;
  cursor: pointer;
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  border-radius: 20%;
  background-color: black;
  font-weight: 700;

  span {
    font-size: 20 !important;
  }
}
</style>
