<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="redactor_land_container">
      <h3>{{ $t(`land.${selected_land}`) }}</h3>

      <div class="shop">
        <label for="tokenName">{{ $t(`message.token`) }}</label>
        <select id="tokenName" v-model="selected_token" name="token">
          <option v-for="home in av_homes" :key="home" :value="home">{{ $t(`home.${home}`) }}</option>
        </select>
      </div>
      <div class="shop">
        <label for="homeName">{{ $t(`message.home`) }}</label>
        <select id="homeName" v-model="selected_home" name="home">
          <option v-for="home in av_homes" :key="home" :value="home">{{ $t(`home.${home}`) }}</option>
        </select>
      </div>
      <div class="shop">
        <label for="orderName">{{ $t(`message.order`) }}</label>
        <select id="orderName" v-model="selected_order" name="home">
          <option v-for="order in av_orders" :key="order" :value="order">{{ $t(`order.${order}`) }}</option>
        </select>
      </div>
      <div class="shop">
        <label for="delAll">{{ $t(`gui_hint.del_all_units`) }}</label>
        <input id="delAll" v-model="delete_all_units" type="checkbox" />
      </div>

      <div class="shop">
        <button @click="addUnit('pawn')">{{ $t(`unit.pawn`) }}: {{ getUnitCount("pawn") }}</button>
        <button @click="addUnit('knite')">{{ $t(`unit.knite`) }}: {{ getUnitCount("knite") }}</button>
        <button @click="addUnit('kata')">{{ $t(`unit.kata`) }}: {{ getUnitCount("kata") }}</button>
        <button @click="addUnit('ship')">{{ $t(`unit.ship`) }}: {{ getUnitCount("ship") }}</button>
        <button @click="resetUnits">{{ $t(`message.reset`) }}</button>
      </div>

      <span>{{ $t(`message.units`) }} {{ units_translated }}</span>

      <button @click="applyChanges">{{ $t(`message.apply`) }}</button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  inject: ["socket"],

  emits: ["close"],

  data() {
    return {
      delete_all_units: false,

      units: [],
      units_translated: [],
      selected_token: "",
      selected_home: "",
      selected_order: "",

      av_orders: [
        "none",
        "hide",
        "crusade_minus",
        "crusade_spec",
        "crusade_zero",
        "support",
        "support_spec",
        "raid",
        "raid_spec",
        "power",
        "power_spec",
        "defence",
        "defence_spec",
      ],
      av_deprecate_orders: ["crusade_spec", "defence", "raid", "support", "power"],

      av_units: ["pawn", "knite", "kata", "ship"],
      av_homes: ["none", "baratheon", "stark", "lannister", "tyrell", "martell", "greyjoy"],
    };
  },

  computed: {
    ...mapGetters("general_store", ["game", "lands", "home"]),
    ...mapState("general_store", ["data_for_modal"]),

    selected_land() {
      return this.data_for_modal.land_name;
    },

    landsNames() {
      return Object.keys(this.lands);
    },
  },

  created() {
    this.selected_token = this.get_selected_token(this.selected_land);
    this.selected_home = this.get_selected_home(this.selected_land);
    this.selected_order = this.get_selected_order(this.selected_land);
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal();
      this.setDataForModal({ reset: true });
    },

    applyChanges() {
      this.socket.emit("client_action", {
        action_type: "LandRedactorApply",
        data: {
          phase_name: "Redactor",
          data: {
            game_id: this.game.id,
            land_name: this.selected_land,
            home: this.selected_home,
            order: this.selected_order,
            token: this.selected_token,
            units: this.units,
            delete_all_units: this.delete_all_units,
          },
        },
      });
    },
    resetUnits() {
      this.units = [];
      this.units_translated = [];
    },

    addUnit(type) {
      this.units.push(type);
      this.units_translated.push(this.$t(`unit.${type}`));
    },

    getUnitCount(type) {
      const land_obj = this.lands[this.selected_land];
      const units = land_obj.units.filter((u) => u.type === type);
      return units.length;
    },
    getLand(land_name) {
      return this.lands[land_name];
    },
    get_selected_token(land_name) {
      const land_obj = this.getLand(land_name);
      if (!land_obj) return;
      return land_obj.token || "";
    },
    get_selected_order(land_name) {
      const land_obj = this.getLand(land_name);
      if (!land_obj) return;
      return land_obj.order || "";
    },
    get_selected_home(land_name) {
      const land_obj = this.getLand(land_name);
      if (!land_obj) return;
      return land_obj.home || "";
    },
  },
};
</script>
<style lang="scss" scoped>
.modal_base {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  pointer-events: all;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}

.redactor_land_container {
  position: absolute;
  padding: 1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  box-sizing: border-box;
  background-color: rgba(22, 22, 22);
  color: white;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border-radius: 0.4rem;
  -webkit-box-shadow: -1px -4px 97px 31px rgb(78, 6, 80);
  -moz-box-shadow: -1px -4px 97px 31px rgb(78, 6, 80);
  box-shadow: -1px -4px 97px 31px rgb(78, 6, 80);
  &::-webkit-scrollbar {
    width: 0;
  }
}

h3 {
  color: rgb(147, 221, 232);
  margin: 0;
  padding: 0;
}

@media (max-width: 900px) {
  .redactor_land_container {
    width: 85dvw;
    height: 90dvh;
  }
}

select {
  color: white;
  background-color: rgb(32, 30, 30);
}

.shop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
button {
  padding: 0.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: rgb(82, 82, 173);
  color: white;
  transition: all 0.35s;
  &:hover {
    background-color: rgb(9, 45, 116);
  }
}
.err {
  width: 100%;
  span {
    color: red;
  }
}
</style>
