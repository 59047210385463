<template>
  <div class="svg_wr">
    <TokensLayer :room="room" />
    <GarrisonLayer :room="room" />
    <ArrowLayer :room="room" />
    <UnitsLayer :room="room" :handler="handler" />
    <TracksLayer :room="room" :handler="handler" @toggle-wild-card="$emit('toggleWildCard')" />
    <OrdersLayer :room="room" :handler="handler" />
    <LandsLayer :room="room" />

    <AdminLayer :room="room" @toggle-redactor="toggleRedactor" />
  </div>
</template>

<script>
export default {
  props: {
    room: {
      type: Object,
    },
    handler: {
      type: Object,
    },
  },
  emits: ["toggleRedactor", "toggleWildCard"],
  methods: {
    toggleRedactor(land_name) {
      this.$emit("toggleRedactor", land_name);
    },
  },
};
</script>

<style lang="scss" scoped></style>
