<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @click="close">
    <div class="confirm_wr">
      <span class="title">{{ $t(`message.${type}`) }}</span>

      <button class="button" role="button" @click="confirm">{{ $t(`message.confirm_action`) }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  inject: ["socket"],

  computed: {
    ...mapGetters("general_store", ["user"]),
    ...mapState("general_store", ["data_for_modal"]),

    type() {
      return this.data_for_modal?.type;
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),

    close(e) {
      if (e.target.id !== "confirm_modal_wr") return;
      this.data_for_modal?.cb(false);
      this.setDataForModal({ reset: true });
      this.processModal({ name: "confirm_del", type: "close" });
    },
    confirm() {
      this.data_for_modal?.cb(true);
      this.setDataForModal({ reset: true });
      this.processModal({ name: "confirm_del", type: "close" });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm_modal_wr {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  pointer-events: all;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
}
.confirm_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  gap: 1rem;
  background-color: rgb(22, 22, 22);
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.4rem;
  -webkit-box-shadow: -1px -4px 97px 31px rgb(154, 13, 3);
  -moz-box-shadow: -1px -4px 97px 31px rgb(154, 13, 3);
  box-shadow: -1px -4px 97px 31px rgb(154, 13, 3);
}

@media (max-width: 500px) {
  .confirm_wr {
    width: 85dvw;
  }
}

.title {
  color: white;
  text-align: center;
  font-size: 1.3rem;
}

.button {
  background-color: #7c1d0e;
  border-radius: 0.4rem;
  color: rgb(243, 243, 243);
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 1rem;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-color: #c2422e;
  }
}
</style>
