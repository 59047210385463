import store from "../store/index.js";

export default class SelectCardHandler {
  constructor() {}

  initialInfoPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const phase_name = store.getters["general_store/phase_name"];
    const home = store.getters["general_store/home"];
    const game_tabs = store.state.gui_store.game_side_bar;
    let cards;

    if (!state) return;
    if (!active_players) return;
    if (phase_name !== "SelectCard") return;

    if (active_players.includes(home)) {
      if (!game_tabs.warActive) {
        store.commit("gui_store/setGameSideTab", { tab: "warActive" });
      }
    }
    const home_state = state.states.find((st) => st.home === home);
    if (home_state) {
      cards = home_state.cards;
    }
    if (!cards) return;
    const activeCards = cards.filter((_card) => _card.active === true);
    if (activeCards.length === 1) {
      const lastActiveCard = cards.find((_card) => _card.active);
      lastActiveCard.active = false;
    }
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }

  resetGui() {
    store.commit("game_store/selectCard", { reset: true });
  }

  clearGui() {
    this.resetGui();
  }

  prepareGui() {
    this.resetGui();
    this.initialInfoPreset();
    this.initialOrdersPreset();
  }

  cardClickHandler(e, card) {
    if (!card) return;
    const home = store.getters["general_store/home"];
    const active_players = store.getters["general_store/active_players"];
    if (card.home !== home) return;
    if (!active_players?.includes(home)) return;

    store.commit("game_store/selectCard", { title: card.title, home: card.home });
    store.commit("game_store/setExecTriger", { doit: true });
    store.commit("general_store/processModal");
    store.commit("gui_store/setGameSideTab", { tab: "warActive" });
  }
}
