<template>
  <div class="modal_base" @pointerdown="close">
    <div class="modal_container">
      <div class="award_title" :class="{ achieved: award_available === processed_user.id }">
        <span>{{ $t(`message.${award_key}_title`) }}</span>
        <span class="subtitle">{{ $t(`message.${award_key}_subtitle`) }}</span>
      </div>
      <div class="award_body">
        <div v-if="award_available === processed_user.id" class="award_info">
          <span>{{ processed_user?.name }}</span>
        </div>
        <div class="award_imgs_wr">
          <div class="award_img" :class="{ achieved: award_available === processed_user.id }">
            <img :src="`/images/awards/${award_key}.png`" :alt="award_key" />
          </div>

          <div v-if="award_available === processed_user.id" class="award_info">
            <img :src="`/images/avatars/${getAvatar(award_available)}`" alt="ava" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("general_store", ["mainRoom", "user"]),
    ...mapState("general_store", ["data_for_modal", "users"]),

    processed_user() {
      return this.data_for_modal?.processed_user;
    },
    award_key() {
      return this.data_for_modal.award_key;
    },
    award_available() {
      return this.mainRoom.main_stats.awards[this.award_key];
    },

    isTempUnitAdmin() {
      if (!this.processed_user) return false;
      return this.processed_user.role?.value.includes("admin");
    },
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),
    close() {
      clearTimeout(this.clear_dalay);
      this.clear_dalay = null;
      this.processModal({ name: "award_hint", type: "close" });
      this.setDataForModal({ reset: true });
    },
    getAvatar(uid) {
      const _user = this.users.find((u) => u.id === uid);
      return _user.avatars[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  position: relative;
  pointer-events: all;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.312);
  z-index: 2000;
  top: 0;
  left: 0;
}

.modal_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 1rem;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-height: 90dvh;
  max-width: 85dvh;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
}

.award_title {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  font-size: 1.5rem;
  color: rgb(231, 216, 2);
  filter: grayscale(100%);
  .subtitle {
    text-align: center;
    width: 100%;
    font-size: 1.2rem;
    color: rgb(226, 215, 215);
  }
}

.award_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  justify-content: center;
}

.award_imgs_wr {
  display: flex;
}

.award_img {
  filter: grayscale(100%);
  img {
    width: 100px;
    background-color: black;
  }
}

.achieved {
  filter: none;
}
.award_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100px;
  }
  span {
    font-size: 1.5rem;
    color: rgb(128, 234, 128);
  }
}
</style>
