<template>
  <div id="social_modal_wr" class="social_modal_wr" @click="close">
    <div class="wrap">
      <div class="title" :class="{ red: type == 'black_list' }">
        <h3>{{ $t(`message.${type}`) }}</h3>
      </div>
      <div v-if="!processed_user?.[type]?.length" class="empty">---</div>
      <div v-for="_user in user_objects" :key="_user.id" class="user_item">
        <div class="ava_wr">
          <div class="user-options-buttons">
            <span
              v-if="_user.id !== user.id && _user.online"
              class="material-icons"
              @click="openConfirmInvitationModal(_user)"
              @pointerenter="hint_label_hover($event, { msg: 'hint_invitation' })"
              @pointerleave="resetHint"
            >
              notification_important
            </span>

            <span
              v-if="_user.id !== user.id"
              class="material-icons"
              @click="openPrivateChatTab(_user.id)"
              @pointerenter="hint_label_hover($event, { msg: 'hint_send_private_message' })"
              @pointerleave="resetHint"
            >
              mail
            </span>
            <span
              v-if="_user.id !== user.id"
              class="material-icons"
              @click="showConfirmTransferMoneyModal(_user)"
              @pointerenter="hint_label_hover($event, { msg: 'hint_transfer_money' })"
              @pointerleave="resetHint"
            >
              paid
            </span>

            <span
              v-if="type === 'black_list' && showBlackList(_user)"
              class="material-icons"
              @pointerenter="hint_label_hover($event, { msg: 'hint_add_black_list' })"
              @pointerleave="resetHint"
              @click="processList(_user, 'black_list', 'add')"
            >
              do_not_disturb_on
            </span>
            <span
              v-if="type === 'black_list' && !showBlackList(_user)"
              class="material-icons"
              @pointerenter="hint_label_hover($event, { msg: 'hint_del_black_list' })"
              @pointerleave="resetHint"
              @click="processList(_user, 'black_list', 'del')"
            >
              do_not_disturb_off
            </span>
            <span
              v-if="type === 'my_friends' && showFriendsList(_user)"
              class="material-icons"
              @pointerenter="hint_label_hover($event, { msg: 'hint_add_friend' })"
              @pointerleave="resetHint"
              @click="processList(_user, 'my_friends', 'add')"
            >
              person_add
            </span>
            <span
              v-if="type === 'my_friends' && !showFriendsList(_user)"
              class="material-icons"
              @pointerenter="hint_label_hover($event, { msg: 'hint_del_friend' })"
              @pointerleave="resetHint"
              @click="processList(_user, 'my_friends', 'del')"
            >
              person_remove
            </span>
            <!-- <span class="material-icons del" @click="delUser(_user.id)"> delete </span> -->
          </div>
          <div class="img_wr">
            <div class="online_status" :class="{ offline: !_user.online }"></div>
            <img :src="`/images/avatars/${_user.avatar}`" alt="ava" />
          </div>
        </div>
        <span>{{ _user.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { genPrivateChatId } from "../../utils/utilsFrontend.js";
export default {
  inject: ["socket"],

  data() {
    return {
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
    };
  },

  computed: {
    ...mapState("general_store", ["data_for_modal", "users"]),
    ...mapGetters("general_store", ["mainRoom", "user", "main_private_chats"]),

    ...mapState({
      private_chat_tab_opened(state) {
        return state.gui_store.main_side_bar.privateChatActive;
      },
    }),

    processed_user() {
      return this.data_for_modal?.processed_user;
    },

    type() {
      return this.data_for_modal?.list_type;
    },

    user_objects() {
      if (!this.processed_user[this.type]?.length) return [];
      return this.processed_user[this.type].map((uid) => {
        return this.users.find((u) => uid === u.id);
      });
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel", "setMainSideTab"]),
    ...mapMutations("general_store", ["processModal", "setDataForModal", "selectPrivateChat", "setTempPrivateChat"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    close(e) {
      if (e.target.id !== "social_modal_wr") return;
      this.processModal({ name: "social", type: "close" });
    },

    closeParent() {
      this.processModal();
    },

    delUser(uid) {
      this.socket.emit("client_action", {
        action_type: "ProcessFriendBlackList",
        data: {
          user_id: this.processed_user.id,
          target_id: uid,
          type: "del",
          list_type: this.type,
        },
      });
    },

    openConfirmInvitationModal(u) {
      const data = {
        processed_user: u,
      };
      this.setDataForModal({ data });
      // this.processModal("social");
      this.processModal({ name: "invitation" });
    },

    showConfirmTransferMoneyModal(user) {
      const data = {
        processed_user: user,
      };
      // this.processModal("social");
      this.setDataForModal({ data });

      this.processModal({ name: "social", type: "close" });
      this.processModal({ name: "transfer" });
    },

    openPrivateChatTab(target_user_id) {
      const pr_chat = this.main_private_chats.find((ch) => ch.users.includes(target_user_id));
      let chat_id = pr_chat?.id;
      if (pr_chat?.id === this.user.id) return;
      if (!pr_chat) {
        chat_id = genPrivateChatId(target_user_id, this.user.id);
        this.setTempPrivateChat({ chat_id, room_id: "mainRoom", target_user_id });
      }
      this.selectPrivateChat({ chat_id, room_id: "mainRoom", target_user_id });

      if (!this.private_chat_tab_opened) {
        this.setMainSideTab({ tab: "privateChatActive" });
      }

      this.closeParent();
    },

    processList(user, list_type, flag) {
      this.socket.emit("client_action", {
        action_type: "ProcessFriendBlackList",
        data: {
          user_id: this.user.id,
          target_id: user.id,
          type: flag,
          list_type,
        },
      });
    },

    showBlackList(user) {
      if (this.user.id === user.id) return false;
      if (this.user.black_list?.includes(user.id)) {
        return false;
      }
      return true;
    },
    showFriendsList(user) {
      if (this.user.id === user.id) return false;
      if (this.user.my_friends?.includes(user.id)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.social_modal_wr {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 20;
  top: 0;
  left: 0;
  pointer-events: all;
}
.wrap {
  // z-index: 22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  // border: 4px solid rgb(255, 255, 255);
  border-radius: 0.4rem;
  width: auto;
  height: auto;
  max-height: 90dvh;
  max-width: 85dvw;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: rgb(22, 22, 22);
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
  -moz-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
  box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
}

.title {
  h3 {
    margin-top: 0;
    color: green;
  }
}

.red {
  h3 {
    color: RED;
  }
}

.user_item {
  width: 100%;
  height: auto;
  display: flex;
  color: white;
  gap: 1rem;
  align-items: center;

  .del {
    color: red;
    cursor: pointer;
  }
}

.ava_wr {
  display: flex;
  gap: 1rem;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_wr {
  position: relative;
  img {
    width: 3rem;
    background-color: black;
  }
}

.online_status {
  width: 0.8rem;
  height: 0.8rem;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
}
.offline {
  background-color: red;
}

.empty {
  color: white;
}

.user-options-buttons {
  // position: absolute;
  display: flex;
  gap: 0.1rem;
  bottom: 0px;
  left: 0px;
  span {
    color: rgb(203, 198, 198);
    font-size: 1.3rem;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}
.wrap::-webkit-scrollbar {
  width: 20px;
}

.wrap::-webkit-scrollbar-track {
  background: grey;
  border-left: 8px solid rgb(53, 53, 53);
  border-right: 9px solid rgb(53, 53, 53); /* color of the tracking area */
}

.wrap::-webkit-scrollbar-thumb {
  background: grey; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */

  border-left: 6px solid rgb(53, 53, 53);
  border-right: 7px solid rgb(53, 53, 53);
}
</style>
