export default {
  en: {
    message: {
      all_cards: "List of cards",
      westeros: "Westeros",
      set_for_next_round: "Apply Westeros for next round",
      reset_prediction: "Reset Westeros prediction",
      phase: "Phase",
      units: "Units",
      reset: "Reset",
      apply: "Apply",
      bid: "Bet",
      left: "Left",
      force: "Force",
      reset_preset: "Return to last preset",
      order: "Order",
      token: "Token",
      delete_avatar: "Remove image",
      delete_game: "Delete Game",
      delete_message: "Delete message",
      delete_all_messages: "Delete all messages",
      delete_private_chat: "Delete chat",
      confirm_action: "Confirm",
      pass_options: "length: 5-20, min 2 digits, no spaces",
      reset_password: "Reset password",
      length: "length",
      enter_email: "Enter email",
      create_username: "Create username",
      create_password: "Create password",
      invalid_password: "Invalid password",
      select_land: "Select Land",
      select_card: "Select this card",
      change_email: "Change email",
      change_password: "Change password",
      new_password: "Enter new password",
      repeat_password: "repeat new password",
      username_email: "Username or email",
      restore_password: "Restore password",
      log_in: "Log In",
      create_account: "Create Account",
      send_code: "Send Code",
      confirm: "Confirm",
      new_email: "Enter new Email",
      code: "Confirmation code",
      error: "Thing goes wrong!",
      code_was_sent: "Code was sent to: ",
      code_send_failed: "Code was NOT sent",
      has_new_email: "has new Email",
      email_apply_failed: "Email was NOT changed",
      user: "User",
      password_changed: "Password was successfully changed",
      password_changed_failed: "Password was not changed",
      password_settings: "Length: 5-20; min 2 digits; no spaces",

      main_sound: "Main theme",
      battle_sound: "Battle theme",
      message_sound: "Ptivate message",
      exec_sound: "Player finished his turn",
      timer_sound: "Your timer ticks",
      round_sound: "Begining of the new round",
      end_sound: "Game Over theme",

      LeaveGame: "Leave this Game",
      LeaveApp: "Logout",
      transfer: "Send Tokens",
      send_money: "Send money",
      cancel: "Cancel",
      total_sum: "Total count",
      target_sum: "Tokens count",
      got_it: "Got it",
      delete: "Delete",
      delete_user: "Delete User",
      close: "Close",
      logout: "Logout",
      open_door_room: "Leave room, but stay in game",
      return_to_room: "Return to room",

      chat: "Chat",
      private_chat: "Private messages",
      play: "Gaming",
      avatar: "Avatar",
      nickname: "Nickname",
      hint_admin: "General Admin",
      hint_admin_helper: "Admin helper",

      pay: "Pay",
      date: "Date",
      max_players: "Players count",
      start_round: "Start (round)",
      end_round: "End (round)",
      full: "From the beginning to the end",
      result: "Result",
      money_earned: "Gained tokens",
      game_duration: "Game duration",
      game_start: "Start to play",
      game_end: "Finish game",
      game_status: "Status",
      game_round: "Round",
      game_invite: "Invite",
      complaint: "Complain",
      complaints: "Complaints",
      complaint_sender: "Initiator",
      complaint_reason: "Reason",
      complaint_message: "Comment",

      money_log: "Money staticstic",
      pool_log: "General Money staticstic",
      money_total: "Total money",
      money_pool_history: "List of events",
      money_type: "Operation type",
      money_total_log: "Main chart",
      money_earn_log: "Earnings history",
      money_donate_log: "Donate history",
      money_send_log: "Remittance history",
      money_spend_log: "Spending history",
      money_target: "Recipient",
      money_sender: "Sender",
      spend_log: "Spend",
      donate_log: "Donate",
      send_private_message: "Private message",

      dollars_count: "Dollars",
      tokens_count: "Game Tokens",

      donate_msg: "Donate",
      donate: "Donated",
      donated: "Donated",
      earn: "Won",
      spend: "Spent",
      send: "Sent",
      drop: "Drop",
      comission: "Burned comission",
      income: "income",

      ban_show_btn: "Ban (info)",
      ban_update: "Update ban",
      ban_minutes: "min",
      ban_hours: "hours",
      ban_days: "days",
      ban: "Ban",
      ban_start: "Ban started",
      ban_duration: "Duration",
      ban_end: "Dan End",
      ban_del: "Delete Ban",
      ban_play: "Ban for play",
      ban_chat: "Ban for chat",
      ban_avatar: "Can`t add avatar",
      ban_nickname: "Can`t change username",
      ban_private_chat: "Can`t send private message",

      sessions: "Active sessions",
      games: "Active games",
      logout_all: "logout from all other sessions",
      delete_messages: "remove all messages",
      hide_messages: "hide all messages",
      removed_message: "removed message",

      reset_photo: "Cancel",
      upload_photo: "Upload",
      apply_photo: "Set as avatar",
      delete_photo: "Remove avatar",
      change_nickname: "Change nickname",
      buyin: "Buy In",
      select_home: "Select home",
      select_home_income: "Select home: cashback",
      del_ban: "Delete Ban",
      game_story_btn: "Games history",

      price: "cost: {data}",
      select_home_random: "Free: random home selection",
      cancel: "Cancel",
      hello: "hello world",
      players_rate: "Player Rating",
      online_checkbox: "only online",
      players_search: "Find player",
      bastard: "Bastard",
      lord: "Lord",
      admin: "Creator",
      experience: "Experience",
      overal_games: "Finished games",
      more_stats: "More",
      change_avatar: "Change Avatar",
      dignity: "Dignity",
      username: "Username",
      closed_mouths: "Closed mouths",
      friends: "Friends",
      registration: "Registration date",

      exec: "Make a move",
      throne: "Bidding for the Iron Throne",
      sword: "Bidding for the Valerian Sword",
      crow: "Bidding for the Messenger Raven",
      wildings: "Wildling Invasion with power {force}",
      dig_wild: "bury the card",
      not_dig_wild: "leave as is",
      UseSword: "Use",
      use_sword: "Use",
      not_use_sword: "Not Use",
      use_card_prop: "Apply property",
      not_use_card_prop: "Not Apply property",
      neitral: "Neutrality",
      support: "Support",
      battle_sum: "Outcome of the battle",

      gathering: "Looking for players",
      end: "Game is finished",
      playing: "Game Of Thrones",
      searching: "Looking for new player",
      gathering_short: "Gathering",
      // end: "Game is finished",
      // playing: "Game Of Thrones",
      searching_short: "Searching",

      max_players: "Number of players",
      game_type: "Type",
      game_name: "Game Title",
      password: "Password",
      intelligence: "Intelligence",
      honor: "Honor",
      charm: "Charm",
      timebank: "Timebank",
      time_to_move: "Extra time for turn",
      random_force: "Force of random cards",
      allow_random_cards: "Play with random cards",
      scull: "Play with sculls",
      join_password: "Enter password",
      join_password_btn: "Join",
      buy_in: "Buy In",
      buy_in_income: "Buy In: cashback",
      share_buy_in: "Sharing Buy In from leaved players",

      classic: "Classic game",
      time: "Created",
      bank: {
        buy_in: "Buy In",
      },
      join_game: "Join the Game",
      watch_game: "Watch the Game",
      new_game: "Create New Game",
      my_friends: "My friends",
      black_list: "Black list",

      Conception: `Conception Phase`,
      Wildings: `Wildings Invasion`,
      plural: "is | are",
      PermitSupport: "Permit Support",
      deny_support: "Deny Support",

      throne_decision_victory: "The Night Watch won\nChoose the bigest rate",
      throne_decision_loose: "The Wildings won\nChoose the lowest rate",
      lowest_rate: "Winlings win! Lowest rate",
      bigest_rate: "Night Watch win! Bigest rate",

      home: "House",
      castles: "Castles",
      castles_strongholds: "Castles and Strongholds",
      castles_extra: "Strongholds",
      supply_track: "Supply level",
      throne_track: "Throne position",
      allow_tokens: "Allowed Power Tokens",
      areas: "Conquered Lands",
      place: "Place",
      player: "Player",
      player_idx: "Active player idx",
      show_stat: "Game Results",
      center_map: "Center map",
      page_reload: "Reload Game",
      rotate_map: "Rotate Map",

      hint_friends: "Only friends",
      hint_complaint: "Complain",
      hint_online: "user is online",
      hint_offline: "user is offline",
      hint_hours_overal: "Total hours in game",
      hint_fullgames_overal: "Finished games from start to end",
      hint_fullgames_overal_short: "Full games",
      hint_games_overal: "Games played",
      hint_intelligence: "Intelligence: win rate (% from full finished games)",
      hint_honor: "Honor: % of finished games",
      hint_charm: "Charm: likes for the games",
      hint_money: "Quantity of game tokens",
      hint_more: "more information...",
      hint_award_count: "Awards count",
      hint_win_overal: "Total wins",
      hint_6_classic: "Classic game for 6 players",
      hint_5_classic: "Classic game for 5 players",
      hint_4_classic: "Classic game for 4 players",
      hint_3_classic: "Classic game for 3 players",

      hint_invitation: "Send invitation message",
      hint_transfer_money: "Send tokens",
      hint_add_black_list: "Add to black list",
      hint_del_black_list: "Romove from black list",
      hint_add_friend: "Add to fiend list",
      hint_del_friend: "Remove from friend list",
      hint_send_private_message: "Send private message",

      win_overal_title: "GOAT",
      win_overal_subtitle: "win count",
      intelligence_overal_title: "Genius",
      intelligence_overal_subtitle: "best intelligence rate",
      intelligence_stark_title: "Warden of the North",
      intelligence_stark_subtitle: "most victories for Stark house",
      intelligence_baratheon_title: "Rightful King",
      intelligence_baratheon_subtitle: "most victories for Baratheon house",
      intelligence_lannister_title: "Golden Lion",
      intelligence_lannister_subtitle: "most victories for Lannister house",
      intelligence_greyjoy_title: "Red Kraken",
      intelligence_greyjoy_subtitle: "most victories for Greyjoy house",
      intelligence_tyrell_title: "A King of the Vale",
      intelligence_tyrell_subtitle: "most victories for Tyrell house",
      intelligence_martell_title: "Prince of Dorne",
      intelligence_martell_subtitle: "most victories for Martell house",
      charm_title: "Fan favorites",
      charm_subtitle: "Fan favorites",
      full_games_count_title: "Experienced player",
      full_games_count_subtitle: "most full games finished",
      full_games_percent_title: "Most reliable player",
      full_games_percent_subtitle: "lowest rate of leaved games",
      total_hours_count_title: "Hidden leader",
      total_hours_count_subtitle: "most hours in game",
      saved_games_count_title: "Bigest Heart",
      saved_games_count_subtitle: "most saved games",
      money_count_title: "Crypto Trader",
      money_count_subtitle: "most game tokens",
      donate_count_title: "Responsible Player",
      donate_count_subtitle: "most donates",
      earned_count_title: "Machine",
      earned_count_subtitle: "most earned game tokens",
    },
  },
  uk: {
    message: {
      all_cards: "Подивитися карти",
      westeros: "Вестерос",
      set_for_next_round: "Задіяти Вестерос для наступного раунду",
      reset_prediction: "Відмінити майбутній Вестерос",
      phase: "Фаза гри",
      units: "Юніти",
      reset: "Відмінити",
      apply: "Застосувати",
      bid: "Ставка",
      left: "Залишок",
      force: "Сила",
      reset_preset: "Завантажити останній пресет",
      order: "Наказ",
      token: "Токен",
      delete_game: "Видалити гру",
      delete_message: "Видалити повідомлення",
      delete_avatar: "Видалити аватар",
      confirm_action: "Підтвердити",
      delete_all_messages: "Видалити всі повідомлення",
      delete_private_chat: "Видалити чат",
      pass_options: "Довжина: 5-20, мінімум 2 цифри, без пробілів",
      reset_password: "Скинути пароль",
      length: "Довжина",
      create_username: "Створіть нікнейм",
      create_password: "Створіть пароль",
      invalid_password: "Невірний пароль",
      select_land: "Обери ціль",
      select_card: "Вибрати цю карту",
      user: "Юзер",
      error: "Виник горбиль",
      change_email: "Змінити email",
      change_password: "Змінити пароль",
      new_password: "Введіть новий пароль",
      repeat_password: "Повторіть новий пароль",
      username_email: "Нікнейм або email",
      password: "Пароль",
      restore_password: "Відновити пароль",
      log_in: "Увійти",
      create_account: "Створити акаунт",
      send_code: "Надіслати код",
      confirm: "Підтвердити",
      new_email: "Новий Email",
      enter_email: "Введіть email",
      code: "Код підтвердження",
      code_was_sent: "Код відправлено на: ",
      code_send_failed: "Код не надіслано",
      has_new_email: "має новий Email",
      email_apply_failed: "Email не було змінено",
      password_changed: "Пароль успішно змінено",
      password_changed_failed: "Пароль не було змінено",
      password_settings: "Довжина: 5-20; мін. 2 цифри; без пробілів",

      main_sound: "Головна тема",
      battle_sound: "Тема бою",
      message_sound: "Приватне повідомлення",
      exec_sound: "Звук заверешення ходу",
      timer_sound: "Звук таймеру",
      round_sound: "Звук початку нового раунду",
      end_sound: "Тема закінчення гри",

      LeaveGame: "Вийти з гри",
      open_door_room: "Вийти, але залишитися у грі",
      return_to_room: "Повернутися у гру",
      logout: "Повністю вийти",
      LeaveApp: "Повністю вийти",
      transfer: "Відправити токени",
      send_money: "Відправити",
      cancel: "Відмінити",
      total_sum: "Загальна сума",
      target_sum: "Кількість токенів",
      got_it: "Зрозуміло",
      delete: "Видалити",
      delete_user: "Видалити гравця",
      close: "Закрити",
      complaint: "Поскаржитись",
      complaints: "Скарги",
      complaint_sender: "Ініціатор",
      complaint_reason: "Причина",
      complaint_message: "Коментар",

      chat: "Чат",
      private_chat: "Приватні повідомлення",
      play: "Гра",
      avatar: "Аватар",
      nickname: "Нікнейм",
      hint_admin: "Головний Адмін",
      hint_admin_helper: "Помічник Адміна",

      pay: "Оплатити",
      date: "Дата",
      max_players: "Кількість гравців",
      start_round: "Початок (раунд)",
      end_round: "Кінець (раунд)",
      full: "Від початку і до кінця",
      result: "Результат",
      money_earned: "Виграно токенів",
      game_duration: "Тривалість гри",
      game_start: "Початок гри",
      game_end: "Кінець гри",
      game_status: "Статус",
      game_round: "Раунд",
      game_invite: "Запросити",

      money_log: "Витрати та поповнення",
      pool_log: "Загальні витрати та донати",
      money_total_log: "Графік витрат",
      money_pool_history: "Список операцій",
      money_earn_log: "Історія виграшів",
      money_donate_log: "Історія донатів",
      money_send_log: "Історія трансферів",
      money_spend_log: "Історія витрат",
      money_total: "Всього токенів",
      dollars_count: "Доллари",
      tokens_count: "Токени",
      money_type: "Тип витрат",
      money_target: "Отримувач",
      money_sender: "Відправник",
      spend_log: "Витрати",
      donate_log: "Донат",
      send_private_message: "Приватне повідомлення",

      donate_msg: "Купити токени",
      donate: "Донат",
      donated: "Донат",
      earn: "Виграш",
      spend: "Витрати",
      send: "Трансфер",
      drop: "Дроп",
      comission: "Комісія",
      income: "Надходження",

      ban_update: "Оновити інформацію про бан",
      ban_show_btn: "Бан (інфо)",
      ban_minutes: "хв.",
      ban_hours: "год.",
      ban_days: "дн.",
      ban: "Бан",
      ban_start: "Початок бану",
      ban_duration: "Тривалість",
      ban_end: "Кінець бану",
      ban_del: "Вийти з бану",
      ban_play: "Бан на гру",
      ban_chat: "Бан у чаті",
      ban_avatar: "Додати новий аватар",
      ban_nickname: "Змінити нікнейм",
      ban_private_chat: "Бан на приватні повідомлення",
      my_friends: "Мої друзі",
      black_list: "Чорний список",

      sessions: "Активні сесії",
      games: "Активні ігри",

      logout_all: "завершити усі активні сесіЇ, крім поточної",
      delete_messages: "Видалити всі повідомлення",
      hide_messages: "Сховати/показати всі повідомлення",
      removed_message: "видалив повідомлення",

      reset_photo: "Відмінити вибір",
      upload_photo: "Додати аватар",
      apply_photo: "Зробити аватаром",
      delete_photo: "Видалити аватар",
      change_nickname: "Змінити нікнейм",
      buyin: "Внесок за гру",
      select_home: "Вибір дому",
      select_home_income: "Вибір дому: повернення",
      del_ban: "Вийти з бану",
      game_story_btn: "Історія ігор",

      price: "вартість: {data}",
      select_home_random: "Безкоштовно: рандомний вибір дому",

      players_rate: "Рейтинг Гравців",
      online_checkbox: "тільки онлайн",
      players_search: "Пошук гравців",
      bastard: "Сирота",
      lord: "Лорд",
      admin: "Творець",
      experience: "Досвід",
      overal_games: "Зіграно ігор",
      more_stats: "Ще",
      change_avatar: "Змінити Аватар",
      dignity: "Титул",
      username: "Нікнейм",
      closed_mouths: "Закриті роти",
      friends: "Друзі",
      registration: "Дата реєстрації",

      exec: "Зробити хід",
      throne: "Торги за Залізний Трон",
      sword: "Торги за Валерійський Меч",
      crow: "Торги за Посильного Ворона",
      wildings: "Нашестя дикунів з силою {force}",
      dig_wild: "закопати",
      not_dig_wild: "залишити",
      UseSword: "Задіяти Меч",
      use_sword: "Задіяти Меч",
      not_use_sword: "Ні",
      use_card_prop: "Задіяти властивість",
      not_use_card_prop: "Нічого не робити",
      neitral: "Нейтралітет",
      support: "Піддтримка",
      battle_sum: "Підсумок битви",

      gathering: "Збираємо гравців",
      end: "Гра заврешена",
      playing: "Гра Престолів триває",
      searching: "Шукаємо гравця на заміну",
      gathering_short: "Збір",
      // end: "Game is finished",
      // playing: "Game Of Thrones",
      searching_short: "Пошук",

      PermitSupport: "Запросити Підтримку",
      deny_support: "Заборонити Підтримку",

      game_type: "Тип",
      max_players: "Кількість гравців",
      game_name: "Назва",

      intelligence: "Розум",
      honor: "Честь",
      charm: "Шарм",
      timebank: "Запас часу",
      time_to_move: "Додатковий час на хід",
      random_force: "Сила карт переваг",
      allow_random_cards: "Грати з картами переваг",
      scull: "Грати з черепами",
      join_password: "Введіть пароль",
      join_password_btn: "Ввести",
      buy_in: "Внесок за гру",
      buy_in_income: "Внесок за гру: повернення",
      share_buy_in: "Розподіл внесків від тих, хто ливнув",

      classic: "Классична гра",
      time: "Час створення",
      join_game: "Приєднатися до гри",
      watch_game: "Подивитися на гру",

      bank: {
        buy_in: "Внесок для гри",
      },
      new_game: "Створити гру",

      plural: "готовий | готові",

      Conception: `Фаза Замислів`,
      Wildings: `Нашестя дикунів`,

      throne_decision_victory: "Нічна Варта перемогла\nОбери найвищу ставку",
      throne_decision_loose: "Дикуни перемогли\nОбери найнижчу ставку",
      lowest_rate: "Перемога дикунів! Найнижча ставка",
      bigest_rate: "Перемога нічного дозору! Найвища ставка",

      home: "Дім",
      castles: "Фортеці",
      castles_strongholds: "Фортеці та замки",
      castles_extra: "Замки",
      supply_track: "Рівень постачання",
      throne_track: "Позиція на Троні",
      allow_tokens: "Доступні токени",
      areas: "Захоплені землі",
      place: "Місце",
      player: "Гравець",
      player_idx: "Індекс активного гравця",
      show_stat: "Результати гри",
      center_map: "Центрувати карту",
      page_reload: "Перезавантажити гру",
      rotate_map: "Повернути карту",

      hint_friends: "Лише друзі",
      hint_complaint: "Поскаржитись",
      hint_online: "гравець зараз онлайн",
      hint_offline: "гравець зараз оффлайн",
      hint_hours_overal: "Зіграно годин",
      hint_fullgames_overal: "Зіграно повних ігор, від початку до кінця",
      hint_fullgames_overal_short: "Зіграно повних ігор",
      hint_games_overal: "Загальна кількість ігор",
      hint_intelligence: "Інтеллект: відсоток перемог (від загальної колькості повних ігор)",
      hint_honor: "Честь: відсоток повних ігор",
      hint_charm: "Шарм: вподобайки за гарну гру",
      hint_money: "Кількість ігрових монет",
      hint_more: "більше інформації...",
      hint_award_count: "Кількість нагород",
      hint_win_overal: "Кількість перемог",
      hint_6_classic: "Классична гра на 6 гравців",
      hint_5_classic: "Классична гра на 5 гравців",
      hint_4_classic: "Классична гра на 4 гравців",
      hint_3_classic: "Классична гра на 3 гравців",

      hint_invitation: "Відправити запрошення",
      hint_transfer_money: "Надіслати токени",
      hint_add_black_list: "Додати у чорний список",
      hint_del_black_list: "Видалити з чорного списку",
      hint_add_friend: "Додати до списку друзів",
      hint_del_friend: "Видалити зі списку друзів",
      hint_send_private_message: "Відправити приватне повідомлення",

      win_overal_title: "Чемпіон усіх часів",
      win_overal_subtitle: "загальна кількість перемог",
      intelligence_overal_title: "Геній",
      intelligence_overal_subtitle: "найвищий інтеллект",
      intelligence_stark_title: "Король півночі",
      intelligence_stark_subtitle: "найбільше перемог за дім Старків",
      intelligence_baratheon_title: "Справжній Король",
      intelligence_baratheon_subtitle: "найбільше перемог за дім Баратеонів",
      intelligence_lannister_title: "Золотий Лев",
      intelligence_lannister_subtitle: "найбільше перемог за дім Ланністерів",
      intelligence_greyjoy_title: "Червоний Кракен",
      intelligence_greyjoy_subtitle: "найбільше перемог за дім Грейджоїв",
      intelligence_tyrell_title: "Король Долини",
      intelligence_tyrell_subtitle: "найбільше перемог за дім Тіреллів",
      intelligence_martell_title: "Принц Дорну",
      intelligence_martell_subtitle: "найбільше перемог за дім Мартеллів",
      charm_title: "Місцевий краш",
      charm_subtitle: "Місцевий краш",

      full_games_count_title: "Найдосвідченіший гравець",
      full_games_count_subtitle: "найбільше заврешених повних ігор",
      full_games_percent_title: "Сталева надійність",
      full_games_percent_subtitle: "найбільший відсоток завершених ігор",
      total_hours_count_title: "Тіньовий лідер",
      total_hours_count_subtitle: "найбільше годин у грі",
      saved_games_count_title: "Велике Серце",
      saved_games_count_subtitle: "найбільше врятованих ігор",
      money_count_title: "Криптотрейдер",
      money_count_subtitle: "найбагатший гравець",
      donate_count_title: "Свідомий Гравець",
      donate_count_subtitle: "найбільше донатів",
      earned_count_title: "Машина",
      earned_count_subtitle: "найбільша кількість здобутих токенів",
    },
  },
};
