import store from "../store/index.js";

export default class WarOfKingsHandler {
  constructor() {}

  resetGui() {
    store.commit("general_store/processModal");
  }
  prepareGui() {
    this.resetGui();
    this.initialTracksPreset();
  }

  initialTracksPreset() {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];

    if (phase_name !== "WarOfKings") return;
    if (!active_players.includes(home)) {
      this.resetGui();
      return;
    }
    const current_track = state.current_track_bid;

    store.commit("general_store/processModal", { name: "bid" });
    store.commit("game_store/setBidTrack", { home: home, bid: 0, trackName: current_track });
  }
}
