const womens = [
  "Asha",
  "Cersei",
  "Catelyn",
  "Obara",
  "Olenna",
  "Brienne",
  "Margaery",
  "Arianne",
  "Nymeria",
  "Melisandre",
];

export default class GameLogger {
  constructor({ t, root }) {
    this.t = t;
    this.root = root;
    this.locale = root.locale;
  }

  get_case(case_type, key) {
    const locale = this.root.locale;
    switch (locale) {
      case "uk": {
        return `${case_type}_${key}`;
      }
      default: {
        return `${key}`;
      }
    }
  }

  generateLog(log_item) {
    if (!log_item || !log_item?.type) return false;

    switch (log_item.type) {
      case "active_players": {
        return this.genIntroData(log_item);
      }
      case "intro": {
        return this.genIntroData(log_item);
      }
      case "exec": {
        return this.genExecData(log_item);
      }
      case "info": {
        return this.genInfoData(log_item);
      }
      default: {
        return false;
      }
    }
  }

  ganerateExtraData(phase_name, data) {
    switch (phase_name) {
      case "Power": {
        return { label_img: `PowerSpec` };
      }
      case "FirstCardAction": {
        const { label_img, home } = data;
        return { label_img, home, alt_phaseName: "home_card" };
      }
      case "LastCardAction": {
        const { label_img, home } = data;
        return { label_img, home, alt_phaseName: "home_card" };
      }
      case "WarOfKings": {
        const { trackName } = data;
        return { trackName };
      }

      default: {
        return {};
      }
    }
  }

  genIntroData({ phase_name, data, id, type, time }) {
    const { active_players = [], home = "", trackName = "", card, land_name = "", force = 0 } = data;

    switch (phase_name) {
      case "GameOver": {
        const main_msg = this.t("exec.GameOver");

        const msg = main_msg;

        return { phase_name, type: "exec", id, time, msg };
      }

      case "Wildings": {
        const plural = this.t("plur.ready", active_players.length);
        const active_players_ = active_players.map((home) => this.t(`home.${home}`)).join(", ");
        const phaseName_ = this.t(`phase.${phase_name}`);

        const force_msg = this.t("init.Wildings", {
          power: data?.force,
          active_players: active_players_,
        });
        const main_msg = this.t("init.Default", { active_players: active_players_, plural, phase_name: phaseName_ });

        const msg = main_msg + "\n" + force_msg;

        return { phase_name, type, id, time, msg };
      }
      case "WarOfKings": {
        const plural = this.t("plur.ready", active_players.length);
        const active_players_ = active_players.map((home) => this.t(`home.${home}`)).join(", ");
        const track_key = this.get_case("accusative", "track_name");
        const _trackName = this.t(`${track_key}.${trackName}`);
        const msg = this.t("init.WarOfKings", {
          trackName: _trackName,
          active_players: active_players_,
          plural,
        });
        return { phase_name, type, id, time, msg };
      }
      case "Retreat": {
        const plural = this.t("plur.ready", active_players.length);
        const home = this.t(`home.${active_players[0]}`);
        const land_name_key = this.get_case("genetive", "land");
        const _land_name = this.t(`${land_name_key}.${land_name}`);
        const _card = this.t(`home_card.${card.title}`);

        const gender = womens.includes(card?.title) ? this.t(`part.ready_fem`) : this.t(`part.ready_male`);
        const msg = this.t("init.Retreat", {
          card: _card,
          home,
          plural,
          gender,
          land_name: _land_name,
        });
        return { phase_name, type, id, time, msg, home: active_players[0], label_img: card.title };
      }

      default: {
        const active_players_ = active_players.map((home) => this.t(`home.${home}`)).join(", ");
        const plural = this.t("plur.ready", active_players.length);
        const extraData = this.ganerateExtraData(phase_name, data);
        let key = "phase";
        let phs_name = phase_name;

        if (extraData.alt_phaseName) {
          key = extraData.alt_phaseName || key;
          phs_name = extraData.label_img || phase_name;
        }
        let single_home = home;
        if (active_players.length === 1) {
          single_home = active_players[0];
        }

        key = this.get_case("genetive", key);

        const phaseName_ = this.t(`${key}.${phs_name}`);
        const msg = this.t("init.Default", { active_players: active_players_, phase_name: phaseName_, plural });
        return { phase_name, type, id, msg, time, ...extraData, home: single_home };
      }
    }
  }

  genInfoData({ time, id, land_name, type, home, sub_type, action, label_img, data }) {
    switch (action) {
      case "GameOver": {
        let msg_base = this.t(`exec.${action}`);

        let _str = "";

        data.sorted_homes.forEach((home, idx) => {
          _str += `\n${idx + 1}. ${this.t(`home.${data.sorted_homes[idx]}`)}`;
        });

        return { type, id, msg: msg_base + _str, time, label_img: action };
      }
      case "StartGame": {
        let msg = this.t(`exec.${action}`);
        return { type, id, msg, time, label_img: action };
      }
      case "SetWesteros": {
        const { westeros_0, westeros_1, westeros_2 } = data;
        const w_0 = this.t(`phase.${westeros_0}`);
        const w_1 = this.t(`phase.${westeros_1}`);
        const w_2 = this.t(`phase.${westeros_2}`);

        let msg = this.t(`exec.${action}`, [w_0, w_1, w_2]);
        return { type, id, msg, time, label_img: action };
      }
      case "StartWar": {
        const { land_name, agressor, victim } = data;

        const land_key = this.get_case("accusative", "land");
        const home_key = this.get_case("ablative", "home");
        const _land_name = this.t(`${land_key}.${land_name}`);
        const _agressor = this.t(`home.${agressor}`);
        const _victim = this.t(`${home_key}.${victim}`);

        let msg = this.t(`exec.${action}`, [_land_name, _agressor, _victim]);
        return { type, id, msg, time, label_img: action };
      }

      case "Winter": {
        const { nextPhase } = data;
        const _nextPhase = this.t(`phase.${nextPhase}`);
        let msg = this.t(`exec.${action}`, { phase: _nextPhase });
        return { type, id, msg, time, label_img: action };
      }
      case "Round": {
        const { round } = data;
        const _round = this.t(`plur.${round}`);
        let msg = this.t(`exec.${action}`, { round: _round });
        return { type, id, msg, time, label_img: action };
      }
      case "ResetCards": {
        const { home } = data;
        const _home = this.t(`home.${home}`);
        let msg = this.t(`exec.ResetCards`, { home: _home });
        return { type, id, msg, time, label_img: "ResetCards" };
      }
      case "Supply": {
        const { homes } = data;
        let _homes = "";
        homes.forEach((s) => {
          const { home, points } = s;
          const _home = this.t(`home.${home}`);
          _homes += `${_home}: ${points}\n`;
        });
        let msg = this.t(`exec.${action}Change`, { homes: _homes });
        return { type, id, msg, time, label_img };
      }
      case "RemoveLand": {
        const _home = this.t(`home.${home}`);
        const key = this.get_case("ablative", "land");

        const _land_name = this.t(`${key}.${land_name}`);

        const msg = this.t(`part.${action}`, { home: _home, land_name: _land_name });

        return { type, sub_type, id, msg, time, home, label_img };
      }
      case "AddLand": {
        const _home = this.t(`home.${home}`);
        const key = this.get_case("accusative", "land");
        const _land_name = this.t(`${key}.${land_name}`);
        const msg = this.t(`part.${action}`, { home: _home, land_name: _land_name });

        return { type, sub_type, id, msg, time, home, label_img };
      }
      case "Power": {
        let msg = `${this.t(`phase.${action}`)}\n`;
        if (!data.lands || !data.lands.length) return;
        data.lands.forEach((l) => {
          const home = l[0];
          l[1].forEach((_l) => {
            const _land_name = this.t(`land.${_l.land_name}`);
            const _home = this.t(`home.${home}`);
            const plur = this.t(`plur.token`, _l.tokens);
            msg += this.t(`part.${action}`, { land_name: _land_name, tokens: _l.tokens, home: _home, plur });
          });
        });

        return { type, id, msg, time, label_img };
      }
      case "GameOfThrones": {
        let msg = `${this.t(`phase.${action}`)}\n`;
        for (let home in data.lands) {
          const arr_lands = data.lands[home];
          arr_lands.forEach((l) => {
            const { tokens, land_name } = l;
            const _home = this.t(`home.${home}`);
            const _land_name = this.t(`land.${land_name}`);
            const plur = this.t(`plur.token`, tokens);
            msg += this.t(`part.Power`, { land_name: _land_name, tokens: tokens, home: _home, plur });
          });
        }

        return { type, id, msg, time, label_img, phase_name: action };
      }
      default: {
        return { type, label_img, sub_type, id, msg: `NO LOG FOR THIS ACTON: ${action}`, time, home };
      }
    }
  }

  genExecData(opt) {
    const { phase_name, data, type, id, time } = opt;

    if (!data) {
      return { phase_name, type, msg: `NO DATA IN LOG FROM EXEC` };
    }
    switch (phase_name) {
      case "PreAttackConflictPort": {
        const { home, deleted_units = [], captured_units = [], supply = false, land_name = "" } = data;
        const _home = this.t(`home.${home}`);

        const del_obj = {};
        const cap_obj = {};
        let del_msg = "";
        let cap_msg = "";
        let msg = "";

        deleted_units.forEach((unit) => {
          const title = this.t(`unit.${unit.type}`);
          if (!del_obj[land_name]) {
            del_obj[land_name] = [title];
            return;
          }
          del_obj[land_name].push(title);
        });

        captured_units.forEach((unit) => {
          const title = this.t(`unit.${unit.type}`);
          if (!cap_obj[land_name]) {
            cap_obj[land_name] = [title];
            return;
          }
          cap_obj[land_name].push(title);
        });
        for (let ob in cap_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${cap_obj[ob].join(", ")}\n`;
          cap_msg += str;
        }
        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }
        if (supply) {
          if (!deleted_units.length) return;
          msg = this.t(`exec.${phase_name}_auto`, { home: _home, del_msg });
        } else {
          msg = this.t(`exec.${phase_name}`, { home: _home, del_msg, cap_msg });
        }
        return { phase_name, home, msg, id, time };
      }
      case "ConflictPort": {
        const { home, deleted_units = [], captured_units = [], supply = false, land_name = "" } = data;
        const _home = this.t(`home.${home}`);

        const del_obj = {};
        const cap_obj = {};
        let del_msg = "";
        let cap_msg = "";
        let msg = "";

        deleted_units.forEach((unit) => {
          const title = this.t(`unit.${unit.type}`);
          if (!del_obj[land_name]) {
            del_obj[land_name] = [title];
            return;
          }
          del_obj[land_name].push(title);
        });

        captured_units.forEach((unit) => {
          const title = this.t(`unit.${unit.type}`);
          if (!cap_obj[land_name]) {
            cap_obj[land_name] = [title];
            return;
          }
          cap_obj[land_name].push(title);
        });
        for (let ob in cap_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${cap_obj[ob].join(", ")}\n`;
          cap_msg += str;
        }
        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }
        if (supply) {
          if (!deleted_units.length) return;
          msg = this.t(`exec.${phase_name}_auto`, { home: _home, del_msg });
        } else {
          msg = this.t(`exec.${phase_name}`, { home: _home, del_msg, cap_msg });
        }
        return { phase_name, home, msg, id, time };
      }

      case "Skinchanger": {
        const { count, home, victory } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${phase_name}`);

        let msg = "";

        if (victory) {
          msg = this.t(`exec.${phase_name}_victory`, { home: _home, count, card: _card });
        }
        if (!victory) {
          msg = this.t(`exec.${phase_name}_loose`, { home: _home, count, card: _card });
        }

        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "MassingMilkwater": {
        const { cardNames = [], home, victory } = data;
        const _home = this.t(`home.${home}`);
        const card = this.t(`wild_card.${phase_name}`);

        const card_key = this.get_case("accusative", "home_card");

        const _cardNames = cardNames.map((title) => this.t(`${card_key}.${title}`)).join(", ");

        let msg = "";

        if (victory) {
          return;
        }
        if (!victory) {
          msg = this.t(`exec.${phase_name}_loose`, { home: _home, cardNames: _cardNames, card });
        }

        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "KingBeyondTheWall": {
        const { trackName, home, victory } = data;
        const _home = this.t(`home.${home}`);
        const card = this.t(`wild_card.${phase_name}`);

        const track_key = this.get_case("genetive", "track_name");
        const _track = this.t(`${track_key}.${trackName}`);

        let msg = "";

        if (trackName === "all") {
          msg = this.t(`exec.${phase_name}_lowest`, { home: _home, card });
        }
        if (victory) {
          msg = this.t(`exec.${phase_name}_victory`, { home: _home, track: _track, card });
        }
        if (!victory && trackName !== "all") {
          msg = this.t(`exec.${phase_name}_loose`, { home: _home, track: _track, card });
        }

        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "MammothRiders": {
        const { deleted_units = [], card_target = "", home, card, victory = false } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${phase_name}`);
        const card_target_key = this.get_case("genetive", "home_card");
        const _target_card = this.t(`${card_target_key}.${card_target}`);
        const del_obj = {};
        let del_msg = "";
        deleted_units.forEach((kn) => {
          if (!kn) return;
          const title = this.t(`unit.${kn.type}`);
          if (!del_obj[kn.land_name]) {
            del_obj[kn.land_name] = [title];
            return;
          }
          del_obj[kn.land_name].push(title);
        });
        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }

        let msg = "";
        if (victory) {
          if (!card_target) {
            msg = this.t(`exec.${phase_name}_victory_skip`, { home: _home, card: _card });
          } else {
            msg = this.t(`exec.${phase_name}_victory`, { home: _home, card: _card, card_target: _target_card });
          }
        } else {
          msg = this.t(`exec.${phase_name}`, { home: _home, del_msg, card: _card });
        }
        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "Horde": {
        const { created_units = [], deleted_units = [], home, card, victory = false } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${phase_name}`);

        const del_obj = {};
        const cr_obj = {};
        let del_msg = "";
        let cr_msg = "";

        deleted_units.forEach((kn) => {
          if (!kn) return;
          const title = this.t(`unit.${kn.type}`);
          if (!del_obj[kn.land_name]) {
            del_obj[kn.land_name] = [title];
            return;
          }
          del_obj[kn.land_name].push(title);
        });
        created_units.forEach((kn) => {
          const title = this.t(`unit.${kn.type}`);
          const land_name = kn.land_name || kn.land_name;
          if (!cr_obj[land_name]) {
            cr_obj[land_name] = [title];
            return;
          }
          cr_obj[land_name].push(title);
        });

        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }
        for (let ob in cr_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${cr_obj[ob].join(", ")}\n`;
          cr_msg += str;
        }
        let msg = "";
        if (victory) {
          msg = this.t(`exec.${phase_name}_victory`, { home: _home, del_msg, cr_msg, card: _card });
        } else {
          msg = this.t(`exec.${phase_name}`, { home: _home, del_msg, cr_msg, card: _card });
        }
        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "PreemptiveRaid": {
        const { deleted_units = [], track, home, card, victory = false } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${phase_name}`);
        if (victory) {
          let msg = this.t(`exec.${phase_name}_victory`, { home: _home, card: _card });
          return { type, id, msg, time, home, label_img: "WildingManual" };
        }
        if (track) {
          const track_key = this.get_case("genetive", "track_name");
          const _track = this.t(`${track_key}.${track}`);
          let msg = this.t(`exec.${phase_name}_track`, { home: _home, track: _track, card: _card });
          return { type, id, msg, time, home, label_img: "WildingManual" };
        }
        const del_obj = {};
        let del_msg = "";
        deleted_units.forEach((kn) => {
          const title = this.t(`unit.${kn.type}`);
          if (!del_obj[kn.land_name]) {
            del_obj[kn.land_name] = [title];
            return;
          }
          del_obj[kn.land_name].push(title);
        });
        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }
        let msg = this.t(`exec.${phase_name}`, { home: _home, del_msg, card: _card });
        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "CrowKillers": {
        const { downgraded_knites = [], deleted_knites = [], upgraded_pawns = [], home, card } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${phase_name}`);

        const down_obj = {};
        const del_obj = {};
        const upd_obj = {};

        upgraded_pawns.forEach((kn) => {
          const title = this.t(`unit.pawn`);
          if (!upd_obj[kn.land_name]) {
            upd_obj[kn.land_name] = [title];
            return;
          }
          upd_obj[kn.land_name].push(title);
        });
        downgraded_knites.forEach((kn) => {
          const title = this.t(`unit.knite`);
          if (!down_obj[kn.land_name]) {
            down_obj[kn.land_name] = [title];
            return;
          }
          down_obj[kn.land_name].push(title);
        });
        deleted_knites.forEach((kn) => {
          const title = this.t(`unit.knite`);
          if (!del_obj[kn.land_name]) {
            del_obj[kn.land_name] = [title];
            return;
          }
          del_obj[kn.land_name].push(title);
        });

        let down_msg = "";
        let del_msg = "";
        let upd_msg = "";
        for (let ob in upd_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${upd_obj[ob].join(", ")}\n`;
          upd_msg += str;
        }
        for (let ob in down_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${down_obj[ob].join(", ")}\n`;
          down_msg += str;
        }
        for (let ob in del_obj) {
          const _land_name = this.t(`land.${ob}`);
          const str = `${_land_name}--> ${del_obj[ob].join(", ")}\n`;
          del_msg += str;
        }

        let msg = this.t(`exec.${phase_name}`, { home: _home, down_msg, del_msg, upd_msg, card: _card });
        return { type, id, msg, time, home, label_img: "WildingManual" };
      }
      case "PutToTheSword": {
        const { nextPhase, home } = data;
        const _home = this.t(`home.${home}`);
        const order = nextPhase === "StormOfSwords" ? "defence" : nextPhase === "AutumnRains" ? "crusade_spec" : false;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const key = order ? "PutToTheSword" : `PutToTheSword_skip`;
        const msg = this.t(`exec.${key}`, { home: _home, order: _dis_order });
        return { phase_name, type, home, msg, id, time };
      }
      case "StormOfSwords": {
        const { order } = data;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const msg = this.t(`exec.StormOfSwords`, { order: _dis_order });
        return { type, msg, id, time, phase_name: "StormOfSwords" };
      }
      case "AutumnRains": {
        const { order } = data;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const msg = this.t(`exec.AutumnRains`, { order: _dis_order });
        return { type, msg, id, time, phase_name: "AutumnRains" };
      }
      case "FeastOfCrows": {
        const { order } = data;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const msg = this.t(`exec.FeastOfCrows`, { order: _dis_order });
        return { type, msg, id, time, phase_name: "FeastOfCrows" };
      }
      case "SeaOfStorms": {
        const { order } = data;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const msg = this.t(`exec.SeaOfStorms`, { order: _dis_order });
        return { type, msg, id, time, phase_name: "SeaOfStorms" };
      }
      case "WebOfLies": {
        const { order } = data;
        const order_key = this.get_case("genetive", "order");
        const _dis_order = this.t(`${order_key}.${order}`);
        const msg = this.t(`exec.WebOfLies`, { order: _dis_order });
        return { type, msg, id, time, phase_name: "WebOfLies" };
      }
      case "ThroneOfBlades": {
        const { nextPhase, home } = data;
        const _home = this.t(`home.${home}`);
        // const _nextPhase = this.t(`phase.${nextPhase}`);
        const phase_key = this.get_case("accusative", "phase");
        const _nextPhase = this.t(`${phase_key}.${nextPhase}`);

        const key = nextPhase ? "WesterosDecision" : "WesterosDecision_skip";
        const msg = this.t(`exec.${key}`, { home: _home, phase: _nextPhase });
        return { phase_name, type, home, msg, id, time };
      }
      case "BlackWings": {
        const { nextPhase, home } = data;
        const _home = this.t(`home.${home}`);
        const phase_key = this.get_case("accusative", "phase");
        const _nextPhase = this.t(`${phase_key}.${nextPhase}`);
        const key = nextPhase ? "WesterosDecision" : "WesterosDecision_skip";
        const msg = this.t(`exec.${key}`, { home: _home, phase: _nextPhase });
        return { phase_name, type, home, msg, id, time };
      }
      case "Wildings": {
        const { home, is_victory, card, force, sum, lowest_candidates, bigest_candidates, bids } = data;
        const _home = this.t(`home.${home}`);
        let msg = this.t(`exec.Bet`, { home: _home });
        if (card) {
          const _card = this.t(`wild_card.${card}`);

          const home_key = this.get_case("genetive", "home");

          const _l_cands = lowest_candidates
            .reduce((arr, h) => {
              const _home = this.t(`${home_key}.${h}`);
              arr.push(_home);
              return arr;
            }, [])
            .join(", ");
          const _b_cands = bigest_candidates
            .reduce((arr, h) => {
              const _home = this.t(`${home_key}.${h}`);
              arr.push(_home);
              return arr;
            }, [])
            .join(", ");

          let bid_msg = ``;

          for (let home in bids) {
            const bid = bids[home];
            const _h = this.t(`home.${home}`);
            const str = `${_h}: ${bid}\n`;
            bid_msg += str;
          }

          if (is_victory) {
            msg = this.t(`exec.WildingsEnd_victory`, {
              force,
              power: sum,
              lowest_candidates: _l_cands,
              bigest_candidates: _b_cands,
              card: _card,
              bid_msg,
            });
          } else {
            msg = this.t(`exec.WildingsEnd_loose`, {
              force,
              power: sum,
              lowest_candidates: _l_cands,
              bigest_candidates: _b_cands,
              card: _card,
              bid_msg,
            });
          }
        }
        return { phase_name, type, home, msg, id, time };
      }
      case "ThroneDecision": {
        const { home, choice } = data;
        const _home = this.t(`home.${home}`);

        const home_key = this.get_case("genetive", "home");
        const _choice = this.t(`${home_key}.${choice}`);
        const msg = this.t(`exec.${phase_name}`, { home: _home, choice: _choice });
        return { phase_name, home, msg, id, time };
      }
      case "WildingManual": {
        const { home, card } = data;
        const _home = this.t(`home.${home}`);
        const _card = this.t(`wild_card.${card}`);
        const msg = this.t(`exec.${phase_name}`, { home: _home, card: _card });
        return { phase_name, home, msg, id, time };
      }
      case "Supply": {
        const { home, units } = data;
        const dest_lands = Array.from(
          units.reduce((set, u) => {
            if (!u.land_name) return set;
            set.add(u.land_name);
            return set;
          }, new Set())
        );

        let str = ``;

        dest_lands.forEach((l) => {
          const _units = units.filter((u) => u.land_name === l);
          const _land_name = this.t(`land.${l}`);
          const units_msg = _units
            .reduce((arr, u) => {
              arr.push(this.t(`unit.${u.type}`));
              return arr;
            }, [])
            .join(", ");
          str += `${_land_name}: ${units_msg} \n`;
        });

        const _home = this.t(`home.${home}`);

        const msg = this.t(`exec.${phase_name}`, { home: _home, str });

        return { phase_name, home, msg, id, time };
      }

      case "DenySupport": {
        const { home, permission } = data;
        const _home = this.t(`home.${home}`);
        const key = permission ? "PermitSupport" : "DenySupport";
        let msg = this.t(`exec.${key}`, { home: _home });
        return { phase_name, home, msg, id, time };
      }

      case "SelectCard": {
        const { home } = data;
        const _home = this.t(`home.${home}`);

        let msg = this.t(`exec.${phase_name}`, { home: _home });
        return { phase_name, home, msg, id, time };
      }

      case "Mastering": {
        const { home, source_land_name, units } = data;
        if (!units) return;

        const dest_lands = Array.from(
          units.reduce((set, u) => {
            if (!u.land_name) return set;
            set.add(u.land_name);
            return set;
          }, new Set())
        );

        let str = ``;

        dest_lands.forEach((l) => {
          const _units = units.filter((u) => u.land_name === l);
          const _land_name = this.t(`land.${l}`);
          const units_msg = _units
            .reduce((arr, u) => {
              arr.push(this.t(`unit.${u.type}`));
              return arr;
            }, [])
            .join(", ");
          str += `${_land_name}-> ${units_msg} \n`;
        });
        const home_key = this.get_case("locative", "home");
        const _home = this.t(`home.${home}`);
        let msg = this.t(`exec.${phase_name}`, {
          home: _home,
          str,
          source_land_name: this.t(`land.${source_land_name}`),
        });
        if (!units.length) {
          msg = this.t(`exec.${phase_name}_skip`, {
            home: _home,
            source_land_name: this.t(`${home_key}.${source_land_name}`),
          });
        }

        return { phase_name, home, msg, id, time };
      }
      case "Conception": {
        const { home } = data;
        const _home = this.t(`home.${home}`);
        const msg = this.t(`exec.${phase_name}`, { home: _home });
        return { phase_name, home, msg, id, time };
      }
      case "CrowCheck": {
        const { home, land_name, order = "", oldOrder = "", dig = false } = data;

        const flag = land_name ? "change_order" : dig ? "dig_card" : "check_card";
        const _fl = flag === "dig_card" ? this.t("message.dig_wild") : this.t("message.not_dig_wild");
        const land_key = this.get_case("locative", "land");
        const oldorder_key = this.get_case("genetive", "order");
        const order_key = this.get_case("accusative", "order");
        const _land_name = this.t(`${land_key}.${land_name}`);
        const _order = this.t(`${order_key}.${order}`);
        const _oldOrder = this.t(`${oldorder_key}.${oldOrder}`);
        const _home = this.t(`home.${home}`);
        let msg =
          flag === "change_order"
            ? this.t(`exec.${phase_name}_order`, {
                home: _home,
                land_name: _land_name,
                order: _order,
                oldOrder: _oldOrder,
              })
            : this.t(`exec.${phase_name}_dig`, {
                home: _home,
                land_name: _land_name,
                flag: _fl,
              });

        return { phase_name, home, msg, sub_type: flag, id, time };
      }
      case "Raid": {
        const { home, land_name, targetLand, target_land_obj, target_order } = data;

        const order_is_power = target_land_obj?.order?.includes("power");

        const _home = this.t(`home.${home}`);
        const _target_home = this.t(`home.${target_land_obj?.home}`);
        const target_land_key = this.get_case("accusative", "land");
        const _land_name = this.t(`${target_land_key}.${targetLand}`);

        const order_key = this.get_case("genetive", "order");
        const _target_order = this.t(`${order_key}.${target_order}`);

        const land_key = this.get_case("genetive", "land");
        const _source_land_name = this.t(`${land_key}.${land_name}`);

        const raid_type = targetLand && order_is_power ? "power" : targetLand && !order_is_power ? "standart" : "empty";

        const msg = this.t(`exec.${phase_name}_${raid_type}`, {
          home: _home,
          targetLand: _land_name,
          target_home: _target_home,
          target_order: _target_order,
          source_land_name: _source_land_name,
        });

        return { phase_name, home, msg, id, time };
      }
      case "Crusade": {
        let { home, source_land_name, units, token, victim, label_img = "", conflict_land = "" } = data;
        let msg = "";
        const token_key = this.get_case("locative", "land");
        const token_land = this.t(`${token_key}.${source_land_name}`);

        let token_msg = token ? this.t("part.LeftToken", { land_name: token_land }) : ``;

        if (!units?.length) {
          const source_key = this.get_case("genetive", "land");
          msg = this.t("part.RemoveOrder", {
            home: this.t(`home.${home}`),
            source_land_name: this.t(`${source_key}.${source_land_name}`),
          });
          return { phase_name, home, msg, sub_type: "cancel", id, time };
        } else {
          const neutral_lord = victim === "neutral" ? victim : "";
          const enemy_lord = victim;
          const enemy_key = this.get_case("ablative", "home");
          const enemy_home = this.t(`${enemy_key}.${enemy_lord}`);
          const target_key = this.get_case("accusative", "land");
          const _land_name = this.t(`${token_key}.${units[0].land_name}`);
          const conf_land = this.t(`${target_key}.${conflict_land}`);
          const war_start_msg = victim && victim !== "neutral" ? this.t("exec.StartWar", [enemy_home, conf_land]) : "";
          const neutral_lord_msg = neutral_lord ? this.t("exec.NeutralLordDefeated", { land_name: _land_name }) : "";
          const source_key = this.get_case("genetive", "land");
          const tab = token ? "\n" : "";
          const tab_2 = neutral_lord ? "\n" : "";
          const tab_3 = victim ? "\n" : "";
          if (neutral_lord) {
            label_img = "NeutralLordDefeated";
          }
          let dest_msg = Array.from(
            units.reduce((arr, u) => {
              arr.add(`${this.t(`${target_key}.${u.land_name}`)}`);
              return arr;
            }, new Set())
          ).join(", ");

          dest_msg += tab;
          dest_msg += token_msg;
          dest_msg += tab_2;
          dest_msg += neutral_lord_msg;
          dest_msg += tab_3;
          dest_msg += war_start_msg;

          msg = this.t(`exec.${phase_name}`, {
            home: this.t(`home.${home}`),
            source_land_name: this.t(`${source_key}.${source_land_name}`),
            dest_msg,
            enemy_home,
            conf_land,
          });
        }
        return { phase_name, home, msg, id, time, label_img };
      }
      case "DefineSupport": {
        const { home, choice } = data;
        const _home = this.t(`home.${home}`);

        const choice_key = this.get_case("genetive", "home");
        let _choice = this.t(`${choice_key}.${choice}`);
        let key = "DefineSupport";
        if (!choice) {
          key = `${phase_name}_skip`;
          _choice = this.t(`home.nobody`);
        }
        const msg = this.t(`exec.${key}`, { home: _home, choice: _choice });
        return { phase_name, home, id, time, msg };
      }
      case "UseSword": {
        const { home, force, change_random_card } = data;
        const _home = this.t(`home.${home}`);

        const key = force ? "UseSword" : change_random_card ? `${phase_name}_random` : `${phase_name}_skip`;

        const msg = this.t(`exec.${key}`, { home: _home, force });
        return { phase_name, home, id, time, msg };
      }

      case "FirstCardAction": {
        const { card = {}, payload = {} } = data;
        const { home, title, extra_power } = card;

        const { land_name, prop, track } = payload;
        let apply = payload.apply;
        let pref = apply ? "_apply" : "_deny";
        // if (apply === undefined) {
        //   pref = "";
        // }
        const card_key = this.get_case("genetive", "home_card");
        const victim_card_title = this.t(`${card_key}.${payload?.card?.title}`);
        const victim_home_key = this.get_case("genetive", "home");
        // const victim_home_key = this.t(`message.${home}`);
        const victim_home = this.t(`${victim_home_key}.${payload?.home}`);
        // const victim_home = this.t(`home.${payload?.home}`);
        const land_key = this.get_case("locative", "land");
        const _targetLand = this.t(`${land_key}.${land_name}`);
        const _home = this.t(`home.${home}`);
        const _prop = this.t(`prop.${prop}`);
        const track_key = this.get_case("genetive", "track_name");
        const _track = this.t(`${track_key}.${track}`);
        let main_key = `${title}`;

        if (title === "Tyrion" || title === "Aeron") {
          main_key = `${title}${pref}`;
        }

        const myCardName = this.t(`home_card.${title}`);

        const msg = this.t(`exec.${main_key}`, {
          victim_card_title,
          home: _home,
          land_name: _targetLand,
          target_home: victim_home,
          extra_power,
          prop: _prop,
          myCardName,
          track: _track,
        });

        return { phase_name, home, msg, id, time, label_img: title };
      }
      case "LastCardAction": {
        const { card = {}, payload = {} } = data;
        let { home, title } = card;
        let label_img = title;
        const { source_land_name, units, land_name, track } = payload;

        const card_key = this.get_case("genetive", "home_card");

        const victim_card_title = this.t(`${card_key}.${payload?.card?.title}`);
        let victim_home = this.t(`home.${payload?.home}`);
        const myCardName = this.t(`home_card.${title}`);
        let victim_land = this.t(`land.${units?.[0]?.land_name}`);
        const _source = this.t(`land.${source_land_name}`);
        const _home = this.t(`home.${home}`);

        let land_key = this.get_case("locative", "land");
        if (title === "Arianne") {
          land_key = this.get_case("accusative", "land");
        }
        let _land_name = this.t(`${land_key}.${land_name}`);
        if (source_land_name) {
          _land_name = this.t(`land.${source_land_name}`);
        }
        const _track = this.t(`track_name.${track}`);

        let key = title;

        if (!units?.length && title === "Robb") {
          // const land_key = this.get_case("locative", "land");
          //  _land_name = this.t(`land.${land_name}`);
          key = "Robb_del";
        }
        if (units?.length && title === "Robb") {
          const home_key = this.get_case("genetive", "home");
          const land_key = this.get_case("accusative", "land");
          victim_home = this.t(`${home_key}.${payload?.units[0].owner}`);
          _land_name = this.t(`${land_key}.${payload?.units[0].land_name}`);
          key = "Robb";
        }
        if (!land_name && title === "Cersei") {
          key = "Cersei_skip";
        }
        if (title === "Patchface" && !payload?.card?.title) {
          key = `${title}_skip`;
        }
        if (title === "Renly" && !payload.land_name) {
          key = `${title}_skip`;
        }

        const msg = this.t(`exec.${key}`, {
          victim_card_title,
          home: _home,
          target_home: victim_home,
          victim_land,
          source: _source,
          land_name: _land_name,
          track: _track,
          myCardName,
        });

        return { phase_name, home, msg, id, time, label_img };
      }
      case "Retreat": {
        const { home, units, source_land_name, card } = data;

        let destination = units?.[0]?.land_name;
        const _home = this.t(`home.${home}`);

        if (!destination) {
          destination = "";
        }

        const womens = [
          "Asha",
          "Cersei",
          "Catelyn",
          "Obara",
          "Olenna",
          "Brienne",
          "Margaery",
          "Arianne",
          "Nymeria",
          "Melisandre",
        ];

        const source_key = this.get_case("locative", "land");
        const dest_key = this.get_case("accusative", "land");
        const _land = this.t(`${dest_key}.${destination}`);

        const _source = this.t(`${source_key}.${source_land_name}`);

        const _card = this.t(`home_card.${card?.title}`);
        const _enemy_home = this.t(`home.${card?.home}`);
        const key = destination ? "Retreat" : `Retreat_del`;

        const kill_gender = womens.includes(card?.title) ? this.t(`part.kill_fem`) : this.t(`part.kill_male`);
        const retreat_gender = womens.includes(card?.title) ? this.t(`part.retreat_fem`) : this.t(`part.retreat_male`);

        const msg = this.t(`exec.${key}`, {
          home: _home,
          destination: _land,
          source: _source,
          card: _card,
          enemy_home: _enemy_home,
          kill_gender,
          retreat_gender,
        });

        return { phase_name, home: card?.home || home, msg, id, time, label_img: card?.title };
      }

      case "KillUnits": {
        const { home, deleted_units, land_name, card } = data;
        const womens = [
          "Asha",
          "Cersei",
          "Catelyn",
          "Obara",
          "Olenna",
          "Brienne",
          "Margaery",
          "Arianne",
          "Nymeria",
          "Melisandre",
        ];
        const unit = deleted_units[0];
        const unit_plur = deleted_units.length > 1 ? "unit_plur" : "unit";

        const _home_key = this.get_case("accusative", "home");
        const _land_key = this.get_case("locative", "land");
        const _unit_key = this.get_case("accusative", unit_plur);

        const _card = this.t(`home_card.${card}`);
        const _land_name = this.t(`${_land_key}.${land_name}`);
        const _unit = this.t(`${_unit_key}.${unit}`);
        const _home = this.t(`${_home_key}.${home}`);
        const kill_gender = womens.includes(card) ? this.t(`part.kill_fem`) : this.t(`part.kill_male`);
        const count_prefix = deleted_units.length > 1 ? "-х" : "";

        const msg = this.t("exec.KillUnits", {
          home: _home,
          card: _card,
          land_name: _land_name,
          unit: _unit,
          kill_gender,
          count: deleted_units.length,
          count_prefix,
        });

        return { phase_name, home, msg, id, time };
      }
      case "KillUnits_scull": {
        const { home, deleted_unit, land_name, card } = data;
        const womens = [
          "Asha",
          "Cersei",
          "Catelyn",
          "Obara",
          "Olenna",
          "Brienne",
          "Margaery",
          "Arianne",
          "Nymeria",
          "Melisandre",
        ];

        const _home_key = this.get_case("accusative", "home");
        const _land_key = this.get_case("locative", "land");
        const _unit_key = this.get_case("accusative", "unit");

        const _card = this.t(`home_card.${card}`);
        const _land_name = this.t(`${_land_key}.${land_name}`);
        const _unit = this.t(`${_unit_key}.${deleted_unit}`);
        const _home = this.t(`${_home_key}.${home}`);
        const kill_gender = womens.includes(card) ? this.t(`part.kill_fem`) : this.t(`part.kill_male`);

        const msg = this.t(`exec.${phase_name}`, {
          home: _home,
          card: _card,
          land_name: _land_name,
          unit: _unit,
          kill_gender,
        });

        return { phase_name, home, msg, id, time };
      }
      case "ManualDelUnits": {
        const { home, units } = data;
        if (!units.length) return;

        const target_land = units[0].land_name;

        let str = ``;

        const _land_name = this.t(`land.${target_land}`);
        const units_msg = units
          .reduce((arr, u) => {
            arr.push(this.t(`unit.${u.type}`));
            return arr;
          }, [])
          .join(", ");
        str += `${_land_name}: ${units_msg}`;

        const _home = this.t(`home.${home}`);

        const msg = this.t(`exec.${phase_name}`, { home: _home, str });

        return { phase_name, home, msg, id, time };
      }

      case "Power": {
        const { home, source_land_name, units, label_img } = data;
        let msg = "";
        const _home = this.t(`home.${home}`);
        const _land_name = this.t(`land.${source_land_name}`);

        if (!units.length) {
          const land_key = this.get_case("genetive", "land");
          const _land_name = this.t(`${land_key}.${source_land_name}`);
          msg = this.t(`exec.Power_token`, { home: _home, land_name: _land_name });
          return { phase_name, home, msg, id, time };
        }

        const dest_lands = Array.from(
          units.reduce((set, u) => {
            if (!u.land_name) return set;
            set.add(u.land_name);
            return set;
          }, new Set())
        );

        let str = ``;

        dest_lands.forEach((l) => {
          const _units = units.filter((u) => {
            return u.land_name === l;
          });

          const units_msg = _units
            .reduce((arr, u) => {
              arr.push(this.t(`unit.${u.type}`));
              return arr;
            }, [])
            .join(", ");
          str += `${this.t(`land.${l}`)}-> ${units_msg} \n`;
        });

        msg = this.t(`exec.Mastering`, { home: _home, source_land_name: _land_name, str });

        if (!units.length) {
          const land_key = this.get_case("genetive", "land");
          const _land_name = this.t(`${land_key}.${source_land_name}`);
          msg = this.t(`exec.Mastering_skip`, { home: _home, source_land_name: _land_name });
        }

        return { msg, home, id, time, label_img };
      }
      case "KingChoice": {
        const { trackName, sortedTrack, home } = data;
        const _home = this.t(`home.${home}`);
        const track_key = this.get_case("accusative", "track_name");
        const _trackName = this.t(`${track_key}.${trackName}`);

        return {
          phase_name,
          home,
          id,
          time,
          msg: this.t(`exec.${phase_name}`, { home: _home, trackName: _trackName }),
        };
      }
      case "WarOfKings": {
        const { home, bid, trackName } = data;
        const _home = this.t(`home.${home}`);
        const track_key = this.get_case("accusative", "track_name");
        const _trackName = this.t(`${track_key}.${trackName}`);

        return {
          phase_name,
          home,
          id,
          time,
          msg: this.t(`exec.${phase_name}`, { home: _home, bid, trackName: _trackName }),
        };
      }
    }
  }
}
