<template>
  <div id="modal_base" class="modal_base" @pointerdown="closeModal">
    <div class="modal_wr">
      <form @submit.prevent>
        <div class="legacy_form">
          <label for="pass-field"
            ><strong>{{ $t("message.new_password") }}</strong></label
          >
          <br />
          <input
            id="pass-field"
            v-model="password"
            required
            type="password"
            autocomplete="new-password"
            min="5"
            max="20"
            :placeholder="$t('message.password_settings')"
            @input="inputFn"
          />
          <input
            id="pass-conf-field"
            v-model="password_confirm"
            required
            type="password"
            autocomplete="new-password"
            min="5"
            max="20"
            :placeholder="$t('message.repeat_password')"
            @input="inputFn"
          />
          <div class="btn" :class="{ enable_btn: creationEnable }" @pointerdown="changePassword">
            {{ $t("message.change_password") }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import passwordValidator from "password-validator";
import { mapMutations, mapGetters } from "vuex";

export default {
  setup() {
    const schema = new passwordValidator().is().min(5).is().max(20).has().digits(2).has().not().spaces();
    return {
      schema,
    };
  },
  data() {
    return {
      response: null,
      creationEnable: false,
      password: "",
      password_confirm: "",
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user"]),
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "showError"]),
    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.resetForm();
      this.response = null;
      this.processModal({ name: "new_password", type: "close" });
    },
    resetForm() {
      this.password = "";
      this.password_confirm = "";
      this.creationEnable = false;
    },

    inputFn() {
      if (this.password !== this.password_confirm) {
        this.creationEnable = false;
        return;
      }

      this.creationEnable = true;
    },

    async changePassword(e) {
      if (!e.target.classList.contains("enable_btn")) return;

      const passIsInvalid = this.schema.validate(this.password, { details: true });
      let res;
      if (passIsInvalid.length) {
        res = passIsInvalid.reduce((str, msg) => {
          str = str + msg.message + "\n";
          return str;
        }, "");
        alert(res);
        return;
      }

      this.response = await this.axios
        .post("/app/reset_password", {
          password: this.password,
          user_id: this.user.id,
          email: this.user.email.value,
        })
        .catch((err) => {
          this.showError({
            error: { code: "000", message: err.message },
          });
        });

      this.resetForm();
      this.processModal({
        name: "new_password",
        type: "close",
      });
      this.showError({
        error: this.response.data.error,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  pointer-events: all;
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 21;
  top: 0;
  left: 0;
}

.modal_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 80dvw;
  max-height: 80dvh;
  color: white;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  -moz-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
}

.status {
  text-align: center;
}

.response_string {
  user-select: text;
}

form {
  max-width: 100%;
}

.legacy_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  label {
    font-size: 1.2rem;
    text-align: center;
  }
  input {
    margin-bottom: 2rem;
    height: auto;
    width: auto;
    max-width: 80%;
    background-color: rgb(134, 184, 185);
    color: rgb(22, 67, 68);
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 0.4rem;
  }
  input::placeholder {
    font-size: 0.6rem;
    white-space: pre-line;
    text-align: center;
    color: black;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    white-space: pre-line;
    position: relative;
    top: -7px;
  }
  .btn {
    width: auto;
    color: rgb(143, 145, 145);
    background-color: rgb(105, 112, 115);
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: not-allowed;
  }

  .enable_btn {
    cursor: pointer;
    color: rgb(213 255 254);
    background-color: rgb(87 124 140);
  }
}
</style>
