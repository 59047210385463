<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="war-wr">
    <div class="main_body">
      <div class="gap_for_logger"></div>
      <div v-if="crusade_state?.conflict_land || crusade_state?.victim?.home" class="top_side">
        <div class="war_title">
          <span>{{
            crusade_state?.conflict_land
              ? $t(`land.${crusade_state?.conflict_land}`)
              : $t(`land.${crusade_state?.agressor.units[0].land_name}`)
          }}</span>
        </div>
        <div class="cards">
          <div v-if="active_players.includes(home) && phase_name === 'UseSword' && !used_sword" class="sword-btns">
            <div class="sword_btn" @click="swordhandler(true)">{{ $t("message.use_sword") }}</div>
            <div class="sword_btn" @click="swordhandler(false)">{{ $t("message.not_use_sword") }}</div>
          </div>
          <div
            class="war_card_wr"
            :class="{
              looser_shadow: crusade_state.looser === 'victim',
              winner_shadow: crusade_state.winner === 'victim',
              animateActiveCard: animateActiveCard(crusade_state?.victim?.home),
            }"
          >
            <div v-if="crusade_state.victim?.home && showCardBtns(crusade_state.victim)" class="card_btns">
              <div @click="cardPropertyPlay(crusade_state.victim)">{{ $t("message.use_card_prop") }}</div>
              <div @click="$emit('exec')">{{ $t("message.not_use_card_prop") }}</div>
            </div>
            <img
              v-if="crusade_state.victim?.home"
              :src="cardFaceVic"
              alt="victimCard"
              :data-home="crusade_state.victim.home"
              @click="setGameSideTab({ tab: 'infoActive' })"
            />
          </div>
          <div class="sword">
            <div class="sword_img_wr">
              <img src="/images/Sword_.png" alt="sword" :class="{ used_sword, sword_active }" />
            </div>
          </div>
          <div
            class="war_card_wr"
            :class="{
              looser_shadow: crusade_state.looser === 'agressor',
              winner_shadow: crusade_state.winner === 'agressor',
              animateActiveCard: animateActiveCard(crusade_state?.agressor?.home),
            }"
          >
            <div v-if="crusade_state.agressor?.home && showCardBtns(crusade_state.agressor)" class="card_btns">
              <div @click="cardPropertyPlay(crusade_state.agressor)">{{ $t("message.use_card_prop") }}</div>
              <div @click="$emit('exec')">{{ $t("message.not_use_card_prop") }}</div>
            </div>
            <img
              v-if="crusade_state.agressor?.home"
              :src="cardFaceAgr"
              :data-home="crusade_state.agressor.home"
              alt="agressorCard"
              @click="setGameSideTab({ tab: 'infoActive' })"
            />
          </div>
        </div>
      </div>

      <div v-if="showPermitBtns" class="buttons_permit">
        <div class="per_btn permit_btn_wr" :data-permission="1" @click="permit_support_handler">
          {{ $t("message.PermitSupport") }}
        </div>

        <div class="per_btn deny_btn_wr" :data-permission="0" @click="permit_support_handler">
          {{ $t("message.deny_support") }}
        </div>
      </div>

      <div v-if="showSupBtns" class="buttons_sup">
        <div v-if="showSupBtn(crusade_state.victim)" class="sup_btn_wr">
          <img
            src="/images/orders/support.png"
            :data-home="crusade_state.victim.home"
            alt="support"
            :class="{ disableSupBtns }"
            @mouseover="supHover"
            @mouseleave="supHover"
            @click="supportHandler"
          />
        </div>

        <div
          v-if="showNeitralBtn"
          class="sup_btn_wr neitral_btn"
          :class="{ disableSupBtns }"
          data-home="neitral"
          @click="supportHandler"
          @mouseover="supHover"
          @mouseleave="supHover"
        >
          {{ $t("message.neitral") }}
        </div>
        <div v-if="showSupBtn(crusade_state.agressor)" class="sup_btn_wr">
          <img
            src="/images/orders/support.png"
            :data-home="crusade_state.agressor.home"
            alt="support"
            :class="{ disableSupBtns }"
            @mouseover="supHover"
            @mouseleave="supHover"
            @click="supportHandler"
          />
        </div>
      </div>

      <div v-if="randomCardShow" class="random_cards_wrap">
        <div class="rand_card_wr">
          <img
            :src="getRandomCardSrc('victim')"
            alt="random_card"
            :class="{ rand_card_hint: rand_card_hint('victim') }"
            @click="swordhandler(false, 'victim')"
          />
        </div>
        <div class="rand_card_wr">
          <img
            :src="getRandomCardSrc('agressor')"
            alt="random_card"
            :class="{ rand_card_hint: rand_card_hint('agressor') }"
            @click="swordhandler(true, 'agressor')"
          />
        </div>
      </div>

      <div v-if="crusade_state?.agressor?.home" class="sup_result_wrap">
        <div class="main_overal">
          <div v-for="obj in [crusade_state?.victim, crusade_state?.agressor]" :key="obj" class="overal">
            <div v-if="obj?.garrison" class="garrison_wrap">
              <img src="/images/garrison.png" alt="" />
              <span class="garrison_force"> {{ obj.garrison }}</span>
            </div>
            <div v-if="crusade_state?.victim.card?.title && crusade_state?.agressor.card?.title" class="extra_power_wr">
              <span class="extra_power_span"
                >={{ (obj.card?.extra_power ? obj.card?.extra_power : 0) + +obj.card?.power }}
              </span>
              <img :src="`/images/cards/${obj?.home}/${locale}/${obj.card?.title}.png`" :alt="obj.order" />
            </div>

            <div v-if="obj?.random_card.id" class="extra_power_wr">
              <span v-if="randomCardSpan(obj.title)" class="extra_power_span">={{ obj.random_card.force }} </span>
              <img :src="getRandomCardSrc(obj.title)" :alt="obj.title" />
            </div>

            <div class="sword_container">
              <img v-if="sword?.force && sword?.home === obj.home" src="/images/Sword_.png" alt="sword" />
            </div>
            <div v-if="showOrderInStat(obj)" class="order_wr_stat">
              <img :src="`/images/orders/${normalizeOrderName(obj.order)}.png`" :alt="obj.order" />
            </div>
            <div class="units_container">
              <div v-for="unit in obj.units" :key="unit.type" class="unit_wr_stat">
                <img
                  :class="{ retreated: unit.retreated, highlight: obj.home === 'greyjoy' }"
                  :src="`/images/units/${obj.home}/new/${unit.type}_${obj.home}.png`"
                  :alt="unit.type"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="sup_overal">
          <div class="sup_side">
            <div v-for="supObj in crusade_state?.victim.get_support_from" :key="supObj" class="sup_icon_wr">
              <img :key="supObj.home" :src="`/images/tokens/${supObj.home}_token.png`" :alt="supObj.home" />
              <span class="sup_label">{{ supObj.overal }}</span>
            </div>
          </div>
          <img src="/images/orders/support.png" alt="support" class="sup_sparator" />
          <div class="sup_side">
            <div v-for="supObj in crusade_state?.agressor.get_support_from" :key="supObj" class="sup_icon_wr">
              <img :key="supObj.home" :src="`/images/tokens/${supObj.home}_token.png`" :alt="supObj.home" />
              <span class="sup_label">{{ supObj.overal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="crusade_state?.agressor?.home" class="total_points">
      <span>{{ $t("message.battle_sum") }}</span>
      <div class="total_points_inner">
        <div v-for="obj in [crusade_state?.victim, crusade_state?.agressor]" :key="obj" class="total_side">
          <span>{{ obj.overal }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  emits: ["exec"],

  computed: {
    ...mapGetters("general_store", ["crusade_state", "phase_name", "active_players", "user", "game", "home"]),

    ...mapState("game_store", ["selected_card"]),
    ...mapState("gui_store", ["game_side_bar"]),

    locale() {
      return this.user.locale;
    },
    sword_active() {
      return this.phase_name === "UseSword";
    },

    randomCardShow() {
      const random_in_options = this.game?.game_options?.allow_random_cards;
      const crusadeActive = this.crusade_state?.agressor.home;
      if (random_in_options && crusadeActive) {
        return true;
      }
      return false;
    },

    used_sword() {
      return this.game?.state.sword_was_used;
    },

    sword() {
      return this.crusade_state?.sword;
    },
    showSupBtns() {
      return this.phase_name === "DefineSupport";
    },
    showPermitBtns() {
      return this.phase_name === "PermitSupport" && this.active_players.includes(this.home);
    },
    disableSupBtns() {
      return !this.active_players.some((home) => home === this.home);
    },
    cardFaceAgr() {
      let faceSrc;
      const role = this.crusade_state?.agressor;
      const tempCardName = this.selected_card?.home === role.home ? this.selected_card.title : "";

      const cardName = role.card ? role.card.title : tempCardName;

      const backSrc = `/images/cards/back/${role.home}_back.png`;
      faceSrc = `/images/cards/${role.home}/${this.locale}/${cardName}.png`;
      if (!cardName) {
        return backSrc;
      }

      if (this.phase_name !== "SelectCard") return faceSrc;
      if (this.home !== role.home) {
        return backSrc;
      }

      return faceSrc;
    },
    cardFaceVic() {
      let faceSrc;
      const role = this.crusade_state.victim;
      const tempCardName = this.selected_card?.home === role.home ? this.selected_card.title : "";
      const cardName = role.card ? role.card.title : tempCardName;

      const backSrc = `/images/cards/back/${role.home}_back.png`;
      faceSrc = `/images/cards/${role.home}/${this.locale}/${cardName}.png`;
      if (!cardName) {
        return backSrc;
      }

      if (this.phase_name !== "SelectCard") return faceSrc;
      if (this.home !== role.home) {
        return backSrc;
      }

      return faceSrc;
    },
    showNeitralBtn() {
      const fighters = [this.crusade_state?.agressor, this.crusade_state?.victim];
      const am_i_fighter = fighters.some((f) => f.home === this.home);
      const somebody_need_support = fighters.some((f) => f.permit_support);
      if (!am_i_fighter && !somebody_need_support) return false;
      return true;
    },
  },

  watch: {
    async phase_name() {
      const phases = [
        "PreAttackConflictPort",
        "PermitSupport",
        "DefineSupport",
        "SelectCard",
        "FirstCardAction",
        "AutoCardActions",
        "UseSword",
        "DefineWinner",
        "ManualDelUnits",
        "Retreat",
        "AastAutoCardActions",
        "LastCardAction",
        "ConflictPort",
        "ResetCrusade",
      ];
      if (phases.includes(this.phase_name)) {
        if (this.active_players.includes(this.home) && this.crusade_state.conflict_land) {
          if (!this.game_side_bar.warActive) {
            this.setGameSideTab();
            await nextTick();
            this.setGameSideTab({ tab: "warActive" });
          }
        }
      }
    },
    async home() {
      const phases = [
        "PreAttackConflictPort",
        "PermitSupport",
        "DefineSupport",
        "SelectCard",
        "FirstCardAction",
        "AutoCardActions",
        "UseSword",
        "DefineWinner",
        "ManualDelUnits",
        "Retreat",
        "AastAutoCardActions",
        "LastCardAction",
        "ConflictPort",
        "ResetCrusade",
      ];
      if (phases.includes(this.phase_name)) {
        if (this.active_players.includes(this.home)) {
          if (!this.game_side_bar.warActive) {
            this.setGameSideTab();
            await nextTick();
            this.setGameSideTab({ tab: "warActive" });
          }
        } else {
          this.setGameSideTab();
        }
      }
    },
  },

  methods: {
    ...mapMutations("game_store", ["setSupportChoice", "setSwordChoice", "setSupportPermissionChoice"]),
    ...mapMutations("gui_store", ["setGameSideTab"]),
    getRandomCardSrc(flag) {
      const card = this.crusade_state[flag].random_card;
      if (!card) return;
      if (!card.id) {
        return `/images/westeros_cards/random_cards/random_back.png`;
      }
      if (this.phase_name === "UseSword") {
        const candHome = this.crusade_state[flag].home;
        if (candHome !== this.home) {
          return `/images/westeros_cards/random_cards/random_back.png`;
        }
      }
      if (card.scull) {
        return `/images/westeros_cards/random_cards/0_s.png`;
      }
      if (card.force === 0) {
        return `/images/westeros_cards/random_cards/0.png`;
      }
      if (card.force === 1) {
        if (card.sword) {
          return `/images/westeros_cards/random_cards/1_a.png`;
        }
        return `/images/westeros_cards/random_cards/1_d.png`;
      }
      if (card.force === 2) {
        return `/images/westeros_cards/random_cards/2.png`;
      }
      if (card.force === 3) {
        return `/images/westeros_cards/random_cards/3.png`;
      }
      return `/images/westeros_cards/random_cards/random_back.png`;
    },

    randomCardSpan(flag) {
      if (this.phase_name === "UseSword") {
        const candHome = this.crusade_state[flag].home;
        if (candHome !== this.home) {
          return false;
        }
        return true;
      }
      return true;
    },

    rand_card_hint(flag) {
      if (this.phase_name !== "UseSword") return;
      const candHome = this.crusade_state[flag].home;

      if (this.home !== candHome) return false;
      if (!this.active_players.includes(candHome)) return;
      return true;
    },

    showOrderInStat(role) {
      if (role.title === "agressor" && !role.order.includes("crusade")) {
        return false;
      }
      if (role.title === "victim" && !role.order.includes("defence")) {
        return false;
      }
      return true;
    },

    showSupBtn(role) {
      const fighters = [this.crusade_state.agressor, this.crusade_state.victim];
      if (!role.permit_support) return false;
      const am_i_fighter = fighters.some((f) => f.home === this.home);
      if (am_i_fighter && role.home !== this.home) return false;
      return true;
    },

    supportHandler(e) {
      if (!this.active_players.includes(this.home)) return;
      let targetHome = e.target.dataset.home;
      const isPlayerInWar = (this.crusade_state.agressor.home === this.crusade_state.victim.home) === this.home;
      if (!targetHome) return;
      if (isPlayerInWar && targetHome !== "neitral" && targetHome !== this.home) return;
      targetHome = targetHome === "neitral" ? "" : targetHome;
      this.setSupportChoice({ choice: targetHome });
      this.$emit("exec");
    },

    async permit_support_handler(e) {
      if (this.phase_name !== "PermitSupport") return;
      if (!this.active_players.includes(this.home)) return;

      let permission = +e.target.dataset.permission;
      this.setSupportPermissionChoice({ permission, home: this.home });
      this.$emit("exec");
    },

    swordhandler(use_sword = false, changeCard = false) {
      if (this.phase_name !== "UseSword") return;
      if (!this.active_players.includes(this.home)) return;
      if (changeCard) {
        const cand = this.crusade_state[changeCard].home;
        if (cand !== this.home) return;
        this.setSwordChoice({ use_sword: false, changeCard: false });
        this.setSwordChoice({ use_sword, changeCard: true });
        this.$emit("exec");
        this.setSwordChoice({ use_sword: false, changeCard: false });
        return;
      }
      this.setSwordChoice({ use_sword: false, changeCard: false });
      this.setSwordChoice({ use_sword });
      this.$emit("exec");
      this.setSwordChoice({ use_sword: false, changeCard: false });
    },

    supHover(e) {
      const className = e.target.nodeName === "IMG" ? "deny_border_sup" : "deny_border_sup_neitral";

      if (!this.active_players.includes(this.home)) {
        e.target.classList.toggle(className);
        return;
      }
      const tergetHome = e.target.dataset.home;
      if (!tergetHome) return;
      const isPlayerInWar =
        this.crusade_state.agressor.home === this.home || this.crusade_state.victim.home === this.home;
      if (isPlayerInWar) {
        if (tergetHome === "neitral") return;
        if (tergetHome !== this.home) {
          e.target.classList.toggle(className);
          return;
        }
      }
    },

    animateActiveCard(home) {
      if (this.phase_name === "SelectCard") {
        return this.active_players.includes(home);
      }
      if (this.phase_name === "UseSword") {
        return this.active_players.includes(home);
      }
      return false;
    },

    showCardBtns(role) {
      if (this.phase_name !== "FirstCardAction") return false;
      if (!role.card) {
        return false;
      }
      if (role.home !== this.home) return false;
      if (!this.active_players.includes(this.home)) return false;
      const card_list = ["Tyrion", "Aeron"];
      if (!card_list.includes(role.card.title)) return false;
      return true;
    },

    cardPropertyPlay(role) {
      role.card.play = true;
      this.$emit("exec");
    },

    normalizeOrderName(orderName) {
      if (!orderName) return;
      const parts = orderName.split(":");

      return parts[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.war-wr {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  // width: 100%;
  // padding-top: 20px;
  // position: relative;
}
.gap_for_logger {
  width: 100%;
  min-height: 4.2rem;
}

.main_body {
  overflow: auto;
  overflow-x: hidden;
  flex: 8;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.top_side {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.war_title {
  color: white;
  font-size: 24px;
  text-align: center;
  margin-bottom: 60px;
  // position: absolute;
  // top: -30px;
}

.sword_img_wr {
  position: relative;

  img {
    position: absolute;
    // margin-top: -20px;
    top: -65px;
    left: -35px;
    width: 70px !important;
  }
}

.sword {
  width: 1%;
  // top: 85px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  // z-index: 10;
}

.sword_active {
  -webkit-filter: drop-shadow(2px 2px 0 rgb(242, 8, 8)) drop-shadow(-2px -2px 0 rgb(242, 8, 8));
  filter: drop-shadow(2px 2px 0 rgb(242, 8, 8)) drop-shadow(-2px -2px 0 rgb(242, 8, 8));
}

.used_sword {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.6;
}

.sword-btns {
  position: absolute;
  top: -50px;
  left: -4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sword_btn {
  width: 30%;
  margin-left: 10px;
  padding: 5px;
  text-align: center;
  color: white;
  border: 2px solid rgb(130, 127, 127);
  background-color: rgb(67, 62, 62);
  border-radius: 15px;
  &:hover {
    cursor: pointer;
    border: 2px solid rgb(130, 97, 97);
  }
}

.random_cards_wrap {
  // margin-top: 0.3rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: auto;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #8e8c8c;
}
.rand_card_wr {
  img {
    height: 80px;
  }
}

.rand_card_hint {
  outline: 2px solid rgb(243, 4, 72);
  border-radius: 5px;
  cursor: pointer;
  // margin: -2px;
}
.cards {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }
}

.war_card_wr {
  width: 40%;
  position: relative;
  display: inline-flex;
  justify-content: center;

  .card_btns {
    top: -4rem;
    width: auto;
    color: white;
    font-size: 0.8rem;
    white-space: pre-line;
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    gap: 0.3rem;

    div {
      border: 2px solid black;
      border-radius: 0.3rem;
      text-align: center;
      box-sizing: border-box;
      width: auto;
      // max-width: 48%;
      // min-width: 48%;
      padding: 5px;
      background-color: rgb(66, 63, 63);
      cursor: pointer;

      &:hover {
        background-color: rgb(0, 0, 0);
      }
    }
  }
}

.looser_shadow::after {
  position: absolute;
  top: 80px;
  // left: 20px;
  color: white;
  background-image: url("/images/fail.png");
  background-size: 130px 50px;
  background-repeat: no-repeat;
  width: 130px;
  height: 50px;
  pointer-events: none;
  content: "";
}
.winner_shadow::after {
  position: absolute;
  top: 80px;
  // left: 40px;
  color: white;
  background-image: url("/images/win.png");
  background-size: 130px 50px;
  background-repeat: no-repeat;
  width: 130px;
  height: 50px;
  pointer-events: none;
  content: "";
}

.tyrion_btns {
  position: absolute;

  top: 286px;
  display: flex;
  justify-content: space-around;
}

.animateActiveCard::after {
  position: absolute;
  top: 0px;
  pointer-events: none;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  animation: pulse 1s linear 0s infinite alternate;
  content: "";
}

.buttons_permit {
  // margin-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #8e8c8c;
  padding-bottom: 1rem;
}

.per_btn {
  width: 200px;
  height: 20px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgb(248, 246, 246);
}

.permit_btn_wr {
  border: 2px solid rgb(2, 66, 29);
  background-color: rgb(4, 100, 57);

  &:hover {
    background-color: rgb(2, 51, 37);
  }
}
.deny_btn_wr {
  border: 2px solid brown;
  background-color: rgb(134, 9, 9);

  &:hover {
    background-color: rgba(144, 14, 14, 0.534);
  }
}

.buttons_sup {
  width: 100%;
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #8e8c8c;
  justify-content: center;
  padding-bottom: 1rem;
}

.sup_btn_wr {
  width: 55px;
  img {
    border: 2px solid green;
    border-radius: 50%;
    width: 100%;
    cursor: pointer;
    transition: border-color 0.15s;
    &:hover {
      border: 2px solid white;
      // margin: 2px;
    }
  }
}

.highlight {
  background-color: gray;
  border-radius: 5px;
}
.neitral_btn {
  width: 84px;
  height: 25px;
  margin: 5px;
  padding: 10px;
  border: 2px solid black;
  border-radius: 15px;

  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: rgb(171, 171, 171);
  cursor: pointer;
  &:hover {
    color: white;
    border: 2px solid rgb(127, 124, 124);
  }
}

.disableSupBtns {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.4;
  pointer-events: none;
}

.sup_result_wrap {
  width: 100%;
  height: auto;
  // margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(129, 127, 127);
  // background-color: rgba(71, 133, 133, 0.578);
  span {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    // color: rgb(132, 130, 128);
  }
}

.main_overal {
  display: flex;
  // gap: 0.4rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #8e8c8c;
  justify-content: space-between;
  align-items: center;
}
.overal:first-of-type {
  border-right: 1px solid #8e8c8c;
}

.overal {
  position: relative;
  display: flex;
  height: auto;
  flex-wrap: wrap;
  // width: 50%;
  gap: 3px;
  flex: 1;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  padding-left: 8px;
  img {
    //margin-left: -10px;
    width: auto;
    // height: 30px;
    // margin-right: 3px;
  }
}

.units_container {
  display: flex;
  justify-content: flex-start;
}
.sword_container {
  img {
    height: 50px;
  }
}
.unit_wr_stat:not(:first-child) {
  margin-left: -5px;
}

.unit_wr_stat {
  width: auto;
  height: auto;

  // margin-left: -7px;
  // margin-right: 3px;

  img {
    width: auto;
    height: 22px;
    margin: 0;
  }
}

.retreated {
  transform: rotate(-45deg);
}

.garrison_wrap {
  img {
    width: 30px;
  }
  position: relative;
}
.garrison_force {
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 10;
  color: white !important;
}

.extra_power_wr {
  position: relative;
  // margin-right: 5px;
  img {
    width: auto;
    height: 50px;
  }
}

.extra_power_span {
  position: absolute;
  top: 46px;
  left: 8px;
  color: white;
  font-size: 14px !important;
}

.order_wr_stat {
  img {
    width: 30px;
  }
  // margin-right: 5px;
  // margin-left: -5px;
}

.sup_sparator {
  width: 40px !important;
  height: auto;
}

.sup_overal {
  height: auto;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgb(142, 140, 140);
  display: flex;

  // flex-direction: column;
  justify-content: space-between;
  color: rgb(129, 127, 127);

  img {
    // &:hover {
    //   cursor: pointer;
    //   -webkit-filter: drop-shadow(2px 3px 0 rgb(255, 255, 255)) drop-shadow(-12px -3px 0 white);
    //   filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px -2px 0 white);
    // }
    width: 30px;
  }
  .sup_side {
    // flex: 4;
    position: relative;
    display: flex;
    justify-content: center;
    // border: 1px solid red;
    width: 50%;
    flex-wrap: wrap;
  }
}

.sup_side_wr {
  display: flex;
}

.sup_icon_wr {
  position: relative;
}
.sup_label {
  position: absolute;
  color: white !important;
  font-size: 14px !important;
  left: 12px;
  top: 25px;
}

.total_points {
  height: auto;
  text-align: center;
  color: rgb(143, 140, 140);
  font-size: 1.2rem;
  padding-top: 1rem;
  background-color: rgba(62, 37, 66, 0.688);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // border: 1px solid red;
  width: 100%;
  flex: 1;
}

.total_points_inner {
  box-sizing: border-box;
  font-size: 1rem;
  padding: 1rem 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.total_side {
  display: flex;
  justify-content: center;
  span {
    color: white;
  }
  // border: 1px solid red;
}

//===================================================

@keyframes pulse {
  from {
    background-color: rgba(249, 249, 249, 0.712);
  }
  to {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes pulse_track {
  from {
    background-color: rgba(237, 68, 68, 0.786);
  }
  to {
    background-color: rgba(239, 49, 49, 0.234);
  }
}
</style>
