<template>
  <div v-if="game" class="log" ref="log_wr" @mousewheel.prevent="handleMouseWheel">
    <div class="inner_log_wr">
      <Transition appear>
        <div v-if="log_id" class="log_msg" :style="{ transform: logYpos }">
          <span>{{ log_msg }}</span>
        </div>
      </Transition>
      <div
        v-for="(log_item, idx) in short_log"
        :key="log_item.id"
        :ref="log_item.id"
        class="log_item"
        @pointerenter="logLabelHover($event, log_item)"
        @pointerleave="logLabelHover($event)"
      >
        <img
          v-if="log_item"
          class="log_label"
          :class="{ hint: idx <= delta_logs, history_hint: log_item.id === this.currentLogItem?.id }"
          :src="getLogImg(log_item)"
          :alt="log_item.phase_name"
        />
        <img
          v-if="log_item?.home"
          class="home_label home_label_shift"
          :src="getHomeLabel(log_item.home)"
          :alt="log_item.home"
        />

        <span v-if="log_item?.sub_type || log_item?.type" class="log_label_span material-icons log_label_shift">{{
          getLogLabel(log_item.sub_type || log_item.type)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import clone from "clone";
import { nextTick } from "vue";
import { mapGetters } from "vuex";
import GameLogger from "../locales/GameLogger.js";

export default {
  data() {
    return {
      log_id: "",
      log_msg: "",
      logYpos: "",
      delta_logs: 0,
      clear_hint_delay: null,
      hint_delay: 150,
    };
  },

  computed: {
    ...mapGetters("general_store", ["game", "currentLogItem", "generatedLogs"]),

    short_log() {
      if (!this.logs) return [];
      const arr = clone(this.logs).reverse();

      return arr.reduce((logs, log_item) => {
        const generated_log = this.game_logger?.generateLog(log_item);
        if (!generated_log) return logs;
        logs.push(generated_log);
        return logs;
      }, []);
    },
    logs() {
      return this.game?.state?.log;
    },
  },

  mounted() {
    this.$refs.log_wr?.scrollTo({ left: this.$refs.log_wr?.scrollWidth, behavior: "smooth" });
  },

  watch: {
    async short_log(newValue, oldValue) {
      const delta = newValue?.length - oldValue?.length;
      if (delta) {
        this.delta_logs = delta - 1;
        await nextTick();
        this.scrollLog();
      }
    },
    async currentLogItem(newV, oldV) {
      if (oldV && !newV) {
        this.$refs.log_wr?.scrollTo({ left: this.$refs.log_wr?.scrollWidth, behavior: "smooth" });
        return;
      }
      if (!this.currentLogItem) return;
      const i_id = this.currentLogItem.id;
      const $log = this.$refs[i_id];
      if (!$log) return;

      $log[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
  },

  beforeCreate() {
    this.game_logger = new GameLogger({ t: this.$t, tc: this.$tc, root: this.$root.$i18n }, this.game?.state);
  },

  methods: {
    scrollLog() {
      const deltaScroll = this.$refs.log_wr?.scrollWidth - +window.innerWidth;
      this.$refs.log_wr?.scrollTo({ left: deltaScroll, behavior: "smooth" });
    },
    handleMouseWheel(event) {
      const delta = Math.max(-1, Math.min(1, event.deltaY || -event.detail));
      if (this.$refs.log_wr) {
        this.$refs.log_wr.scrollLeft += delta * 100;
      }
    },
    logLabelHover(e, log_item) {
      if (e.pointerType === "touch") {
        if (e.type === "pointerleave") {
          clearTimeout(this.pressTimerHint);
          this.pressTimerHint = null;
          return;
        }
        if (e.type === "pointerenter") {
          clearTimeout(this.clear_hint_delay);
          clearTimeout(this.pressTimerHint);
          this.pressTimerHint = setTimeout(() => {
            this.log_id = log_item.id;
            this.log_msg = log_item.msg;
            const coords = e.target.getBoundingClientRect(e.target);
            const msg_width = 250;

            const edge_right = coords.left + msg_width;

            clearTimeout(this.clear_hint_delay);
            this.clear_hint_delay = setTimeout(() => {
              this.log_id = "";
              this.log_msg = "";
              this.logYpos = "";
            }, 3000);
            if (edge_right > window.innerWidth) {
              this.logYpos = `translateX(${window.innerWidth - msg_width}px)`;
              return;
            }
            if (coords.left < msg_width / 2) {
              this.logYpos = `translateX(${0}px)`;
              return;
            }
            this.logYpos = `translateX(${coords.left - msg_width / 2}px)`;
          }, this.hint_delay);
        }
        return;
      }

      if (e.pointerType === "mouse") {
        if (e.type === "pointerleave") {
          clearTimeout(this.clear_hint_delay);
          this.clear_hint_delay = null;
          this.log_id = "";
          this.log_msg = "";
          this.logYpos = "";
          return;
        }
        this.log_id = log_item.id;
        this.log_msg = log_item.msg;
        const coords = e.target.getBoundingClientRect(e.target);
        const msg_width = 250;

        const edge_right = coords.left + msg_width;

        clearTimeout(this.clear_hint_delay);
        this.clear_hint_delay = setTimeout(() => {
          this.log_id = "";
          this.log_msg = "";
          this.logYpos = "";
        }, 3000);

        if (edge_right > window.innerWidth) {
          this.logYpos = `translateX(${window.innerWidth - msg_width}px)`;
          return;
        }
        if (coords.left < msg_width / 2) {
          this.logYpos = `translateX(${0}px)`;
          return;
        }
        this.logYpos = `translateX(${coords.left - msg_width / 2}px)`;
      }
    },

    getLogImg(log_item) {
      if (!log_item) return;
      if (log_item.label_img) {
        return `/images/logger/${log_item.label_img}.png`;
      }
      return `/images/logger/${log_item.phase_name}.png`;
    },
    getHomeLabel(home) {
      return `/images/tokens/${home}_token.png`;
    },
    getLogLabel(type) {
      switch (type) {
        case "autoAction": {
          return "build";
        }
        case "cancel": {
          return "cancel";
        }
        case "active_players": {
          return "search";
        }
        case "change_order": {
          return "published_with_changes";
        }
        case "dig_card": {
          return "low_priority";
        }
        case "check_card": {
          return "visibility";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.log::-webkit-scrollbar-track {
  background: rgb(53, 53, 53);
  border-left: 8px solid #6c6b6b;
  border-right: 9px solid #6c6b6b; /* color of the tracking area */
}

.log::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53); /* color of the scroll thumb */
  border-radius: 25px; /* roundness of the scroll thumb */

  border-left: 5px solid #6c6b6b;
  border-right: 5px solid #6c6b6b;
}

.log {
  box-sizing: border-box;
  display: flex;
  height: auto;
  width: 100dvw;
  overflow-y: auto;
  overflow-x: auto;
}

.inner_log_wr {
  pointer-events: all;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  position: relative;
  overflow: visible;
}

.hint {
  border-radius: 5px;
  background-color: rgba(10, 122, 115, 0.5);
  transition: background-color 0.3s;
}

.history_hint {
  border-radius: 5px;
  background-color: rgba(191, 12, 57, 0.616);
  transition: background-color 0.3s;
}

.log::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.log {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.log_item {
  display: inline-block;
  pointer-events: all;
  position: relative;
  width: auto;
  overflow: visible;
  cursor: all-scroll;
  transition: background-color 0.3s;
  :hover {
    background-color: rgb(51, 52, 52);
  }

  img,
  span {
    pointer-events: none;
  }
}

.log_msg {
  position: fixed;
  box-sizing: border-box;
  top: 5rem;
  z-index: 152;
  width: 250px;
  height: auto;
  padding: 0.3rem;
  text-align: center;
  background-color: rgba(51, 52, 52, 0.815);
  color: white;
  border-radius: 0.3rem;
  span {
    font-size: 0.8rem;
    white-space: pre-wrap;
  }
}

.log_label {
  width: auto;
  height: 2.5rem;
  padding: 5px;
  border-radius: 0.5rem;
}

.home_label {
  width: 1rem;
  position: absolute;
  left: 40px;
  top: 30px;
  pointer-events: none;
}
.log_label_span {
  font-size: 1rem;
  // width: 18px;
  position: absolute;
  left: 1rem;
  top: 2rem;
  z-index: 151;
  pointer-events: none;
  color: red;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.35s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
