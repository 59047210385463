import store from "../store/index.js";
export default class WesterosDecisionHandler {
  constructor() {}

  initialTracksPreset() {}

  westeros_btn_click(num) {
    const phase_name = store.getters["general_store/phase_name"];
    const variants = {
      ThroneOfBlades: { 1: "Supply", 2: "Mastering", 3: null },
      BlackWings: { 1: "WarOfKings", 2: "GameOfThrones", 3: null },
      PutToTheSword: { 1: "StormOfSwords", 2: "AutumnRains", 3: null },
    };
    const choice = variants?.[phase_name]?.[num];

    store.commit("game_store/setWesterosChoice", { choice });
    store.commit("game_store/setExecTriger", { doit: true });
  }
}
