import { v4 as uuidv4 } from "uuid";
import store from "../store/index.js";

export default class MasteringHandler {
  constructor() {
    this.master_points = store.state.game_store.mastering_state?.points;
  }

  prepareGui() {
    store.commit("game_store/setMasteringState", {
      reset: true,
    });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    this.beckupGui();
    this.initialOrdersPreset();
  }

  initialOrdersPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const home = active_players[0];

    if (!state) return;

    const lands = state.lands;
    const states = state.states;

    const home_state = states.find((st) => st.home === home);
    const occupied_lands = home_state.lands;

    for (let land_name of occupied_lands) {
      const land_obj = lands[land_name];
      const order = land_obj.order;
      if (!order.includes("power_spec")) continue;
      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
    }
  }

  beckupGui() {
    const game = store.getters["general_store/game"];
    const beckuped_lands = store.state.general_store.active_lands_beckup;
    store.commit("general_store/beckupLands", {
      room_id: game.id,
      lands: beckuped_lands,
    });
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const masteringState = store.getters["game_store/masteringState"];
    const prev_area = masteringState.land_name;
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (e.target?.dataset?.ordertype !== "power_spec") return;
    if (e.target.dataset.owner !== home) return;

    const active_orders = store.state.gui_store.active_orders;

    if (active_orders.size) {
      this.prepareGui();
    }
    if (e.target?.dataset.source === prev_area) {
      store.commit("game_store/setMasteringState", {
        reset: true,
      });
      store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
      return;
    }
    this.unitHeepOpener(e);
  }

  unitHeepOpener(e) {
    const land_name = e.target?.dataset?.source;
    const state = store.getters["general_store/state"];
    if (!state) return;

    const lands = state.lands;

    store.commit("game_store/setMasteringState", {
      reset: true,
    });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    store.commit("gui_store/setOrder", { key: "active_orders", land_name });
    store.commit("game_store/setMasteringState", {
      land_name,
      points: e.target.dataset.master_points,
    });

    this.master_points = lands[land_name].master_points;
  }

  async mousedownHandler(e) {
    e.preventDefault();
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];
    const mapPosition = store.state.gui_store.mapPosition;
    const scale = mapPosition.scale;

    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (this.master_points < 1) return;
    if (e.target?.dataset?.type !== "unit") return;
    if (e.target.classList.contains("disabled")) return;

    this.unit = e.target;
    this.unit.setPointerCapture(e.pointerId);

    const { owner, type_unit, force, id } = e.target.dataset;
    this.hintTargetPlace(owner, type_unit);
    const unit_obj = { owner, type: type_unit, force, id };
    const src = `/images/units/${owner}/new/${type_unit}_${owner}.png`;

    store.commit("gui_store/setSelectedUnits", { unit: { ...unit_obj, src } });

    const default_styles = {
      width: "content-length",
      height: "auto",
      position: "absolute",
      zIndex: 10000,
      // backgroundColor: "black",
      display: "flex",
      color: "white",
      pointerEvents: "none",
    };
    this.clones_container = document.createElement("div");
    const clone = document.createElement("div");
    const img = document.createElement("img");
    clone.style.width = "80px";
    clone.style.height = "80px";
    // clone.style.backgroundColor = "red";
    img.src = `/images/units/${unit_obj.owner}/new/${unit_obj.type}_${unit_obj.owner}.png`;
    img.style.width = "100%";
    img.style.height = "auto";
    img.style.pointerEvents = "none";
    clone.appendChild(img);
    this.clones_container.appendChild(clone);
    const map = document.querySelector("#camera");
    Object.assign(this.clones_container.style, default_styles);
    map.appendChild(this.clones_container);

    default_styles.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
      e.clientY - this.clones_container.offsetHeight / 2
    }px) scale(${scale})`;

    Object.assign(this.clones_container.style, default_styles);

    this.unit.onpointermove = (e) => {
      this.clones_container.style.transform = `translate(${e.clientX - this.clones_container.offsetWidth / 2}px, ${
        e.clientY - this.clones_container.offsetHeight / 2
      }px) scale(${scale})`;

      let elementBelow = document.elementFromPoint(e.clientX, e.clientY);

      if (elementBelow.dataset?.type === "empty_place") {
        this.$empty_space = elementBelow;
        elementBelow.src = `images/plus.png`;
        elementBelow.style.transform = `translate(0px, 10px) scale(1.3)`;
      } else if (this.$empty_space) {
        this.$empty_space.src = `images/plus_simple.png`;
        this.$empty_space.style.transform = `translate(0px, 10px) scale(1)`;
      }

      store.commit("game_store/setCurrentArea", {
        land_name: elementBelow.dataset?.source,
        owner: elementBelow.dataset?.owner,
      });

      const masteringState = store.state.game_store.mastering_state;
      const selected_units = store.state.gui_store.selected_units;
      if (!masteringState?.land_name) return;
      const tempUnits = Array.from(selected_units.values());
      const temp_unit = tempUnits[0];
      const type = elementBelow?.dataset?.unit_type;
      if (temp_unit?.type !== "knite" && temp_unit?.type !== "kata") return;
      if (type !== "pawn") return;
      if (selected_units.has(elementBelow.id)) {
        store.commit("gui_store/setHighlightedUnits", { reset: true });
        return;
      }
      store.commit("gui_store/setHighlightedUnits", { id: elementBelow.id });
    };

    this.unit.onpointerup = this.dragEnd.bind(this);

    this.unit.onpointercancel = (e) => {
      this.removeDragClones();
      this.bindedCleaner();
    };
  }

  dragEnd(e) {
    e.preventDefault();
    this.unit.onpointermove = null;
    this.unit.onpointermove = null;
    this.$empty_space = null;
    const masteringState = store.state.game_store.mastering_state;
    const selected_units = store.state.gui_store.selected_units;
    this.removeDragClones();
    const state = store.getters["general_store/state"];
    // const home = store.getters["general_store/home"];;

    if (!state) return;
    const cur_land_name = store.state.game_store.current_area_under_cursor.land_name;
    const lands = state.lands;
    const tempUnits = Array.from(selected_units.values());
    const temp_unit = tempUnits[0];

    if (temp_unit && this.creatingZones.some((zn) => zn.land_name === cur_land_name)) {
      let decPoints = temp_unit.type === "kata" ? 2 : temp_unit.type === "knite" ? 2 : 1;
      let unitToAdd = this.createUnit(temp_unit.type, temp_unit.owner, cur_land_name);
      let unitToUpgrade = { ...unitToAdd };
      let elementBelow = document.elementFromPoint(e.clientX, e.clientY);

      if (elementBelow?.dataset?.type === "empty_place") {
        if (masteringState.points < 2 && temp_unit.type !== "pawn" && temp_unit.type !== "ship") {
          this.removeHintTargetPlace();
          this.prepareGui();
          return;
        }
      }
      if (
        masteringState.points < 2 &&
        temp_unit.type !== "pawn" &&
        temp_unit.type !== "ship" &&
        elementBelow?.dataset?.unit_type !== "pawn"
      ) {
        this.removeHintTargetPlace();
        this.prepareGui();
        return;
      }

      if (elementBelow?.dataset?.unit_type === "pawn" && temp_unit.type !== "pawn") {
        const pawnId = elementBelow.id;

        unitToAdd = lands[cur_land_name].units.find((u) => u.id === pawnId);
        unitToAdd.upgrade = temp_unit.type;
        unitToAdd.land_name = cur_land_name;

        unitToUpgrade = { ...unitToAdd };
        unitToAdd.type = temp_unit.type;

        lands[cur_land_name].units = lands[cur_land_name].units.filter((u) => u.id !== pawnId);
        decPoints = 1;
      }
      this.master_points = this.master_points - decPoints;

      store.commit("game_store/setMasteringState", { units: [{ ...unitToUpgrade }], points: this.master_points });

      const home_state = state.states.find((st) => st.home === unitToAdd.owner);
      home_state.units[unitToAdd.type].push(unitToAdd);

      lands?.[cur_land_name]?.units.push(unitToAdd);
    }

    this.removeHintTargetPlace();
  }

  createUnit(type, home, land_name) {
    const force = type === "pawn" || type === "ship" ? 1 : type === "knite" ? 2 : 4;
    return { type, owner: home, id: `${type}:${home}:-${uuidv4()}`, force, land_name };
  }

  removeDragClones() {
    if (this.unit) {
      this.unit.onpointermove = null;
      this.unit.onpointerup = null;
      this.unit.onpointercancel = null;
    }

    const map = document.querySelector("#camera");
    if (this.clones_container && map.contains(this.clones_container)) {
      map.removeChild(this.clones_container);
    }
  }

  hintTargetPlace(owner, type_unit) {
    const active_players = store.getters["general_store/active_players"];

    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];

    if (!state) return;
    const lands = state.lands;
    const masteringState = store.state.game_store.mastering_state;
    const source_land_name = masteringState.land_name;
    const source_land_obj = lands[source_land_name];

    if (!active_players) return;
    if (!active_players.includes(home)) return;

    this.creatingZones = source_land_obj.adjacent_lands.reduce((arr, land_name) => {
      const candidateLandObj = lands[land_name];

      if (!candidateLandObj) return arr;
      if (candidateLandObj.home && candidateLandObj.home !== owner) return arr;

      if (type_unit === "ship") {
        if (!candidateLandObj.sea && !land_name.includes("_port")) return arr;
        arr.push({ land_name, ship: true });
        return arr;
      }
      if (candidateLandObj.sea || land_name.includes("_port")) return arr;
      if (source_land_name === land_name) {
        arr.push({ land_name, ship: false });
      }
      return arr;
    }, []);

    if (type_unit !== "ship") {
      this.creatingZones.push({ land_name: source_land_name, ship: false });
    }

    this.hints_create_unit_list = [];

    this.creatingZones.forEach(({ land_name }) => {
      store.commit("gui_store/setGapLand", { land_name });

      const dropZone = document.querySelector([`#${land_name}-drop`][0]);

      if (!dropZone) return;
      this.pawn_wrappers = [];

      if (type_unit === "knite" || type_unit === "kata") {
        this.pawn_wrappers = [...dropZone.firstElementChild.children].filter((unit_wr) => {
          return unit_wr.dataset.unit_type === "pawn";
        });
      }

      this.pawn_wrappers.forEach((pawn_wr) => {
        store.commit("gui_store/setPawnsForUpgrade", { id: pawn_wr?.id });
      });

      if (masteringState.points < 2 && type_unit !== "pawn" && type_unit !== "ship") {
        return;
      }
      const hint_img = new Image(40, 40);
      hint_img.src = `images/plus_simple.png`;
      hint_img.id = `empty_space`;
      hint_img.style.marginLeft = `10px`;
      hint_img.style.transform = `translate(0px, 10px) scale(1)`;
      hint_img.style.transition = `transform 0.25s`;
      hint_img.dataset.type = `empty_place`;
      hint_img.dataset.source = land_name;

      dropZone.firstElementChild.appendChild(hint_img);
      this.hints_create_unit_list.push(hint_img);

      // img hint DOM Node ref push to array, due to remove those nodes later
    });
  }

  removeHintTargetPlace() {
    this.hints_create_unit_list?.forEach(($img_hint) => {
      $img_hint?.remove();
    });

    store.commit("gui_store/setPawnsForUpgrade", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setHighlightedUnits", { reset: true });
    store.commit("gui_store/setGapLand", { reset: true });
  }

  validate(data = {}) {
    const { source_land_name } = data;
    if (!source_land_name) return false;
    return true;
  }
}
