<template>
  <div class="main_app_container">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
          return;
        }
      },
      { passive: false }
    );
  },
};
</script>

<style lang="scss" scoped>
.main_app_container {
  overflow: hidden;
  height: 100dvh;
  width: 100dvw;
  user-select: none;
  background-image: url("/images/main_theme.png");
  background-size: 100% 100%;
}

@media screen and (max-aspect-ratio: 16/10) {
  .main_app_container {
    background-size: cover;
    background-position: 50%;
  }
}
</style>
