import store from "../store/index.js";

export default class DoranHandler {
  constructor() {}
  prepareGui() {
    this.resetGui();
    this.initialTracksPreset();
  }

  resetGui() {
    store.commit("gui_store/setHintTrackLabels", { reset: true });
    store.commit("game_store/setTrackLabel", { reset: true });
  }

  initialTracksPreset() {
    const phase_name = store.getters["general_store/phase_name"];

    if (phase_name !== "FirstCardAction") return;
    const labels = ["throne", "sword", "crow"];
    store.commit("gui_store/setHintTrackLabels", { trackNames: labels });
  }

  trackLabelClick(e) {
    const enemyRole = store.getters["general_store/enemyRole"];
    const trackName = e.target.dataset.source;

    store.commit("game_store/setTrackLabel", { trackName, home: enemyRole?.home });
    store.commit("game_store/setExecTriger", { doit: true });
  }
  allowCursorPointerOnTrack(permit) {
    const active_players = store.getters["general_store/active_players"];
    const home = store.getters["general_store/home"];

    if (!active_players.includes(home)) return;
    if (!permit) return;

    return true;
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }
}
