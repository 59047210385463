import store from "../store/index.js";
export default class RenleyCardHandler {
  constructor() {
    this.bindedHandler = this.retreatWindowClearHandler.bind(this);
  }

  allowCursorPointer(unit) {
    if (!unit) return;
    const home = store.getters["general_store/home"];
    const crusade_state = store.getters["general_store/crusade_state"];
    if (unit.owner !== home) return;
    if (!crusade_state.card_action_candidates.some((u) => u.id === unit.id)) return;

    return true;
  }

  resetGui() {
    store.commit("gui_store/setGapLand", { reset: true });
    store.commit("game_store/setMasteringState", { reset: true });
    store.commit("gui_store/setSelectedUnits", { reset: true });
    store.commit("gui_store/setHighlightedUnits", { reset: true });
  }

  prepareGui() {
    this.resetGui();
    this.initialUnitsPreset();
  }

  initialUnitsPreset() {
    const state = store.getters["general_store/state"];
    if (!state) return;
    const crusade_state = state.phase.crusade_state;
    const candidatesUnits = crusade_state.card_action_candidates;

    candidatesUnits?.forEach((u) => {
      store.commit("gui_store/setHighlightedUnits", { id: u.id });

      store.commit("gui_store/setGapLand", { land_name: u.land_name });
    });
  }

  retreatWindowClearHandler(e) {
    if (e.target.dataset.type === "unit" || e.target.dataset.type === "exec") {
      if (e.target.dataset.type === "exec") {
        return;
      }
      document.addEventListener("mousedown", this.bindedHandler, { once: true });
      return;
    }
    this.prepareGui();
  }

  pointerdownHandler(e) {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    const myRole = store.getters["general_store/myRole"];
    if (!state) return;
    if (!active_players.includes(home)) return;

    const crusade_state = state.phase.crusade_state;
    const winner = crusade_state.winner;
    const selected_units = store.state.gui_store.selected_units;

    if (!winner) return;

    const candidatesPawns = crusade_state.card_action_candidates;

    const _home = crusade_state[winner].home;
    if (_home !== myRole.home) return;

    if (e.target.dataset.type !== "unit") return;
    if (e.target.dataset.house !== _home) return;

    if (selected_units.size) {
      this.prepareGui();
      return;
    }

    const candidateUnit = candidatesPawns.find((u) => u.id === e.target.id);
    if (!candidateUnit) return;
    const land_name = e.target.dataset.source;

    store.commit("game_store/setMasteringState", { land_name, units: [candidateUnit] });
    store.commit("gui_store/setSelectedUnits", { unit: candidateUnit });

    document.addEventListener("mousedown", this.bindedHandler, { once: true });
    return;
  }
}
