<template>
  <div id="modal_base" class="modal_base" @pointerdown="closeModal">
    <div class="modal_wr">
      <div v-if="response_code" class="response">
        <div class="status">
          <div v-if="response_code?.code?.length === 4" class="res_ok">
            <span class="material-icons"> verified </span>
            <div class="response_string">
              {{ $t("error.0111", { email: response_code?.data?.email }) }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <form @submit.prevent>
          <div class="room">
            <div class="legacy_form">
              <div class="inner_mini_form_wr">
                <!-- <label for="email-field"><strong>Enter new Email</strong></label> -->
                <br />
                <input
                  id="email-field"
                  v-model="email"
                  required
                  type="text"
                  autocomplete="email"
                  min="5"
                  max="30"
                  :placeholder="$t('message.new_email')"
                  @input="inputFn"
                />
                <div class="btn" :class="{ enable_btn: requestEnable }" @pointerdown="sendRequest">
                  {{ $t("message.send_code") }}
                </div>
              </div>

              <!-- <label for="email-conf-field"><strong>Enter the code that was sent to the new Email</strong></label> -->
              <br />
              <input
                id="email-conf-field"
                v-model="email_confirm"
                required
                type="text"
                autocomplete="confirmation code"
                min="5"
                max="20"
                :placeholder="$t('message.code')"
                @input="inputFn"
              />
              <div class="btn" :class="{ enable_btn: creationEnable }" @pointerdown="changeEmail">
                {{ $t("message.confirm") }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      response_code: null,
      response_apply: null,
      creationEnable: false,
      requestEnable: false,
      email: "",
      email_confirm: "",
      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
    };
  },

  computed: {
    ...mapGetters("general_store", ["user"]),
  },

  methods: {
    ...mapMutations("general_store", ["processModal", "showError"]),
    closeModal(e) {
      if (e.target.id !== "modal_base") return;
      this.resetForm();
      this.response_code = null;
      this.response_apply = null;
      this.processModal({ name: "new_email", type: "close" });
    },
    resetForm() {
      this.email = "";
      this.email_confirm = "";
      this.creationEnable = false;
      this.requestEnable = false;
    },

    inputFn(e) {
      if (e.target.id === "email-field") {
        this.response_code = null;
      }
      if (e.target.id === "email-conf-field") {
        this.response_apply = null;
      }

      if (this.email.length < 5) {
        this.creationEnable = false;
        this.requestEnable = false;
        return;
      }
      this.requestEnable = true;

      if (this.email_confirm.length < 5) {
        this.creationEnable = false;
        return;
      }

      this.creationEnable = true;
    },

    async sendRequest() {
      this.email = this.email.trim().toLowerCase();
      if (!this.pattern.test(this.email)) {
        alert("email is invalid");
        return;
      }
      const response = await this.axios.post("/app/change_email?request=true", {
        user_id: this.user.id,
        email: this.email,
      });

      this.response_code = response.data.error;
      if (this.response_code.code.length === 3) {
        this.showError({
          error: {
            code: this.response_code.code,
            data: this.response_code.data,
          },
        });
        this.processModal({
          name: "new_email",
          type: "close",
        });
      }
    },

    async changeEmail(e) {
      if (!e.target.classList.contains("enable_btn")) return;

      this.email = this.email.trim().toLowerCase();
      if (!this.pattern.test(this.email)) {
        alert("email is invalid");
        return;
      }

      this.response = await this.axios
        .post("/app/change_email", {
          user_id: this.user.id,
          email: this.user.email.value,
          code: this.email_confirm,
        })
        .catch((err) => {
          this.showError({
            error: { code: "000", message: err.message },
          });
        });

      this.resetForm();
      this.processModal({
        name: "new_email",
        type: "close",
      });
      this.showError({
        error: this.response.data.error,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_base {
  pointer-events: all;
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(2, 2, 2, 0.43);
  z-index: 21;
  top: 0;
  left: 0;
}

.modal_wr {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  padding: 2rem;
  transform: translate(-50%, -50%);

  width: auto;
  height: auto;
  max-width: 85dvw;
  max-height: 90dvh;
  color: white;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  border-radius: 0.4rem;
  background-color: rgb(22, 22, 22);
  -webkit-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  -moz-box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
  box-shadow: -1px -4px 97px 31px rgb(101, 171, 96);
}

.status {
  text-align: center;
}

.response_string {
  user-select: text;
}

.legacy_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    margin-bottom: 1rem;
    height: 2rem;
    min-width: 80%;
    background-color: rgb(134, 184, 185);
    color: rgb(22, 67, 68);
    font-size: 1rem;
    font-weight: bold;
    border-radius: 0.4rem;
  }
  input::placeholder {
    font-size: 0.8rem;
    white-space: pre-line;
    text-align: center;
    color: black;
  }
  .btn {
    width: auto;
    color: rgb(143, 145, 145);
    background-color: rgb(105, 112, 115);
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 0.4rem;
    cursor: not-allowed;
  }

  .enable_btn {
    cursor: pointer;
    color: rgb(213 255 254);
    background-color: rgb(87 124 140);
  }
}

.inner_mini_form_wr {
  // margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.room {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
</style>
