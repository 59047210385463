<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="money_log_container">
      <div class="nav_bar">
        <div :class="{ tab: true, active: active_tab === 'overal_log' }" @click="active_tab = 'overal_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_user_history' })"
            @mouseleave="resetHint"
          >
            format_list_numbered
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'earn_log' }" @click="active_tab = 'earn_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_earn_log' })"
            @mouseleave="resetHint"
          >
            emoji_events
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'donate_log' }" @click="active_tab = 'donate_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_donate_log' })"
            @mouseleave="resetHint"
          >
            bloodtype
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'spend_log' }" @click="active_tab = 'spend_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_spend_log' })"
            @mouseleave="resetHint"
          >
            shopping_cart
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'send_log' }" @click="active_tab = 'send_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_send_log' })"
            @mouseleave="resetHint"
          >
            send
          </span>
        </div>
      </div>
      <div class="charts_wr">
        <div class="tables">
          <div class="wrr">
            <div v-for="log in log_stats" :key="log" v-show="active_tab === log" class="wr">
              <table v-if="getTableData(log).length">
                <tr>
                  <th class="small_cell">#</th>
                  <th v-if="log === 'send_log'" class="small_cell">{{ $t("message.game_type") }}</th>
                  <th class="date_cell">{{ $t("message.date") }}</th>
                  <th class="small_cell">{{ $t("message.game_type") }}</th>
                  <th>{{ $t("message.money_type") }}</th>
                  <th v-if="log === 'send_log'">{{ $t("message.money_sender") }}</th>
                  <th v-if="log === 'send_log'">{{ $t("message.money_target") }}</th>
                  <th class="small_cell">{{ $t("message.tokens_count") }}</th>
                  <th class="small_cell">{{ $t("message.money_total") }}</th>
                </tr>
                <tr v-for="(log_item, idx) in getTableData(log)" :key="log_item">
                  <td>{{ idx + 1 }}</td>
                  <td v-if="log === 'send_log' && !getLogType(log_item.title)">
                    <span class="material-icons red_arrow"> south </span>
                  </td>
                  <td v-else-if="log === 'send_log' && getLogType(log_item.title)">
                    <span class="material-icons green_arrow"> north </span>
                  </td>
                  <td class="small_cell">{{ getDate(log_item.time) }}</td>
                  <td v-if="!log_item.transfer_direction">
                    <span class="material-icons red_arrow"> south </span>
                  </td>
                  <td v-else><span class="material-icons green_arrow"> north </span></td>
                  <td>{{ $t(`message.${log_item.title}`) }}</td>
                  <td v-if="log === 'send_log'">{{ getUserName(log_item.sender) }}</td>
                  <td v-if="log === 'send_log'">{{ getUserName(log_item.target) }}</td>
                  <td class="yellow">{{ log_item.tokens_count }}</td>
                  <td class="green">{{ log_item.user_total_money || "0" }}</td>
                </tr>
              </table>
              <div v-else class="empty">---</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
      active_tab: "overal_log",
      log_stats: ["overal_log", "earn_log", "donate_log", "spend_log", "send_log"],
      options: {
        borderRadius: 3,
        scales: {
          y: {
            stacked: true,
            grid: {
              //  display: false,
            },
            ticks: {
              // display: false,
            },
          },
          x: {
            grid: {
              // display: false,
            },
            ticks: {
              // display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },

        responsive: true,
        animation: false,
      },
    };
  },

  computed: {
    ...mapState("general_store", ["data_for_modal", "users"]),
    ...mapGetters("general_store", ["mainRoom", "user"]),

    processed_user() {
      return this.data_for_modal.processed_user;
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel"]),
    ...mapMutations("general_store", ["processModal"]),

    getLogType(log_title) {
      if (log_title.includes("income")) {
        return true;
      }
      return false;
    },
    getUserName(uid) {
      const user = this.users.find((u) => u.id === uid);
      return user?.name;
    },

    close(e) {
      if (e.target.id !== "modal_base") return;
      this.processModal({ name: "money_log", type: "close" });
    },

    getDate(ms) {
      const date = new Date(ms);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formattedDate = date.toLocaleString(undefined, options).replace(",", "");
      const [datePart, timePart] = formattedDate.split(" ");
      const [month, day, year] = datePart.split("/");
      const reorderedDate = `${day}.${month}.${year}, ${timePart}`;
      return reorderedDate;
    },

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    getTableData(key) {
      if (key === "overal_log") {
        const logs = ["earn_log", "send_log", "donate_log", "spend_log"];
        const res = logs.reduce((main_acc, log_key) => {
          main_acc = main_acc.concat(this.processed_user.money_logs[log_key]);

          return main_acc;
        }, []);
        res.sort((a, b) => b.time - a.time);
        return res;
      }
      const arr = this.processed_user.money_logs[key];
      arr.sort((a, b) => b.time - a.time);
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal_base {
  position: relative;
  z-index: 22;
  background-color: rgba(0, 0, 0, 0.49);
  width: 100dvw;
  height: 100dvh;
  pointer-events: all;
}
.money_log_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 2rem;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  border-radius: 0.4rem;
  max-width: 85dvw;
  max-height: 90dvh;
  justify-content: flex-start;
  background-color: rgb(22, 22, 22);

  -webkit-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
  -moz-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
  box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
}

@media (max-height: 400px) {
  .money_log_container {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}
@media (max-width: 400px) {
  .money_log_container {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
@media (max-width: 900px) {
  .money_log_container {
    width: 85dvw;
    height: 90dvh;
  }
}

.charts_wr {
  height: 600px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tables {
  height: 90%;
  position: relative;
}

.wrr {
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.wr {
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.empty {
  color: white;
  font-size: 2rem;
  text-align: center;
  // min-width: 80dvw;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 1;
}

table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

th {
  color: rgb(199, 193, 237);
  padding: 0.6rem;
  position: sticky;
  z-index: 2;
  background: rgb(22, 69, 108);
  top: 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-right: 2px solid;
  text-align: center;
}

td {
  font-size: 1rem;
  position: relative;
  color: white;
  text-align: center;
  vertical-align: center;
  padding: 0.6rem;
  border-bottom: 2px solid rgba(255, 255, 255, 0.625);
  border-right: 2px solid rgba(255, 255, 255, 0.625);
}

.nav_bar {
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: white;
}

.tab {
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: grey;
  transition: top 0.35s ease-in, background-color 0.35s ease-in;
  top: 0;
}

.red_arrow {
  color: red;
}
.green_arrow {
  color: green;
}
.yellow {
  color: yellow;
}
.green {
  color: rgb(73, 190, 73);
}

.date_cell {
  width: auto;
  max-width: 140px;
  min-width: 100px;
}
.small_cell {
  width: auto;
  max-width: 50px;
}

table th:first-child,
table td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 2px solid rgba(255, 255, 255, 0.625);
}
.active {
  // transition: top 0.35s ease-in;

  background-color: rgb(71, 52, 168);
  top: 5px;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 253, 253, 0.863);
}

$delay: 100ms;

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
  position: absolute;
  left: 0;
  top: 0;
}
.fade-enter-active {
  transition: all 0.35s ease;
  transition-delay: 0.35s;
}
.fade-leave-active {
  transition: all 0.35s ease;
}
.mt-enter-from,
.mt-leave-to {
  opacity: 0;
}
.mt-enter-active {
  transition: all 0.35s ease;
  transition-delay: 0.35s;
}
.mt-leave-active {
  transition: all 0.35s ease;
}
</style>
