import store from "../store/index.js";

export default class DefineSupportHandler {
  constructor() {}

  prepareGui() {
    this.initialOrdersPreset();
  }

  initialOrdersPreset() {
    const crusade_state = store.getters["general_store/crusade_state"];
    const lands = store.getters["general_store/lands"];
    const conf_land_obj = lands[crusade_state.conflict_land];

    conf_land_obj.adjacent_lands.forEach((land_name) => {
      const land_obj = lands[land_name];
      if (!land_obj.order.includes("support")) {
        if (land_name === crusade_state.source_land) {
          store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
        }
      } else {
        store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
      }
    });
  }
}
