<template>
  <div class="redactor_container prevent-select">
    <div class="redactor_inner_wr prevent-select">
      <div class="shop">
        <span class="material-icons"> alarm_on </span>
        <div class="timer_wr">
          <span>{{ timebank }}</span>

          <input type="range" v-model="timebank" min="30" max="300" step="10" />
        </div>
        <span class="material-icons"> more_time </span>
        <div class="timer_wr">
          <span>{{ time_to_move }}</span>

          <input type="range" v-model="time_to_move" min="10" max="120" step="10" />
        </div>
      </div>
      <span v-if="westeros_prediction" class="shop temp_shop">
        {{ ($t(`message.westeros`), genStr(game.state.westeros_prediction)) }}</span
      >
      <div class="westeros_cards">
        <div class="shop">
          <img :src="`/images/westeros_cards/westeros_0/westeros_0_back.png`" alt="west_1_icon" />

          <select id="westeros_1" v-model="westeros_0" name="westeros_0">
            <option v-for="phase in av_westeros_0" :key="phase" :value="phase">{{ $t(`phase.${phase}`) }}</option>
          </select>
        </div>
        <div class="shop">
          <img :src="`/images/westeros_cards/westeros_1/westeros_1_back.png`" alt="west_2_icon" />
          <select id="westeros_2" v-model="westeros_1" name="westeros_1">
            <option v-for="phase in av_westeros_1" :key="phase" :value="phase">{{ $t(`phase.${phase}`) }}</option>
          </select>
        </div>
        <div class="shop">
          <img :src="`/images/westeros_cards/westeros_2/westeros_2_back.png`" alt="west_3_icon" />
          <select id="westeros_3" v-model="westeros_2" name="westeros_2">
            <option v-for="phase in av_westeros_2" :key="phase" :value="phase">{{ $t(`phase.${phase}`) }}</option>
          </select>
        </div>
        <div v-if="show_set_prediction" class="shop temp_shop">
          <label for="prediction">{{ $t(`message.set_for_next_round`) }} </label>
          <input id="prediction" v-model="prediction" type="checkbox" name="prediction" />
        </div>
        <div v-if="westeros_prediction.length" class="shop temp_shop">
          <label for="del_prediction">{{ $t(`message.reset_prediction`) }}</label>
          <input id="del_prediction" v-model="del_prediction" type="checkbox" name="del_prediction" />
        </div>
      </div>

      <div class="shop round_wr">
        <img :src="`/images/round_token.png`" alt="round_token_icon" />
        <label for="cur_round">{{ round }}</label>
        <input type="range" v-model="round" min="1" max="10" step="1" name="cur_round" />
      </div>
      <div class="shop">
        <label for="land_nameSel">{{ $t(`message.phase`) }}</label>
        <select id="phaseSel" v-model="phase" name="phaseSel">
          <option v-for="name in av_phases" :key="name" :value="name">{{ $t(`phase.${name}`) }}</option>
        </select>
      </div>
      <div class="shop">
        <label for="land_nameSel">{{ $t(`message.player_idx`) }}</label>
        <label for="land_nameSel">{{ current_player_idx }}</label>
        <input type="range" v-model="current_player_idx" min="1" :max="game.players.length" step="1" />
      </div>
      <div class="shop">
        <img :src="`/images/tokens/wilding_token_track.png`" alt="wildings_force_icon" />
        <label for="wild_range">{{ wildings_force }}</label>
        <input type="range" v-model="wildings_force" min="0" max="12" step="2" name="wild_range" />
      </div>
      <div class="shop">
        <img :src="`/images/logger/Supply.png`" :alt="`supply_icon`" />
        <div v-for="home in av_homes" :key="home" class="supply_wr">
          <img :src="`/images/tokens/${home}_token.png`" :alt="`${home}_token`" />

          <select v-if="supply" id="roundSel" v-model="supply[home]" name="roundSel">
            <option v-for="num in av_supply" :key="num">{{ num }}</option>
          </select>
        </div>
      </div>
      <div class="shop">
        <div class="rand_wr">
          <img :src="`/images/westeros_cards/random_cards/random_back.png`" alt="rand_card_back" />
          <input type="checkbox" v-model="random_cards" />
        </div>
        <div v-if="random_cards" class="rand_wr">
          <img :src="`/images/westeros_cards/random_cards/0_s.png`" alt="rand_card_scull" />
          <input type="checkbox" v-model="scull" />
        </div>
        <div v-if="random_cards" class="rand_wr">
          <span>{{ random_cards_force }}</span>
          <input type="range" v-model="random_cards_force" min="0" max="3" />
        </div>
      </div>

      <div class="shop shop_track">
        <div class="wildings_queue queue">
          <img :src="`/images/tokens/wildings_back.png`" alt="wildings_card_back" />
          <ul ref="av_wilds">
            <li v-for="h in av_wilds" :key="h" data-flag="av_wilds" class="wild_item">{{ $t(`wild_card.${h}`) }}</li>
          </ul>
        </div>

        <div class="track_queue queue">
          <img :src="`/images/logger/ThroneDecision.png`" alt="throne" />
          <ul ref="av_throne">
            <li v-for="h in av_throne" :key="h" data-flag="av_throne" class="wild_item">
              <img :src="`/images/tokens/${h}_token.png`" :alt="h" />
            </li>
          </ul>
        </div>

        <div class="track_queue queue">
          <img :src="`/images/logger/UseSword.png`" alt="sword" />
          <ul ref="av_sword">
            <li v-for="h in av_sword" :key="h" data-flag="av_sword" class="wild_item">
              <img :src="`/images/tokens/${h}_token.png`" :alt="h" />
            </li>
          </ul>
        </div>

        <div class="track_queue queue">
          <img :src="`/images/logger/CrowCheck.png`" alt="crow" />
          <ul ref="av_crow">
            <li v-for="h in av_crow" :key="h" data-flag="av_crow" class="wild_item">
              <img :src="`/images/tokens/${h}_token.png`" :alt="h" />
            </li>
          </ul>
        </div>
      </div>

      <div class="shop save_preset_wr">
        <span class="material-icons cancel_btn" @click="resetComponentData">cancel</span>

        <label for="preset">
          <span class="material-icons save_btn">save</span> <span class="save_btn_label">(Save Preset)</span>
        </label>
        <input id="preset" v-model="save_preset" type="checkbox" name="preset" class="check" />
      </div>
      <div class="btns">
        <div class="main_btn" @click="applyChanges">{{ $t(`message.apply`) }}</div>
        <div class="main_btn" @click="resetToPreset">{{ $t(`message.reset_preset`) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  inject: ["socket"],

  data() {
    return {
      westeros_0: "",
      westeros_1: "",
      westeros_2: "",
      phase: "",
      round: "",
      wildings_force: "",
      random_cards_force: 0,
      current_player_idx: 1,
      timebank: 0,
      time_to_move: 0,

      scull: false,
      random_cards: false,
      save_preset: false,
      prediction: false,
      del_prediction: false,

      supply: {},

      av_homes: null,
      av_throne: null,
      av_sword: null,
      av_crow: null,
      av_wilds: null,

      av_wild_force: [0, 2, 4, 6, 8, 10, 12],
      av_supply: [0, 1, 2, 3, 4, 5, 6],
      keys: ["av_wilds", "av_throne", "av_sword", "av_crow"],
      av_phases: [
        "Mastering",
        "Supply",
        "ThroneOfBlades",
        "Winter",
        "GameOfThrones",
        "WarOfKings",
        "BlackWings",
        "PutToTheSword",
        "WebOfLies",

        "Wildings",
        "StormOfSwords",

        "SeaOfStorms",
        "AutumnRains",
        "FeastOfCrows",

        "Conception",
        "CrowCheck",
        "Raid",
        "Crusade",
        "Power",
      ].sort(),
      av_westeros_0: ["empty", "Mastering", "Supply", "ThroneOfBlades", "Winter", "LastDays"],
      av_westeros_1: ["empty", "GameOfThrones", "WarOfKings", "Winter", "BlackWings", "LastDays"],
      av_westeros_2: ["empty", "PutToTheSword", "Wildings", "AutumnRains", "StormOfSwords", "WebOfLies"],
    };
  },
  computed: {
    ...mapGetters("general_store", ["game", "phase_name", "tracks", "active_players"]),
    ...mapState("gui_store", ["freeze"]),

    westeros_prediction() {
      return this.game.state.westeros_prediction.length ? this.game.state.westeros_prediction : [];
    },
    show_set_prediction() {
      const arr = [this.westeros_0, this.westeros_1, this.westeros_2];
      // if (arr.some((w) => w || w !== "empty")) return true;
      return arr.find((w) => w && w !== "empty");
    },
  },

  mounted() {
    this.initSortables(this.keys);
    if (!this.game) return;
    this.resetComponentData();
  },

  watch: {
    "game.state": {
      handler() {
        if (!this.game) return;
        this.resetComponentData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setFreeze"]),

    resetComponentData() {
      const cur_pl_idx = this.game.state.tracks.throne.findIndex((h) => h === this.active_players[0]);
      this.westeros_0 = this.game.state.westeros_0 || "empty";
      this.westeros_1 = this.game.state.westeros_1 || "empty";
      this.westeros_2 = this.game.state.westeros_2 || "empty";
      this.phase = this.game.state.phase.name;
      this.current_player_idx = cur_pl_idx + 1;
      this.round = this.game.state.phase.round;
      this.wildings_force = this.game.state.tracks.wildings;

      this.timebank = this.game.game_options.timebank;
      this.time_to_move = this.game.game_options.time_to_move;

      this.save_preset = false;
      this.prediction = false;
      this.del_prediction = false;

      this.random_cards = this.game.game_options.allow_random_cards;
      this.random_cards_force = this.game.game_options.random_force;
      this.scull = this.game.game_options.scull;

      this.av_wilds = [...this.game.state.wildings_heep];
      this.av_throne = [...this.game.state.tracks.throne];
      this.av_sword = [...this.game.state.tracks.sword];
      this.av_crow = [...this.game.state.tracks.crow];
      this.av_homes = this.game.players.reduce((arr, player) => {
        arr.push(player.home);
        return arr;
      }, []);

      this.supply = {};
      this.av_homes.forEach((home) => {
        this.supply[`${home}`] = this.tracks.supply.findIndex((arr) => arr.includes(home));
      });
    },

    initSortables(keys) {
      function swapElements(arr, i1, i2) {
        [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
      }
      keys.forEach((key) => {
        const elem = this.$refs[key];
        new Sortable(elem, {
          onEnd: function (evt) {
            const flag = evt.item.dataset.flag;
            const changed_flag = `${flag}_changed`;
            const flag_to_original = `original_${flag}`;

            swapElements(this[flag], evt.oldIndex, evt.newIndex);

            this[changed_flag] = true;
            if (JSON.stringify(this[flag]) === this[flag_to_original]) {
              this[changed_flag] = false;
            }
          }.bind(this),
        });
      });
    },

    genStr(arr) {
      const translated_arr = arr.reduce((res, str) => {
        if (str) {
          res.push(this.$t(`phase.${str}`));
        }
        return res;
      }, []);
      return `\n ${translated_arr.join(`\n`)}`;
    },

    applyChanges() {
      if (this.freeze.freeze) return;
      this.socket.emit("client_action", {
        action_type: "RedactorApply",
        data: {
          phase_name: "Redactor",
          data: {
            save_preset: this.save_preset,
            room_id: this.game.id,
            round: this.round,
            phase_name: this.phase,
            prediction: this.prediction,
            del_prediction: this.del_prediction,
            randomPhases: [this.westeros_0, this.westeros_1, this.westeros_2],
            wild_order: this.av_wilds,
            throne_order: this.av_throne,
            sword_order: this.av_sword,
            crow_order: this.av_crow,
            random_cards: this.random_cards,
            random_cards_force: this.random_cards_force,
            scull: this.scull,
            wildings_force: this.wildings_force,
            supply: this.supply,
            timebank: this.timebank,
            time_to_move: this.time_to_move,
            disable_random_card: !this.random_cards,
            current_player_idx: this.current_player_idx - 1,
          },
        },
      });
      this.setFreeze({ freeze: true, color: "pink" });
    },

    resetToPreset() {
      if (this.freeze.freeze) return;

      this.socket.emit("client_action", {
        action_type: "RedactorApply",
        data: {
          phase_name: "Redactor",
          data: {
            room_id: this.game.id,
            reset: true,
          },
        },
      });
      this.setFreeze({ freeze: true, color: "pink" });
    },
  },
};
</script>
<style lang="scss" scoped>
.redactor_container {
  box-sizing: border-box;

  height: 100%;
  position: absolute;
  overflow: auto;
  user-select: all;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.redactor_container::-webkit-scrollbar {
  display: none;
}
.redactor_inner_wr {
  box-sizing: border-box;
  position: relative;
  padding: 1rem;
  padding-top: 6rem;
  // padding-top: 3rem;
  width: 100%;
  height: auto;
  min-height: 100%;
  // margin-bottom: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  font-size: 1rem;
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  // align-content: center;
  align-items: center;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.redactor_inner_wr::-webkit-scrollbar {
  display: none;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.btns {
  display: flex;
  z-index: 200;
  gap: 0.5rem;
  width: 100%;
}

.main_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  width: 50%;
  height: auto;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 0.4rem;
  color: white;
  background-color: rgba(79, 80, 80, 0.562);
  &:hover {
    color: rgb(35, 35, 35);
    background-color: rgba(217, 221, 221, 0.568);
  }
}

@media screen and (max-width: 400px) {
  .main_btn {
    font-size: 1rem;
  }
}

select {
  background-color: #7750b6d8;
  color: white;
}
.westeros_cards {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.shop {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
  img {
    width: 40px;
  }
  select {
    cursor: pointer;
    height: 20px;
    width: 60%;
    font-size: 12px;
  }
}

.timer_wr {
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
}

.round_wr {
  img {
    height: 40px;
    width: auto;
  }
}

.shop_track {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  // height: 200px;
  img {
    height: 40px;
    width: auto;
  }
}

@media screen and (max-width: 400px) {
  .shop_track {
    gap: 0;
  }
}

.wildings_queue {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.queue {
  display: flex;
  flex-direction: column;

  align-items: center;
  ul {
    list-style-type: none;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.track_queue {
  height: 100%;
}

.temp_shop {
  background-color: rgba(12, 80, 80, 0.888);
  border-radius: 10px;
  padding: 0 30px;
}

.wild_item {
  padding: 2px;
  display: block;
  font-size: 12px;
  color: rgb(4, 180, 255);
  margin: 3px;
  text-align: center;
  font-weight: 600;
  background-color: rgb(81, 83, 83);
  border-radius: 5px;
  &:hover {
    background-color: rgb(176, 176, 176);
    color: black;
    cursor: grab;
  }
}

.main_track {
  padding: 3px;
  font-size: 14px;
}

.supply_wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65px;
  width: 65px;
  gap: 0.1rem;
  img {
    height: 50%;
    width: auto;
  }
}

.save_preset_wr {
  justify-content: center;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: rgb(250, 250, 250);
    font-size: 0.8rem;

    .save_btn {
      &:hover {
        color: red;
      }
    }
    .save_btn_label {
      color: gray;
    }
  }

  .cancel_btn {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}

.check {
  position: relative;
  width: 30px;
}

.check:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 15px;
  background-color: #6f6e6e;
  border-radius: 15px;
}

.check:checked:before {
  background: #4cdc0f;
}

.check:after {
  content: "";
  position: absolute;
  left: 0px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 15px;
  transition: 0.25s;
  border: 2px solid #333;
  box-sizing: border-box;
}

.check:checked:after {
  left: 15px;
  border: 2px solid #4cdc0f;
}

.lannister_background {
  background-color: rgb(136, 18, 18);
  color: rgb(225, 221, 221);
}
.stark_background {
  background-color: rgb(116, 115, 115);
  color: rgb(255, 253, 253);
}
.baratheon_background {
  background-color: rgb(191, 200, 15);
  color: rgb(18, 18, 18);
}
.greyjoy_background {
  background-color: rgb(29, 29, 28);
  color: rgb(226, 223, 223);
}
.tyrell_background {
  background-color: rgb(6, 73, 10);
  color: rgb(240, 236, 236);
}
.martell_background {
  background-color: rgb(167, 108, 6);
  color: rgb(18, 18, 18);
}
</style>
