<template>
  <div class="listUsers_container">
    <div v-for="(u, idx) in visibleUsers" :key="u.id" class="user_card_container" :class="{ myself: user.id === u.id }">
      <div class="user_title_container">
        <span class="user_name"> {{ u.name }}</span>
        <span class="user_num">{{ idx + 1 }}</span>

        <span
          v-if="u.role.value === 'admin'"
          class="admin_hint material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_admin' })"
          @pointerleave="resetHint"
        >
          stars
        </span>
        <span
          v-if="u.role.value === 'admin_helper'"
          class="admin_hint material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_admin_helper' })"
          @pointerleave="resetHint"
        >
          military_tech
        </span>
      </div>

      <div class="user_info_container">
        <div class="avatar">
          <img
            :src="`images/avatars/${u.avatars[0]}`"
            alt="avatar"
            :class="{ offline: !u.online }"
            class="avatar_img"
          />

          <div
            v-if="getAwardsCount(u)"
            class="award-status"
            @pointerenter="hint_label_hover($event, { msg: 'hint_award_count' })"
            @pointerleave="resetHint"
          >
            <img src="/images/awards/award_icon.png" alt="award_icon" />
            <span>{{ getAwardsCount(u) }}</span>
          </div>
        </div>

        <div class="user_info">
          <div class="unser_info_ineer_wr">
            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_win_overal' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: 'rgb(253 248 0)',
                }"
              >
                emoji_events
              </span>
              <span>{{ u.stats.win_overal }}</span>
            </div>
            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_intelligence' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: '#bfbff1',
                }"
              >
                psychology
              </span>
              <span>{{ u.stats.intelligence_overal }}</span>
            </div>
            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_fullgames_overal' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: '#75cf75',
                }"
              >
                verified
              </span>
              <span>{{ u.stats.full_games_count || 0 }}</span>
            </div>

            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_honor' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: 'orange',
                }"
              >
                stars
              </span>
              <span>{{ u.stats.full_games_percent }}</span>
            </div>

            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_hours_overal' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: '#e26464',
                }"
              >
                query_builder
              </span>
              <span>{{ Math.ceil(u.stats.total_hours_count) }}</span>
            </div>
            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_charm' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: '#da1717',
                }"
              >
                favorite
              </span>
              <span>{{ u.stats.charm || 0 }}</span>
            </div>
            <div
              class="info_line"
              @pointerenter="hint_label_hover($event, { msg: 'hint_money' })"
              @pointerleave="resetHint"
            >
              <span
                class="material-icons"
                :style="{
                  color: 'green',
                }"
              >
                currency_bitcoin
              </span>
              <span>{{ u.money.total }}</span>
            </div>
            <div
              class="info_line more"
              @pointerenter="hint_label_hover($event, { msg: 'hint_more' })"
              @pointerleave="resetHint"
              @click="openModal($event, u, 'user_info')"
            >
              <span class="material-icons"> more_horiz </span>
            </div>
          </div>
        </div>
        <div class="awards_container">
          <div v-for="(value, key) in getAwards(u)" :key="key" class="award_wr" @click="awardClick($event, u, key)">
            <img :src="`/images/awards/${key}.png`" alt="award" :class="{ disable_award: !checkAward(key, u) }" />
          </div>
        </div>
      </div>

      <div class="user_bts_container">
        <span
          v-if="u.id !== user.id && u.online"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_invitation' })"
          @pointerleave="resetHint"
          @click="openModal($event, u, 'invitation')"
        >
          notification_important
        </span>

        <span
          v-if="u.id !== user.id"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_send_private_message' })"
          @pointerleave="resetHint"
          @click="openPrivateChatTab($event, u.id)"
        >
          mail
        </span>
        <span
          v-if="u.id !== user.id"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_transfer_money' })"
          @pointerleave="resetHint"
          @click="openModal($event, u, 'transfer')"
        >
          paid
        </span>
        <span
          v-if="showBlackListBtn(u)"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_add_black_list' })"
          @pointerleave="resetHint"
          @click="processList($event, u, 'black_list', 'add')"
        >
          do_not_disturb_on
        </span>
        <span
          v-if="user.id !== u.id && !showBlackListBtn(u)"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_del_black_list' })"
          @pointerleave="resetHint"
          @click="processList($event, u, 'black_list', 'del')"
        >
          do_not_disturb_off
        </span>
        <span
          v-if="showFriendsListBtn(u)"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_add_friend' })"
          @pointerleave="resetHint($event)"
          @click="processList($event, u, 'my_friends', 'add')"
        >
          person_add
        </span>
        <span
          v-if="user.id !== u.id && !showFriendsListBtn(u)"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_del_friend' })"
          @pointerleave="resetHint"
          @click="processList($event, u, 'my_friends', 'del')"
        >
          person_remove
        </span>
        <span v-if="isAdmin" class="material-icons" @click="openModal($event, u, 'user_edit')"> settings </span>
        <span
          v-if="isMainAdmin && u.role?.value !== 'admin'"
          class="material-icons"
          @click="openModal($event, u, 'delete_user_confirm')"
        >
          delete
        </span>
        <span
          v-if="user.role.value === 'admin' && u.id !== user.id"
          class="admin_hint material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_admin_helper' })"
          @pointerleave="resetHint"
          @click="initAdminHelper($event, u.id)"
        >
          military_tech
        </span>

        <span
          v-if="user.id !== u.id && !u.role?.value.includes('admin')"
          class="material-icons"
          @pointerenter="hint_label_hover($event, { msg: 'hint_complaint' })"
          @pointerleave="resetHint"
          @click="openModal($event, u, 'complaint')"
        >
          report
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { genPrivateChatId } from "../utils/utilsFrontend.js";
export default {
  inject: ["socket"],
  props: {
    visibleUsers: {
      type: Array,
    },
  },
  data() {
    return {
      hint_delay: 250,
      clear_hint_delay: null,
      hint_timer_delay: null,
    };
  },

  computed: {
    ...mapGetters("general_store", ["mainRoom", "main_private_chats", "isAdmin", "user", "isMainAdmin"]),

    awards() {
      return this.user.stats.awards || {};
    },
  },

  methods: {
    ...mapMutations("gui_store", ["setHintLabel", "setMainSideTab"]),
    ...mapMutations("general_store", ["setDataForModal", "processModal", "setTempPrivateChat", "selectPrivateChat"]),

    resetHint() {
      clearTimeout(this.hint_timer_delay);
      clearTimeout(this.clear_hint_delay);

      this.clear_hint_delay = null;
      this.hint_timer_delay = null;
      this.setHintLabel({ reset: true });
    },
    hint_label_hover(e, item) {
      if (e.pointerType !== "mouse") return;
      if (!item) return;
      this.resetHint();

      this.hint_timer_delay = setTimeout(() => {
        const coords = e.target.getBoundingClientRect();
        const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`;
        this.setHintLabel({
          msg: item.msg,
          coords: logYpos,
        });

        this.clear_hint_delay = setTimeout(() => {
          this.resetHint();
        }, 2000);
      }, this.hint_delay);
    },

    processList(e, user, list_type, flag) {
      this.socket.emit("client_action", {
        action_type: "ProcessFriendBlackList",
        data: {
          user_id: this.user.id,
          target_id: user?.id,
          type: flag,
          list_type,
        },
      });
    },

    openPrivateChatTab(e, target_user_id) {
      if (target_user_id === this.user.id) return;
      const chat_id = genPrivateChatId(target_user_id, this.user.id);

      let existed_pr_chat = this.main_private_chats.find((ch) => ch.users.includes(target_user_id));
      if (!existed_pr_chat) {
        this.setTempPrivateChat({ room_id: "mainRoom", target_user_id, chat_id });
        existed_pr_chat = this.main_private_chats.find((ch) => ch.users.includes(target_user_id));
      }
      this.selectPrivateChat({ chat_id: existed_pr_chat.id || chat_id, room_id: "mainRoom" });
      this.setMainSideTab({ tab: "privateChatActive" });
    },

    initAdminHelper(e, user_id) {
      this.socket.emit("client_action", {
        action_type: "InitAdminHelper",
        data: {
          user_id,
        },
      });
    },

    openModal(e, user, modal) {
      this.resetHint();
      const data = {
        processed_user: user,
      };
      this.setDataForModal({ data });
      this.processModal({ name: modal });
    },

    awardClick(e, unit, award_key) {
      this.resetHint();
      this.setDataForModal({
        data: { processed_user: unit, award_key },
      });
      this.processModal({ name: "award_hint" });
    },

    showBlackListBtn(user) {
      if (this.user.id === user.id) return false;
      if (this.user.black_list?.includes(user.id)) {
        return false;
      }
      return true;
    },
    showFriendsListBtn(user) {
      if (this.user.id === user.id) return false;
      if (this.user.my_friends?.includes(user.id)) {
        return false;
      }
      return true;
    },

    checkAward(key, user) {
      return this.mainRoom.main_stats.awards[key] === user.id;
    },

    getAwards(user) {
      const copy = { ...user.stats };
      delete copy.total_games_count;
      delete copy.leaved_games_count;
      delete copy.awards_count;

      delete copy.dev_helper;
      delete copy.more;
      copy.earned_count = user.money.earned;
      copy.donate_count = user.money.donate;
      copy.money_count = user.money.total;
      return copy;
    },

    getAwardsCount(user) {
      return Object.values(this.mainRoom.main_stats.awards).reduce((acc, user_id) => {
        if (user_id === user.id) return ++acc;
        return acc;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.listUsers_container {
  display: flex;
  height: 80%;
  flex-direction: column;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 5px;
}

.listUsers_container::-webkit-scrollbar {
  width: 20px;
}

.listUsers_container::-webkit-scrollbar-track {
  background: grey;
  border-left: 8px solid rgb(53, 53, 53);
  border-right: 9px solid rgb(53, 53, 53); /* color of the tracking area */
}

.listUsers_container::-webkit-scrollbar-thumb {
  background: grey; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */

  border-left: 6px solid rgb(53, 53, 53);
  border-right: 7px solid rgb(53, 53, 53);
}

.user_card_container {
  position: relative;
  width: 95%;
  display: grid;
  place-items: center;
  padding: 0.3rem;
  grid-template-rows: auto auto auto;
  background-color: rgb(53, 66, 62);
  border: 1px solid white;
  margin-bottom: 0.5rem;
}
.myself {
  border: none;
  outline: 3px solid rgb(89, 170, 228);
  background-color: rgba(163, 206, 192, 0.456);
}

.user_title_container {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 1rem;

  .user_name {
    font-size: 1.2rem;
  }
  .admin_hint {
    color: orange;
  }
  .user_num {
    position: absolute;
    font-size: 0.7rem;
    z-index: 2;
    border-radius: 0.4rem;
    background-color: rgb(2, 2, 2);
    font-weight: 900;
    padding: 0.1rem 0.3rem;
    justify-content: center;
    display: flex;
    align-items: center;
    top: 5px;
    left: 5px;
    span {
      pointer-events: none;
    }
  }
}

.user_info_container {
  display: grid;
  grid-template-rows: minmax(auto, 10rem);
  grid-template-columns: minmax(auto, 25%) minmax(auto, 30%) minmax(auto, 45%);
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 0.5rem;
  column-gap: 0.5rem;
  .disable_award {
    filter: grayscale(100%);
    opacity: 0.7;
  }

  .avatar {
    .avatar_img {
      box-sizing: border-box;
      // border: 2px solid black;
      border-radius: 0.4rem;
      width: 100%;
      -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
      -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
      box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
    }
    .offline {
      -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
      -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
      box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
    }

    .award-status {
      width: fit-content;
      position: relative;
      span {
        position: absolute;
        left: 11px;
        top: 7px;
        color: red;
        font-size: 1rem;
        font-weight: 600;
        pointer-events: none;
      }
      img {
        pointer-events: none;
        width: 2rem;
      }
    }
  }

  .user_info {
    width: 100%;
    display: flex;
    justify-content: center;
    .unser_info_ineer_wr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      overflow-y: visible;
      height: 100%;
      width: 70%;
      .info_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
        span {
          text-align: center;
          pointer-events: none;
          font-size: 1rem;
        }
      }

      @media (max-width: 500px) {
        .info_line {
          gap: 1rem;
        }
      }

      .more {
        justify-content: center;
        span {
          font-size: 2rem;
        }
      }
    }
  }

  .awards_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.user_bts_container {
  display: flex;
  gap: 0.5rem;
  justify-self: left;
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;

  span {
    font-size: 1.4rem;
    color: black;

    &:hover {
      cursor: pointer;
      color: white;
    }
  }

  @media (max-width: 500px) {
    span {
      font-size: 1.2rem;
    }
  }
}

.awards_container::-webkit-scrollbar {
  width: 0; /* Ширина скролбара */
}

/* Забираємо скролбар */
.awards_container::-webkit-scrollbar-thumb {
  display: none;
}

/* Забираємо трек (доріжку) */
.awards_container::-webkit-scrollbar-track {
  display: none;
}

.award_wr {
  pointer-events: all;
  cursor: pointer;

  img {
    border-radius: 0.2rem;
    pointer-events: none;
    width: 1.9rem;
  }
}
</style>
