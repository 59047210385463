<template>
  <div class="loginpage_container">
    <LayerModalWrapper />
    <form @submit.prevent>
      <div class="legacy_form">
        <input
          id="username-field"
          v-model="username"
          autocomplete="username"
          required
          type="text"
          min="3"
          max="20"
          :placeholder="$t('message.username_email')"
          @input="inputFn"
        />
        <input
          id="pass-field"
          v-model="password"
          required
          type="password"
          autocomplete="password"
          min="5"
          max="20"
          :placeholder="$t('message.password')"
          @input="inputFn"
        />
        <div class="standart_btns_wr">
          <div class="btn enable_btn" @click="restorePassord">
            <span>{{ $t('message.restore_password') }}</span>
          </div>
          <div class="btn" :class="{ disable_btn: !joinGameEnable }" @click="login">
            <span>
              {{ $t('message.log_in') }}
            </span>
          </div>
          <div class="btn enable_btn" @click="createUserOpen">
            <span>
              {{ $t('message.create_account') }}
            </span>
          </div>
        </div>
      </div>

      <GoogleLogin :callback="G_login" />

      <div class="social_bnts">
        <div class="social_btn btn-facebook" @click="F_login">
          <i class="fa fa-facebook mr-1"></i>
        </div>

        <div ref="google_btn_wr" class="social_btn"></div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  import { createInitialUser } from '@/utils/createUser.js'
  import shortid from 'shortid'
  import { decodeCredential } from 'vue3-google-login'

  export default {
    data() {
      return {
        joinGameEnable: false,
        password: '',
        username: '',
        email: '',
        FB: {},
      }
    },
    computed: {
      ...mapGetters('general_store', ['user']),
      ...mapState('general_store', ['error']),
    },

    watch: {
      error(newValue) {
        if (!newValue) return

        this.setFreeze({ reset: true })

        this.processModal({
          name: 'error',
        })
      },
    },

    mounted() {
      if (!window.google) {
        window.google = null
      }
      if (google) {
        google.accounts.id.initialize({
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
          callback: this.G_login,
        })
        google.accounts.id.renderButton(this.$refs.google_btn_wr, {
          theme: 'filled_black',
          type: 'icon',
          locale: 'en',
          size: 'large',
        })
      }
      if (this.error) {
        this.processModal({
          name: 'error',
        })
      }
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'showError', 'logIn', 'processUser']),
      ...mapMutations('gui_store', ['setFreeze']),

      restorePassord() {
        this.processModal({ name: 'reset_password' })
      },
      createUserOpen() {
        this.processModal({ name: 'new_user' })
      },
      G_login(res) {
        const axios = this.axios
        const router = this.$router
        const google_data = decodeCredential(res.credential)

        const img_url = google_data.picture.replaceAll('s96-c', 's192-c')
        const filename = `${shortid.generate()}.jpg`
        const user_id = google_data.sub
        const user_name = google_data.name
        const email = google_data.email

        const user = createInitialUser({ user_id, user_name, filename, email })
        axios
          .post('/app/login_social', { user, img_url })
          .then((res) => {
            const { auth, user: _user, sid } = res.data
            if (auth) {
              this.logIn({
                sid,
                user_id: _user.id,
              })
              router.push('/lobby')
            }
          })
          .catch((e) => {
            console.error('google login error: ', e)
            const { code = '000', message = '' } = e.response.data?.error
            this.showError({ error: { code, message } })
          })
      },

      resetForm() {
        this.password = ''
        this.username = ''
        this.joinGameEnable = false
      },

      inputFn() {
        if (this.username.length < 3 || this.username.length > 30) {
          this.joinGameEnable = false
          return
        }
        if (this.password.length < 5 || this.password.length > 20) {
          this.joinGameEnable = false
          return
        }

        this.joinGameEnable = true
      },

      async login(e) {
        if (e.target.classList.contains('disable_btn')) return
        const logPass = {
          name: this.username,
          password: this.password,
        }
        try {
          const { data } = await this.axios.post('/app/login', {
            user: logPass,
          })
          const { auth, user, sid, error } = data

          if (auth) {
            this.logIn({
              sid,
              user_id: user.id,
            })
            this.$router.push('/lobby')
          } else {
            this.showError({ error: { code: error.code, data: error.data } })
            this.resetForm()
          }
        } catch (e) {
          console.error('simple login error: ', e)
          const { code = '000', message = '' } = e.response.data?.error
          this.showError({ error: { code, message } })
        }
      },

      async F_login() {
        const axios = this.axios
        const router = this.$router

        const login = (res) => {
          const { auth, user: _user, sid } = res.data
          if (auth) {
            this.logIn({
              sid,
              user_id: _user.id,
            })
            router.push('/lobby')
          }
        }

        FB.login(
          function (res) {
            if (res.status !== 'connected') return
            const access_token = res.authResponse.accessToken
            const user_id = res.authResponse.userID

            try {
              FB.api('/me', (response) => {
                const user_name = response.name
                FB.api(`/me/picture?redirect=false&height=200&width=200`, 'GET', { access_token }, (ress) => {
                  const img_url = ress.data.url
                  const filename = `${shortid.generate()}.jpg`

                  const user = createInitialUser({ user_id, user_name, filename })
                  axios
                    .post('/app/login_social', { user, img_url })
                    .then(login)
                    .catch((err) => {
                      const { code = '000', message = '' } = err.response.data?.error
                      this.showError({ error: { code, message } })
                    })
                })
              })
            } catch (e) {
              console.error('facebook login error: ', e)
              this.showError({ error: { code: '000' } })
            }
          }.bind(this),
          {
            scope: 'public_profile',
            enable_profile_selector: true,
          }
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .loginpage_container {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
  }

  form {
    display: grid;
    justify-items: center;
  }

  .legacy_form {
    height: 100%;
    max-width: 80%;
    display: grid;
    place-items: center;
    grid-template-rows: repeat(3, 4rem);

    input {
      height: 2rem;
      width: 80%;
      max-width: 20rem;
      background-color: rgb(134, 184, 185);
      color: rgb(25, 106, 107);
      font-size: 20px;
      font-weight: bold;
      border-radius: 0.4rem;
    }
    input::placeholder {
      font-size: 0.8rem;
      white-space: pre-line;
      color: rgb(25, 106, 107);
      text-align: center;
    }

    .standart_btns_wr {
      display: flex;
      gap: 1rem;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      padding: 0.5rem;
      box-sizing: border-box;
      color: rgb(213 255 254);
      background-color: rgb(87 124 140);
      border-radius: 0.4rem;
      transition: 0.35s;
      &:hover {
        transform: scale(1.2);
      }

      span {
        text-align: center;
      }
    }

    .disable_btn {
      cursor: not-allowed;
      color: rgb(143, 145, 145);
      background-color: rgb(105, 112, 115);
      &:hover {
        transform: none;
      }
    }
  }

  .social_bnts {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;

    .social_btn {
      transition: 0.35s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .btn-facebook {
    cursor: pointer;
    background: #3b5998;
    color: #fff;
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
