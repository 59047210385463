<template>
  <div>
    <span
      v-for="(land, land_name) in lands"
      :key="land_name"
      class="material-icons admin_settings"
      :class="{ [`${land_name}_admin`]: true, allow_access: !isHistoryModeOn }"
      @click="landAdminClick(land_name)"
    >
      settings
    </span>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("general_store", ["lands", "state", "game", "isHistoryModeOn"]),
  },
  methods: {
    ...mapMutations("general_store", ["processModal", "setDataForModal"]),
    landAdminClick(land_name) {
      if (this.isHistoryModeOn) return;
      this.setDataForModal({ data: { land_name, owner: this.lands[land_name].home, room: this.game } });
      this.processModal({ name: "redactor_land" });
    },
  },
};
</script>
<style lang="scss">
.admin_settings {
  position: absolute;
  min-width: 20px;
  min-height: 20px;

  // background-color: rgb(251, 12, 16);
}
.admin_settings:hover {
  color: rgb(249, 6, 6);
  cursor: not-allowed;
}

.allow_access:hover {
  color: white;
  cursor: pointer;
}

//stark
//stark
//stark

.winterfell_admin {
  top: 661px;
  left: 634px;
}
.winterfell_port_admin {
  top: 439px;
  left: 396px;
}
.white_harbor_admin {
  top: 756px;
  left: 942px;
}
.white_harbor_port_admin {
  top: 1005px;
  left: 898px;
}
.widows_watch_admin {
  top: 838px;
  left: 1108px;
}
.the_shivering_sea_admin {
  top: 747px;
  left: 1415px;
}
.the_narrow_sea_admin {
  top: 1056px;
  left: 1415px;
}
.moat_cailin_admin {
  top: 1148px;
  left: 704px;
}

.bay_of_ice_admin {
  top: 377px;
  left: 117px;
}
.castle_black_admin {
  top: 243px;
  left: 922px;
}
.karhold_admin {
  top: 412px;
  left: 1124px;
}
.the_eyrie_admin {
  top: 1593px;
  left: 1053px;
}

.the_twins_admin {
  top: 1329px;
  left: 689px;
}
.the_fingers_admin {
  top: 1252px;
  left: 974px;
}
.the_mountains_of_the_moon_admin {
  top: 1437px;
  left: 848px;
}
.the_stony_shore_admin {
  top: 755px;
  left: 383px;
}

//baratheon
//baratheon
//baratheon
//baratheon

.shipsrearer_bay_admin {
  top: 2064px;
  left: 1415px;
}
.storms_end_admin {
  top: 2323px;
  left: 1096px;
}
.storms_end_port_admin {
  top: 2212px;
  left: 1158px;
}
.dragonstone_admin {
  top: 1697px;
  left: 1415px;
}
.dragonstone_port_admin {
  top: 1861px;
  left: 1376px;
}
.crackclaw_point_admin {
  top: 1805px;
  left: 1013px;
}
.kings_landing_admin {
  top: 1994px;
  left: 881px;
}
.blackwater_bay_admin {
  top: 1882px;
  left: 1070px;
}
.kingswood_admin {
  top: 2061px;
  left: 1070px;
}

// greyjoy
// greyjoy
// greyjoy
// greyjoy

.greywater_watch_admin {
  top: 1104px;
  left: 536px;
}
.sunset_sea_admin {
  top: 1128px;
  left: 12px;
}
.flints_finger_admin {
  top: 1148px;
  left: 272px;
}
.seagard_admin {
  top: 1290px;
  left: 425px;
}
.ironmans_bay_admin {
  top: 1281px;
  left: 192px;
}
.pyke_admin {
  top: 1365px;
  left: 111px;
}
.pyke_port_admin {
  top: 1303px;
  left: 343px;
}

//lannister
//lannister
//lannister
//lannister

.the_golden_sound_admin {
  top: 1863px;
  left: 44px;
}
.lannisport_admin {
  top: 1637px;
  left: 364px;
}
.lannisport_port_admin {
  top: 1668px;
  left: 273px;
}
.riverrun_admin {
  top: 1580px;
  left: 558px;
}
.harrenhal_admin {
  top: 1700px;
  left: 725px;
}
.stoney_sept_admin {
  top: 1751px;
  left: 542px;
}
.searoad_marches_admin {
  top: 1944px;
  left: 295px;
}
.blackwater_admin {
  top: 1960px;
  left: 593px;
}

//tyrell
//tyrell
//tyrell
//tyrell

.the_reach_admin {
  top: 2192px;
  left: 651px;
}

.highgarden_admin {
  top: 2153px;
  left: 270px;
}
.dornish_marches_admin {
  top: 2309px;
  left: 553px;
}

.three_towers_admin {
  top: 2538px;
  left: 383px;
}
.oldtown_admin {
  top: 2416px;
  left: 282px;
}
.oldtown_port_admin {
  top: 2346px;
  left: 182px;
}
.redwyne_straights_admin {
  top: 2626px;
  left: 100px;
}
.the_arbor_admin {
  top: 2760px;
  left: 77px;
}
.west_summer_sea_admin {
  top: 2874px;
  left: 377px;
}

//martell
//martell
//martell
//martell

.starfall_admin {
  top: 2736px;
  left: 528px;
}
.yronwood_admin {
  top: 2592px;
  left: 672px;
}
.salt_shore_admin {
  top: 2684px;
  left: 890px;
}
.east_summer_sea_admin {
  top: 2888px;
  left: 1035px;
}
.sunspear_admin {
  top: 2575px;
  left: 1094px;
}
.sunspear_port_admin {
  top: 2594px;
  left: 1322px;
}
.sea_of_dorne_admin {
  top: 2479px;
  left: 962px;
}
.princes_pass_admin {
  top: 2433px;
  left: 569px;
}
.the_boneway_admin {
  top: 2344px;
  left: 837px;
}
</style>
