<template>
  <div class="wr">
    <svg xmlns="http://www.w3.org/2000/svg" width="1978" height="2970" viewBox="0 0 1978 2970">
      <path
        v-for="{ id, d } in lands_data"
        :id="id"
        :key="id"
        :ref="getRef(id)"
        :data-source="getSource(id)"
        :data-home="lands?.[id]?.home"
        :d="d"
        :class="{
          [lands?.[id]?.home]: lands?.[id]?.home,
          hint_land: hint_land(id),
          av_land: available_lands.some((al) => al === id),
        }"
      />
    </svg>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import lands_json from "/src/lands.json";

export default {
  setup() {
    const lands_data = lands_json;

    return {
      lands_data,
    };
  },

  computed: {
    ...mapGetters("general_store", ["lands", "state"]),
    ...mapState("gui_store", ["available_lands"]),
    ...mapState("game_store", ["current_area_under_cursor"]),
  },

  methods: {
    getRef(id) {
      if (id.includes("full")) return "";
      return id;
    },
    getSource(id) {
      if (id.includes("full")) {
        const res = id.replace("_full", "");
        return res;
      }
      return id;
    },
    hint_land(land_name) {
      return this.current_area_under_cursor.land_name === land_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.wr {
  position: absolute;
  // margin-top: -20px;
}

.av_land {
  stroke: rgb(0, 214, 252);
  stroke-width: 5px;
}
.hint_land {
  stroke: rgb(0, 0, 0);
  stroke-width: 5px;
}
path {
  fill: rgba(255, 255, 255, 0);
  fill-rule: evenodd;
}

.garrison_land {
  z-index: 100;
}

.martell {
  fill: #eaa31f8f;
}

.tyrell {
  fill: #1a783b7e;
}

.stark {
  fill: #a2a1a18c;
}

.greyjoy {
  fill: #21202056;
}

.baratheon {
  fill: #a9a55ca7;
}

.lannister {
  fill: #c2404083;
}
.neutral_disabled {
  fill: #0b0000ab;
}
</style>
