<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="modal_wr">
      <div class="inner_wr">
        <div class="section_wr">
          <div v-for="(heep, idx_heep) in westeros_cards" :key="idx_heep" class="heep_wr">
            <section v-for="card in heep" :key="card">
              <div v-for="num in card.count || 1" :key="num" class="inner_card_wr">
                <img
                  :src="`/images/westeros_cards/westeros_${idx_heep}/${locale}/${card.title}.png`"
                  :alt="card.title"
                  @click="openCardZoomModal(card.title, `westeros_${idx_heep}`)"
                />
              </div>
            </section>
          </div>
          <div class="heep_wr">
            <div v-for="card in wildings_cards" :key="card.title" class="inner_card_wr">
              <img
                :src="`/images/westeros_cards/wildings/${locale}/${card.title}.png`"
                :alt="card.title"
                @click="openCardZoomModal(card.title, 'wildings')"
              />
            </div>
          </div>

          <div v-for="card in random_cards" :key="card.title" class="heep_wr">
            <div v-for="num in card.count || 1" :key="num" class="random_card_wr">
              <img :src="`/images/westeros_cards/random_cards/${card.title}.png`" :alt="card.title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex'
  export default {
    data() {
      return {
        westeros_cards: [
          [
            { title: 'Mastering', count: 3 },
            { title: 'Supply', count: 3 },
            { title: 'ThroneOfBlades', count: 2 },
            { title: 'Winter' },
            { title: 'LastDays' },
          ],
          [
            { title: 'GameOfThrones', count: 3 },
            { title: 'WarOfKings', count: 3 },
            { title: 'BlackWings', count: 2 },
            { title: 'Winter' },
            { title: 'LastDays' },
          ],
          [
            { title: 'Wildings', count: 3 },
            { title: 'PutToTheSword', count: 2 },
            { title: 'FeastOfCrows' },
            { title: 'SeaOfStorms' },
            { title: 'AutumnRains' },
            { title: 'StormOfSwords' },
            { title: 'WebOfLies' },
          ],
        ],
        wildings_cards: [
          { title: 'Horde' },
          { title: 'MammothRiders' },
          { title: 'KingBeyondTheWall' },
          { title: 'CrowKillers' },
          { title: 'SilenceAtWall' },
          { title: 'PreemptiveRaid' },
          { title: 'MassingMilkwater' },
          { title: 'Rattleshirts' },
          { title: 'Skinchanger' },
        ],
        random_cards: [
          { title: '0_s', count: 2 },
          { title: '0', count: 8 },
          { title: '1_a', count: 4 },
          { title: '1_d', count: 4 },
          { title: '2', count: 4 },
          { title: '3', count: 2 },
        ],
      }
    },

    computed: {
      ...mapGetters('general_store', ['user']),

      locale() {
        return this.user.locale
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'all_cards', type: 'close' })
      },

      openCardZoomModal(title, type) {
        this.setDataForModal({
          data: {
            card: title,
            type,
          },
        })
        this.processModal({
          name: 'card_zoom',
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(2, 2, 2, 0.43);
    z-index: 20;
    top: 0;
    pointer-events: all;
  }
  .modal_wr {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    color: white;
    box-sizing: border-box;
    width: fit-content;
    max-width: 85dvw;
    height: fit-content;
    max-height: 90dvh;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    border-radius: 0.4rem;
    background-color: rgb(22, 22, 22);
    -webkit-box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);
    -moz-box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);
    box-shadow: -1px -4px 97px 31px rgb(75, 225, 239);
  }
  .inner_wr {
    height: auto;
    overflow-y: auto;
    // display: flex;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background: grey;
      border-left: 8px solid rgb(22, 22, 22);
      border-right: 9px solid rgb(22, 22, 22); /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background: grey; /* color of the scroll thumb */
      border-radius: 10px; /* roundness of the scroll thumb */

      border-left: 6px solid rgb(22, 22, 22);
      border-right: 7px solid rgb(22, 22, 22);
    }
  }

  .section_wr {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .heep_wr {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    section {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .inner_card_wr {
    cursor: pointer;
    img {
      width: 150px;
    }
    &:hover {
      outline: 1px solid rgb(196, 171, 171);
      border-radius: 0.4rem;
    }
  }
  .random_card_wr {
    img {
      width: 75px;
    }
  }
</style>
