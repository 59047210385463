import store from "../store/index.js";

export default class RaidHandler {
  constructor() {
    this.bindedMapClickClearHandler = this.mapClickClearHandler.bind(this);
  }

  prepareGui() {
    this.initialOrdersPreset();
  }
  resetGui() {
    store.commit("gui_store/setOrder", { key: "hint_orders", reset: true });
    store.commit("gui_store/setOrder", { key: "active_orders", reset: true });
    store.commit("gui_store/setOrder", { key: "available_orders", reset: true });
    store.commit("game_store/setRaidState", {
      reset: true,
    });
  }

  initialOrdersPreset() {
    const active_players = store.getters["general_store/active_players"];
    const state = store.getters["general_store/state"];

    if (!state) return;

    const home = active_players[0];
    const lands = state.lands;
    const states = state.states;

    const home_state = states.find((st) => st.home === home);
    const occupied_lands = home_state.lands;

    for (let land_name of occupied_lands) {
      const land_obj = lands[land_name];
      if (!land_obj) continue;
      const order = land_obj.order;
      if (!order.includes("raid")) continue;
      store.commit("gui_store/setOrder", { key: "hint_orders", land_name });
    }
  }

  mapClickClearHandler(e) {
    const raidState = store.state.game_store.raid_state;
    const home = store.getters["general_store/home"];
    if (!raidState.source_land) {
      return;
    }
    if (e.target.dataset.type === "order" && e.target.dataset.owner === home) {
      document.addEventListener("click", this.bindedMapClickClearHandler, { once: true });
      return;
    }
    this.resetGui();
    this.prepareGui();
  }

  orderClick(e) {
    const active_players = store.getters["general_store/active_players"];
    const phase_name = store.getters["general_store/phase_name"];
    const state = store.getters["general_store/state"];
    const home = store.getters["general_store/home"];
    const raidState = store.state.game_store.raid_state;
    const nodeTarget = e.target;
    const raid_ready = raidState?.source_land;

    if (!state) return;
    if (!active_players) return;
    if (!active_players.includes(home)) return;
    if (phase_name !== "Raid") return;
    if (!active_players.includes(home)) return;

    const lands = state.lands;
    const orderSource = nodeTarget.dataset.source;
    if (!orderSource) {
      console.error(`Raid Handler Error: nodeTarget doesn't have "source" data-attribute. Can't continue handler.`);
      return;
    }

    if (raid_ready) {
      if (e.target.dataset.ordertype.includes("raid") && nodeTarget.dataset.owner === home) {
        if (raid_ready === orderSource) {
          this.prepareGui();
          return;
        }
        this.prepareGui();

        store.commit("game_store/setRaidState", {
          source_land: orderSource,
          target_land: "",
          target_order: "",
        });

        store.commit("gui_store/setOrder", { key: "active_orders", land_name: orderSource });

        const av_lands = this.getAvEnemyLands(orderSource, lands);

        av_lands.forEach((land_name) => {
          store.commit("gui_store/setOrder", { key: "available_orders", land_name });
        });
        document.addEventListener("click", this.bindedMapClickClearHandler, { once: true });
        return;
      }

      const av_lands = this.getAvEnemyLands(raid_ready, lands);
      if (!av_lands.includes(orderSource)) {
        return;
      }
      const target_land_obj = lands[orderSource];

      if (!target_land_obj) {
        console.error(`Raid Handler Error: land with name "${orderSource}" doesn't exist`);
        return;
      }

      store.commit("game_store/setRaidState", {
        target_land: orderSource,
        target_order: target_land_obj.order,
      });

      store.commit("game_store/setExecTriger", { doit: true });

      document.removeEventListener("click", this.bindedMapClickClearHandler, { once: true });
    }

    if (!raid_ready) {
      if (!e.target.dataset.ordertype.includes("raid")) return;
      if (nodeTarget.dataset.owner !== home) return;
      store.commit("gui_store/setOrder", { key: "active_orders", land_name: orderSource });
      store.commit("game_store/setRaidState", {
        source_land: orderSource,
        target_land: "",
        target_order: "",
      });
      const av_lands = this.getAvEnemyLands(orderSource, lands);
      av_lands.forEach((land_name) => {
        store.commit("gui_store/setOrder", { key: "available_orders", land_name });
      });
      document.addEventListener("click", this.bindedMapClickClearHandler, { once: true });
      return;
    }
  }

  getAvEnemyLands(land_name, lands) {
    if (!lands) {
      console.error(`getAvEnemyLands error: didn't receive second argument named "lands"`);
      return [];
    }
    const home = store.getters["general_store/home"];
    const types = ["support", "support_spec", "power", "power_spec", "raid", "raid_spec"];
    const addTypes = ["support", "support_spec", "power", "power_spec", "raid", "raid_spec", "defence", "defence_spec"];

    const land_obj = lands[land_name];
    if (!land_obj) {
      console.error(`getAvEnemyLands error: land_obj with name "${land_name}" doesn't exist`);
      return [];
    }
    const adjacentLands = land_obj.adjacent_lands;

    return adjacentLands.reduce((arr, landN) => {
      const target_land_obj = lands[landN];
      if (!target_land_obj) return arr;
      const normOrderName = land_obj.order;
      if (target_land_obj.home === home) return arr;

      if (normOrderName.includes("raid_spec")) {
        if (!addTypes.includes(this.normalizeOrderName(target_land_obj.order))) return arr;
      } else {
        if (!types.includes(this.normalizeOrderName(target_land_obj.order))) return arr;
      }
      if (land_obj.sea) {
        arr.push(landN);
        return arr;
      }
      if (land_name.includes("_port")) {
        if (!target_land_obj.sea) return arr;
        arr.push(landN);
        return arr;
      }

      if (target_land_obj.sea || landN.includes("_port")) return arr;

      arr.push(landN);
      return arr;
    }, []);
  }

  normalizeOrderName(orderName) {
    const parts = orderName.split(":");

    return parts[0];
  }
}
